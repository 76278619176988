import React from 'react';
import { FallbackRender } from '@sentry/react';
import { Alert } from 'antd';

// NOTE: There is an antd/es/alert/ErrorBoundary component we could use, but we DIY to integrate with Sentry
const fallbackRender: FallbackRender = ({ error, componentStack }) => (
  <Alert
    type={'error'}
    message={error.message}
    description={
      <pre style={{ fontSize: '0.9em', overflowX: 'auto' }}>
        {componentStack}
      </pre>
    }
  />
);

export default fallbackRender;
