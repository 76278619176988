import { Category } from 'layouts/matrix/category';
import { matchPath, useLocation } from 'react-router-dom';
import { isCommercialTab, isLogisticsTab, isOperationalTab } from 'layouts/matrix/matrix';

// NOTE: We cannot match on optional params, so we need to match on all possible combinations
// open issue : https://github.com/remix-run/react-router/discussions/9862

/***
 * Get the category from the current path
 * @param pathname - the path to check, example location.pathname
 */
export function getMatrixCategory(pathname: string): Category | undefined {
  if (matchPath({ path: '/', end: true }, pathname)) {
    return 'operational';
  }

  // Need to get TAB from levels root, retailer and machine:
  const matchTab1 = matchPath({ path: '/machine/:machineId/:tab', end: false }, pathname);
  const matchTab2 = matchPath({ path: '/retailer/:retailerId/:tab', end: false }, pathname);
  const matchTab3 = matchPath({ path: '/prediction/:predictionId/:tab', end: false }, pathname);
  const matchTab4 = matchPath({ path: '/:tab', end: false }, pathname);

  let tab =
    matchTab1?.params.tab || matchTab2?.params.tab || matchTab3?.params.tab || matchTab4?.params.tab || undefined;

  if (tab?.indexOf('?')) {
    tab = tab.split('?')[0];
  }

  if (isOperationalTab(tab)) {
    return 'operational';
  } else if (isCommercialTab(tab)) {
    return 'commercial';
  } else if (isLogisticsTab(tab)) {
    return 'logistics';
  }

  // Need to match all routes in operational category since it is on the root path:
  // NOTE: When adding new routes to the operational category, make sure to add them here as well
  const matchTicket1 = !!matchPath({ path: '/ticket', end: false }, pathname);
  const matchTicket2 = !!matchPath({ path: '/tickets', end: false }, pathname);
  const matchTicket3 = !!matchPath({ path: '/retailer/:retailerId/tickets/new', end: true }, pathname);
  const matchRetailerNew = !!matchPath({ path: '/retailer/new', end: true }, pathname);
  const matchRetailerOperational = !!matchPath({ path: '/retailer/:retailerId', end: false }, pathname);
  const matchMachineOperational = !!matchPath({ path: '/machine/:machineId', end: false }, pathname);
  const matchPredictionOperational = !!matchPath({ path: '/prediction/:predictionId', end: false }, pathname);

  if (
    matchTicket1 ||
    matchTicket2 ||
    matchTicket3 ||
    matchRetailerNew ||
    matchRetailerOperational ||
    matchMachineOperational ||
    matchPredictionOperational
  ) {
    return 'operational';
  }
  return undefined;
}

export function useMatrixCategory(): Category | undefined {
  const location = useLocation();
  return getMatrixCategory(location.pathname);
}
