import { Link as LinkInternal, LinkProps } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';

const StyledLinkInternal = styled(LinkInternal)`
  text-decoration: ${(props) => props.theme.ant.linkDecoration};
  transition: color 0.3s;
  color: ${(props) => props.theme.ant.colorLink};
  outline: none;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.ant.colorLinkHover};
    text-decoration: ${(props) => props.theme.ant.linkHoverDecoration};
  }

  &:active {
    text-decoration: ${(props) => props.theme.ant.linkFocusDecoration};
    color: ${(props) => props.theme.ant.colorLinkActive};
  }
  &:focus {
    text-decoration: ${(props) => props.theme.ant.linkFocusDecoration};
  }
`;

/***
 * This is a wrapper around react-router-dom's Link component.
 * We don't use the Link component from antd (Typography.Link)
 */
const Link: React.FC<LinkProps> = (props) => {
  return <StyledLinkInternal {...props} />;
};

export default Link;
