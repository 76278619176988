import React, { CSSProperties } from 'react';
import { defineMessages } from 'react-intl';
import Alert, { AlertProps } from 'components/lib/Alert/Alert.tsx';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styled from 'styled-components';

const messages = defineMessages({
  differentSerialNo: {
    id: 'ticket_alert.different_serial_no',
    defaultMessage: 'The ticket is from a different physical machine, {ticketSerialNo}',
  },
  differentMachineInstance: {
    id: 'ticket_alert.different_machine_instance',
    defaultMessage:
      'This ticket is from a different machine entity, but the same physical machine ({serialNo})',
  },
});

export const TicketAlertDifferentSerialNo: React.FC<
  {
    ticketSerialNo: string;
    machineSerialNo: string;
  } & AlertProps
> = ({ ticketSerialNo, machineSerialNo, ...rest }) => {
  const intl = useConnectIntl();
  return (
    <Alert
      showIcon={true}
      type={'warning'}
      message={intl.formatMsg(messages.differentSerialNo, {
        ticketSerialNo,
        machineSerialNo,
      })}
      {...rest}
    />
  );
};

const WarningExclamationCircleFilled = styled(ExclamationCircleFilled)`
  color: ${(props) => props.theme.ant.colorWarning};
`;

export const TicketAlertDifferentSerialNoTooltip: React.FC<{
  ticketSerialNo: string;
  machineSerialNo: string;
  style?: CSSProperties;
}> = ({ ticketSerialNo, machineSerialNo, style }) => {
  const intl = useConnectIntl();
  return (
    <Tooltip
      title={intl.formatMsg(messages.differentSerialNo, {
        ticketSerialNo,
        machineSerialNo,
      })}
    >
      <WarningExclamationCircleFilled style={style} />
    </Tooltip>
  );
};

export const TicketAlertDifferentMachineInstanceTooltip: React.FC<{ serialNo: string }> = ({
  serialNo,
}) => {
  const intl = useConnectIntl();
  return (
    <Tooltip title={intl.formatMsg(messages.differentMachineInstance, { serialNo })}>
      <WarningExclamationCircleFilled />
    </Tooltip>
  );
};

export const TicketAlertCreateDeactivatedMachine: React.FC<AlertProps> = (props) => {
  const intl = useConnectIntl();
  return (
    <Alert
      showIcon={true}
      type={'warning'}
      message={intl.formatMessage({
        id: 'ticket_alert.create_deactivated_machine',
        defaultMessage: 'This will create a ticket for a deactivated machine',
      })}
      {...props}
    />
  );
};
