import { useMemo } from 'react';
import predictionMessages from 'components/prediction/predictionMessages.ts';
import { PredictionItemFragment } from 'generated/types.tsx';
import useConnectIntl from 'i18n/useConnectIntl.ts';

type PredictionResultFormat = 'short' | 'full' | 'id';

const usePredictionResultMessage = (
  predictionCurrent: PredictionItemFragment | undefined,
  type: PredictionResultFormat
) => {
  const intl = useConnectIntl();

  return useMemo(() => {
    if (!predictionCurrent) {
      return '';
    }
    const typeName = predictionCurrent.result.__typename;

    if (type === 'id') {
      if (typeName === 'EmptyChamberPredictionResult') {
        return predictionCurrent.result.predictedProductId.toString();
      }
      if (typeName === 'ProductFoundPredictionResult') {
        return predictionCurrent.result.product.productId.toString();
      }
      if (typeName === 'ProductUnknownPredictionResult') {
        return predictionCurrent.result.predictedProductId.toString();
      }
    } else if (type === 'short') {
      if (typeName === 'EmptyChamberPredictionResult') {
        return intl.formatMsg(predictionMessages.emptyChamberShort);
      }
      if (typeName === 'ProductFoundPredictionResult') {
        return predictionCurrent.result.product.productId.toString();
      }
      if (typeName === 'ProductUnknownPredictionResult') {
        return intl.formatMsg(predictionMessages.unknownShort);
      }
    } else if (type === 'full') {
      if (typeName === 'EmptyChamberPredictionResult') {
        return intl.formatMsg(predictionMessages.emptyChamber);
      }
      if (typeName === 'ProductFoundPredictionResult') {
        return intl.formatMsg(predictionMessages.productFound, {
          productId: predictionCurrent.result.product?.productId,
          productName: predictionCurrent.result.productName,
        });
      }
      if (typeName === 'ProductUnknownPredictionResult') {
        return intl.formatMsg(predictionMessages.unknownProduct);
      }
    }

    return '';
  }, [predictionCurrent, type, intl]);
};

export default usePredictionResultMessage;
