export function getLocalStorageItem<T>(key: string): T | undefined {
  const item = window.localStorage.getItem(key);
  if (item === null) {
    return undefined;
  }
  return JSON.parse(item) as T;
}

export function addLocalStorageItem<T>(key: string, value: T) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

export function removeLocalStorageItem(key: string) {
  window.localStorage.removeItem(key);
}
