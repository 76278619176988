import { BaseOptionType, DefaultOptionType, SelectProps } from 'antd/es/select';
import { Select } from 'antd';
import React from 'react';

/***
 * Thin wrapper around Ant design Select component
 * (in case we need to override stuff globally)
 *
 * Note that options are NOT strongly typed, use children if you need to customize?
 * Example usage with strongly typed enum value in onSelect:
 *
 * function TicketSeveritySelect<T extends SelectValue = TicketSeverities>({
 *   children,
 *   ...props
 * }: SelectProps<T>) {
 *   return <Select<T> {...props}>{children}</Select>;
 * }
 *
 * Example usage with selected value string, and strongly typed Option:
 *
 *
 * @param children
 * @param props
 * @constructor
 */
function GenericSelect<T, TItem extends BaseOptionType | DefaultOptionType = DefaultOptionType>({
  children,
  ...props
}: SelectProps<T, TItem>) {
  return <Select<T, TItem> {...props}>{children}</Select>;
}

// TODO: How to re-export ? issue after upgrading...
// import Test from 'rc-select';
export const { Option, OptGroup } = Select;

export default GenericSelect;
