import { useAppSelector } from 'redux/store';
import { selectCurrentLocale } from 'i18n/i18nSlice';
import { loadAntLocale } from 'i18n/loadAntLocale.ts';
import { useEffect, useState } from 'react';
import { Locale as AntLocale } from 'antd/lib/locale';

function useAntLocale(): { locale: AntLocale | undefined; loading: boolean } {
  const currentLocale = useAppSelector(selectCurrentLocale);
  const [locale, setLocale] = useState<AntLocale | undefined>(undefined);

  useEffect(() => {
    async function loadLocale() {
      const antLocale = await loadAntLocale(currentLocale);
      setLocale(antLocale);
    }

    loadLocale();
  }, [currentLocale]);
  return { locale, loading: locale === undefined };
}

export default useAntLocale;
