import React, { Suspense } from 'react';
import styled from 'styled-components';
import MachineNav from 'layouts/components/MachineNav/MachineNav';
import MainNav from 'layouts/MainLayout/MainNav/MainNav';
import FooterMobile from 'layouts/MainLayout/FooterMobile/FooterMobile';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { useIsInMatrix } from 'layouts/matrix/useIsInMatrix';
import useIsMobile from 'layouts/responsive/useIsMobile';
import * as Sentry from '@sentry/react';
import ScopeDrawerMobile from 'layouts/MainLayout/ScopeDrawerMobile/ScopeDrawerMobile';
import ScrollToTop from 'layouts/components/ScrollToTop/ScrollToTop';
import fallbackRender from 'layouts/components/fallbackRender.tsx';
import Fallback from 'components/lib/Fallback/Fallback.tsx';
import OfflineOverlay from 'layouts/components/OfflineOverlay/OfflineOverlay.tsx';

const Container = styled.div`
  position: relative;
  height: 100%;
  background-color: ${(props) => props.theme.ant.colorBgLayout};
`;

const Content = styled.div<{ $isInMatrix: boolean; $isMobile: boolean }>`
  margin-left: 0; // mobile, no menus on the left

  @media (min-width: 480px) {
    margin-left: ${(props) =>
      props.$isMobile ? 0 : props.$isInMatrix ? 'calc(64px + 256px)' : '64px'};
  }

  @media (max-width: 480px) {
    padding-bottom: 48px;
  }

  z-index: 1;
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  height: 100%;

  overflow-x: hidden;
  overflow-y: auto;

  @media only print {
    overflow: visible;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const MainLayout: React.FC = () => {
  const isMobile = useIsMobile();
  const isInMatrix = useIsInMatrix();

  return (
    <Container>
      <OfflineOverlay />
      <ScrollRestoration />
      {!isMobile && <MainNav />}
      {!isMobile && <MachineNav />}
      <Content $isInMatrix={isInMatrix} $isMobile={isMobile}>
        <ScrollToTop />
        <Sentry.ErrorBoundary showDialog={false} fallback={fallbackRender}>
          <Suspense fallback={<Fallback />}>
            <Outlet />
          </Suspense>
        </Sentry.ErrorBoundary>
      </Content>
      {isMobile && <FooterMobile />}
      {isMobile && <ScopeDrawerMobile />}
    </Container>
  );
};

export default MainLayout;
