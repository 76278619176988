import { allCategories, Category } from 'layouts/matrix/category';
import { allLevels, Level } from 'layouts/matrix/level';

/*************************************************
 * 0101010      T H E    M A T R I X       010101
 * - matrix navigation structure
 * - helpers to parse, check valid routes, etc.
 * **********************************************/
export type AllTabs =
  | 'events'
  | 'health'
  | 'inventory'
  | 'machines'
  | 'price-matrix'
  | 'properties'
  | 'reconciliation'
  | 'refill-order'
  | 'retailers'
  | 'statistics'
  | 'operational'
  | 'transactions'
  | 'tickets'
  | 'users'
  | 'accounting'
  | 'integrations'
  | 'commercial' // commercial dashboard
  | 'ticket'
  | 'logistics'
  | 'predictions'
  | 'prediction'
  | 'products';

type MatrixNavStructure = {
  [categoryKey in Category]: {
    [levelKey in Level]: Array<AllTabs> | undefined; // undefined means that level is not valid for given category
  };
};

// The MATRIX defines which tabs are valid under which category and level.
const matrix: MatrixNavStructure = {
  operational: {
    root: [
      'operational', // <== / (root) becomes operational
      'tickets',
      'events',
      'properties',
      'users',
      'integrations',
      'predictions',
    ],
    retailer: [
      'operational', // <== /retailer/:id becomes operational
      'tickets',
      'events',
      'properties',
      'users',
      'integrations',
      'predictions',
      'products', // <== only valid on retailer level :-S
    ],
    machine: ['operational', 'tickets', 'events', 'properties', 'users', 'integrations', 'predictions'],
    ticket: ['ticket', 'accounting'],
    prediction: ['prediction'],
  },
  commercial: {
    root: ['commercial'],
    retailer: ['commercial', 'price-matrix', 'transactions', 'reconciliation', 'statistics'],
    machine: ['commercial', 'price-matrix', 'transactions', 'reconciliation', 'statistics'],
    ticket: undefined, // tickets are not valid under commercial
    prediction: undefined, // predictions are not valid under commercial
  },
  logistics: {
    root: ['logistics'],
    retailer: ['logistics', 'refill-order'],
    machine: ['logistics', 'refill-order', 'inventory'],
    ticket: undefined, // tickets are not valid under logistics
    prediction: undefined, // predictions are not valid under logistics
  },
};

export const allOperationalTabs = [
  ...(matrix.operational.root || []),
  ...(matrix.operational.retailer || []),
  ...(matrix.operational.machine || []),
  ...(matrix.operational.ticket || []),
  ...(matrix.operational.prediction || []),
] as const;
export type OperationalTab = (typeof allOperationalTabs)[number];

// Type guard to check if given tab is valid operational tab
export function isOperationalTab(tab?: string): tab is OperationalTab {
  if (!tab) return false;
  return allOperationalTabs.some((c) => c === tab);
}

export const allCommercialTabs = [
  ...(matrix.commercial.root || []),
  ...(matrix.commercial.retailer || []),
  ...(matrix.commercial.machine || []),
  ...(matrix.commercial.ticket || []),
] as const;
export type CommercialTab = (typeof allCommercialTabs)[number];
export function isCommercialTab(tab?: string): tab is CommercialTab {
  if (!tab) return false;
  return allCommercialTabs.some((c) => c === tab);
}

export const allLogisticsTabs = [
  ...(matrix.logistics.root || []),
  ...(matrix.logistics.retailer || []),
  ...(matrix.logistics.machine || []),
  ...(matrix.logistics.ticket || []),
] as const;
export type LogisticsTab = (typeof allLogisticsTabs)[number];
export function isLogisticsTab(tab?: string): tab is LogisticsTab {
  if (!tab) return false;
  return allLogisticsTabs.some((c) => c === tab);
}

export const allRootTabs = [
  ...(matrix.operational.root || []),
  ...(matrix.commercial.root || []),
  ...(matrix.logistics.root || []),
] as const;

export type RootTab = (typeof allRootTabs)[number];
export function isRootTab(tab?: string): tab is RootTab {
  if (!tab) return false;
  return allRootTabs.some((c) => c === tab);
}

export const allRetailerTabs = [
  ...(matrix.operational.retailer || []),
  ...(matrix.commercial.retailer || []),
  ...(matrix.logistics.retailer || []),
] as const;
export type RetailerTab = (typeof allRetailerTabs)[number];
export function isRetailerTab(tab?: string): tab is RetailerTab {
  if (!tab) return false;
  return allRetailerTabs.some((c) => c === tab);
}

export const allMachineTabs = [
  ...(matrix.operational.machine || []),
  ...(matrix.commercial.machine || []),
  ...(matrix.logistics.machine || []),
] as const;
export type MachineTab = (typeof allMachineTabs)[number];
export function isMachineTab(tab?: string): tab is MachineTab {
  if (!tab) return false;
  return allMachineTabs.some((c) => c === tab);
}

export const allTicketTabs = [
  ...(matrix.operational.ticket || []),
  ...(matrix.commercial.ticket || []),
  ...(matrix.logistics.ticket || []),
] as const;
export type TicketTab = (typeof allTicketTabs)[number];
export function isTicketTab(tab?: string): tab is TicketTab {
  if (!tab) return false;
  return allTicketTabs.some((c) => c === tab);
}

export function isValidLevelInCategory(category: Category, level?: Level) {
  if (allCategories.indexOf(category) === -1) {
    return false;
  }
  if (level === undefined) {
    return false;
  }
  if (allLevels.indexOf(level) === -1) {
    return false;
  }

  // NOTE: should return false for level ticket and category commercial (write unit test later..)
  return matrix[category][level] !== undefined;
}

export function isValidTabInCategory(
  category: Category | undefined,
  level: Level,
  tab: AllTabs | string | undefined
): boolean {
  if (category === undefined) {
    return false;
  }
  if (allCategories.indexOf(category) === -1) {
    return false;
  }
  if (allLevels.indexOf(level) === -1) {
    return false;
  }
  return !!matrix[category][level]?.find((c) => c === tab);
}
