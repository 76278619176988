import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from 'redux/store.ts';
import { apolloClient } from 'graphql/apollo/apolloClient.ts';
import {
  UpdateMachineNavFiltersDocument,
  UpdateMachineNavFiltersMutation,
  UpdateMachineNavFiltersMutationVariables,
} from 'generated/types.tsx';
import { debounce } from 'lodash-es';

type ExpandedRetailersDictionary = { [retailerId: number]: boolean };

interface MachineNavFilter {
  showRetailersWithoutMachines: boolean;
  showInactiveMachines: boolean;
}

interface MachineNavState {
  searchTerm: string;
  filter: MachineNavFilter;
  isMobileSearchVisible: boolean;
  expandedRetailersNormal: ExpandedRetailersDictionary;
  expandedRetailersFiltered: ExpandedRetailersDictionary;
}

const defaultState: MachineNavState = {
  searchTerm: '',
  filter: {
    showRetailersWithoutMachines: false,
    showInactiveMachines: false,
  },
  isMobileSearchVisible: false,
  expandedRetailersNormal: {},
  expandedRetailersFiltered: {},
};

interface ExpandRetailerPayload {
  retailerId: number;
  useFiltered: boolean;
}

interface CollapseRetailerPayload {
  retailerId: number;
  useFiltered: boolean;
}

export const expandRetailerActionCreator = createAction<ExpandRetailerPayload>(
  'machineNav/expandRetailer'
);
export const collapseRetailerActionCreator = createAction<CollapseRetailerPayload>(
  'machineNav/collapseRetailer'
);

export const expandRetailer = createAsyncThunk<void, { retailerId: number }>(
  'machineNav/expandRetailer',
  async (payload, thunkAPI) => {
    const { retailerId } = payload;
    const state = thunkAPI.getState() as RootState;
    const useFiltered = state.machineNav.searchTerm.length > 0;
    thunkAPI.dispatch(
      expandRetailerActionCreator({
        retailerId,
        useFiltered,
      })
    );
  }
);

export const collapseRetailer = createAsyncThunk<void, { retailerId: number }>(
  'machineNav/collapseRetailer',
  async (payload, thunkAPI) => {
    const { retailerId } = payload;
    const state = thunkAPI.getState() as RootState;
    const useFiltered = state.machineNav.searchTerm.length > 0;
    thunkAPI.dispatch(
      collapseRetailerActionCreator({
        retailerId,
        useFiltered,
      })
    );
  }
);

export const machineNavSlice = createSlice({
  name: 'machineNav',
  initialState: defaultState,
  reducers: {
    closeMobileSearchDrawer(state) {
      state.isMobileSearchVisible = false;
    },
    openMobileSearchDrawer(state) {
      state.isMobileSearchVisible = true;
    },
    setFilter(state, action: PayloadAction<Partial<MachineNavFilter>>) {
      state.filter = {
        ...state.filter,
        ...action.payload,
      };
    },
    setSearchTerm(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
    },
    clearFilteredExpandedRetailers(state) {
      state.expandedRetailersFiltered = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(expandRetailerActionCreator, (state, action) => {
      const { retailerId, useFiltered } = action.payload;
      if (useFiltered) {
        state.expandedRetailersFiltered[retailerId] = true;
      } else {
        state.expandedRetailersNormal[retailerId] = true;
      }
    });
    builder.addCase(collapseRetailerActionCreator, (state, action) => {
      const { retailerId, useFiltered } = action.payload;
      if (useFiltered) {
        delete state.expandedRetailersFiltered[retailerId];
      } else {
        delete state.expandedRetailersNormal[retailerId];
      }
    });
  },
});

interface FilterArg {
  filter: Partial<MachineNavFilter>;
  store: boolean;
}

const debouncedSaveFilters = debounce(async (newFilters: MachineNavFilter) => {
  try {
    await apolloClient.mutate<
      UpdateMachineNavFiltersMutation,
      UpdateMachineNavFiltersMutationVariables
    >({
      mutation: UpdateMachineNavFiltersDocument,
      variables: {
        input: {
          filters: {
            showActiveMachines: newFilters.showInactiveMachines,
            showInactiveRetailers: newFilters.showRetailersWithoutMachines,
          },
        },
      },
    });
  } catch (err) {
    console.error('Failed to save users machine nav filters', err);
  }
}, 10000);

export const setMachineNavFilter = createAsyncThunk<void, FilterArg>(
  'machineNav/setMachineNavFilter',
  async (arg, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const currentFiltersFromState = rootState.machineNav.filter;

    const newFilters = {
      ...currentFiltersFromState,
      ...arg.filter,
    };

    // Dispatch action to update filters in redux state immediately
    thunkAPI.dispatch(machineNavSlice.actions.setFilter(newFilters));

    if (arg.store) {
      debouncedSaveFilters(newFilters);
    }
  }
);

export const setMachineNavSearchTerm = createAsyncThunk<void, string>(
  'machineNav/setMachineNavSearchTerm',
  async (searchTerm, thunkAPI) => {
    const currentSearchTerm = (thunkAPI.getState() as RootState).machineNav.searchTerm;
    if (currentSearchTerm !== searchTerm) {
      console.log('Clearing filtered expanded retailers');
      thunkAPI.dispatch(machineNavSlice.actions.clearFilteredExpandedRetailers());
    }

    console.log('Setting search term to:', searchTerm);
    thunkAPI.dispatch(machineNavSlice.actions.setSearchTerm(searchTerm));
  }
);

export const machineNavSliceReducer = machineNavSlice.reducer;
const machineNavActions = machineNavSlice.actions;
export const { closeMobileSearchDrawer, openMobileSearchDrawer } = machineNavActions;

export const selectMachineNavFilter = (state: RootState) => state.machineNav.filter;

export const selectSearchTerm = (state: RootState) => state.machineNav.searchTerm;
export const selectIsMobileSearchVisible = (state: RootState) =>
  state.machineNav.isMobileSearchVisible;

const selectExpandedRetailersNormal = (state: RootState) =>
  state.machineNav.expandedRetailersNormal;
const selectExpandedRetailersFiltered = (state: RootState) =>
  state.machineNav.expandedRetailersFiltered;
const selectHasSearchTerm = createSelector(
  [selectSearchTerm],
  (searchTerm) => searchTerm.length > 0
);

export const selectExpandedRetailers = createSelector(
  [selectExpandedRetailersNormal, selectExpandedRetailersFiltered, selectHasSearchTerm],
  (expandedRetailersNormal, expandedRetailersFiltered, hasSearchTerm) => {
    return hasSearchTerm ? expandedRetailersFiltered : expandedRetailersNormal;
  }
);
