import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form, FormProps, Input, Space } from 'antd';
import { gql } from '@apollo/client/core';
import {
  CreateTicketInput,
  MachineListItemFragment,
  MachineType,
  TicketFullFragmentDoc,
  TicketSeverity,
  TicketTypeFullFragment,
  useCreateTicketMutation,
  useGetMachineListItemLazyQuery,
  useGetMachineListItemQuery,
} from 'generated/types';
import { useForm } from 'antd/es/form/Form';
import { FormattedMessage } from 'react-intl';
import RetailerSelect from 'components/retailer/RetailerSelect/RetailerSelect';
import TicketTagsSelect from 'components/ticket/TicketTagsSelect/TicketTagsSelect';
import TicketSeveritySelect from 'components/ticket/TicketSeverity/TicketSeveritySelect';
import UserSelect from 'components/user/UserSelect/UserSelect';
import { ClearOutlined, CloseOutlined, SaveOutlined } from '@ant-design/icons';
import MachineSelect from 'components/machine/MachineSelect/MachineSelect';
import {
  addLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} from 'common/localStorage';
import { RemirrorJSON } from 'remirror';
import useMatrixNav from 'layouts/matrix/useMatrixNav';
import { VendanorEditorDef } from 'components/lib/ConnectEditor/vendanorEditorDef';
import { useNavigate } from 'react-router-dom';
import TicketTypeRadio from 'components/ticket/TicketType/TicketTypeRadio';
import useMessageApi from 'components/global/useMessageApi';
import commonMessages from 'components/i18n/commonMessages.ts';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import ConnectEditor from 'components/lib/ConnectEditor/ConnectEditor.tsx';
import ResponsiveListCard from 'components/lib/List/ResponsiveListCard.tsx';
import { TicketAlertCreateDeactivatedMachine } from 'components/ticket/TicketList/TicketAlert.tsx';
import {
  KEY_DRAFT_CREATE_TICKET_OPENING_COMMENT,
  KEY_DRAFT_CREATE_TICKET_FORM,
} from 'auth/constants.ts';
import { RecursivePartial, useAutoSaveForm } from 'components/lib/Form/useAutoSaveForm.ts';
import { OnAutoSaveCallback } from 'components/lib/ConnectEditor/AutoSave.tsx';

gql`
  mutation CreateTicket($input: CreateTicketInput!) {
    createTicket(input: $input) {
      ticket {
        ...TicketFull
      }
    }
  }
  ${TicketFullFragmentDoc}
`;

type CreateForm = CreateTicketInput;

function TypedCreateForm<T extends object = CreateForm>({
  children,
  ...props
}: FormProps<T> & { children?: React.ReactNode }) {
  return <Form<T> {...props}>{children}</Form>;
}

interface CreateTicketCommentDraft {
  json: RemirrorJSON;
  saved: Date;
}

const CreateTicketForm: React.FC = () => {
  const { retailerId, machineId, resolving, getUrlToTicket, getUrlToTicketList } = useMatrixNav();
  const navigate = useNavigate();
  const [createTicket, { loading }] = useCreateTicketMutation();
  const [createForm] = useForm<CreateForm>();
  const intl = useConnectIntl();
  const [selectedRetailerId, setSelectedRetailerId] = useState(retailerId);
  const editorRef = useRef<VendanorEditorDef | undefined>();

  // TODO: restore initial values from local storage if any drafts
  const initialValues: RecursivePartial<CreateForm> = {
    machineId,
    retailerId,
    tags: [],
    severity: TicketSeverity.D,
    ticketTypeId: 5,
  };

  const [getMachine] = useGetMachineListItemLazyQuery();
  const [selectedMachineType, setSelectedSelectedMachineType] = useState<MachineType | undefined>(
    undefined
  );
  const [selectedMachine, setSelectedMachine] = useState<MachineListItemFragment | undefined>(
    undefined
  );

  const { data } = useGetMachineListItemQuery({
    skip: !machineId,
    variables: machineId
      ? {
          id: machineId,
        }
      : undefined,
  });

  const showWarning = useMemo(() => {
    if (selectedMachine) {
      return !selectedMachine.active;
    }

    if (!data) {
      return false;
    }

    return !data.machine.active;
  }, [data, selectedMachine]);

  const message = useMessageApi();

  const includeInactiveMachineInstances = useMemo(() => {
    return data?.machine.active === false;
  }, [data]);

  const handleChangeRetailer = (value: number) => {
    createForm.setFieldsValue({
      retailerId: value,
      machineId: undefined,
    });
    setSelectedRetailerId(value);
    setSelectedSelectedMachineType(undefined);
  };

  const handleChangeMachine = async (value: number) => {
    createForm.setFieldsValue({
      machineId: value,
    });

    const machine = await getMachine({
      variables: {
        id: value,
      },
    });

    if (machine.data) {
      createForm.setFieldsValue({
        retailerId: machine.data.machine.retailerIdRaw,
      });

      if (selectedMachineType !== machine.data.machine.machineType) {
        createForm.setFieldsValue({
          ticketTypeId: 5, // remote. TODO: let backend decide this...
        });
      }
      setSelectedSelectedMachineType(machine.data.machine.machineType);
      setSelectedMachine(machine.data.machine);
    }
  };

  const handleCancel = () => {
    navigate(getUrlToTicketList(retailerId, machineId));
  };

  const handleFailedSubmit = async () => {
    message.error({
      content: intl.formatMessage({
        id: 'create_ticket_form.error_submit',
        defaultMessage: 'Invalid form 😔',
      }),
    });
  };

  const handleSubmit = async (values: CreateForm) => {
    try {
      message.loading({
        key: 'create_ticket',
        content: intl.formatMessage({
          id: 'create_ticket_form.creating',
          defaultMessage: 'Creating ticket...',
        }),
      });

      if (editorRef.current === undefined) {
        throw new Error('could not get data from editor');
      }

      // We need to fetch current editor state, cannot use data binding:
      const state = editorRef.current.getState();
      const html = editorRef.current.helpers.getHTML(state);

      const result = await createTicket({
        variables: {
          input: {
            ...values,
            contentHtml: html,
          },
        },
        notifyOnNetworkStatusChange: true,
      });

      message.success({
        key: 'create_ticket',
        content: intl.formatMessage(
          {
            id: 'create_ticket_form.success',
            defaultMessage: 'Ticket #{id} created 🚀',
          },
          {
            id: result.data?.createTicket?.ticket?.ticketId || '00',
          }
        ),
      });

      removeLocalStorageItem(KEY_DRAFT_CREATE_TICKET_OPENING_COMMENT);
      clearFormDraft();

      if (result.data?.createTicket?.ticket?.id) {
        navigate(getUrlToTicket(result.data.createTicket.ticket.ticketId));
      }
    } catch (err: unknown) {
      message.error({
        key: 'create_ticket',
        content: intl.formatMessage({
          id: 'create_ticket_form.error',
          defaultMessage: 'Could not create ticket 😞',
        }),
      });
    }
  };

  const [titleRequired, setTitleRequired] = useState(true);
  const handleChangeType = (ticketType: TicketTypeFullFragment) => {
    setTitleRequired(ticketType.titleRequired);
  };

  // === AUTOSAVE ===

  // AUTOSAVE - Opening comment SAVE
  const handleAutoSaveOpeningComment = useCallback<OnAutoSaveCallback>(({ isEmpty, document }) => {
    if (isEmpty) {
      removeLocalStorageItem(KEY_DRAFT_CREATE_TICKET_OPENING_COMMENT);
    } else if (document) {
      addLocalStorageItem<CreateTicketCommentDraft>(KEY_DRAFT_CREATE_TICKET_OPENING_COMMENT, {
        saved: new Date(),
        json: document.remirrorJson,
      });
    }
  }, []);

  // AUTOSAVE - Opening comment RESTORE
  const restoreOpeningComment = useCallback(() => {
    const openingCommentDraft = getLocalStorageItem<CreateTicketCommentDraft>(
      KEY_DRAFT_CREATE_TICKET_OPENING_COMMENT
    );
    if (openingCommentDraft) {
      editorRef.current?.setContent(openingCommentDraft.json, { triggerChange: true });
    }
  }, []);
  useEffect(() => {
    restoreOpeningComment();
  }, [restoreOpeningComment]);

  // AUTOSAVE - reusable hook for ANTD FORMS
  const { handleFormValuesChange, handleFormBlur, restoreFormDraft, clearFormDraft } =
    useAutoSaveForm<CreateForm>({
      key: KEY_DRAFT_CREATE_TICKET_FORM,
    });

  // AUTOSAVE - form RESTORE
  const formRef = useRef(createForm);
  useEffect(() => {
    const formDraft = restoreFormDraft();
    if (formDraft) {
      // NOTE: We don't restore retailerId and machineId. We only store one ticket draft regardless of where it was created!
      const { retailerId, machineId, ...rest } = formDraft;
      formRef.current.setFieldsValue(rest);
    }
  }, [restoreFormDraft]);

  const handleReset = () => {
    clearFormDraft();
    removeLocalStorageItem(KEY_DRAFT_CREATE_TICKET_OPENING_COMMENT);
    createForm.resetFields();
    editorRef.current?.clearContent({ triggerChange: true });
  };

  return (
    <>
      {showWarning && <TicketAlertCreateDeactivatedMachine style={{ marginBottom: 16 }} />}
      <ResponsiveListCard>
        {!resolving && (
          <TypedCreateForm
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            form={createForm}
            name={'createForm'}
            initialValues={initialValues}
            onFinish={handleSubmit}
            onFinishFailed={handleFailedSubmit}
            onValuesChange={handleFormValuesChange}
            onBlur={handleFormBlur}
          >
            <Form.Item name={'retailerId'} label={'Retailer'} rules={[{ required: true }]}>
              <RetailerSelect onChange={handleChangeRetailer} />
            </Form.Item>
            <Form.Item name={'machineId'} label={'Machine'} rules={[{ required: true }]}>
              <MachineSelect
                retailerId={selectedRetailerId}
                onChange={handleChangeMachine}
                includeInactiveMachineInstances={includeInactiveMachineInstances}
              />
            </Form.Item>
            <Form.Item name={'title'} label={'Title'} rules={[{ required: titleRequired }]}>
              <Input
                style={{ width: '100%' }}
                placeholder={intl.formatMessage({
                  id: 'create_ticket_form.title_placeholder',
                  defaultMessage: 'Title',
                })}
              />
            </Form.Item>
            <Form.Item label={'Description'}>
              <ConnectEditor
                ref={editorRef}
                placeholder={intl.formatMessage({
                  id: 'create_ticket_form.description_placeholder',
                  defaultMessage: 'Leave a comment',
                })}
                onAutoSave={handleAutoSaveOpeningComment}
                editable={true}
              />
            </Form.Item>
            <Form.Item name={'ticketTypeId'} label={'Ticket type'}>
              <TicketTypeRadio
                optionType={'button'}
                machineType={selectedMachineType}
                onChangeTicketType={handleChangeType}
              />
            </Form.Item>
            <Form.Item name={'tags'} label={'Tags'}>
              <TicketTagsSelect />
            </Form.Item>
            <Form.Item name={'severity'} label={'Severity'}>
              <TicketSeveritySelect />
            </Form.Item>
            <Form.Item name={'assignedUserId'} label={'Assignee'}>
              <UserSelect
                placeholder={intl.formatMessage({
                  id: 'create_ticket_form.placeholder_assign_user',
                  defaultMessage: 'Assign a user',
                })}
                onClear={() => {
                  createForm.setFieldsValue({
                    assignedUserId: undefined,
                  });
                }}
              />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
              <Space>
                <Button
                  type={'primary'}
                  icon={<SaveOutlined />}
                  htmlType={'submit'}
                  disabled={loading}
                >
                  <FormattedMessage
                    id={'create_ticket_form.submit'}
                    defaultMessage={'Submit new ticket'}
                    tagName={'span'}
                  />
                </Button>
                <Button icon={<ClearOutlined />} onClick={handleReset}>
                  {intl.formatMsg(commonMessages.reset)}
                </Button>
                <Button icon={<CloseOutlined />} onClick={handleCancel} disabled={loading}>
                  {intl.formatMsg(commonMessages.cancel)}
                </Button>
              </Space>
            </Form.Item>
          </TypedCreateForm>
        )}
      </ResponsiveListCard>
    </>
  );
};

export default CreateTicketForm;
