import { Locale as DateFnsLocale } from 'date-fns';
import { useAppSelector } from 'redux/store';
import { selectCurrentLocale } from 'i18n/i18nSlice';
import { useEffect, useState } from 'react';
import { loadDateFnsLocale } from 'i18n/loadDateFnsLocale.ts';

/***
 * Custom hook to get current date-fns locale
 */
function useDateFnsLocale() {
  const currentLocale = useAppSelector(selectCurrentLocale);
  const [locale, setLocale] = useState<DateFnsLocale | undefined>(undefined);

  useEffect(() => {
    const load = async () => {
      setLocale(await loadDateFnsLocale(currentLocale));
    };

    load();
  }, [currentLocale]);
  return locale;
}

export default useDateFnsLocale;
