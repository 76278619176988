import { gql } from '@apollo/client/core';
import {
  TranslationItemFragmentDoc,
  TranslationValueItemFragmentDoc,
} from 'generated/types.tsx';

export const GET_LOGIN_TRANSLATIONS = gql`
  query GetLoginTranslations($languages: [String!]!) {
    loginTranslations {
      ...TranslationItem
      values(languages: $languages) {
        ...TranslationValueItem
      }
    }
  }
  ${TranslationItemFragmentDoc}
  ${TranslationValueItemFragmentDoc}
`;

export const GET_APP_TRANSLATIONS = gql`
  query GetAppTranslations($languages: [String!]!) {
    appTranslations {
      ...TranslationItem
      values(languages: $languages) {
        ...TranslationValueItem
      }
    }
  }
  ${TranslationItemFragmentDoc}
  ${TranslationValueItemFragmentDoc}
`;
