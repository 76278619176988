import React, { useCallback, useState } from 'react';
import { Col, Row, Space, Switch, Typography } from 'antd';
import ConnectMap from 'components/lib/ConnectMap/ConnectMap';
import useTicketDisplayMode from 'components/ticket/TicketFilter/useTicketDisplayMode';
import TicketMarkerLayer, {
  LoadingTicketMarkerLayerData,
} from 'components/ticket/TicketMap/TicketMarkerLayer';
import { defineMessages } from 'react-intl';
import TicketMapFilters from 'components/ticket/TicketMap/TicketMapFilters';
import MyPositionMarker from 'components/lib/ConnectMap/MyPositionMarker';
import MapText from 'components/lib/ConnectMap/MapText';
import commonMessages from 'components/i18n/commonMessages';
import TicketListDisplayModeSegmented from 'components/ticket/TicketList/TicketListDisplayModeSegmented';
import { MapLayerControlItem } from 'components/lib/ConnectMap/MapLayerControls';
import TicketMapContextFilter from 'components/ticket/TicketMap/ticketMapContextFilter.ts';
import useConnectIntl from 'i18n/useConnectIntl.ts';

interface Props {
  contextFilter?: TicketMapContextFilter;
}

const messages = defineMessages({
  summary: {
    id: 'ticket_map.summary',
    defaultMessage: '{count, plural, =0 {No tickets 👌} one {# ticket} other {# tickets}}',
    description: 'Text label on ticket map showing ticket count',
  },
  ticketsLayer: {
    id: 'ticket_map.tickets_layer',
    defaultMessage: 'Tickets',
  },
  serviceCar: {
    id: 'ticket_map.service_car',
    defaultMessage: 'Service car',
  },
});

const TicketMap: React.FC<Props> = ({ contextFilter }) => {
  const intl = useConnectIntl();
  const { setTicketDisplayMode } = useTicketDisplayMode();
  const [showTicketsLayer, setShowTicketsLayer] = useState(true);
  const [showMyPosition, setShowMyPosition] = useState(true);
  const [loading, setLoading] = useState(false);
  const [ticketCount, setTicketCount] = useState<number | undefined>(undefined);
  const handleTicketMarkerLoading = useCallback((data: LoadingTicketMarkerLayerData) => {
    setLoading(data.loading);
    setTicketCount(data.ticketCount);
  }, []);

  return (
    <ConnectMap
      actions={
        <Space>
          <TicketListDisplayModeSegmented
            value={'map'}
            onChange={(value) => setTicketDisplayMode(value)}
          />
          {loading && <MapText text={intl.formatMessage(commonMessages.loading) + '...'} />}
          {!loading && ticketCount !== undefined && (
            <MapText
              text={intl.formatMessage(messages.summary, {
                count: ticketCount,
              })}
            />
          )}
        </Space>
      }
      filters={<TicketMapFilters />}
      layerControls={
        <>
          <MapLayerControlItem>
            <Row justify={'space-between'} gutter={[4, 4]}>
              <Col>
                <Typography.Text>
                  {intl.formatMessage({
                    id: 'ticket_map.me_layer',
                    defaultMessage: 'My position',
                  })}
                </Typography.Text>
              </Col>
              <Col>
                <Switch
                  checked={showMyPosition}
                  title={intl.formatMessage({
                    id: 'ticket_map.me_layer',
                    defaultMessage: 'My position',
                  })}
                  checkedChildren={intl.formatMessage(commonMessages.on)}
                  unCheckedChildren={intl.formatMessage(commonMessages.off)}
                  onChange={(checked: boolean) => setShowMyPosition(checked)}
                />
              </Col>
            </Row>
          </MapLayerControlItem>
          <MapLayerControlItem>
            <Row justify={'space-between'} gutter={[4, 4]}>
              <Col>
                <Typography.Text>
                  {intl.formatMessage({
                    id: 'ticket_map.tickets_layer',
                    defaultMessage: 'Tickets',
                  })}
                </Typography.Text>
              </Col>
              <Col>
                <Switch
                  checked={showTicketsLayer}
                  title={intl.formatMessage(messages.ticketsLayer)}
                  checkedChildren={intl.formatMessage(commonMessages.on)}
                  unCheckedChildren={intl.formatMessage(commonMessages.off)}
                  onChange={(checked: boolean) => setShowTicketsLayer(checked)}
                />
              </Col>
            </Row>
          </MapLayerControlItem>
          <MapLayerControlItem>
            <Row justify={'space-between'} gutter={[4, 4]}>
              <Col>
                <Typography.Text>{intl.formatMessage(messages.serviceCar)}</Typography.Text>
              </Col>
              <Col>
                <Switch
                  disabled={true}
                  title={intl.formatMessage(messages.serviceCar)}
                  checkedChildren={intl.formatMessage(commonMessages.on)}
                  unCheckedChildren={intl.formatMessage(commonMessages.off)}
                />
              </Col>
            </Row>{' '}
          </MapLayerControlItem>
        </>
      }
    >
      {showTicketsLayer && (
        <TicketMarkerLayer onLoading={handleTicketMarkerLoading} contextFilter={contextFilter} />
      )}

      {showMyPosition && <MyPositionMarker />}
    </ConnectMap>
  );
};

export default TicketMap;
