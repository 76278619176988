import { useAppDispatch } from 'redux/store';
import { setOnlineStatus } from 'redux/appSlice';
import { useEffect } from 'react';

function useReduxOnlineStatus() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Set initial online status
    dispatch(setOnlineStatus(window.navigator.onLine));

    // Define event handlers
    const handleOnline = () => dispatch(setOnlineStatus(true));
    const handleOffline = () => dispatch(setOnlineStatus(false));

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [dispatch]);
}

export default useReduxOnlineStatus;
