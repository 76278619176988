import React, { createContext, useContext, useState, useEffect } from 'react';
import { LocalThemeInfo } from 'themes/useConnectAppTheme.ts';
import { getLocalStorageItem } from 'common/localStorage.ts';
import { KEY_THEME } from 'auth/constants.ts';

const LocalThemeContext = createContext<LocalThemeInfo | undefined>(undefined);
export const useLocalTheme = () => useContext(LocalThemeContext)?.theme;
const LocalThemeProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [localStorageValue, setLocalStorageValue] = useState<LocalThemeInfo | undefined>(undefined);

  useEffect(() => {
    const storedValue = getLocalStorageItem<LocalThemeInfo>(KEY_THEME);
    if (storedValue) {
      setLocalStorageValue(storedValue);
    }
  }, []);

  return (
    <LocalThemeContext.Provider value={localStorageValue}>{children}</LocalThemeContext.Provider>
  );
};

export default LocalThemeProvider;
