import { matchPath, useLocation, useParams } from 'react-router-dom';
import {
  useGetMachineListItemQuery,
  useGetPredictionResolveQuery,
  useGetTicketAndMachineAndRetailerQuery,
} from 'generated/types';
import { ApolloError } from '@apollo/client';
import { useCallback } from 'react';
import { Category } from 'layouts/matrix/category';
import { Level } from 'layouts/matrix/level';
import { isValidLevelInCategory, isValidTabInCategory } from 'layouts/matrix/matrix';
import { useMatrixCategory } from 'layouts/matrix/useMatrixCategory';
import { useMatrixLevel } from 'layouts/matrix/useMatrixLevel';
import parseNumber from 'util/parseNumber';
import { useAppSelector } from 'redux/store.ts';
import { selectRootPermissions } from 'auth/authSlice.ts';
import { gql } from '@apollo/client/core';

gql`
  query GetPredictionResolve($predictionId: UUID!) {
    prediction(id: $predictionId) {
      id
      predictionId
      retailer {
        ... on Retailer {
          id
          retailerId
        }
      }
      machine {
        id
        machineId
      }
    }
  }
`;

/********
 * 010101001      M A T R I X   N A V        01010101001
 * ---------------------------------------------------------------------------
 * Matrix nav is our navigation in two dimensions [category, level]
 * The user can change either category or level, and this changes which tabs are
 * available on a page
 *
 * USAGE:
 * - Use hook useMatrixNav()
 * - Use functions here to navigate, don't manually build urls in components
 * - When adding new pages, tabs, etc. use strongly typed tabs, example AllTicketTabs
 *
 * category                   level
 *                            root      retailer      machine     ticket
 *
 * NAV TABS
 *    Dashboard(default)      x         x             x           -
 *    Retailers               ?         -             -
 *    Machines                X         X             -
 *
 * operational                                                    * no effect!
 *  - Status                  X         X             X           -
 *  - Health                  X         X             X           -
 *    Tickets                 X         X             X           -
 *    Event log               (x)       (x)           X           -
 *    Properties              (x)       (x)           X           -
 *    Authorization manager   X         X             X(view)     -
 *    Predictions             X         X             X           -
 *
 * commercial                                                     * no effect!
 *    price matrix            -         X (edit m)    y (bind)    -
 *    transaction             -         X             X           -
 *    reconciliation          -         X             X           -
 *    statistics              ?         ?             ?           -
 *
 *
 * logistics                                                      * no effect!
 *    inventory               -         -             x,y         -
 *    refill order            -         x             x           -
 *
 *
 * HomePage(root)
 *    Always: (retailers, machines)
 *    operational: (status, health, tickets, events, properties, users)
 *    commercial: -
 *    logistics: -
 *
 * RetailerPage:
 *    always: (machines)
 *    operational: (status, health, tickets, events, properties, users)
 *    commercial: (price-matrix, transaction reconciliation, statistics)
 *    logistics: (refill-order)
 *
 * MachinePage:
 *    always: -
 *    operational: (status, health, tickets, events, properties, users)
 *    commercial: (price-matrix, transaction reconciliation, statistics)
 *    logistics: (refill-order, inventory)
 */

interface ResolvedLocationData {
  selectedCategory?: Category /** current selected category. Can be undefined, example url /profile **/;
  selectedLevel?: Level /** current selected level (root, retailer, machine, ticket) **/;

  resolving: boolean /** true if currently resolving. Example url /machine/123 will run a query to resolve the parent retailerId **/;
  error?: ApolloError | undefined /** not found, invalid id / url, etc. **/;

  // resolved ids:
  retailerId?: number;
  machineId?: number;
  serialNo?: string;
  ticketId?: number;
  predictionId?: string;
}

interface NavigationUtils {
  getUrlToMachine: (machineId: number) => string;
  getUrlToRetailer: (retailerId: number) => string;
  getUrlToTicket: (ticketId: number) => string;
  getUrlToTicketList: (retailerId?: number, machineId?: number) => string;
  getUrlToPredictionList: (retailerId?: number, machineId?: number) => string;
  getUrlToRoot: () => string;
  getDefaultRootUrl: () => string;
  getUrlToCategory: (category: Category) => string;
  getUrlToCreateTicket: () => string;
  getUrlToPrediction: (predictionId: string) => string;
}

type MatrixNav = ResolvedLocationData & NavigationUtils;

/***
 * Hook to get all relevant matrix nav location info given "incomplete" data from url
 *
 * if route is    /retailer/:retailerId   => no resolve, return category, level and id
 * if route is    /machine/:machineId   => resolve => parent retailer
 * if route is    /ticket/:ticketId    => resolve => parent machineId and retailerId
 * if route is    /    => no resolve, return category + level,
 * if route is    /retailer/invalid-id    => resolve with error from apollo
 * if route is    /prediction/:predictionId    => resolve => parent machineId and retailerId
 *
 * Note that we don't handle 404, etc. here
 *
 * Special use case:
 * /ticket/id => machineUrl should resolve to /machine/id/tickets
 * /ticket/id => retailerUrl should resolve to /retailer/id/tickets
 * /prediction/id => machineUrl should resolve to /machine/id/predictions
 * /prediction/id => retailerUrl should resolve to /retailer/id/predictions
 */
function useMatrixNav(): MatrixNav {
  // These simply match urls:
  const selectedCategory = useMatrixCategory();
  const selectedLevel = useMatrixLevel();
  const location = useLocation();

  // used to generate links
  const linkLevel: Level = selectedLevel || 'root';
  const { retailerId, machineId, ticketId, predictionId, tab } = useParams();

  const isMatchTicket = !!matchPath({ path: '/ticket/:ticketId', end: false }, location.pathname);
  const isMatchNewTicket1 = !!matchPath({ path: '/tickets/new' }, location.pathname);
  const isMatchNewTicket2 = !!matchPath(
    { path: 'retailer/:retailerId/tickets/new' },
    location.pathname
  );
  const isMatchNewTicket3 = !!matchPath(
    { path: 'machine/:machineId/tickets/new' },
    location.pathname
  );

  const isMatchPredictionRoute = !!matchPath(
    { path: '/prediction/:predictionId', end: false },
    location.pathname
  );

  // This is used to make the /tickets context "sticky".
  // Example: url /ticket:123 -> navigate to retailer 5 -> url /retailer/5/tickets
  const isMatchTicketRoute =
    isMatchTicket || isMatchNewTicket1 || isMatchNewTicket2 || isMatchNewTicket3;

  const retailerIdNum = parseNumber(retailerId);
  const machineIdNum = parseNumber(machineId);
  const ticketIdNum = parseNumber(ticketId);

  const getUrlToCategory = useCallback(
    (nextCategory: Category) => {
      const isTabValidInNextCategory = isValidTabInCategory(nextCategory, linkLevel, tab);
      const isLevelValidInNextCategory = isValidLevelInCategory(nextCategory, linkLevel);

      const parts: string[] = [];

      if (
        isLevelValidInNextCategory &&
        linkLevel !== 'root' &&
        (retailerIdNum || machineIdNum || ticketIdNum || predictionId)
      ) {
        parts.push(linkLevel);
        if (linkLevel === 'retailer' && retailerIdNum) {
          parts.push(retailerIdNum.toString());
        } else if (linkLevel === 'machine' && machineIdNum) {
          parts.push(machineIdNum.toString());
        } else if (linkLevel === 'ticket' && ticketIdNum) {
          parts.push(ticketIdNum.toString());
        } else if (linkLevel === 'prediction' && predictionId) {
          parts.push(predictionId.toString());
        }
      }

      if (
        isLevelValidInNextCategory &&
        (retailerIdNum || machineIdNum || ticketIdNum) &&
        isTabValidInNextCategory &&
        tab !== undefined
      ) {
        parts.push(tab);
      } else if (nextCategory === 'commercial' || nextCategory === 'logistics') {
        parts.push(nextCategory); // this will append the category to the end of the url, which is also the category dashboard tab id
      }
      return '/' + parts.join('/');
    },
    [retailerIdNum, machineIdNum, ticketIdNum, linkLevel, tab, predictionId]
  );

  const getUrlToRetailer = useCallback(
    (retailerId: number) => {
      const tabSuffix = isValidTabInCategory(selectedCategory, 'retailer', tab)
        ? `/${tab}${window.location.search || ''}`
        : isMatchTicketRoute
        ? '/tickets'
        : isMatchPredictionRoute
        ? '/predictions'
        : '';
      return `/retailer/${retailerId.toString()}${tabSuffix}`;
    },
    [selectedCategory, tab, isMatchTicketRoute, isMatchPredictionRoute]
  );

  const getUrlToMachine = useCallback(
    (machineId: number) => {
      const tabSuffix = isValidTabInCategory(selectedCategory, 'machine', tab)
        ? `/${tab}${window.location.search || ''}`
        : isMatchTicketRoute
        ? '/tickets'
        : isMatchPredictionRoute
        ? '/predictions'
        : '';
      return `/machine/${machineId.toString()}${tabSuffix}`;
    },
    [selectedCategory, tab, isMatchTicketRoute, isMatchPredictionRoute]
  );

  const getUrlToTicket = useCallback((ticketId: number) => {
    return `/ticket/${ticketId.toString()}`;
  }, []);

  const getUrlToPrediction = useCallback((predictionId: string) => {
    return `/prediction/${predictionId.toString()}`;
  }, []);

  const getUrlToTicketList = useCallback((retailerId?: number, machineId?: number) => {
    const parts: string[] = [];
    if (machineId) {
      parts.push('machine');
      parts.push(machineId.toString());
    } else if (retailerId !== undefined) {
      parts.push('retailer');
      parts.push(retailerId.toString());
    }
    parts.push('tickets');
    return `/${parts.join('/')}`;
  }, []);

  const getUrlToPredictionList = useCallback((retailerId?: number, machineId?: number) => {
    const parts: string[] = [];
    if (machineId) {
      parts.push('machine');
      parts.push(machineId.toString());
    } else if (retailerId !== undefined) {
      parts.push('retailer');
      parts.push(retailerId.toString());
    }
    parts.push('predictions');
    return `/${parts.join('/')}`;
  }, []);

  const getUrlToCreateTicket = useCallback(() => {
    const parts: string[] = [];
    if (linkLevel !== 'root') {
      parts.push(linkLevel);
    }

    if (linkLevel === 'retailer' && retailerIdNum) {
      parts.push(retailerIdNum.toString());
    } else if (linkLevel === 'machine' && machineIdNum) {
      parts.push(machineIdNum.toString());
    } else if (linkLevel === 'ticket' && ticketIdNum) {
      parts.push(ticketIdNum.toString());
    }
    parts.push('tickets');
    parts.push('new');
    return '/' + parts.join('/');
  }, [linkLevel, retailerIdNum, machineIdNum, ticketIdNum]);

  const rootPermissions = useAppSelector(selectRootPermissions);

  const getDefaultRootUrl = useCallback(() => {
    if (rootPermissions?.categories.operational === true) {
      return '/';
    } else if (rootPermissions?.categories.commercial === true) {
      return '/commercial';
    } else if (rootPermissions?.categories.logistics === true) {
      return '/logistics';
    } else {
      return '/';
    }
  }, [rootPermissions]);

  const getUrlToRoot = useCallback(() => {
    // Note that not all categories are visible!
    // If operational is not visible, we should navigate to the first visible category
    return isValidTabInCategory(selectedCategory, 'root', tab)
      ? `/${tab}${window.location.search || ''}`
      : isMatchTicketRoute
      ? '/tickets'
      : isMatchPredictionRoute
      ? '/predictions'
      : getDefaultRootUrl();
  }, [selectedCategory, tab, isMatchTicketRoute, isMatchPredictionRoute, getDefaultRootUrl]);

  const resolveByTicket = selectedLevel === 'ticket' && ticketIdNum !== undefined;
  const resolveByMachine = selectedLevel === 'machine' && machineIdNum !== undefined;
  const resolveByRetailer = selectedLevel === 'retailer' && retailerIdNum !== undefined;
  const resolveByPrediction = selectedLevel === 'prediction' && predictionId !== undefined;

  // we know ONE id before resolving, let's return that one early
  const knownIds: Pick<MatrixNav, 'ticketId' | 'machineId' | 'retailerId' | 'predictionId'> = {
    ticketId: resolveByTicket ? ticketIdNum : undefined,
    machineId: resolveByMachine ? machineIdNum : undefined,
    retailerId: resolveByRetailer ? retailerIdNum : undefined,
    predictionId: resolveByPrediction ? predictionId : undefined,
  };

  const ticketIdParam = parseNumber(ticketId);
  // The "skip" below makes sure we call the correct query
  const resolveByTicketQuery = useGetTicketAndMachineAndRetailerQuery({
    variables: ticketIdParam
      ? {
          ticketId: ticketIdParam,
        }
      : undefined,
    fetchPolicy: 'cache-first',
    skip: !resolveByTicket,
  });

  const machineIdParam = parseNumber(machineId);
  const resolveByMachineQuery = useGetMachineListItemQuery({
    variables: machineIdParam
      ? {
          id: machineIdParam,
        }
      : undefined,
    fetchPolicy: 'cache-first',
    skip: !resolveByMachine,
  });

  const resolveByPredictionQuery = useGetPredictionResolveQuery({
    variables: predictionId
      ? {
          predictionId: predictionId,
        }
      : undefined,
    fetchPolicy: 'cache-first',
    skip: !resolveByPrediction,
  });

  const defaultConnectNav: MatrixNav = {
    resolving: false,
    selectedCategory,
    selectedLevel,
    getUrlToRetailer,
    getUrlToMachine,
    getUrlToTicket,
    getUrlToTicketList,
    getUrlToPredictionList,
    getUrlToRoot,
    getUrlToCategory,
    getUrlToCreateTicket,
    getDefaultRootUrl,
    getUrlToPrediction,
  };

  // NOTE: Since we're happy with just the IDs, we don't need to resolve for retailer since this is our "top level"
  if (resolveByTicket) {
    return {
      ...defaultConnectNav,
      getUrlToCategory: (nextCategory) => {
        const parentMachineId = resolveByTicketQuery.data?.ticket.machine?.machineId;
        if (selectedLevel === 'ticket' && nextCategory !== 'operational' && parentMachineId) {
          // Handle special case here, view ticket 123 -> navigating away from operational category
          // Ticket is not applicable to commercial and logistics categories
          // Default behavior is to navigate to root level, but let's navigate to the parent level instead
          const tabSuffix = isValidTabInCategory(selectedCategory, 'machine', tab)
            ? `/${tab}${window.location.search || ''}`
            : '';
          return `/machine/${parentMachineId.toString()}${tabSuffix}`;
        } else {
          return getUrlToCategory(nextCategory);
        }
      },
      resolving: resolveByTicketQuery.loading,
      error: resolveByTicketQuery.error,
      ticketId: resolveByTicketQuery.data?.ticket.ticketId || knownIds.ticketId,
      machineId: resolveByTicketQuery.data?.ticket.machine?.machineId,
      retailerId:
        (resolveByTicketQuery.data?.ticket.retailer?.__typename === 'Retailer' &&
          resolveByTicketQuery.data.ticket.retailer.retailerId) ||
        undefined,
    };
  } else if (resolveByMachine) {
    return {
      ...defaultConnectNav,
      resolving: resolveByMachineQuery.loading,
      error: resolveByMachineQuery.error,
      machineId: resolveByMachineQuery.data?.machine.machineId || knownIds.machineId,
      serialNo: resolveByMachineQuery.data?.machine.serialNo,
      retailerId: resolveByMachineQuery.data?.machine.retailerIdRaw,
    };
  } else if (resolveByPrediction) {
    return {
      ...defaultConnectNav,
      resolving: resolveByPredictionQuery.loading,
      error: resolveByPredictionQuery.error,
      machineId: resolveByPredictionQuery.data?.prediction.machine?.machineId || knownIds.machineId,
      retailerId:
        (resolveByPredictionQuery.data?.prediction.retailer?.__typename === 'Retailer' &&
          resolveByPredictionQuery.data?.prediction.retailer?.retailerId) ||
        knownIds.retailerId,
    };
  }

  // NOTE: Resolving by retailerId (no need to actually resolve anything, just trust id from url)
  return {
    ...defaultConnectNav,
    ...knownIds,
  };
}

export default useMatrixNav;
