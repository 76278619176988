import React, { useCallback, useState } from 'react';
import { Button, Input, Popover, Space, Spin } from 'antd';
import styled from 'styled-components';
import { HexColorPicker } from 'react-colorful';
import { useDebouncedCallback } from 'use-debounce';
import { colord } from 'colord';
import useAntThemeToken from 'themes/useAntThemeToken';

interface Props {
  loading?: boolean;
  value?: string | null;
  onChange?: (value: string) => void;
  children?: React.ReactNode;
  size?: 'small' | 'middle' | 'large';
}

const DefaultPickButton = styled(Button)<{ $color: string; $isDark: boolean }>`
  && {
    background-color: ${(props) => props.$color};
    color: ${(props) => props.theme.ant.colorText};

    &:hover,
    &:focus,
    &:active {
      background-color: ${(props) => props.$color};
      color: ${(props) => props.theme.ant.colorText};
    }
  }
  min-width: 95px;
`;

const Box = styled.div<{ bg: string }>`
  border-radius: ${(props) => props.theme.ant.borderRadius}px;
  width: 22px;
  height: 22px;
  background-color: ${(props) => props.bg};

  cursor: pointer;
`;

/***
 * ColorPicker - a color picker component
 * @param value
 * @param onChange
 * @param size
 * @param children
 * @param loading
 * @constructor
 */
const ColorPicker: React.FC<Props> = ({
  value,
  onChange,
  size,
  children,
  loading,
}) => {
  const [open, setOpen] = useState(false);
  const defaultColor = '#c7c7c7';

  const handleOpenChange = useCallback((newOpen: boolean) => {
    setOpen(newOpen);
  }, []);

  const handleChangeDebounced = useDebouncedCallback((value: string) => {
    onChange?.(value);
  }, 200);

  const token = useAntThemeToken();

  const colors = [
    token.colorPrimary,
    token.colorSuccess,
    token.colorWarning,
    token.colorError,
    token.colorInfo,
    token.colorText,
    token.colorBgContainer,
    token.colorFill,
  ];

  const isDark = colord(value || defaultColor).isDark();

  const defaultButton = (
    <DefaultPickButton
      size={size || 'middle'}
      $color={value || defaultColor}
      $isDark={isDark}
    >
      {loading && <Spin size={'small'} />}
      {!loading && value}
    </DefaultPickButton>
  );

  const [hex, setHex] = useState(value || defaultColor);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleChangeColor = useCallback(
    (value: string) => {
      const isValidHex = /^#[0-9A-F]{6}$/i.test(value);
      if (isValidHex) {
        onChange?.(value);
        setError(undefined);
      } else {
        setError('Invalid color');
      }

      setHex(value);
    },
    [onChange]
  );

  const pick = (
    <Space direction={'vertical'} size={'small'}>
      <HexColorPicker
        color={value || undefined}
        onChange={handleChangeDebounced}
      />
      <Space>
        {colors.map((c, index) => (
          <Box key={`${index}_${c}`} bg={c} onClick={() => onChange?.(c)} />
        ))}
      </Space>
      <Input
        value={hex}
        onChange={(e) => handleChangeColor(e.target.value)}
        status={error ? 'error' : undefined}
      />
    </Space>
  );

  return (
    <Popover
      trigger={'click'}
      open={open}
      onOpenChange={handleOpenChange}
      content={pick}
    >
      {children || defaultButton}
    </Popover>
  );
};

export default ColorPicker;
