import React from 'react';
import { gql } from '@apollo/client/core';
import { useGetTicketOrderLineCategoriesQuery } from 'generated/types';
import Select, { Option } from 'components/lib/Select/Select';
import { Space } from 'antd';
import CategoryTag from 'components/ticket/TicketAccounting/CategoryTag';
import { SelectProps } from 'antd/es/select';

const ID_SERVICE_AGREEMENT = 'VGlja2V0T3JkZXJMaW5lQ2F0ZWdvcnkKaTI=';

interface Props {
  hasServiceAgreement?: boolean;
  value?: string;
  onChange?: (value: string) => void;
}

type OtherSelectProps = Omit<SelectProps<string>, 'options' | 'value' | 'onChange'>;

gql`
  query GetTicketOrderLineCategories {
    allTicketOrderLineCategories {
      id
      ticketOrderLineCategoryId
      name
    }
  }
`;

const CategorySelect: React.FC<Props & OtherSelectProps> = (props) => {
  const { value, onChange, hasServiceAgreement, ...rest } = props;
  const { data, loading } = useGetTicketOrderLineCategoriesQuery({
    notifyOnNetworkStatusChange: true,
  });

  return (
    <Select
      value={loading ? undefined : value} // Mini hack to avoid rendering ugly id as selected value while loading data
      onChange={(selectedValue) => {
        onChange?.(selectedValue);
      }}
      loading={loading}
      {...rest}
    >
      {data?.allTicketOrderLineCategories.map((c) => {
        const disabled =
          hasServiceAgreement !== undefined &&
          !hasServiceAgreement &&
          c.id === ID_SERVICE_AGREEMENT;
        return (
          <Option value={c.id} key={c.id} disabled={disabled}>
            <Space>
              {c.name && <CategoryTag name={c.name} disabled={disabled} />}
              <div>{c.name}</div>
            </Space>
          </Option>
        );
      })}
    </Select>
  );
};

export default CategorySelect;
