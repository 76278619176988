import React, {
  forwardRef,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import styled from 'styled-components';
import Arrow from 'components/lib/Menu/Arrow';
import Link from 'components/lib/Link/Link';
import { Skeleton } from 'antd';
import { useHoverIntent } from 'react-use-hoverintent';

const StyledLi = styled.li<{ $disabled: boolean }>`
  position: relative;
  color: ${(props) =>
    props.$disabled ? props.theme.ant.colorTextDisabled : props.theme.ant.colorText};
`;

const MenuHeaderSkeleton = styled.div`
  list-style: none;
  outline: none;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: ${(props) => props.theme.ant.borderRadius}px;
  min-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 24px);
  margin-left: 0;
  padding-inline-end: 8px;
  padding-inline-start: 8px;
  margin-inline: 12px;
  margin-block: 4px;
  box-sizing: border-box;
  padding: 0 0 0.02px;
`;

const MenuHeader = styled.div<{
  $selected: boolean;
  $disabled: boolean;
  $indent: number;
}>`
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: ${(props) => props.theme.ant.borderRadius}px;
  height: 40px;
  line-height: 40px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 8px);

  padding-inline-end: 8px;
  padding-inline-start: calc(8px + ${(props) => props.$indent * 8}px);
  margin-inline: 4px;
  margin-block: 4px;

  box-sizing: border-box;
  padding-bottom: 0.02px;

  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};

  background-color: ${(props) => (props.$selected ? props.theme.ant.colorPrimary : 'transparent')};
  transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

  color: ${(props) =>
    props.$disabled
      ? props.theme.ant.colorTextDisabled
      : props.$selected
      ? props.theme.ant.colorWhite
      : props.theme.ant.colorText};

  &:hover {
    background-color: ${(props) =>
      props.$selected ? props.theme.ant.colorPrimary : props.theme.ant.colorFillSecondary};
  }

  &:active {
    background-color: ${(props) =>
      props.$disabled ? props.theme.ant.colorFillSecondary : props.theme.ant.colorPrimary};
  }
`;

const LeftIconContainer = styled.div`
  flex: 0 0 24px;
`;
const Right = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;

  overflow: hidden;
`;

const Title = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const RightIconContainer = styled.div`
  flex: 0 0 auto;
  padding-inline-start: 4px;
  padding-inline-end: 4px;
  overflow: hidden;
`;

const ArrowContainer = styled.div`
  flex: 0 0 24px;
  height: 100%;
  position: relative;
`;

export interface MenuItemProps {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  title: string;
  isExpanded?: boolean;
  isSelected?: boolean;
  onChangeExpanded?: (isExpanded: boolean) => void;
  to: string;
  children?: ReactNode;
  disabled?: boolean;
  indent?: number;
  onClick?: () => void;
  onHoverIntent?: () => void; // when a user intends to hover over the item (not accidentally scrolling past it)
}

const StyledLink = styled(Link)`
  transition: color ${(props) => props.theme.ant.motionDurationMid}
    ${(props) => props.theme.ant.motionEaseInOut};
  &:hover {
    color: ${(props) => props.theme.ant.colorLink}; // override link hover color
  }

  & ${MenuHeader}:active {
    color: ${(props) => props.theme.ant.colorWhite};
  }
`;

const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>((props, forwardedRef) => {
  const {
    leftIcon,
    rightIcon,
    title,
    isExpanded,
    isSelected,
    onChangeExpanded,
    children,
    disabled,
    indent,
    to,
    onClick,
    onHoverIntent,
  } = props;

  const isExpandable = isExpanded !== undefined;

  const handleClickExpand = useCallback<MouseEventHandler<HTMLElement>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (disabled) return;
      if (isExpandable) {
        onChangeExpanded?.(!isExpanded);
      }
    },
    [isExpanded, onChangeExpanded, isExpandable, disabled]
  );

  const [isHovering, intentRef] = useHoverIntent<HTMLLIElement>();
  const prevIsHoveringRef = useRef(false);
  useEffect(() => {
    if (isHovering && !prevIsHoveringRef.current) {
      onHoverIntent?.();
    }
    prevIsHoveringRef.current = isHovering;
  }, [isHovering, onHoverIntent]);

  // Merge the refs
  useImperativeHandle(
    forwardedRef,
    () => {
      return intentRef.current as HTMLLIElement;
    },
    [intentRef]
  );

  return (
    <StyledLi ref={intentRef} $disabled={disabled || false}>
      <StyledLink to={to} onClick={onClick}>
        <MenuHeader
          $selected={isSelected || false}
          $disabled={disabled || false}
          $indent={indent || 0}
        >
          {isExpandable && (
            <ArrowContainer onClick={handleClickExpand}>
              <Arrow $open={isExpanded} />
            </ArrowContainer>
          )}

          {leftIcon && <LeftIconContainer>{leftIcon}</LeftIconContainer>}

          <Right>
            <Title>{title}</Title>
            {rightIcon && <RightIconContainer>{rightIcon}</RightIconContainer>}
          </Right>
        </MenuHeader>
      </StyledLink>
      {isExpanded && children}
    </StyledLi>
  );
});

MenuItem.displayName = 'MenuItem';

export default MenuItem;

export const MenuItemSkeleton: React.FC<{ rows?: number }> = ({ rows }) => {
  const showRows = rows || 1;
  return (
    <MenuHeaderSkeleton>
      <Skeleton active={true} avatar={false} title={false} paragraph={{ rows: showRows }} />
    </MenuHeaderSkeleton>
  );
};
