import React, { SVGProps } from 'react';

const ErrorIllustration: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 254 294'
    {...props}
  >
    <g clipPath='url(#clip0_1811_185)'>
      <path
        fill='#E4EBF7'
        fillRule='evenodd'
        d='M0 128.201v-2.11C0 56.675 56.273.401 125.69.401h2.11c69.416 0 125.69 56.274 125.69 125.69v2.11c0 69.417-56.274 125.69-125.69 125.69h-2.11C56.273 253.891 0 197.618 0 128.201Z'
        clipRule='evenodd'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M39.989 132.108a8.331 8.331 0 0 1-16.27 1.565 8.333 8.333 0 0 1-.311-3.236 8.334 8.334 0 0 1 9.125-7.447 8.337 8.337 0 0 1 7.456 9.118Z'
        clipRule='evenodd'
      />
      <path
        stroke='#fff'
        strokeWidth='2'
        d='m37.19 135.59 10.553 5.983m.922 6.311-12.734 10.861'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M40.11 160.816a5.706 5.706 0 1 1-11.364-1.049 5.706 5.706 0 0 1 11.364 1.049ZM57.943 144.6a5.749 5.749 0 0 1-10.18 3.076 5.742 5.742 0 0 1-1.256-4.228 5.749 5.749 0 0 1 6.293-5.13 5.748 5.748 0 0 1 5.143 6.282ZM99.656 27.434l30.024-.013a4.614 4.614 0 0 0 4.266-2.853 4.621 4.621 0 0 0-2.502-6.034 4.62 4.62 0 0 0-1.768-.351l-30.024.013a4.62 4.62 0 0 0 .004 9.238Zm11.484 18.462 30.023-.013a4.617 4.617 0 0 0 4.714-4.621c0-.615-.123-1.223-.362-1.79a4.604 4.604 0 0 0-2.559-2.504 4.614 4.614 0 0 0-1.797-.323l-30.024.013a4.62 4.62 0 0 0-3.263 7.886 4.623 4.623 0 0 0 3.268 1.352Z'
        clipRule='evenodd'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M113.53 27.421v-.002l15.89-.007a4.617 4.617 0 0 0-4.617 4.621 4.62 4.62 0 0 0 4.622 4.617l-15.892.007v-.002a4.619 4.619 0 0 0-.003-9.234Zm36.637 42.67h-3.979a4.791 4.791 0 0 1-4.774-4.775 4.791 4.791 0 0 1 4.774-4.774h3.979a4.79 4.79 0 0 1 4.775 4.774 4.789 4.789 0 0 1-4.775 4.775Z'
        clipRule='evenodd'
      />
      <path
        fill='#FF603B'
        fillRule='evenodd'
        d='M171.687 30.234c0-16.392 13.289-29.68 29.681-29.68 16.392 0 29.68 13.288 29.68 29.68 0 16.393-13.288 29.681-29.68 29.681s-29.68-13.288-29.68-29.68'
        clipRule='evenodd'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='m203.557 19.435-.676 15.035a1.514 1.514 0 0 1-3.026 0l-.675-15.035a2.19 2.19 0 1 1 4.377 0Zm-.264 19.378c.513.477.77 1.1.77 1.87s-.257 1.393-.77 1.907c-.55.476-1.21.733-1.943.733a2.548 2.548 0 0 1-1.87-.77c-.55-.514-.806-1.136-.806-1.87 0-.77.256-1.393.806-1.87.513-.513 1.137-.733 1.87-.733.77 0 1.43.22 1.943.733Z'
        clipRule='evenodd'
      />
      <path
        fill='#FFB594'
        fillRule='evenodd'
        d='M119.3 133.275c4.426-.598 3.612-1.204 4.079-4.778.675-5.18-3.108-16.935-8.262-25.118-1.088-10.72-12.598-11.24-12.598-11.24s4.312 4.895 4.196 16.199c1.398 5.243.804 14.45.804 14.45s5.256 11.369 11.781 10.487Z'
        clipRule='evenodd'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M100.944 91.61s1.463-.583 3.211.582c8.08 1.398 10.368 6.706 11.3 11.368 1.864 1.282 1.864 2.33 1.864 3.496.365.777 1.515 3.03 1.515 3.03s-7.225 1.748-10.954 6.758c-1.399-6.41-6.936-25.234-6.936-25.234Z'
        clipRule='evenodd'
      />
      <path
        fill='#FFB594'
        fillRule='evenodd'
        d='m94.008 90.5 1.019-5.815-9.23-11.874-5.233 5.581-2.593 9.863s8.39 5.127 16.037 2.245Z'
        clipRule='evenodd'
      />
      <path
        fill='#FFC6A0'
        fillRule='evenodd'
        d='M82.931 78.216s-4.557-2.868-2.445-6.892c1.632-3.107 4.537 1.139 4.537 1.139s.524-3.662 3.139-3.662c.523-1.046 1.569-4.184 1.569-4.184s11.507 2.615 13.6 3.138c-.001 5.23-2.317 19.529-7.884 19.969-8.94.706-12.516-9.508-12.516-9.508Z'
        clipRule='evenodd'
      />
      <path
        fill='#520038'
        fillRule='evenodd'
        d='M102.971 72.243c2.616-2.093 3.489-9.775 3.489-9.775s-2.492-.492-6.676-2.062c-4.708-2.092-12.867-4.771-17.575.982-9.54 4.41-2.062 19.93-2.062 19.93l2.729-3.037s-3.956-3.304-2.092-6.277c2.183-3.48 3.943 1.08 3.943 1.08s.64-2.4 3.6-3.36c.356-.714 1.04-2.69 1.44-3.872a1.08 1.08 0 0 1 1.27-.707c2.41.56 8.723 2.03 11.417 2.676.524.126.876.619.825 1.156l-.308 3.266Z'
        clipRule='evenodd'
      />
      <path
        fill='#552950'
        fillRule='evenodd'
        d='M101.22 76.514c-.104.613-.585 1.044-1.076.96-.49-.082-.805-.646-.702-1.26.104-.613.585-1.044 1.076-.961.491.083.805.648.702 1.261Zm-6.96-1.44c-.104.613-.585 1.044-1.076.96-.49-.082-.805-.646-.702-1.26.104-.613.585-1.044 1.076-.96.491.082.805.646.702 1.26Z'
        clipRule='evenodd'
      />
      <path
        stroke='#DB836E'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.063'
        d='m99.206 73.644-.9 1.62-.3 4.38h-2.24'
      />
      <path
        stroke='#5C2552'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.117'
        d='M99.926 73.284s1.8-.72 2.52.54'
      />
      <path
        stroke='#DB836E'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.117'
        d='M81.367 73.084s.48-1.12 1.12-.72c.64.4 1.28 1.44.56 2s.16 1.68.16 1.68'
      />
      <path
        stroke='#5C2552'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.117'
        d='M92.326 71.724s1.84 1.12 4.16.96'
      />
      <path
        stroke='#DB836E'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.063'
        d='M92.726 80.604s2.24 1.2 4.4 1.2m-3.44 1.36s.96.4 1.52.32m-11.519-3.44s1.786 6.547 9.262 7.954'
      />
      <path
        stroke='#E4EBF7'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.136'
        d='M95.548 91.663s-1.068 2.821-8.298 2.105c-7.23-.717-10.29-5.044-10.29-5.044'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M78.126 87.478s6.526 4.972 16.47 2.486c0 0 9.577 1.02 11.536 5.322 5.36 11.77.543 36.835 0 39.962 3.496 4.055-.466 8.483-.466 8.483-15.624-3.548-35.81-.6-35.81-.6-4.849-3.546-1.223-9.044-1.223-9.044L62.38 110.32c-2.485-15.227.833-19.803 3.549-20.743 3.03-1.049 8.04-1.282 8.04-1.282.496-.058 1.08-.076 1.37-.233 2.36-1.282 2.787-.584 2.787-.584Z'
        clipRule='evenodd'
      />
      <path
        fill='#FFC6A0'
        fillRule='evenodd'
        d='M65.828 89.81s-6.875.465-7.59 8.156c-.466 8.857 3.03 10.954 3.03 10.954s6.075 22.102 16.796 22.957c8.39-2.176 4.758-6.702 4.661-11.42-.233-11.304-7.108-16.897-7.108-16.897s-4.212-13.75-9.789-13.75Z'
        clipRule='evenodd'
      />
      <path
        fill='#FFC6A0'
        fillRule='evenodd'
        d='M71.716 124.225s.855 11.264 9.828 6.486c4.765-2.536 7.581-13.828 9.789-22.568 1.456-5.768 2.58-12.197 2.58-12.197l-4.973-1.709s-2.408 5.516-7.769 12.275c-4.335 5.467-9.144 11.11-9.455 17.713Z'
        clipRule='evenodd'
      />
      <path
        stroke='#E4EBF7'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.085'
        d='M108.463 105.191s1.747 2.724-2.331 30.535c2.376 2.216 1.053 6.012-.233 7.51'
      />
      <path
        fill='#FFC6A0'
        fillRule='evenodd'
        d='M123.262 131.527s-.427 2.732-11.77 1.981c-15.187-1.006-25.326-3.25-25.326-3.25l.933-5.8s.723.215 9.71-.068c11.887-.373 18.714-6.07 24.964-1.022 4.039 3.263 1.489 8.16 1.489 8.16'
        clipRule='evenodd'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M70.24 90.974s-5.593-4.739-11.054 2.68c-3.318 7.223.517 15.284 2.664 19.578-.31 3.729 2.33 4.311 2.33 4.311s.108.895 1.516 2.68c4.078-7.03 6.72-9.166 13.711-12.546-.328-.656-1.877-3.265-1.825-3.767.175-1.69-1.282-2.623-1.282-2.623s-.286-.156-1.165-2.738c-.788-2.313-2.036-5.177-4.895-7.575Z'
        clipRule='evenodd'
      />
      <path
        fill='#CBD1D1'
        fillRule='evenodd'
        d='M90.232 288.027s4.855 2.308 8.313 1.155c3.188-1.063 5.12.755 8.002 1.331 2.881.577 7.769 1.243 13.207-1.424-.117-6.228-7.786-4.499-13.518-7.588-2.895-1.56-4.276-5.336-4.066-9.944H91.544s-1.573 11.89-1.312 16.47Z'
        clipRule='evenodd'
      />
      <path
        fill='#2B0849'
        fillRule='evenodd'
        d='M90.207 287.833s2.745 1.437 7.639.738c3.456-.494 3.223.66 7.418 1.282 4.195.621 13.092-.194 14.334-1.126.466 1.242-.388 2.33-.388 2.33s-1.709.682-5.438.932c-2.295.154-8.098.276-10.14-.621-2.02-1.554-4.894-1.515-6.06-.234-4.427 1.075-7.184-.31-7.184-.31l-.181-2.991Z'
        clipRule='evenodd'
      />
      <path
        fill='#A4AABA'
        fillRule='evenodd'
        d='M98.429 272.257h3.496s-.117 7.574 5.127 9.671c-5.244.7-9.672-2.602-8.623-9.671Z'
        clipRule='evenodd'
      />
      <path
        fill='#CBD1D1'
        fillRule='evenodd'
        d='M44.425 272.046s-2.208 7.774-4.702 12.899c-1.884 3.874-4.428 7.854 5.729 7.854 6.97 0 9.385-.503 7.782-6.917-1.604-6.415.279-13.836.279-13.836h-9.088Z'
        clipRule='evenodd'
      />
      <path
        fill='#2B0849'
        fillRule='evenodd'
        d='M38.066 290.277s2.198 1.225 6.954 1.225c6.376 0 8.646-1.73 8.646-1.73s.63 1.168-.649 2.27c-1.04.897-3.77 1.668-7.745 1.621-4.347-.05-6.115-.593-7.062-1.224-.864-.577-.72-1.657-.144-2.162Z'
        clipRule='evenodd'
      />
      <path
        fill='#A4AABA'
        fillRule='evenodd'
        d='M45.344 274.041s.035 1.592-.329 3.07c-.365 1.49-1.13 3.255-1.184 4.34-.061 1.206 4.755 1.657 5.403.036.65-1.622 1.357-6.737 2.006-7.602.648-.865-5.14-2.222-5.896.156Z'
        clipRule='evenodd'
      />
      <path
        fill='#7BB2F9'
        fillRule='evenodd'
        d='m89.476 277.57 13.899.095s1.349-56.643 1.925-66.909c.576-10.267 3.923-45.052 1.042-65.585l-13.037-.669-23.737.81s-.452 4.12-1.243 10.365c-.065.515-.708.874-.777 1.417-.078.608.439 1.407.332 2.044-2.455 14.627-5.797 32.736-8.256 46.837-.121.693-1.282 1.048-1.515 2.796-.042.314.22 1.584.116 1.865-7.14 19.473-12.202 52.601-15.66 67.19l15.176-.015s2.282-10.145 4.185-18.871c2.922-13.389 24.012-88.32 24.012-88.32l3.133-.954-.158 48.568s-.233 1.282.35 2.098c.583.815-.581 1.167-.408 2.331l.408 1.864s-.466 7.458-.932 12.352c-.467 4.895 1.145 40.69 1.145 40.69'
        clipRule='evenodd'
      />
      <path
        stroke='#648BD8'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.085'
        d='M64.57 218.881c1.197.099 4.195-2.097 7.225-5.127m24.229 8.78s2.881-1.152 6.34-4.034'
      />
      <path
        stroke='#648BD8'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.032'
        d='M96.973 219.373s2.882-1.153 6.34-4.034'
      />
      <path
        stroke='#648BD8'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.085'
        d='M63.172 222.144s2.724-.614 6.759-3.496m4.972-72.482c-.281 3.226.31 8.856-4.506 9.478m22.785-11.3s.115 14.557-1.344 15.65c-2.305 1.73-3.107 2.02-3.107 2.02m.466-17.091s.269 13.144-1.01 25.088m-4.662.699s6.81-1.051 9.116-1.051M46.026 270.045l-.892 4.538m1.803-11.294-.815 4.157m16.603-64.943c-.33 1.618-.102 1.904-.449 3.438 0 0-2.756 1.903-2.29 3.923.466 2.02-.31 3.424-4.505 17.252-1.762 5.807-4.233 18.922-6.165 28.278-.03.144-.521 2.646-1.14 5.8m15.982-67.058c-.295 1.658-.6 3.31-.917 4.938m8.089-52.287-1.244 10.877s-1.14.155-.519 2.33c.117 1.399-2.778 16.39-5.382 31.615m-19.943 82.118H58.07'
      />
      <path
        fill='#192064'
        fillRule='evenodd'
        d='M106.18 142.117c-3.028-.489-18.825-2.744-36.219.2a.628.628 0 0 0-.518.644c.063 1.307.044 2.343.015 2.995a.62.62 0 0 0 .445.62c.088.025.18.031.271.016 3.303-.534 17.037-2.412 35.664-.266.347.04.66-.214.692-.56a41.06 41.06 0 0 0 .17-3.029.614.614 0 0 0-.52-.62Z'
        clipRule='evenodd'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='m96.398 145.264.003-5.102a.844.844 0 0 0-.809-.847 114.502 114.502 0 0 0-8.141-.014.85.85 0 0 0-.82.847l-.003 5.097c0 .476.388.857.864.845 2.478-.064 5.166-.067 8.03.017a.837.837 0 0 0 .616-.236.853.853 0 0 0 .26-.607Z'
        clipRule='evenodd'
      />
      <path
        fill='#192064'
        fillRule='evenodd'
        d='m95.239 144.296.002-3.195a.668.668 0 0 0-.643-.672 99.425 99.425 0 0 0-6.094-.01.675.675 0 0 0-.654.672l-.002 3.192c0 .376.305.677.68.669 1.859-.042 3.874-.043 6.02.012.376.01.69-.291.691-.668Z'
        clipRule='evenodd'
      />
      <path
        stroke='#648BD8'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.085'
        d='M90.102 273.522h12.819m-11.705-3.761c.006 3.519-.072 5.55 0 6.292m-.293-12.579c-.009 1.599-.016 2.558-.016 4.505m-.467-97.575.932 46.38s.7 1.631-.233 2.796c-.932 1.166 2.564.7.932 2.33-1.63 1.633.933 1.166 0 3.497-.618 1.546-1.031 21.921-1.138 36.513'
      />
      <path
        stroke='#E4EBF7'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.085'
        d='m73.736 98.665 2.214 4.312s2.098.816 1.865 2.68l.816 2.214m-14.334 8.74c.233-.932 2.176-7.147 12.585-10.488m.716-16.081s7.691 6.137 16.547 2.72'
      />
      <path
        fill='#FFC6A0'
        fillRule='evenodd'
        d='M91.974 86.954s5.476-.816 7.574-4.545c1.297-.345.72 2.212-.33 3.671-.7.971-1.01 1.554-1.01 1.554s.194.31.155.816c-.053.697-.175.653-.272 1.048-.081.335.108.657 0 1.049-.046.17-.198.5-.382.878-.12.249-.072.687-.2.948-.231.469-1.562 1.87-2.622 2.855-3.826 3.554-5.018 1.644-6.001-.408-.894-1.865-.661-5.127-.874-6.875-.35-2.914-2.622-3.03-1.923-4.429.343-.685 2.87.69 3.263 1.748.757 2.04 2.952 1.807 2.622 1.69Z'
        clipRule='evenodd'
      />
      <path
        stroke='#DB836E'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='.774'
        d='M99.8 82.429c-.465.077-.35.272-.97 1.243-.622.971-4.817 2.932-6.39 3.224-2.589.48-2.278-1.56-4.254-2.855-1.69-1.107-3.562-.638-1.398 1.398.99.932.932 1.107 1.398 3.205.335 1.506-.64 3.67.7 5.593'
      />
      <path
        stroke='#E59788'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='.774'
        d='M79.543 108.673c-2.1 2.926-4.266 6.175-5.557 8.762'
      />
      <path
        fill='#FFC6A0'
        fillRule='evenodd'
        d='M87.72 124.768s-2.098-1.942-5.127-2.719c-3.03-.777-3.574-.155-5.516.078-1.942.233-3.885-.932-3.652.7.233 1.63 5.05 1.01 5.206 2.097.155 1.087-6.37 2.796-8.313 2.175-.777.777.466 1.864 2.02 2.175.233 1.554 2.253 1.554 2.253 1.554s.699 1.01 2.641 1.088c2.486 1.32 8.934-.7 10.954-1.554 2.02-.855-.466-5.594-.466-5.594Z'
        clipRule='evenodd'
      />
      <path
        stroke='#E59788'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='.774'
        d='M73.425 122.826s.66 1.127 3.167 1.418c2.315.27 2.563.583 2.563.583s-2.545 2.894-9.07 2.272m2.331 2.175s3.826.097 4.933-.718m-2.369 2.194s1.961.136 3.36-.505m-1.108 1.671s1.748.019 2.914-.505m-6.818-9.09s-.595-1.032 1.262-.427c1.671.544 2.833.055 5.128.155 1.389.061 3.067-.297 3.982.15 1.606.784 3.632 2.181 3.632 2.181s10.526 1.204 19.033-1.127m-27.501-15.149s-8.39 2.758-13.168 12.12'
      />
      <path
        stroke='#E4EBF7'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.085'
        d='M109.278 112.533s3.38-3.613 7.575-4.662'
      />
      <path
        stroke='#E59788'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='.774'
        d='M107.375 123.006s9.697-2.745 11.445-.88'
      />
      <path
        stroke='#BFCDDD'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='m194.605 83.656 3.971-3.886m-11.41 11.163 3.736-3.655m.85-3.071-4.462-4.56m11.163 11.41-4.133-4.225m-65.064 76.242 3.718-3.718m-10.683 10.683 3.498-3.498m.772-2.915-4.27-4.27m10.684 10.683-3.955-3.955'
      />
      <path
        fill='#A3B4C6'
        fillRule='evenodd'
        d='M190.156 211.779h-1.604a4.025 4.025 0 0 1-4.011-4.011V175.68a4.025 4.025 0 0 1 4.01-4.01h1.605a4.021 4.021 0 0 1 4.011 4.01v32.088a4.025 4.025 0 0 1-4.01 4.01'
        clipRule='evenodd'
      />
      <path
        fill='#A3B4C6'
        fillRule='evenodd'
        d='M237.824 212.977a4.81 4.81 0 0 1-4.813 4.813h-86.636a4.81 4.81 0 0 1-4.813-4.813 4.81 4.81 0 0 1 4.813-4.813h86.636a4.81 4.81 0 0 1 4.813 4.813ZM154.098 190.096h70.513v-84.617h-70.513v84.617Z'
        clipRule='evenodd'
      />
      <path
        fill='#BFCDDD'
        fillRule='evenodd'
        d='M224.928 190.096H153.78a3.221 3.221 0 0 1-3.208-3.209V167.92a3.216 3.216 0 0 1 3.208-3.21h71.148a3.22 3.22 0 0 1 3.209 3.21v18.967a3.218 3.218 0 0 1-3.209 3.209Zm0-59.264H153.78a3.218 3.218 0 0 1-3.208-3.208v-18.968a3.22 3.22 0 0 1 3.208-3.209h71.148a3.22 3.22 0 0 1 3.209 3.21v18.967a3.221 3.221 0 0 1-3.209 3.208Z'
        clipRule='evenodd'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M159.563 120.546a2.4 2.4 0 0 1-1.734-.687 2.418 2.418 0 0 1-.722-1.72 2.406 2.406 0 1 1 2.456 2.407Zm7.417 0a2.4 2.4 0 0 1-1.734-.687 2.418 2.418 0 0 1-.722-1.72 2.406 2.406 0 1 1 2.456 2.407Zm7.417 0a2.4 2.4 0 0 1-1.734-.687 2.418 2.418 0 0 1-.722-1.72 2.406 2.406 0 1 1 2.456 2.407Zm48.142 0h-22.461a.802.802 0 0 1-.802-.802v-3.208c0-.443.359-.803.802-.803h22.46c.444 0 .803.36.803.803v3.208c0 .443-.36.802-.802.802Z'
        clipRule='evenodd'
      />
      <path
        fill='#BFCDDD'
        fillRule='evenodd'
        d='M224.928 160.464H153.78a3.221 3.221 0 0 1-3.208-3.209v-18.967a3.22 3.22 0 0 1 3.208-3.209h71.148a3.221 3.221 0 0 1 3.209 3.209v18.967a3.214 3.214 0 0 1-3.209 3.209Z'
        clipRule='evenodd'
      />
      <path
        stroke='#7C90A5'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.124'
        d='M173.455 130.832h49.301m-57.772 0h6.089m-15.121 0h6.75m11.135 29.781h49.3m-57.772 0h6.089m-15.884 0h6.751'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M159.563 151.038a2.409 2.409 0 0 1-1.702-4.109 2.409 2.409 0 0 1 4.109 1.702 2.409 2.409 0 0 1-2.407 2.407Zm7.417 0a2.409 2.409 0 0 1-1.702-4.109 2.409 2.409 0 0 1 4.109 1.702 2.409 2.409 0 0 1-2.407 2.407Zm7.417 0a2.406 2.406 0 0 1-.92-4.631 2.406 2.406 0 0 1 3.328 2.224 2.409 2.409 0 0 1-2.408 2.407Zm48.142 0h-22.461a.798.798 0 0 1-.802-.802v-3.209c0-.443.359-.802.802-.802h22.46c.444 0 .803.36.803.802v3.209c0 .443-.36.802-.802.802Zm-62.976 28.949a2.4 2.4 0 0 1-1.734-.687 2.418 2.418 0 0 1-.722-1.719 2.407 2.407 0 1 1 2.456 2.406Zm7.417 0a2.4 2.4 0 0 1-1.734-.687 2.418 2.418 0 0 1-.722-1.719 2.407 2.407 0 1 1 2.456 2.406Zm7.417 0a2.4 2.4 0 0 1-1.734-.687 2.418 2.418 0 0 1-.722-1.719 2.407 2.407 0 1 1 2.456 2.406Zm48.142 0h-22.461a.798.798 0 0 1-.802-.802v-3.209c0-.443.359-.802.802-.802h22.46c.444 0 .803.36.803.802v3.209c0 .443-.36.802-.802.802Z'
        clipRule='evenodd'
      />
      <path
        fill='#BFCDDD'
        fillRule='evenodd'
        d='M203.04 221.108h-27.372a2.414 2.414 0 0 1-2.406-2.407v-11.448a2.414 2.414 0 0 1 2.406-2.407h27.372a2.414 2.414 0 0 1 2.407 2.407V218.7a2.414 2.414 0 0 1-2.407 2.407'
        clipRule='evenodd'
      />
      <path
        stroke='#A3B4C6'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.124'
        d='M177.259 207.217v11.52m23.791-11.52v11.52'
      />
      <path
        fill='#5BA02E'
        fillRule='evenodd'
        d='M162.873 267.894a9.421 9.421 0 0 1-9.422-9.422v-14.82a9.426 9.426 0 0 1 9.423-9.326 9.423 9.423 0 0 1 9.422 9.326v14.82a9.421 9.421 0 0 1-9.423 9.422Z'
        clipRule='evenodd'
      />
      <path
        fill='#92C110'
        fillRule='evenodd'
        d='M171.22 267.83a9.434 9.434 0 0 1-6.663-2.76 9.415 9.415 0 0 1-2.759-6.663v-3.438a9.426 9.426 0 0 1 9.423-9.326 9.423 9.423 0 0 1 9.422 9.326v3.438a9.424 9.424 0 0 1-9.423 9.423Z'
        clipRule='evenodd'
      />
      <path
        fill='#F2D7AD'
        fillRule='evenodd'
        d='M181.31 293.666h-27.712a3.214 3.214 0 0 1-2.269-.94 3.203 3.203 0 0 1-.94-2.27V269.79a3.21 3.21 0 0 1 3.209-3.21h27.711a3.21 3.21 0 0 1 3.209 3.21v20.668a3.209 3.209 0 0 1-3.209 3.209'
        clipRule='evenodd'
      />
      <path
        fill='#D4DADD'
        fillRule='evenodd'
        d='M0 128.201v-2.11C0 56.675 56.273.401 125.69.401h2.11c69.416 0 125.69 56.274 125.69 125.69v2.11c0 69.417-56.274 125.69-125.69 125.69h-2.11C56.273 253.891 0 197.618 0 128.201Z'
        clipRule='evenodd'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M39.989 132.108a8.331 8.331 0 0 1-16.27 1.565 8.333 8.333 0 0 1-.311-3.236 8.334 8.334 0 0 1 9.125-7.447 8.337 8.337 0 0 1 7.456 9.118Z'
        clipRule='evenodd'
      />
      <path
        stroke='#fff'
        strokeWidth='2'
        d='m37.19 135.59 10.553 5.983m.922 6.311-12.734 10.861'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M40.11 160.816a5.706 5.706 0 1 1-11.364-1.049 5.706 5.706 0 0 1 11.364 1.049ZM57.943 144.6a5.749 5.749 0 0 1-10.18 3.076 5.742 5.742 0 0 1-1.256-4.228 5.749 5.749 0 0 1 6.293-5.13 5.748 5.748 0 0 1 5.143 6.282ZM99.656 27.434l30.024-.013a4.614 4.614 0 0 0 4.266-2.853 4.621 4.621 0 0 0-2.502-6.034 4.62 4.62 0 0 0-1.768-.351l-30.024.013a4.62 4.62 0 0 0 .004 9.238Zm11.484 18.462 30.023-.013a4.617 4.617 0 0 0 4.714-4.621c0-.615-.123-1.223-.362-1.79a4.604 4.604 0 0 0-2.559-2.504 4.614 4.614 0 0 0-1.797-.323l-30.024.013a4.62 4.62 0 0 0-3.263 7.886 4.623 4.623 0 0 0 3.268 1.352Z'
        clipRule='evenodd'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M113.53 27.421v-.002l15.89-.007a4.617 4.617 0 0 0-4.617 4.621 4.62 4.62 0 0 0 4.622 4.617l-15.892.007v-.002a4.619 4.619 0 0 0-.003-9.234Zm36.637 42.67h-3.979a4.791 4.791 0 0 1-4.774-4.775 4.791 4.791 0 0 1 4.774-4.774h3.979a4.79 4.79 0 0 1 4.775 4.774 4.789 4.789 0 0 1-4.775 4.775Z'
        clipRule='evenodd'
      />
      <path
        fill='#F04134'
        fillRule='evenodd'
        d='M171.687 30.234c0-16.392 13.289-29.68 29.681-29.68 16.392 0 29.68 13.288 29.68 29.68 0 16.393-13.288 29.681-29.68 29.681s-29.68-13.288-29.68-29.68'
        clipRule='evenodd'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='m203.557 19.435-.676 15.035a1.514 1.514 0 0 1-3.026 0l-.675-15.035a2.19 2.19 0 1 1 4.377 0Zm-.264 19.378c.513.477.77 1.1.77 1.87s-.257 1.393-.77 1.907c-.55.476-1.21.733-1.943.733a2.548 2.548 0 0 1-1.87-.77c-.55-.514-.806-1.136-.806-1.87 0-.77.256-1.393.806-1.87.513-.513 1.137-.733 1.87-.733.77 0 1.43.22 1.943.733Z'
        clipRule='evenodd'
      />
      <path
        fill='#FFB594'
        fillRule='evenodd'
        d='M119.3 133.275c4.426-.598 3.612-1.204 4.079-4.778.675-5.18-3.108-16.935-8.262-25.118-1.088-10.72-12.598-11.24-12.598-11.24s4.312 4.895 4.196 16.199c1.398 5.243.804 14.45.804 14.45s5.256 11.369 11.781 10.487Z'
        clipRule='evenodd'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M100.944 91.61s1.463-.583 3.211.582c8.08 1.398 10.368 6.706 11.3 11.368 1.864 1.282 1.864 2.33 1.864 3.496.365.777 1.515 3.03 1.515 3.03s-7.225 1.748-10.954 6.758c-1.399-6.41-6.936-25.234-6.936-25.234Z'
        clipRule='evenodd'
      />
      <path
        fill='#FFB594'
        fillRule='evenodd'
        d='m94.008 90.5 1.019-5.815-9.23-11.874-5.233 5.581-2.593 9.863s8.39 5.127 16.037 2.245Z'
        clipRule='evenodd'
      />
      <path
        fill='#FFC6A0'
        fillRule='evenodd'
        d='M82.931 78.216s-4.557-2.868-2.445-6.892c1.632-3.107 4.537 1.139 4.537 1.139s.524-3.662 3.139-3.662c.523-1.046 1.569-4.184 1.569-4.184s11.507 2.615 13.6 3.138c-.001 5.23-2.317 19.529-7.884 19.969-8.94.706-12.516-9.508-12.516-9.508Z'
        clipRule='evenodd'
      />
      <path
        fill='#845207'
        fillRule='evenodd'
        d='M102.971 72.243c2.616-2.093 3.489-9.775 3.489-9.775s-2.492-.492-6.676-2.062c-4.708-2.092-12.867-4.771-17.575.982-9.54 4.41-2.062 19.93-2.062 19.93l2.729-3.037s-3.956-3.304-2.092-6.277c2.183-3.48 3.943 1.08 3.943 1.08s.64-2.4 3.6-3.36c.356-.714 1.04-2.69 1.44-3.872a1.08 1.08 0 0 1 1.27-.707c2.41.56 8.723 2.03 11.417 2.676.524.126.876.619.825 1.156l-.308 3.266Z'
        clipRule='evenodd'
      />
      <path
        fill='#552950'
        fillRule='evenodd'
        d='M101.22 76.514c-.104.613-.585 1.044-1.076.96-.49-.082-.805-.646-.702-1.26.104-.613.585-1.044 1.076-.961.491.083.805.648.702 1.261Zm-6.96-1.44c-.104.613-.585 1.044-1.076.96-.49-.082-.805-.646-.702-1.26.104-.613.585-1.044 1.076-.96.491.082.805.646.702 1.26Z'
        clipRule='evenodd'
      />
      <path
        stroke='#DB836E'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.063'
        d='m99.206 73.644-.9 1.62-.3 4.38h-2.24'
      />
      <path
        stroke='#5C2552'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.117'
        d='M99.926 73.284s1.8-.72 2.52.54'
      />
      <path
        stroke='#DB836E'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.117'
        d='M81.367 73.084s.48-1.12 1.12-.72c.64.4 1.28 1.44.56 2s.16 1.68.16 1.68'
      />
      <path
        stroke='#5C2552'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.117'
        d='M92.326 71.724s1.84 1.12 4.16.96'
      />
      <path
        stroke='#DB836E'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.063'
        d='M92.726 80.604s2.24 1.2 4.4 1.2m-3.44 1.36s.96.4 1.52.32m-11.519-3.44s1.786 6.547 9.262 7.954'
      />
      <path
        stroke='#E4EBF7'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.136'
        d='M95.548 91.663s-1.068 2.821-8.298 2.105c-7.23-.717-10.29-5.044-10.29-5.044'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M78.126 87.478s6.526 4.972 16.47 2.486c0 0 9.577 1.02 11.536 5.322 5.36 11.77.543 36.835 0 39.962 3.496 4.055-.466 8.483-.466 8.483-15.624-3.548-35.81-.6-35.81-.6-4.849-3.546-1.223-9.044-1.223-9.044L62.38 110.32c-2.485-15.227.833-19.803 3.549-20.743 3.03-1.049 8.04-1.282 8.04-1.282.496-.058 1.08-.076 1.37-.233 2.36-1.282 2.787-.584 2.787-.584Z'
        clipRule='evenodd'
      />
      <path
        fill='#FFC6A0'
        fillRule='evenodd'
        d='M65.828 89.81s-6.875.465-7.59 8.156c-.466 8.857 3.03 10.954 3.03 10.954s6.075 22.102 16.796 22.957c8.39-2.176 4.758-6.702 4.661-11.42-.233-11.304-7.108-16.897-7.108-16.897s-4.212-13.75-9.789-13.75Z'
        clipRule='evenodd'
      />
      <path
        fill='#FFC6A0'
        fillRule='evenodd'
        d='M71.716 124.225s.855 11.264 9.828 6.486c4.765-2.536 7.581-13.828 9.789-22.568 1.456-5.768 2.58-12.197 2.58-12.197l-4.973-1.709s-2.408 5.516-7.769 12.275c-4.335 5.467-9.144 11.11-9.455 17.713Z'
        clipRule='evenodd'
      />
      <path
        stroke='#E4EBF7'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.085'
        d='M108.463 105.191s1.747 2.724-2.331 30.535c2.376 2.216 1.053 6.012-.233 7.51'
      />
      <path
        fill='#FFC6A0'
        fillRule='evenodd'
        d='M123.262 131.527s-.427 2.732-11.77 1.981c-15.187-1.006-25.326-3.25-25.326-3.25l.933-5.8s.723.215 9.71-.068c11.887-.373 18.714-6.07 24.964-1.022 4.039 3.263 1.489 8.16 1.489 8.16'
        clipRule='evenodd'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M70.24 90.974s-5.593-4.739-11.054 2.68c-3.318 7.223.517 15.284 2.664 19.578-.31 3.729 2.33 4.311 2.33 4.311s.108.895 1.516 2.68c4.078-7.03 6.72-9.166 13.711-12.546-.328-.656-1.877-3.265-1.825-3.767.175-1.69-1.282-2.623-1.282-2.623s-.286-.156-1.165-2.738c-.788-2.313-2.036-5.177-4.895-7.575ZM90.232 288.027s4.855 2.308 8.313 1.155c3.188-1.063 5.12.755 8.002 1.331 2.881.577 7.769 1.243 13.207-1.424-.117-6.228-7.786-4.499-13.518-7.588-2.895-1.56-4.276-5.336-4.066-9.944H91.544s-1.573 11.89-1.312 16.47Z'
        clipRule='evenodd'
      />
      <path
        fill='#2B0849'
        fillRule='evenodd'
        d='M90.207 287.833s2.745 1.437 7.639.738c3.456-.494 3.223.66 7.418 1.282 4.195.621 13.092-.194 14.334-1.126.466 1.242-.388 2.33-.388 2.33s-1.709.682-5.438.932c-2.295.154-8.098.276-10.14-.621-2.02-1.554-4.894-1.515-6.06-.234-4.427 1.075-7.184-.31-7.184-.31l-.181-2.991Z'
        clipRule='evenodd'
      />
      <path
        fill='#A4AABA'
        fillRule='evenodd'
        d='M98.429 272.257h3.496s-.117 7.574 5.127 9.671c-5.244.7-9.672-2.602-8.623-9.671Z'
        clipRule='evenodd'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M44.425 272.046s-2.208 7.774-4.702 12.899c-1.884 3.874-4.428 7.854 5.729 7.854 6.97 0 9.385-.503 7.782-6.917-1.604-6.415.279-13.836.279-13.836h-9.088Z'
        clipRule='evenodd'
      />
      <path
        fill='#2B0849'
        fillRule='evenodd'
        d='M38.066 290.277s2.198 1.225 6.954 1.225c6.376 0 8.646-1.73 8.646-1.73s.63 1.168-.649 2.27c-1.04.897-3.77 1.668-7.745 1.621-4.347-.05-6.115-.593-7.062-1.224-.864-.577-.72-1.657-.144-2.162Z'
        clipRule='evenodd'
      />
      <path
        fill='#A4AABA'
        fillRule='evenodd'
        d='M45.344 274.041s.035 1.592-.329 3.07c-.365 1.49-1.13 3.255-1.184 4.34-.061 1.206 4.755 1.657 5.403.036.65-1.622 1.357-6.737 2.006-7.602.648-.865-5.14-2.222-5.896.156Z'
        clipRule='evenodd'
      />
      <path
        fill='#F04134'
        fillRule='evenodd'
        d='m89.476 277.57 13.899.095s1.349-56.643 1.925-66.909c.576-10.267 3.923-45.052 1.042-65.585l-13.037-.669-23.737.81s-.452 4.12-1.243 10.365c-.065.515-.708.874-.777 1.417-.078.608.439 1.407.332 2.044-2.455 14.627-5.797 32.736-8.256 46.837-.121.693-1.282 1.048-1.515 2.796-.042.314.22 1.584.116 1.865-7.14 19.473-12.202 52.601-15.66 67.19l15.176-.015s2.282-10.145 4.185-18.871c2.922-13.389 24.012-88.32 24.012-88.32l3.133-.954-.158 48.568s-.233 1.282.35 2.098c.583.815-.581 1.167-.408 2.331l.408 1.864s-.466 7.458-.932 12.352c-.467 4.895 1.145 40.69 1.145 40.69'
        clipRule='evenodd'
      />
      <path
        stroke='#666'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.085'
        d='M64.57 218.881c1.197.099 4.195-2.097 7.225-5.127m24.229 8.78s2.881-1.152 6.34-4.034'
      />
      <path
        stroke='#666'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.032'
        d='M96.973 219.373s2.882-1.153 6.34-4.034'
      />
      <path
        stroke='#666'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.085'
        d='M63.172 222.144s2.724-.614 6.759-3.496m4.972-72.482c-.281 3.226.31 8.856-4.506 9.478m22.785-11.3s.115 14.557-1.344 15.65c-2.305 1.73-3.107 2.02-3.107 2.02m.466-17.091s.269 13.144-1.01 25.088m-4.662.699s6.81-1.051 9.116-1.051M46.026 270.045l-.892 4.538m1.803-11.294-.815 4.157m16.603-64.943c-.33 1.618-.102 1.904-.449 3.438 0 0-2.756 1.903-2.29 3.923.466 2.02-.31 3.424-4.505 17.252-1.762 5.807-4.233 18.922-6.165 28.278-.03.144-.521 2.646-1.14 5.8m15.982-67.058c-.295 1.658-.6 3.31-.917 4.938m8.089-52.287-1.244 10.877s-1.14.155-.519 2.33c.117 1.399-2.778 16.39-5.382 31.615m-19.943 82.118H58.07'
      />
      <path
        fill='#192064'
        fillRule='evenodd'
        d='M106.18 142.117c-3.028-.489-18.825-2.744-36.219.2a.628.628 0 0 0-.518.644c.063 1.307.044 2.343.015 2.995a.62.62 0 0 0 .445.62c.088.025.18.031.271.016 3.303-.534 17.037-2.412 35.664-.266.347.04.66-.214.692-.56a41.06 41.06 0 0 0 .17-3.029.614.614 0 0 0-.52-.62Z'
        clipRule='evenodd'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='m96.398 145.264.003-5.102a.844.844 0 0 0-.809-.847 114.502 114.502 0 0 0-8.141-.014.85.85 0 0 0-.82.847l-.003 5.097c0 .476.388.857.864.845 2.478-.064 5.166-.067 8.03.017a.837.837 0 0 0 .616-.236.853.853 0 0 0 .26-.607Z'
        clipRule='evenodd'
      />
      <path
        fill='#192064'
        fillRule='evenodd'
        d='m95.239 144.296.002-3.195a.668.668 0 0 0-.643-.672 99.425 99.425 0 0 0-6.094-.01.675.675 0 0 0-.654.672l-.002 3.192c0 .376.305.677.68.669 1.859-.042 3.874-.043 6.02.012.376.01.69-.291.691-.668Z'
        clipRule='evenodd'
      />
      <path
        stroke='#666'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.085'
        d='M90.102 273.522h12.819m-11.705-3.761c.006 3.519-.072 5.55 0 6.292m-.293-12.579c-.009 1.599-.016 2.558-.016 4.505m-.467-97.575.932 46.38s.7 1.631-.233 2.796c-.932 1.166 2.564.7.932 2.33-1.63 1.633.933 1.166 0 3.497-.618 1.546-1.031 21.921-1.138 36.513'
      />
      <path
        stroke='#E4EBF7'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.085'
        d='m73.736 98.665 2.214 4.312s2.098.816 1.865 2.68l.816 2.214m-14.334 8.74c.233-.932 2.176-7.147 12.585-10.488m.716-16.081s7.691 6.137 16.547 2.72'
      />
      <path
        fill='#FFC6A0'
        fillRule='evenodd'
        d='M91.974 86.954s5.476-.816 7.574-4.545c1.297-.345.72 2.212-.33 3.671-.7.971-1.01 1.554-1.01 1.554s.194.31.155.816c-.053.697-.175.653-.272 1.048-.081.335.108.657 0 1.049-.046.17-.198.5-.382.878-.12.249-.072.687-.2.948-.231.469-1.562 1.87-2.622 2.855-3.826 3.554-5.018 1.644-6.001-.408-.894-1.865-.661-5.127-.874-6.875-.35-2.914-2.622-3.03-1.923-4.429.343-.685 2.87.69 3.263 1.748.757 2.04 2.952 1.807 2.622 1.69Z'
        clipRule='evenodd'
      />
      <path
        stroke='#DB836E'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='.774'
        d='M99.8 82.429c-.465.077-.35.272-.97 1.243-.622.971-4.817 2.932-6.39 3.224-2.589.48-2.278-1.56-4.254-2.855-1.69-1.107-3.562-.638-1.398 1.398.99.932.932 1.107 1.398 3.205.335 1.506-.64 3.67.7 5.593'
      />
      <path
        stroke='#E59788'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='.774'
        d='M79.543 108.673c-2.1 2.926-4.266 6.175-5.557 8.762'
      />
      <path
        fill='#FFC6A0'
        fillRule='evenodd'
        d='M87.72 124.768s-2.098-1.942-5.127-2.719c-3.03-.777-3.574-.155-5.516.078-1.942.233-3.885-.932-3.652.7.233 1.63 5.05 1.01 5.206 2.097.155 1.087-6.37 2.796-8.313 2.175-.777.777.466 1.864 2.02 2.175.233 1.554 2.253 1.554 2.253 1.554s.699 1.01 2.641 1.088c2.486 1.32 8.934-.7 10.954-1.554 2.02-.855-.466-5.594-.466-5.594Z'
        clipRule='evenodd'
      />
      <path
        stroke='#E59788'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='.774'
        d='M73.425 122.826s.66 1.127 3.167 1.418c2.315.27 2.563.583 2.563.583s-2.545 2.894-9.07 2.272m2.331 2.175s3.826.097 4.933-.718m-2.369 2.194s1.961.136 3.36-.505m-1.108 1.671s1.748.019 2.914-.505m-6.818-9.09s-.595-1.032 1.262-.427c1.671.544 2.833.055 5.128.155 1.389.061 3.067-.297 3.982.15 1.606.784 3.632 2.181 3.632 2.181s10.526 1.204 19.033-1.127m-27.501-15.149s-8.39 2.758-13.168 12.12'
      />
      <path
        stroke='#E4EBF7'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.085'
        d='M109.278 112.533s3.38-3.613 7.575-4.662'
      />
      <path
        stroke='#E59788'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='.774'
        d='M107.375 123.006s9.697-2.745 11.445-.88'
      />
      <path
        stroke='#BFCDDD'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='m194.605 83.656 3.971-3.886m-11.41 11.163 3.736-3.655m.85-3.071-4.462-4.56m11.163 11.41-4.133-4.225m-65.064 76.242 3.718-3.718m-10.683 10.683 3.498-3.498m.772-2.915-4.27-4.27m10.684 10.683-3.955-3.955'
      />
      <path
        fill='#A3B4C6'
        fillRule='evenodd'
        d='M190.156 211.779h-1.604a4.025 4.025 0 0 1-4.011-4.011V175.68a4.025 4.025 0 0 1 4.01-4.01h1.605a4.021 4.021 0 0 1 4.011 4.01v32.088a4.025 4.025 0 0 1-4.01 4.01'
        clipRule='evenodd'
      />
      <path
        fill='#A3B4C6'
        fillRule='evenodd'
        d='M237.824 212.977a4.81 4.81 0 0 1-4.813 4.813h-86.636a4.81 4.81 0 0 1-4.813-4.813 4.81 4.81 0 0 1 4.813-4.813h86.636a4.81 4.81 0 0 1 4.813 4.813ZM154.098 190.096h70.513v-84.617h-70.513v84.617Z'
        clipRule='evenodd'
      />
      <path
        fill='#BFCDDD'
        fillRule='evenodd'
        d='M224.928 190.096H153.78a3.221 3.221 0 0 1-3.208-3.209V167.92a3.216 3.216 0 0 1 3.208-3.21h71.148a3.22 3.22 0 0 1 3.209 3.21v18.967a3.218 3.218 0 0 1-3.209 3.209Zm0-59.264H153.78a3.218 3.218 0 0 1-3.208-3.208v-18.968a3.22 3.22 0 0 1 3.208-3.209h71.148a3.22 3.22 0 0 1 3.209 3.21v18.967a3.221 3.221 0 0 1-3.209 3.208Z'
        clipRule='evenodd'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M159.563 120.546a2.4 2.4 0 0 1-1.734-.687 2.418 2.418 0 0 1-.722-1.72 2.406 2.406 0 1 1 2.456 2.407Zm7.417 0a2.4 2.4 0 0 1-1.734-.687 2.418 2.418 0 0 1-.722-1.72 2.406 2.406 0 1 1 2.456 2.407Zm7.417 0a2.4 2.4 0 0 1-1.734-.687 2.418 2.418 0 0 1-.722-1.72 2.406 2.406 0 1 1 2.456 2.407Zm48.142 0h-22.461a.802.802 0 0 1-.802-.802v-3.208c0-.443.359-.803.802-.803h22.46c.444 0 .803.36.803.803v3.208c0 .443-.36.802-.802.802Z'
        clipRule='evenodd'
      />
      <path
        fill='#BFCDDD'
        fillRule='evenodd'
        d='M224.928 160.464H153.78a3.221 3.221 0 0 1-3.208-3.209v-18.967a3.22 3.22 0 0 1 3.208-3.209h71.148a3.221 3.221 0 0 1 3.209 3.209v18.967a3.214 3.214 0 0 1-3.209 3.209Z'
        clipRule='evenodd'
      />
      <path
        stroke='#7C90A5'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.124'
        d='M173.455 130.832h49.301m-57.772 0h6.089m-15.121 0h6.75m11.135 29.781h49.3m-57.772 0h6.089m-15.884 0h6.751'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M159.563 151.038a2.409 2.409 0 0 1-1.702-4.109 2.409 2.409 0 0 1 4.109 1.702 2.409 2.409 0 0 1-2.407 2.407Zm7.417 0a2.409 2.409 0 0 1-1.702-4.109 2.409 2.409 0 0 1 4.109 1.702 2.409 2.409 0 0 1-2.407 2.407Zm7.417 0a2.406 2.406 0 0 1-.92-4.631 2.406 2.406 0 0 1 3.328 2.224 2.409 2.409 0 0 1-2.408 2.407Zm48.142 0h-22.461a.798.798 0 0 1-.802-.802v-3.209c0-.443.359-.802.802-.802h22.46c.444 0 .803.36.803.802v3.209c0 .443-.36.802-.802.802Zm-62.976 28.949a2.4 2.4 0 0 1-1.734-.687 2.418 2.418 0 0 1-.722-1.719 2.407 2.407 0 1 1 2.456 2.406Zm7.417 0a2.4 2.4 0 0 1-1.734-.687 2.418 2.418 0 0 1-.722-1.719 2.407 2.407 0 1 1 2.456 2.406Zm7.417 0a2.4 2.4 0 0 1-1.734-.687 2.418 2.418 0 0 1-.722-1.719 2.407 2.407 0 1 1 2.456 2.406Zm48.142 0h-22.461a.798.798 0 0 1-.802-.802v-3.209c0-.443.359-.802.802-.802h22.46c.444 0 .803.36.803.802v3.209c0 .443-.36.802-.802.802Z'
        clipRule='evenodd'
      />
      <path
        fill='#BFCDDD'
        fillRule='evenodd'
        d='M203.04 221.108h-27.372a2.414 2.414 0 0 1-2.406-2.407v-11.448a2.414 2.414 0 0 1 2.406-2.407h27.372a2.414 2.414 0 0 1 2.407 2.407V218.7a2.414 2.414 0 0 1-2.407 2.407'
        clipRule='evenodd'
      />
      <path
        stroke='#A3B4C6'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.124'
        d='M177.259 207.217v11.52m23.791-11.52v11.52'
      />
      <path
        fill='#78B154'
        fillRule='evenodd'
        d='M162.873 267.894a9.421 9.421 0 0 1-9.422-9.422v-14.82a9.426 9.426 0 0 1 9.423-9.326 9.423 9.423 0 0 1 9.422 9.326v14.82a9.421 9.421 0 0 1-9.423 9.422Z'
        clipRule='evenodd'
      />
      <path
        fill='#548733'
        fillRule='evenodd'
        d='M171.22 267.83a9.434 9.434 0 0 1-6.663-2.76 9.415 9.415 0 0 1-2.759-6.663v-3.438a9.426 9.426 0 0 1 9.423-9.326 9.423 9.423 0 0 1 9.422 9.326v3.438a9.424 9.424 0 0 1-9.423 9.423Z'
        clipRule='evenodd'
      />
      <path
        fill='#0A747E'
        fillRule='evenodd'
        d='M181.31 293.666h-27.712a3.214 3.214 0 0 1-2.269-.94 3.203 3.203 0 0 1-.94-2.27V269.79a3.21 3.21 0 0 1 3.209-3.21h27.711a3.21 3.21 0 0 1 3.209 3.21v20.668a3.209 3.209 0 0 1-3.209 3.209'
        clipRule='evenodd'
      />
    </g>
    <defs>
      <clipPath id='clip0_1811_185'>
        <path fill='#fff' d='M0 0h254v294H0z' />
      </clipPath>
    </defs>
  </svg>
);

export default ErrorIllustration;
