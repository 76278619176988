import { useCallback, useEffect, useMemo } from 'react';
import {
  Theme,
  UpdateThemeMutation,
  UpdateThemeMutationVariables,
  useGetConnectAppSettingsQuery,
} from 'generated/types';
import { useAppSelector } from 'redux/store';
import { apolloClient } from 'graphql/apollo/apolloClient';
import { UPDATE_THEME } from 'graphql/user/settings/settings.mutations';
import { selectIsAuthenticated, selectIsAuthInitialized } from 'auth/authSlice';
import useMessageApi from 'components/global/useMessageApi';
import { selectIsServerAvailable } from 'redux/appSlice.ts';
import { addLocalStorageItem } from 'common/localStorage.ts';
import { KEY_THEME } from 'auth/constants.ts';
import { useLocalTheme } from 'pages/util/AppProvider/LocalThemeProvider.tsx';

export interface LocalThemeInfo {
  theme: Theme;
  stored: Date;
}

// Returns the theme from server user settings or local storage
export function useConnectAppTheme() {
  const message = useMessageApi();
  const isAuth = useAppSelector(selectIsAuthenticated);
  const isAuthInit = useAppSelector(selectIsAuthInitialized);
  const isServerAvailable = useAppSelector(selectIsServerAvailable);
  const localTheme = useLocalTheme();

  const { data } = useGetConnectAppSettingsQuery({
    skip: !isAuth || !isAuthInit || !isServerAvailable,
    client: apolloClient,
  });

  const serverTheme = data?.me.connectAppSettings.appearance.theme;

  useEffect(() => {
    if (serverTheme) {
      addLocalStorageItem<LocalThemeInfo>(KEY_THEME, {
        theme: serverTheme,
        stored: new Date(),
      });
    }
  }, [serverTheme]);

  // Get the theme from the server or local storage
  const connectAppTheme = useMemo(() => {
    return serverTheme || localTheme;
  }, [serverTheme, localTheme]);

  const changeConnectAppTheme = useCallback(
    async (newTheme: Theme) => {
      try {
        message.loading({
          key: 'change-theme',
          type: 'loading',
          content: 'Changing theme...',
        });

        await apolloClient.mutate<UpdateThemeMutation, UpdateThemeMutationVariables>({
          mutation: UPDATE_THEME,
          variables: {
            theme: newTheme,
          },
        });

        addLocalStorageItem<LocalThemeInfo>(KEY_THEME, {
          theme: newTheme,
          stored: new Date(),
        });

        message.success({
          key: 'change-theme',
          content: 'Theme updated',
        });
      } catch (err) {
        message.error({
          key: 'change-theme',
          content: 'Could not change theme',
        });
      }
    },
    [message]
  );

  return {
    connectAppTheme,
    changeConnectAppTheme,
    isDarkMode: connectAppTheme === Theme.Dark,
  };
}
