import React from 'react';
import { Select } from 'antd';
import { useConnectAppTheme } from 'themes/useConnectAppTheme';
import { useEnumSelectOptions } from 'components/lib/Select/useEnumSelectOptions';
import { Theme } from 'generated/types';

const ThemeSelect: React.FC = () => {
  const { connectAppTheme, changeConnectAppTheme } = useConnectAppTheme();
  const options = useEnumSelectOptions({
    enumObject: Theme,
    customLabelMessages: {
      LIGHT: {
        id: 'theme.light',
        defaultMessage: 'Light theme',
      },
      DARK: {
        id: 'theme.dark',
        defaultMessage: 'Dark theme',
      },
    },
  });

  return (
    <Select
      value={connectAppTheme}
      style={{ minWidth: 200 }}
      options={options}
      onSelect={(value) => changeConnectAppTheme(value)}
    />
  );
};

export default ThemeSelect;
