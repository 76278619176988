import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { apolloClient } from 'graphql/apollo/apolloClient';
import { RootState } from 'redux/store';
import {
  addLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} from 'common/localStorage';
import { MessageFormatElement } from 'react-intl';
import {
  GetAppTranslationsQuery,
  GetAppTranslationsQueryVariables,
  GetLoginTranslationsQuery,
  GetLoginTranslationsQueryVariables,
} from 'generated/types.tsx';
import { GET_APP_TRANSLATIONS, GET_LOGIN_TRANSLATIONS } from 'graphql/i18n/translation.queries.ts';
import { SupportedLocaleKeys, supportedLocales } from 'i18n/supportedLocaleKeys.ts';

export const DEFAULT_LOCALE: SupportedLocaleKeys = 'en';
export const KEY_LOGIN_TRANSLATIONS = 'vn-login-translations';
export const KEY_APP_TRANSLATIONS = 'vn-app-translations';
export const KEY_USER_LOCALE = 'vn-user-locale';
const VERSION = '1'; // use this to force refresh of translations

export interface LocalTranslations {
  locale: string;
  messages: TranslatedMessages;
  fallbackMessages: Record<string, string>;
  lastFetched: string;
  version: string;
}

export type TranslatedMessages = Record<string, string> | Record<string, MessageFormatElement[]>;

export interface I18nState {
  currentLocale: SupportedLocaleKeys;
  loadingLoginMessages: boolean;
  loadingAppMessages: boolean;
  loginMessages?: TranslatedMessages;
  loginFallbackMessages?: TranslatedMessages;
  appMessages?: TranslatedMessages;
  appFallbackMessages?: TranslatedMessages;
}

const defaultState: I18nState = {
  currentLocale: DEFAULT_LOCALE,
  loadingAppMessages: false,
  loadingLoginMessages: false,
};

interface LoadTranslationsResult {
  currentLocale: SupportedLocaleKeys;
  messages: TranslatedMessages;
  fallbackMessages: Record<string, string>;
  version: string;
}

export interface LoadTranslationsArgs {
  locale: string;
}

export const restoreUserLocale = createAsyncThunk('i18n/restore-user-locale', (arg, thunkAPI) => {
  const localUserLocale = getLocalStorageItem<SupportedLocaleKeys>(KEY_USER_LOCALE);
  return localUserLocale || thunkAPI.rejectWithValue(undefined);
});

export const restoreAppTranslationMessages = createAsyncThunk(
  'i18n/restore-app-translation-messages',
  (arg, thunkAPI) => {
    const localAppTranslationMessages =
      getLocalStorageItem<LocalTranslations>(KEY_APP_TRANSLATIONS);

    if (localAppTranslationMessages === undefined) {
      return thunkAPI.rejectWithValue(undefined);
    }

    if (
      localAppTranslationMessages?.lastFetched &&
      localAppTranslationMessages.version !== VERSION
    ) {
      return thunkAPI.rejectWithValue(undefined);
    }

    return (
      {
        appMessages: localAppTranslationMessages?.messages,
        appFallbackMessages: localAppTranslationMessages?.fallbackMessages,
      } || thunkAPI.rejectWithValue(undefined)
    );
  }
);

export const restoreLoginTranslationMessages = createAsyncThunk(
  'i18n/restore-login-translation-messages',
  (arg, thunkAPI) => {
    const localLoginTranslationMessages =
      getLocalStorageItem<LocalTranslations>(KEY_LOGIN_TRANSLATIONS);

    if (localLoginTranslationMessages === undefined) {
      return thunkAPI.rejectWithValue(undefined);
    }

    if (
      localLoginTranslationMessages?.lastFetched &&
      localLoginTranslationMessages.version !== VERSION
    ) {
      return thunkAPI.rejectWithValue(undefined);
    }

    return (
      {
        loginMessages: localLoginTranslationMessages?.messages,
        loginFallbackMessages: localLoginTranslationMessages?.fallbackMessages,
      } || thunkAPI.rejectWithValue(undefined)
    );
  }
);

export const changeLocale = createAsyncThunk<void, { locale: string }>(
  'i18n/change-locale',
  async (arg, thunkAPI) => {
    removeLocalStorageItem(KEY_LOGIN_TRANSLATIONS);
    removeLocalStorageItem(KEY_APP_TRANSLATIONS);
    await thunkAPI.dispatch(loadLoginMessages({ locale: arg.locale }));
    await thunkAPI.dispatch(loadAppMessages({ locale: arg.locale }));
  }
);

export const refreshAllMessages = createAsyncThunk<void>(
  'i18n/refresh-all-messages',
  async (arg, thunkAPI) => {
    await thunkAPI.dispatch(refreshLoginMessages());
    await thunkAPI.dispatch(refreshAppMessages());
  }
);

export const refreshAppMessages = createAsyncThunk<void>(
  'i18n/refresh-app-messages',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const isAuthenticated = state.auth.isAuthenticated;
    const currentLocale = state.i18n.currentLocale;
    if (isAuthenticated) {
      await thunkAPI.dispatch(loadAppMessages({ locale: currentLocale }));
    }
  }
);

export const refreshLoginMessages = createAsyncThunk<void>(
  'i18n/refresh-login-messages',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const currentLocale = state.i18n.currentLocale;
    removeLocalStorageItem(KEY_LOGIN_TRANSLATIONS);
    await thunkAPI.dispatch(loadLoginMessages({ locale: currentLocale }));
  }
);

export const loadLoginMessages = createAsyncThunk<LoadTranslationsResult, LoadTranslationsArgs>(
  'i18n/load-login-messages',
  async (arg) => {
    const argKey = arg.locale.toLowerCase();
    const validKey = supportedLocales.find((c) => c === arg.locale);
    if (!validKey) {
      throw new Error(`Invalid locale key [${argKey}]`);
    }

    const { data } = await apolloClient.query<
      GetLoginTranslationsQuery,
      GetLoginTranslationsQueryVariables
    >({
      query: GET_LOGIN_TRANSLATIONS,
      variables: {
        languages: validKey === DEFAULT_LOCALE ? [DEFAULT_LOCALE] : [DEFAULT_LOCALE, validKey],
      },
      fetchPolicy: 'network-only',
    });

    const loginTranslations: LocalTranslations = {
      locale: arg.locale,
      lastFetched: new Date().toISOString(),
      messages: {},
      fallbackMessages: {},
      version: VERSION,
    };

    data.loginTranslations.forEach((c) => {
      c.values.forEach((v) => {
        if (v.isFallback && v.translationText) {
          loginTranslations.fallbackMessages[c.key] = v.translationText;
        } else if (!v.isFallback && v.translationText) {
          loginTranslations.messages[c.key] = v.translationText;
        }
      });
    });

    addLocalStorageItem(KEY_USER_LOCALE, arg.locale);
    addLocalStorageItem(KEY_LOGIN_TRANSLATIONS, loginTranslations);

    return {
      currentLocale: validKey,
      messages: loginTranslations.messages,
      fallbackMessages: loginTranslations.fallbackMessages,
      version: VERSION,
    };
  }
);

export const loadAppMessages = createAsyncThunk<LoadTranslationsResult, LoadTranslationsArgs>(
  'i18n/load-app-messages',
  async (arg) => {
    const argKey = arg.locale.toLowerCase();
    const validKey = supportedLocales.find((c) => c === arg.locale);
    if (!validKey) {
      throw new Error(`Invalid locale key [${argKey}]`);
    }

    const { data: dataTranslations } = await apolloClient.query<
      GetAppTranslationsQuery,
      GetAppTranslationsQueryVariables
    >({
      query: GET_APP_TRANSLATIONS,
      variables: {
        languages: validKey === DEFAULT_LOCALE ? [validKey] : [DEFAULT_LOCALE, validKey],
      },
      fetchPolicy: 'network-only',
    });

    const localTranslations: LocalTranslations = {
      locale: arg.locale,
      lastFetched: new Date().toISOString(),
      messages: {},
      fallbackMessages: {},
      version: VERSION,
    };

    dataTranslations.appTranslations.forEach((c) => {
      c.values.forEach((v) => {
        if (v.isFallback && v.translationText) {
          localTranslations.fallbackMessages[c.key] = v.translationText;
        } else if (!v.isFallback && v.translationText) {
          localTranslations.messages[c.key] = v.translationText;
        }
      });
    });

    addLocalStorageItem(KEY_USER_LOCALE, arg.locale);
    addLocalStorageItem(KEY_APP_TRANSLATIONS, localTranslations);

    return {
      currentLocale: validKey,
      messages: localTranslations.messages,
      fallbackMessages: localTranslations.fallbackMessages,
      version: VERSION,
    };
  }
);

const i18nSlice = createSlice({
  name: 'i18n',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(restoreUserLocale.fulfilled, (state, action) => {
      return {
        ...state,
        currentLocale: action.payload,
      };
    });
    builder.addCase(restoreLoginTranslationMessages.fulfilled, (state, action) => {
      return {
        ...state,
        loginMessages: action.payload.loginMessages,
        loginFallbackMessages: action.payload.loginFallbackMessages,
      };
    });
    builder.addCase(restoreAppTranslationMessages.fulfilled, (state, action) => {
      return {
        ...state,
        appMessages: action.payload.appMessages,
        appFallbackMessages: action.payload.appFallbackMessages,
      };
    });
    builder.addCase(loadLoginMessages.pending, (state): I18nState => {
      return {
        ...state,
        loadingLoginMessages: true,
      };
    });
    builder.addCase(loadLoginMessages.fulfilled, (state, action): I18nState => {
      return {
        ...state,
        currentLocale: action.payload.currentLocale,
        loginMessages: action.payload.messages,
        loginFallbackMessages: action.payload.fallbackMessages,
        loadingLoginMessages: false,
      };
    });
    builder.addCase(loadLoginMessages.rejected, (state): I18nState => {
      return {
        ...state,
        loadingLoginMessages: false,
      };
    });

    builder.addCase(loadAppMessages.pending, (state) => ({
      ...state,
      loadingAppMessages: true,
    }));
    builder.addCase(loadAppMessages.fulfilled, (state, action): I18nState => {
      return {
        ...state,
        currentLocale: action.payload.currentLocale,
        appMessages: action.payload.messages,
        appFallbackMessages: action.payload.fallbackMessages,
        loadingAppMessages: false,
      };
    });

    builder.addCase(loadAppMessages.rejected, (state) => ({
      ...state,
      loadingAppMessages: false,
    }));
  },
});

export const i18nSliceReducer = i18nSlice.reducer;

export const selectCurrentLocale = (state: RootState) => state.i18n.currentLocale;

export const selectIsLoginMessagesLoaded = (state: RootState) =>
  state.i18n.loginMessages !== undefined;
export const selectIsAppMessagesLoaded = (state: RootState) => state.i18n.appMessages !== undefined;

const selectLoginMessages = (state: RootState) => state.i18n.loginMessages;
export const selectLoginFallbackMessages = (state: RootState) => state.i18n.loginFallbackMessages;
const selectAppMessages = (state: RootState) => state.i18n.appMessages;
export const selectAppFallbackMessages = (state: RootState) => state.i18n.appFallbackMessages;

export const selectAllFallbackMessages = createSelector(
  [selectLoginFallbackMessages, selectAppFallbackMessages],
  (loginFallbackMessages, appFallbackMessages) => {
    return {
      ...loginFallbackMessages,
      ...appFallbackMessages,
    } as Record<string, string>;
  }
);

export const selectAllMessages = createSelector(
  [selectLoginMessages, selectAppMessages],
  (loginMessages, appMessages) => {
    return {
      ...loginMessages,
      ...appMessages,
    } as Record<string, string>;
  }
);
