import React from 'react';
import { CountryCode } from 'graphql/scalars';
import {
  BE,
  DE,
  DK,
  EE,
  FI,
  GB,
  IS,
  LU,
  NL,
  NO,
  SE,
} from 'country-flag-icons/react/3x2';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { CountryListItemFragment } from 'generated/types';
import useFormatTools from 'i18n/useFormatTools';

interface Props {
  country: Pick<CountryListItemFragment, 'countryCode' | 'nameEnglish'>;
  showTooltip?: boolean;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 1em;
  }
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
`;

const dict: { [key in CountryCode]?: React.ReactNode } = {
  [CountryCode.Belgium]: <BE />,
  [CountryCode.Germany]: <DE />,
  [CountryCode.Denmark]: <DK />,
  [CountryCode.Estonia]: <EE />,
  [CountryCode.Finland]: <FI />,
  [CountryCode.Iceland]: <IS />,
  [CountryCode.Luxembourg]: <LU />,
  [CountryCode.Netherlands]: <NL />,
  [CountryCode.Norway]: <NO />,
  [CountryCode.Sweden]: <SE />,
  [CountryCode.UnitedKingdom]: <GB />,
};

const Flag: React.FC<Props> = ({ country, showTooltip }) => {
  const { formatCountry } = useFormatTools();
  if (!country.countryCode) return null;
  if (!country.nameEnglish) return null;
  if (dict[country.countryCode] === undefined) return null;
  const temp = <Container>{dict[country.countryCode]}</Container>;
  return showTooltip ? (
    <Tooltip title={formatCountry(country.nameEnglish)}>{temp}</Tooltip>
  ) : (
    temp
  );
};

export default Flag;
