import styled from 'styled-components';
import { ScreenMap } from 'antd/es/_util/responsiveObserver';
import React from 'react';
import { pagePadding } from 'components/lib/Page/pagePadding';
import { useScreenInfo } from 'layouts/responsive/useScreenInfo';

const PageTopDiv = styled.div<{ breakpoint: ScreenMap }>`
  background-color: ${(props) => props.theme.ant.colorBgContainer};
  ${pagePadding};
`;

export interface PageHeaderRowProps {
  className?: string;
  children?: React.ReactNode;
}

const PageHeaderRow: React.FC<PageHeaderRowProps> = (props) => {
  const { screenMap: breakpoint } = useScreenInfo();

  return (
    <PageTopDiv breakpoint={breakpoint} className={props.className}>
      {props.children}
    </PageTopDiv>
  );
};

export default PageHeaderRow;
