import { UserListItemFragment } from 'generated/types';

function getUserLetters(user: UserListItemFragment) {
  const name = user.name?.trim() || '';

  if (name.length <= 3) {
    return name;
  }

  return name
    .split(' ', 3)
    .map((p) => p.substring(0, 1))
    .filter((str) => /^[a-zA-Z]+$/.test(str))
    .join('')
    .toUpperCase();
}

export default getUserLetters;
