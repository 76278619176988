import React, { useCallback } from 'react';
import Card from 'components/lib/Card/Card';
import styled from 'styled-components';
import { Form, Space, Switch, Typography } from 'antd';
import { defineMessages, useIntl } from 'react-intl';
import { useGetMachineListItemQuery, useSetMachineMuteEventsMutation } from 'generated/types';
import { SwitchChangeEventHandler } from 'antd/es/switch';
import { gql } from '@apollo/client/core';
import { Level } from 'layouts/matrix/level';
import { MuteMachineIcon } from 'components/icons/Icons';
import useMessageApi from 'components/global/useMessageApi';
import { getFriendlyApolloErrorMessage } from 'graphql/apollo/apolloErrorUtil.ts';
import { useMachinePermissions } from 'auth/RequireMachineAccess.tsx';
const { Text } = Typography;

gql`
  mutation setMachineMuteEvents($input: SetMachineMuteEventsInput!) {
    setMachineMuteEvents(input: $input) {
      machine {
        id
        machineId
        notificationMute
      }
    }
  }
`;

const StyledForm = styled(Form)`
  && {
    .ant-form-item:not(:last-child) {
      margin-bottom: 8px;
    }
    .ant-form-item:last-child {
      margin-bottom: 0;
    }
  }
`;

const messages = defineMessages({
  muted: {
    id: 'event_action_card.muted',
    defaultMessage: 'Muted',
  },
  unmuted: {
    id: 'event_action_card.unmuted',
    defaultMessage: 'Un-muted',
  },
  eventsMute: {
    id: 'event_action_card.events_mute',
    defaultMessage: 'Events mute',
  },
  muteSwitchLabel: {
    id: 'event_action_card.mute_switch_label',
    defaultMessage: 'Mute events',
  },
  muteDescription: {
    id: 'event_action_card.mute_description',
    defaultMessage:
      'Mute events for {machine}. Useful when servicing the machine. Remember to un-mute when done.',
  },
  mutedMachines: {
    id: 'event_action_card.muted_machines',
    defaultMessage: 'Events mute',
  },
});

interface Props {
  retailerId?: number;
  machineId?: number;
}

const EventActionCard: React.FC<Props> = ({ machineId, retailerId }) => {
  const intl = useIntl();
  const message = useMessageApi();
  const { canMuteEvents } = useMachinePermissions();

  const { data, loading } = useGetMachineListItemQuery({
    variables: machineId ? { id: machineId } : undefined,
    skip: !machineId,
  });

  const [setMachineMute, { loading: muting }] = useSetMachineMuteEventsMutation();

  const handleChangeMute = useCallback<SwitchChangeEventHandler>(
    async (checked: boolean) => {
      try {
        if (!machineId) {
          message.error('No machine selected');
          return;
        }
        await setMachineMute({
          variables: {
            input: {
              mute: checked,
              machineId,
            },
          },
          notifyOnNetworkStatusChange: true,
          optimisticResponse: data
            ? {
                __typename: 'Mutation',
                setMachineMuteEvents: {
                  __typename: 'SetMachineMuteEventsPayload',
                  machine: {
                    __typename: 'Machine',
                    id: data.machine.id,
                    machineId: data.machine.machineId,
                    notificationMute: checked,
                  },
                },
              }
            : undefined,
        });
      } catch (err: unknown) {
        message.error(getFriendlyApolloErrorMessage(err, 'Could not mute machine. '));
      }
    },
    [machineId, setMachineMute, data, message]
  );

  const level: Level = machineId ? 'machine' : retailerId ? 'retailer' : 'root';

  return (
    <Card>
      <Space direction={'vertical'} style={{ width: '100%' }}>
        <Text type={'secondary'}>{intl.formatMessage(messages.eventsMute)}</Text>
        <StyledForm>
          {level === 'machine' && (
            <Form.Item>
              <Switch
                loading={!machineId || muting || loading}
                checked={data?.machine.notificationMute}
                onChange={handleChangeMute}
                checkedChildren={<MuteMachineIcon />}
                disabled={!canMuteEvents}
              />
            </Form.Item>
          )}
        </StyledForm>
      </Space>
    </Card>
  );
};

export default EventActionCard;
