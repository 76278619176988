export interface AppConfigEnvironmentVariables {
  ENVIRONMENT: string;
  API_REST_URL: string;
  API_GRAPHQL_URL: string;
  API_GRAPHQL_SUBSCRIPTION_URL: string;
  GOOGLE_MAPS_API_KEY: string;
}

/***
 * Application config injected on runtime
 *
 * How this works:
 * - For local dev we use .env.preview which is copied to output folder
 * - For production, staging and preview we use Kubernetes configmaps to
 *   create runtime environment variables. See configmap.yaml and values.yml
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const appConfig: AppConfigEnvironmentVariables = window._env_;
