import { defineMessages } from 'react-intl';
import { Message } from 'typescript-react-intl';
import { TicketStatus } from 'generated/types';

type TicketStatusMessages = { [key in TicketStatus]: Message };

const statusMsg: TicketStatusMessages = {
  [TicketStatus.Closed]: {
    id: 'ticket_status_closed',
    defaultMessage: 'Closed',
  },
  [TicketStatus.Open]: {
    id: 'ticket_status_open',
    defaultMessage: 'Open',
  },
  [TicketStatus.ClosedAsNotCompleted]: {
    id: 'ticket_status_closed_as_not_completed',
    defaultMessage: 'Closed as not completed',
  },
};

export const ticketStatusMessages = defineMessages(statusMsg);
