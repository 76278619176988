import React, { useCallback } from 'react';
import { Radio, RadioChangeEvent, RadioGroupProps, Space } from 'antd';
import {
  MachineType,
  TicketTypeFullFragment,
  useGetTicketTypesQuery,
} from 'generated/types';
import { getTicketTypeIcon } from 'components/ticket/TicketType/getTicketTypeIcon';
import useConnectIntl from 'i18n/useConnectIntl.ts';

interface Props {
  machineType?: MachineType;

  // quick and dirty solution. Keep original onChange as is (for ant forms) + add this when needed elsewhere
  onChangeTicketType?: (ticketType: TicketTypeFullFragment) => void;
}

const TicketTypeRadio: React.FC<RadioGroupProps & Props> = (props) => {
  const { children, machineType, onChangeTicketType, onChange, ...rest } =
    props;

  const { data } = useGetTicketTypesQuery({
    variables: {
      machineType: machineType,
    },
    notifyOnNetworkStatusChange: true,
  });

  const intl = useConnectIntl();

  const handleChangeTicketType = useCallback(
    (event: RadioChangeEvent) => {
      onChange?.(event);
      if (onChangeTicketType) {
        const item = data?.allTicketTypes.find(
          (c) => c.ticketTypeId === event.target.value
        );
        if (item) {
          onChangeTicketType(item);
        }
      }
    },
    [onChange, onChangeTicketType, data?.allTicketTypes]
  );

  return (
    <Radio.Group {...rest} onChange={handleChangeTicketType}>
      <Space direction={'vertical'}>
        {data?.allTicketTypes.map((c) => (
          <Radio key={c.ticketTypeId} value={c.ticketTypeId}>
            <Space>
              <div>{getTicketTypeIcon(c)}</div>
              <div>
                {intl.formatMsg({
                  id: c.nameKeyI18n,
                })}
              </div>
            </Space>
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  );
};

export default TicketTypeRadio;
