import React, { useCallback, useReducer } from 'react';
import CheckboxFormField from 'components/ticket/TicketForm/CheckboxFormField';
import { Row, Empty, Col, Skeleton } from 'antd';
import {
  CheckboxFormFieldItemFragment,
  InspectionFormFieldItemFragment,
  OperatorInspectionValues,
  useGetTicketFormQuery,
  useSetTicketCheckboxFormFieldValueMutation,
  useSetTicketInspectionFormFieldValueMutation,
} from 'generated/types';
import { useIntl } from 'react-intl';
import InspectionFormField from 'components/ticket/TicketForm/InspectionFormField';
import useMessageApi from 'components/global/useMessageApi';

interface Props {
  ticketId: number;
  showAllDisabledFields?: boolean;
}

type LoadingState = { [key: string]: boolean };

type LoadingStateAction = {
  type: 'set-loading-state';
  id: string;
  value: boolean;
};

const TicketForm: React.FC<Props> = (props) => {
  const { ticketId } = props;
  const intl = useIntl();

  const { data, loading } = useGetTicketFormQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      ticketId: ticketId,
    },
  });

  const [loadingState, dispatch] = useReducer(
    (state: LoadingState, action: LoadingStateAction): LoadingState => {
      switch (action.type) {
        case 'set-loading-state':
          return {
            ...state,
            [action.id]: action.value,
          };
      }
    },
    {}
  );

  const message = useMessageApi();

  const [setTicketCheckboxFormFieldValue] =
    useSetTicketCheckboxFormFieldValueMutation({
      notifyOnNetworkStatusChange: true,
    });

  const [setTicketInspectionFormFieldValue] =
    useSetTicketInspectionFormFieldValueMutation({
      notifyOnNetworkStatusChange: true,
    });

  const handleChangeInspection = useCallback(
    async (
      field: InspectionFormFieldItemFragment,
      value: OperatorInspectionValues
    ) => {
      try {
        dispatch({
          type: 'set-loading-state',
          id: field.id,
          value: true,
        });

        const mockFields = [...(data?.ticket.formFields || [])];

        const originalIndex = mockFields.findIndex((c) => c.id === field.id);
        const original = mockFields[originalIndex];
        mockFields.splice(originalIndex, 1, {
          ...original,
          __typename: 'InspectionFormField', // type guard
          inspectionValue: value,
        });

        await setTicketInspectionFormFieldValue({
          variables: {
            input: {
              ticketId: ticketId,
              ticketFormFieldId: field.formFieldId,
              value: value,
            },
          },
          optimisticResponse: data
            ? {
                __typename: 'Mutation',
                setTicketInspectionFormFieldValue: {
                  __typename: 'Ticket',
                  id: data.ticket.id,
                  ticketId: data.ticket.ticketId,
                  formFields: [...mockFields],
                },
              }
            : undefined,
        });
      } catch (err) {
        message.error(
          intl.formatMessage({
            id: 'ticket_form.set_ticket_field_value_error',
            defaultMessage: 'Could not set ticket field value',
          })
        );
      } finally {
        dispatch({
          type: 'set-loading-state',
          id: field.id,
          value: false,
        });
      }
    },
    [data, intl, setTicketInspectionFormFieldValue, ticketId, message]
  );

  const handleChangeCheckBox = useCallback(
    async (field: CheckboxFormFieldItemFragment, value: boolean) => {
      try {
        dispatch({
          type: 'set-loading-state',
          id: field.id,
          value: true,
        });
        const mockFields = [...(data?.ticket.formFields || [])];

        const originalIndex = mockFields.findIndex((c) => c.id === field.id);
        const original = mockFields[originalIndex];
        mockFields.splice(originalIndex, 1, {
          ...original,
          __typename: 'CheckBoxFormField', // type guard
          checkedValue: value,
        });

        await setTicketCheckboxFormFieldValue({
          variables: {
            input: {
              ticketId,
              ticketFormFieldId: field.formFieldId,
              value: value,
            },
          },
          optimisticResponse: data
            ? {
                __typename: 'Mutation',
                setTicketCheckBoxFormFieldValue: {
                  id: data.ticket.id,
                  ticketId: data.ticket.ticketId,
                  __typename: 'Ticket',
                  formFields: [...mockFields],
                },
              }
            : undefined,
        });
      } catch (err: unknown) {
        console.error(err);
        message.error(
          intl.formatMessage({
            id: 'ticket_form.set_ticket_field_value_error',
            defaultMessage: 'Could not set ticket field value',
          })
        );
      } finally {
        dispatch({
          type: 'set-loading-state',
          id: field.id,
          value: false,
        });
      }
    },
    [setTicketCheckboxFormFieldValue, ticketId, intl, data, message]
  );

  if (loading) {
    return (
      <Skeleton
        paragraph={{
          rows: 7,
        }}
      />
    );
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        {data?.ticket.formFields?.length === 0 && <Empty key={'empty_one'} />}
        {data?.ticket.formFields?.map((field) => {
          if (field.__typename === 'CheckBoxFormField') {
            return (
              <CheckboxFormField
                field={field}
                key={field.id}
                loading={loadingState[field.id]}
                onChange={(value) => handleChangeCheckBox(field, value)}
              />
            );
          } else if (field.__typename === 'InspectionFormField') {
            return (
              <InspectionFormField
                key={field.id}
                field={field}
                loading={loadingState[field.id]}
                onChange={(value) => handleChangeInspection(field, value)}
              />
            );
          } else {
            return (
              <Col key={field.id} xs={24}>
                unsupported field {field.__typename}
              </Col>
            );
          }
        })}
      </Row>
    </>
  );
};

export default TicketForm;
