import React, { useCallback, useEffect } from 'react';
import { Col, Row, Typography } from 'antd';
import styled from 'styled-components';
import PageHeaderRow from 'components/lib/Page/PageHeaderRow';
import MatrixBreadcrumbs from 'layouts/MainLayout/MatrixBreadcrumbs/MatrixBreadcrumbs';
import PageTitleRow from 'layouts/components/Page/PageTitleRow';
import PageTabs from 'components/lib/Page/PageTabs';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import SearchButtonMobile from 'layouts/MainLayout/SearchButtonMobile/SearchButtonMobile';
import { Helmet } from 'react-helmet-async';
import TicketList from 'components/ticket/TicketList/TicketList';
import { FormattedMessage } from 'react-intl';
import TicketMap from 'components/ticket/TicketMap/TicketMap';
import { Category, categoryIcons, categoryMessages } from 'layouts/matrix/category';
import { useMatrixCategory } from 'layouts/matrix/useMatrixCategory';
import useTicketDisplayMode from 'components/ticket/TicketFilter/useTicketDisplayMode';
import useIsMobile from 'layouts/responsive/useIsMobile';
import tabMessages from 'pages/matrix/tabMessages';
import { TypedPageTab } from 'components/lib/Page/typedPageTab';
import PageTab from 'components/lib/Page/PageTab';
import DataTransferList from 'components/datatransfer/DataTransferList';
import { isRootTab } from 'layouts/matrix/matrix';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { appVersion } from 'common/appVersion.ts';
import { selectRootPermissions } from 'auth/authSlice.ts';
import { useAppSelector } from 'redux/store.ts';
import useMatrixNav from 'layouts/matrix/useMatrixNav.ts';
import RootActionsCard from 'components/root/RootActionsCard/RootActionsCard.tsx';
import LastPredictionCard from 'components/prediction/LastPredictionCard.tsx';
import OrderLineTool from 'components/ticket/TicketAccounting/OrderLineTool/OrderLineTool.tsx';
const { Text, Title } = Typography;

const BreadcrumbRow = styled(PageHeaderRow)`
  flex: 0 0 46px; // Same as tabs
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
`;

const LogoAndTitleContainer = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  position: relative;
  flex: 1 1 auto;
  height: ${(props) => (!props.$isMobile ? ' 80px' : ' 32px')};
  overflow: hidden;
  margin-right: 16px;
`;

const TitleAndSubtitleContainer = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

const SearchButtonContainerMobile = styled.div`
  flex: 0 0 auto;
`;

const IconContainer = styled.div<{ $isMobile?: boolean }>`
  background-color: ${(props) => props.theme.ant.colorBgElevated};
  width: ${(props) => (props.$isMobile ? 35 : 80)}px;
  height: ${(props) => (props.$isMobile ? 35 : 80)}px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: ${(props) => (props.$isMobile ? 20 : 60)}px;
    height: ${(props) => (props.$isMobile ? 20 : 60)}px;
  }
`;

const RootPage: React.FC = () => {
  const intl = useConnectIntl();
  const navigate = useNavigate();
  const category = useMatrixCategory();
  const isMobile = useIsMobile();
  const { ticketDisplayMode } = useTicketDisplayMode();
  const { tab } = useParams();
  const activeTab = tab || 'operational';
  const isValidRootTab = isRootTab(activeTab);
  const permissions = useAppSelector(selectRootPermissions);
  const { getDefaultRootUrl } = useMatrixNav();
  const location = useLocation();

  useEffect(() => {
    const defaultRootUrl = getDefaultRootUrl();
    if (location.pathname !== defaultRootUrl && location.pathname === '/') {
      navigate(defaultRootUrl);
    }
  }, [permissions, navigate, getDefaultRootUrl, location.pathname]);

  const handleTabChange = useCallback(
    (tabKey: string) => {
      navigate(`/${tabKey === 'operational' ? '' : tabKey}`);
    },
    [navigate]
  );

  if (!category || !isValidRootTab) {
    return <Navigate to={'/'} />;
  }

  const title = category ? intl.formatMsg(categoryMessages[category]) : '';

  const tabs: { [key in Category]: TypedPageTab[] } = {
    operational: [
      {
        key: 'operational',
        active: activeTab === 'operational',
        label: intl.formatMsg(categoryMessages.operational),
        children: (
          <PageTab>
            <Row gutter={[16, 16]} wrap={true} style={{ paddingBottom: 64 }}>
              <Col
                xs={24}
                xl={{
                  offset: 16,
                  span: 8,
                }}
              >
                <RootActionsCard />
              </Col>
            </Row>
          </PageTab>
        ),
      },
      {
        key: 'tickets',
        disabled: !permissions?.tabs?.operational.tickets,
        active: activeTab === 'tickets',
        label: intl.formatMsg(tabMessages.tickets),
        children: (
          <PageTab fullscreen={ticketDisplayMode === 'map'}>
            {ticketDisplayMode === 'list' && (
              <TicketList canCreateTicket={permissions?.canCreateTicket || false} />
            )}
            {ticketDisplayMode === 'map' && <TicketMap />}
            {ticketDisplayMode === 'order-lines' && <OrderLineTool />}
          </PageTab>
        ),
      },
      // {
      //   key: 'events',
      //   disabled: !permissions?.tabs?.operational.events,
      //   active: activeTab === 'events',
      //   label: intl.formatMsg(tabMessages.events),
      //   children: <PageTab>events</PageTab>,
      // },
      // {
      //   key: 'properties',
      //   disabled: !permissions?.tabs?.operational.properties,
      //   active: activeTab === 'properties',
      //   label: intl.formatMsg(tabMessages.properties),
      //   children: <PageTab>properties</PageTab>,
      // },
      // {
      //   key: 'users',
      //   disabled: !permissions?.tabs?.operational.users,
      //   active: activeTab === 'users',
      //   label: intl.formatMsg(tabMessages.users),
      //   children: <PageTab>users</PageTab>,
      // },
      {
        key: 'integrations',
        disabled: !permissions?.tabs?.operational.integrations,
        active: activeTab === 'integrations',
        label: intl.formatMsg(tabMessages.integrations),
        children: (
          <PageTab>
            <DataTransferList />
          </PageTab>
        ),
      },
      {
        key: 'predictions',
        disabled: !permissions?.tabs.operational.predictions,
        active: activeTab === 'predictions',
        label: intl.formatMessage(tabMessages.predictions),
        children: (
          <PageTab>
            <LastPredictionCard />
          </PageTab>
        ),
      },
    ],
    commercial: [
      {
        key: 'commercial',
        active: activeTab === 'commercial',
        label: intl.formatMsg(categoryMessages.commercial),
        children: <PageTab>{''}</PageTab>,
      },
    ],
    logistics: [
      {
        key: 'logistics',
        active: activeTab === 'logistics',
        label: intl.formatMsg(categoryMessages.logistics),
        children: <PageTab>{''}</PageTab>,
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <BreadcrumbRow>
        <MatrixBreadcrumbs />
      </BreadcrumbRow>
      <PageTitleRow>
        <LogoAndTitleContainer $isMobile={isMobile}>
          <IconContainer $isMobile={isMobile}>{categoryIcons[category]}</IconContainer>
          <TitleAndSubtitleContainer>
            <Title level={3} style={{ marginBottom: 0 }}>
              {title}
            </Title>
            {!isMobile && (
              <Text type={'secondary'}>
                <FormattedMessage
                  id={'root_page.vendanor_connect'}
                  description={'The app name used in root page'}
                  defaultMessage={'Vendanor Connect v{version}'}
                  values={{
                    version: appVersion.version,
                  }}
                />
              </Text>
            )}
          </TitleAndSubtitleContainer>
        </LogoAndTitleContainer>
        {isMobile && (
          <SearchButtonContainerMobile>
            <SearchButtonMobile />
          </SearchButtonContainerMobile>
        )}
      </PageTitleRow>
      <PageTabs
        defaultActiveKey={category}
        activeKey={activeTab}
        onChange={handleTabChange}
        items={tabs[category]}
      />
    </>
  );
};

export default RootPage;
