import React from 'react';
import { TicketStatus } from 'generated/types';
import { Tooltip } from 'antd';
import Tag from 'components/lib/Tag/Tag';
import {
  TicketCloseAsNotCompletedIcon,
  TicketCloseIcon,
  TicketOpenIcon,
} from 'components/ticket/TicketDiscussion/ticketIcons';
import useAntThemeToken from 'themes/useAntThemeToken';
import { ticketStatusMessages } from 'components/ticket/TicketStatusIcon/ticketStatusMessages.ts';
import useConnectIntl from 'i18n/useConnectIntl.ts';

/***
 * TicketStatus
 * Renders a ticket status icon or tag with icon
 */
const TicketStatusIcon: React.FC<{
  status: TicketStatus;
  showText?: boolean;
}> = (props) => {
  const { showText, status } = props;
  const intl = useConnectIntl();

  const token = useAntThemeToken();
  const isOpen = status === TicketStatus.Open;

  const getTicketStatusLabel = (status: TicketStatus) => {
    switch (status) {
      case TicketStatus.Open:
        return intl.formatMsg(ticketStatusMessages.OPEN);
      case TicketStatus.Closed:
        return intl.formatMsg(ticketStatusMessages.CLOSED);
      case TicketStatus.ClosedAsNotCompleted:
        return intl.formatMsg(ticketStatusMessages.CLOSED_AS_NOT_COMPLETED);
    }
  };

  const getTicketStatusIcon = (status: TicketStatus) => {
    switch (status) {
      case TicketStatus.Open:
        return <TicketOpenIcon />;
      case TicketStatus.Closed:
        return <TicketCloseIcon />;
      case TicketStatus.ClosedAsNotCompleted:
        return <TicketCloseAsNotCompletedIcon />;
      default:
        throw new Error('Invalid status');
    }
  };

  const label = getTicketStatusLabel(status);
  const c1 = token.colorSuccess;
  const c2 = token.colorTextSecondary;
  const standaloneIconColor = isOpen ? c1 : c2;
  const tagColor = isOpen ? 'success' : 'default';

  return (
    <Tooltip title={label}>
      {showText && (
        <Tag icon={getTicketStatusIcon(status)} color={tagColor}>
          {label}
        </Tag>
      )}
      {!showText && (
        <span style={{ color: standaloneIconColor }}>{getTicketStatusIcon(status)}</span>
      )}
    </Tooltip>
  );
};

export default TicketStatusIcon;
