import React from 'react';
import { UserRootPermissionsFullFragment } from 'generated/types.tsx';
import { useAppSelector } from 'redux/store.ts';
import { selectRootPermissions } from 'auth/authSlice.ts';
import NotAuthorizedPage from 'pages/util/NotAuthorizedPage/NotAuthorizedPage.tsx';

interface Props {
  children?: React.ReactNode;
  authorize: (permissions: UserRootPermissionsFullFragment) => boolean;
}

const RequireRootPermission: React.FC<Props> = ({ children, authorize }) => {
  const permissions = useAppSelector(selectRootPermissions);

  if (!permissions || !authorize(permissions)) {
    return <NotAuthorizedPage />;
  }

  return children;
};

export default RequireRootPermission;
