import { Select } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  FullLanguageFragment,
  useGetMeAndLanguagesQuery,
  useUpdateMyLanguageMutation,
} from 'generated/types';
import { useAppDispatch } from 'redux/store';
import { changeLocale } from 'i18n/i18nSlice';
import useMessageApi from 'components/global/useMessageApi';

const LanguageSelect: React.FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const messageApi = useMessageApi();

  const { data: dataLang, loading: loadingLang } = useGetMeAndLanguagesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const { options, languageDict } = useMemo(() => {
    const languageDict: { [languageId: string]: FullLanguageFragment } = {};
    const options: Array<{ label: string; value: string }> = [];
    dataLang?.allLanguages?.nodes?.forEach((c) => {
      options.push({
        label: c.nameNative,
        value: c.id,
      });
      languageDict[c.id] = c;
    });

    return {
      options,
      languageDict,
    };
  }, [dataLang]);

  const [updateMyLanguage] = useUpdateMyLanguageMutation({
    notifyOnNetworkStatusChange: true,
  });

  const handleSelect = useCallback(
    async (languageId: string) => {
      try {
        await updateMyLanguage({
          variables: {
            input: {
              languageId: languageId,
            },
          },
          optimisticResponse: dataLang
            ? {
                __typename: 'Mutation',
                updateMyLanguage: {
                  __typename: 'UpdateMyLanguagePayload',
                  user: {
                    ...dataLang.me,
                    languageId: languageId,
                  },
                },
              }
            : undefined,
        });

        const locale = languageDict[languageId].code;

        await dispatch(changeLocale({ locale }));
        messageApi.success(
          intl.formatMessage({
            id: 'language_select.language_update_ok',
            defaultMessage: 'Language updated',
          })
        );
      } catch (err) {
        messageApi.error(
          intl.formatMessage({
            id: 'language_select.language_update_fail',
            defaultMessage: 'Could not change user language',
          })
        );
      }
    },
    [languageDict, dataLang, dispatch, intl, updateMyLanguage, messageApi]
  );

  return (
    <>
      <Select
        style={{ minWidth: 200 }}
        placeholder={intl.formatMessage({
          id: 'language_select.select_language',
          defaultMessage: 'Language',
        })}
        options={options}
        loading={loadingLang}
        value={dataLang?.me.languageId}
        onSelect={handleSelect}
      />
    </>
  );
};

export default LanguageSelect;
