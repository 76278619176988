import { defineMessages } from 'react-intl';

const machineMessages = defineMessages({
  detailsTitle: {
    id: 'machine.details_title',
    defaultMessage: 'Details',
  },
  deliveryDate: {
    id: 'machine.delivery_date',
    defaultMessage: 'Delivered',
  },
  operationDate: {
    id: 'machine.operation_date',
    defaultMessage: 'In operation',
  },
  warrantyExpiryDate: {
    id: 'machine.warranty_expiry_date',
    defaultMessage: 'Warranty',
  },
  errorNoRetailerId: {
    id: 'machine.error_no_retailer_id',
    defaultMessage: 'No retailer ID',
  },
  invalidMachineId: {
    id: 'machine.invalid_machine_id',
    defaultMessage: 'Invalid machine ID',
  },
});

export default machineMessages;
