import * as Sentry from '@sentry/react';
import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import api, { LoginRequest, LoginResponse } from 'api/api';
import { message } from 'antd';
import { addLocalStorageItem, removeLocalStorageItem } from 'common/localStorage';
import { apolloClient } from 'graphql/apollo/apolloClient';
import { IntlShape } from 'react-intl/src/types';
import { KEY_APP_TRANSLATIONS } from 'i18n/i18nSlice';
import {
  GetUserAndSettingsQuery,
  GetUserAndSettingsQueryVariables,
  UserRootPermissionsFullFragment,
} from 'generated/types';
import { GET_USER_AND_SETTINGS } from 'graphql/user/user.queries';
import {
  KEY_DRAFT_CREATE_TICKET_FORM,
  KEY_DRAFT_CREATE_TICKET_OPENING_COMMENT,
  KEY_LOGIN,
  KEY_THEME,
} from 'auth/constants';
import checkAccessToken from 'auth/checkAccessToken';
import { clearRefreshFlagCookie, setRefreshFlagCookie } from 'auth/refreshFlagCookie';
import { logoutChannel } from 'auth/useAuthSync';
import { HttpStatusCode, isAxiosError } from 'axios';
import { machineNavSlice } from 'redux/machineNavSlice.ts';
import { removeTicketCommentDrafts } from 'components/ticket/TicketDiscussion/useAutoSaveTicketComment.ts';

export interface AuthState {
  publicSaltError?: SerializedError;
  publicSalt?: string;
  isAuthenticated: boolean;
  isLoggingIn: boolean;
  isAuthInitialized: boolean; // true if the first call to refresh-token is finished
  permissions?: UserRootPermissionsFullFragment;
}

const defaultState: AuthState = {
  isLoggingIn: false,
  isAuthInitialized: false,
  isAuthenticated: false,
};

// isAuthenticated is true if we have a valid refresh_token. The access_token might be expired even if isAuthenticated is true.
export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectIsAuthInitialized = (state: RootState) => state.auth.isAuthInitialized;
export const selectPublicSalt = (state: RootState) => state.auth.publicSalt;
export const selectIsLoggingIn = (state: RootState) => state.auth.isLoggingIn;
export const selectRootPermissions = (state: RootState) => state.auth.permissions;

// thunks:
export const getPublicSalt = createAsyncThunk('auth/getPublicSalt', async (arg, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const salt = selectPublicSalt(state);
  if (salt) {
    return salt;
  }

  // NOTE: This can throw AxiosError, the thunk will return a "SerializedError" object
  return await api.getPublicSalt();
});

export const restoreAuth = createAsyncThunk('auth/restore', async () => {
  return checkAccessToken();
});

// https://stackoverflow.com/questions/63439021/handling-errors-with-redux-toolkit
export const login = createAsyncThunk(
  'auth/login',
  async (request: LoginRequest & { intl: IntlShape }, { rejectWithValue }) => {
    const { intl, ...rest } = request;

    const unknownError: LoginError = {
      status: 500,
      message: 'Unknown error',
    };

    try {
      const result = await api.login(rest); // might throw AxiosError
      // Store access token in local storage
      addLocalStorageItem<LoginResponse>(KEY_LOGIN, result.data);
      // Add cookie with the same lifetime as refresh-token:
      setRefreshFlagCookie('login', result.data.remember, result.data.expires);
      return result.data;
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        const temp: LoginError = {
          status: err.response?.status ?? 500,
          message: err.response?.data ?? 'Unknown error',
        };
        return rejectWithValue(temp);
      } else {
        return rejectWithValue(unknownError);
      }
    }
  }
);

export interface LoginError {
  status:
    | HttpStatusCode.Unauthorized
    | HttpStatusCode.TooManyRequests
    | HttpStatusCode.Locked
    | HttpStatusCode.Forbidden
    | HttpStatusCode.InternalServerError
    | HttpStatusCode.PreconditionFailed;
  message?: string;
}

export function isLoginError(err: unknown): err is LoginError {
  return (
    (err as LoginError).status !== undefined &&
    !isNaN((err as LoginError).status) &&
    (err as LoginError).message !== undefined
  );
}

// This thunk loads authenticated user info before rendering the logged in part of the app
export const loadUserInfo = createAsyncThunk('app/load-user', async (arg, thunkAPI) => {
  try {
    console.log('loading user');
    const { data } = await apolloClient.query<
      GetUserAndSettingsQuery,
      GetUserAndSettingsQueryVariables
    >({
      query: GET_USER_AND_SETTINGS,
      fetchPolicy: 'network-only',
    });

    Sentry.setUser({
      id: data.me.userId,
      name: data.me.name,
      language: data.me.language.code,
    });
    console.log('👋 Welcome ' + data.me.name);

    // Set user stored filters in machine nav (no save)
    thunkAPI.dispatch(
      machineNavSlice.actions.setFilter({
        showInactiveMachines: data.me.connectAppSettings.filters.showActiveMachines || false,
        showRetailersWithoutMachines:
          data.me.connectAppSettings.filters.showInactiveRetailers || false,
      })
    );

    return {
      permissions: data.me.permissions,
    };
  } catch (err) {
    console.error('error loading user', err);
    throw err;
  }
});

export const refreshUserInfo = createAsyncThunk('app/refresh-user', async () => {
  try {
    console.log('refresh user info');
    const { data } = await apolloClient.query<
      GetUserAndSettingsQuery,
      GetUserAndSettingsQueryVariables
    >({
      query: GET_USER_AND_SETTINGS,
      fetchPolicy: 'network-only',
    });

    console.log('🔑 Reloaded access rights for ' + data.me.name);

    return {
      permissions: data.me.permissions,
    };
  } catch (err) {
    console.error('error refreshing user', err);
    throw err;
  }
});

const cleanAuthLocalStorage = () => {
  removeLocalStorageItem(KEY_LOGIN);
  removeLocalStorageItem(KEY_APP_TRANSLATIONS);
};

const cleanDrafts = () => {
  removeLocalStorageItem(KEY_DRAFT_CREATE_TICKET_OPENING_COMMENT);
  removeLocalStorageItem(KEY_DRAFT_CREATE_TICKET_FORM);
  removeTicketCommentDrafts();
};

const cleanThemeLocalStorage = () => {
  removeLocalStorageItem(KEY_THEME);
};

export const logout = createAsyncThunk(
  'auth/logout',
  async (options: { skipSignal: boolean } | undefined) => {
    try {
      if (navigator.onLine) {
        try {
          await api.logout();
        } catch (err) {
          console.warn('Error when logging out from server:', err);
        }
      }
      cleanAuthLocalStorage();
      cleanDrafts();
      cleanThemeLocalStorage();
      clearRefreshFlagCookie();
      apolloClient.stop(); // stops any active GraphQL requests
      await apolloClient.resetStore(); // clears local cache. (hmm, app will be faster if we remove this and just keep the cache? :) But I guess cached data will be there if I log on with a different user? :-O )
      if (!options?.skipSignal) {
        try {
          logoutChannel.postMessage('good bye');
        } catch (err) {
          console.warn('Error when sending logout signal', err);
        }
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        message.error('Error when logging out: ' + err.message);
      }
    }
  }
);

// reducer:
const authSlice = createSlice({
  name: 'auth',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPublicSalt.fulfilled, (state, action): AuthState => {
        return {
          ...state,
          publicSalt: action.payload,
          publicSaltError: undefined,
        };
      })
      .addCase(getPublicSalt.rejected, (state, action): AuthState => {
        return {
          ...state,
          publicSalt: undefined,
          publicSaltError: action.error,
        };
      })
      .addCase(getPublicSalt.pending, (state) => {
        return {
          ...state,
          publicSalt: undefined,
          publicSaltError: undefined,
        };
      })
      .addCase(restoreAuth.fulfilled, (state, action): AuthState => {
        return {
          ...state,
          isAuthInitialized: true,
          isAuthenticated: action.payload.hasRefreshFlagCookie, // NEW
        };
      })
      .addCase(restoreAuth.rejected, (state): AuthState => {
        return {
          ...state,
          isAuthInitialized: true,
          permissions: undefined,
          isAuthenticated: false,
        };
      })
      .addCase(login.pending, (state): AuthState => {
        return {
          ...state,
          isLoggingIn: true,
        };
      })
      .addCase(login.rejected, (state): AuthState => {
        return {
          ...state,
          isAuthenticated: false,
          permissions: undefined,
          isLoggingIn: false,
        };
      })
      .addCase(login.fulfilled, (state): AuthState => {
        return {
          ...state,
          isAuthenticated: true,
          isLoggingIn: false,
        };
      })
      .addCase(logout.fulfilled, (state): AuthState => {
        return {
          ...state,
          isAuthenticated: false,
          isLoggingIn: false,
          permissions: undefined,
        };
      })
      .addCase(logout.rejected, (state): AuthState => {
        return {
          ...state,
          isAuthenticated: false,
          isLoggingIn: false,
          permissions: undefined,
        };
      })
      .addCase(loadUserInfo.fulfilled, (state, action): AuthState => {
        return {
          ...state,
          permissions: action.payload.permissions,
        };
      })
      .addCase(loadUserInfo.rejected, (state): AuthState => {
        return {
          ...state,
          permissions: undefined,
        };
      });
  },
});

export const authSliceReducer = authSlice.reducer;
