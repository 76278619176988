import React, { useEffect, useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import {
  MachineMapItemFragmentDoc,
  MinimalPageInfoFragmentDoc,
  TicketMapItemFragmentDoc,
  useGetTicketMapQuery,
  useOnTicketCreatedSubscription,
  useOnTicketUpdatedSubscription,
} from 'generated/types';
import TicketMarker, { TicketMarkerItem } from 'components/ticket/TicketMap/TicketMarker';
import useTicketFilter from 'components/ticket/TicketFilter/useTicketFilter';
import { useGoogleMap } from '@react-google-maps/api';
import TicketMarkerInfoWindow from 'components/ticket/TicketMap/TicketMarkerInfoWindow';
import TicketMapContextFilter from 'components/ticket/TicketMap/ticketMapContextFilter.ts';
import { useAppSelector } from 'redux/store.ts';
import { selectSearchTerm } from 'redux/machineNavSlice.ts';

export interface LoadingTicketMarkerLayerData {
  loading: boolean;
  ticketCount?: number;
  machineCount?: number;
}

interface Props {
  contextFilter?: TicketMapContextFilter;
  openInfoWindowOnHover?: boolean;
  onLoading?: (data: LoadingTicketMarkerLayerData) => void;
}

// Query for all machines with one most severe ticket:
gql`
  query GetTicketMap(
    $retailerId: Int
    $machineId: Int
    $serialNo: String
    $machineSearch: String
    $assignedToUserIds: [UUID!]
    $closedFrom: DateTime
    $closedTo: DateTime
    $createdFrom: DateTime
    $createdTo: DateTime
    $search: String
    $ticketStatuses: [TicketStatus!]
    $ticketTypeIds: [Int!]
    $ticketTags: [String!]
    $includeUnassignedUsers: Boolean
    $ticketSeverities: [TicketSeverity!]
    $dueTo: DateTime
    $cursor: String
  ) {
    allTickets(
      filter: {
        assignedToUserIds: $assignedToUserIds
        closedFrom: $closedFrom
        closedTo: $closedTo
        createdFrom: $createdFrom
        createdTo: $createdTo
        search: $search
        ticketStatuses: $ticketStatuses
        ticketTypeIds: $ticketTypeIds
        ticketTags: $ticketTags
        includeUnassignedUsers: $includeUnassignedUsers
        ticketSeverities: $ticketSeverities
        includeNoDueDate: true
        dueTo: $dueTo
        retailerId: $retailerId
        machineId: $machineId
        serialNo: $serialNo
      }
      sortField: OPENED
      sortDirection: DESCENDING
    ) {
      totalCount # we query only for total count of all tickets here, we need it for showing the total in the map
    }
    allMachines(
      allMachineFilter: {
        retailerId: $retailerId
        search: $machineSearch
        active: null # both active and inactive!
        machineId: $machineId
        serialNo: $serialNo
        ticketFilter: {
          assignedToUserIds: $assignedToUserIds
          closedFrom: $closedFrom
          closedTo: $closedTo
          createdFrom: $createdFrom
          createdTo: $createdTo
          search: $search
          ticketStatuses: $ticketStatuses
          ticketTypeIds: $ticketTypeIds
          ticketTags: $ticketTags
          includeUnassignedUsers: $includeUnassignedUsers
          ticketSeverities: $ticketSeverities
          includeNoDueDate: true
          dueTo: $dueTo
        }
      }
      first: 50
      after: $cursor
    ) {
      totalCount
      pageInfo {
        ...MinimalPageInfo
      }
      edges {
        node {
          ...MachineMapItem
          tickets(
            filter: {
              assignedToUserIds: $assignedToUserIds
              closedFrom: $closedFrom
              closedTo: $closedTo
              createdFrom: $createdFrom
              createdTo: $createdTo
              search: $search
              ticketStatuses: $ticketStatuses
              ticketTypeIds: $ticketTypeIds
              ticketTags: $ticketTags
              includeUnassignedUsers: $includeUnassignedUsers
              ticketSeverities: $ticketSeverities
              includeNoDueDate: true
              dueTo: $dueTo
            }
            sortField: SEVERITY
            sortDirection: ASCENDING
            first: 1
          ) {
            # totalCount # NOTE: this is the bad guy, one SQL query per machine!
            edges {
              node {
                ...TicketMapItem
              }
            }
          }
        }
      }
    }
  }
  ${MinimalPageInfoFragmentDoc}
  ${MachineMapItemFragmentDoc}
  ${TicketMapItemFragmentDoc}
`;

/***
 * TicketMarkerLayer
 * This component is responsible for rendering tickets on the map.
 * We need two queries, one for root and retailer level, and one for machine level.
 * TODO:
 * - In theory we could get tickets in our result without coordinates. How to show?
 *    - Currently logged to console
 *    - We should add a machine property "hide from public map", and make coordinates mandatory
 * @param props
 * @constructor
 */
const TicketMarkerLayer: React.FC<Props> = (props) => {
  const { contextFilter, openInfoWindowOnHover, onLoading } = props;
  const { ticketFilter } = useTicketFilter();
  const searchTerm = useAppSelector(selectSearchTerm);

  const [activeMarker, setActiveMarker] = useState<TicketMarkerItem | undefined>(undefined);

  const map = useGoogleMap();
  if (!map) {
    throw new Error('Cannot use TicketMarkerLayer outside map');
  }

  map.addListener('click', () => {
    setActiveMarker(undefined);
  });

  const { data, fetchMore, loading, refetch } = useGetTicketMapQuery({
    variables: {
      machineSearch: searchTerm,
      assignedToUserIds: ticketFilter.assignedToUserIds,
      ticketTypeIds: ticketFilter.ticketTypeIds,
      ticketStatuses: ticketFilter.statuses,
      closedFrom: ticketFilter.closedFrom,
      closedTo: ticketFilter.closedTo,
      createdFrom: ticketFilter.createdFrom,
      createdTo: ticketFilter.createdTo,
      search: ticketFilter.search,
      ticketTags: ticketFilter.tags,
      ticketSeverities: ticketFilter.severities,
      includeUnassignedUsers: ticketFilter.includeUnassignedUsers,
      cursor: null,
      dueTo: ticketFilter.dueTo,
      ...contextFilter,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    pollInterval: 60 * 1000 * 5, // + additional polling every 5 minutes
  });

  const hasNextPageRootAndRetailer = data?.allMachines?.pageInfo.hasNextPage || false;
  const nextCursor = data?.allMachines?.pageInfo.endCursor;

  // Close the info window when the ticket filter changes
  useEffect(() => {
    setActiveMarker(undefined);
  }, [ticketFilter]);

  useEffect(() => {
    async function fetchMoreData() {
      await fetchMore({
        variables: {
          cursor: nextCursor,
        },
      });
    }

    if (hasNextPageRootAndRetailer) {
      fetchMoreData();
    }
  }, [fetchMore, hasNextPageRootAndRetailer, nextCursor]);

  useOnTicketUpdatedSubscription({
    variables: {
      machineId: contextFilter?.machineId,
      retailerId: contextFilter?.retailerId,
      ticketId: undefined,
    },
    onData: async () => {
      try {
        await refetch();
      } catch (err: unknown) {
        console.error(err);
      }
    },
  });

  useOnTicketCreatedSubscription({
    variables: {
      machineId: contextFilter?.machineId,
      retailerId: contextFilter?.retailerId,
    },
    onData: async () => {
      try {
        await refetch();
      } catch (err: unknown) {
        console.error(err);
      }
    },
  });

  const result = useMemo<TicketMarkerItem[] | undefined>(() => {
    const rootAndRetailerNodes = data?.allMachines?.edges?.map((c) => c.node);
    const combinedMarkers: TicketMarkerItem[] = [];

    rootAndRetailerNodes?.forEach((c) => {
      combinedMarkers.push({
        machine: c,
        ticket: c.tickets?.edges?.at(0)?.node, // only the first, most severe ticket
        position:
          c.geoLng && c.geoLng
            ? {
                lat: c.geoLat || 0, // mini lint hack
                lng: c.geoLng || 0,
              }
            : undefined,
      });
    });
    return combinedMarkers;
  }, [data]);

  const realLoading = loading || hasNextPageRootAndRetailer;

  const totalMachineCount = result?.length;
  const totalTicketCount = data?.allTickets?.totalCount;

  useEffect(() => {
    // NOTE: this needs to be inside useEffect to avoid setting state on parent while rendering the child component
    onLoading?.({
      loading: realLoading,
      ticketCount: totalTicketCount,
      machineCount: result?.length,
    });
  }, [realLoading, result, totalMachineCount, onLoading, totalTicketCount]);

  useEffect(() => {
    if (realLoading) {
      return;
    }
    const missingPosition = result?.filter((c) => c.position === undefined);
    if (missingPosition?.length) {
      const machineIdsWithMissingLocation = missingPosition.map((c) => c.machine.machineId);
      console.log('Missing location for machine ids ' + machineIdsWithMissingLocation.join(', '));
    }
  }, [realLoading, result]);

  return (
    <>
      {result?.map((c) => {
        return (
          <TicketMarker
            data={c}
            key={c.machine.id}
            size={'small'}
            onClick={() => {
              setActiveMarker(c);
            }}
            onMouseOver={() => {
              if (openInfoWindowOnHover) {
                setActiveMarker(c);
              }
            }}
          />
        );
      })}

      {activeMarker && activeMarker.position && (
        <TicketMarkerInfoWindow
          marker={activeMarker}
          onClose={() => {
            setActiveMarker(undefined);
          }}
        />
      )}
    </>
  );
};

export default TicketMarkerLayer;
