import { defineMessages } from 'react-intl';

const validationMessages = defineMessages({
  invalidUrl: {
    id: 'validation.invalid_url',
    defaultMessage: 'Invalid URL (http:// or https://)',
  },
  invalidEmail: {
    id: 'validation.invalid_email',
    defaultMessage: 'Invalid email address',
  },
});

export default validationMessages;
