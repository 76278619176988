import React from 'react';
import { TicketListItemFragment } from 'generated/types.tsx';
import { motion } from 'framer-motion';
import TicketStatusIcon from 'components/ticket/TicketStatusIcon/TicketStatusIcon.tsx';
import styled from 'styled-components';
import { Flex } from 'antd';
import TicketSeverityCircle from 'components/ticket/TicketSeverity/TicketSeverityCircle.tsx';
import TicketTitle from 'components/ticket/TicketTitle/TicketTitle.tsx';
import { TicketAlertDifferentMachineInstanceTooltip } from 'components/ticket/TicketList/TicketAlert.tsx';

const TicketListItemMini: React.FC<{
  ticket: TicketListItemFragment;
  serialNoInScope?: string;
}> = ({ ticket, serialNoInScope }) => {
  return (
    <Container
      key={ticket.id}
      initial={{ opacity: 0, y: 5 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 5 }}
    >
      <Flex gap={'small'}>
        <TicketStatusIcon status={ticket.status} showText={false} />
        <TicketTitle ticket={ticket} showTags={false} showType={true} />
      </Flex>

      <Flex gap={'small'} align={'center'}>
        {serialNoInScope && ticket.serialNo && ticket.serialNo !== serialNoInScope && (
          <TicketAlertDifferentMachineInstanceTooltip serialNo={ticket.serialNo} />
        )}

        <TicketSeverityCircle
          severity={ticket.severity}
          size={'tiny'}
          style={{ marginTop: '2px' }}
        />
      </Flex>
    </Container>
  );
};

const Container = styled(motion.div)`
  width: 100%;
  display: flex;
  gap: 4px;
  justify-content: space-between;
  align-items: start;
`;

export default TicketListItemMini;
