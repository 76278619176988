import React from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import PageTitleRow from 'layouts/components/Page/PageTitleRow';
import { Card, Typography } from 'antd';
import SearchButtonMobile from 'layouts/MainLayout/SearchButtonMobile/SearchButtonMobile';
import PageContentRow from 'components/lib/Page/PageContentRow';
import useIsMobile from 'layouts/responsive/useIsMobile';
import CreateRetailerForm from 'components/retailer/CreateRetailerForm/CreateRetailerForm';

const StyledPageTitleRow = styled(PageTitleRow)`
  &&& {
    margin-bottom: 16px;
  }
`;

const TitleAndInfoContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const SearchButtonContainerMobile = styled.div`
  flex: 0 0 auto;
`;

const CreateRetailerPage: React.FC = () => {
  const intl = useIntl();
  const isMobile = useIsMobile();

  const createRetailerTitle = intl.formatMessage({
    id: 'create_retailer_page.title',
    defaultMessage: 'Create retailer',
  });

  return (
    <>
      <Helmet>
        <title>{createRetailerTitle}</title>
      </Helmet>
      <StyledPageTitleRow gutter={[16, 16]}>
        <TitleAndInfoContainer>
          <Typography.Title level={1} style={{ flex: '1 1 auto' }}>
            {createRetailerTitle}
          </Typography.Title>
          {isMobile && (
            <SearchButtonContainerMobile>
              <SearchButtonMobile />
            </SearchButtonContainerMobile>
          )}
        </TitleAndInfoContainer>
      </StyledPageTitleRow>
      <PageContentRow style={{ paddingBottom: 64 }}>
        <Card>
          <CreateRetailerForm />
        </Card>
      </PageContentRow>
    </>
  );
};

export default CreateRetailerPage;
