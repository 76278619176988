import React from 'react';
import { motion } from 'framer-motion';
import { Flex, Typography } from 'antd';
import styled from 'styled-components';
import { CloseCircleFilled, WarningFilled } from '@ant-design/icons';

const ErrorIcon = styled(CloseCircleFilled)`
  color: #f04134;
`;

const WarningIcon = styled(WarningFilled)`
  color: #faad14;
`;

const AnimatedMessage: React.FC<{
  type: 'warn' | 'error' | 'loading';
  message: string;
  code?: string;
}> = ({ message, type, code }) => (
  <motion.div
    initial={{ opacity: 0, y: 5 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -5 }}
  >
    <Flex gap={6} align={'center'} style={{ maxWidth: 400 }}>
      <Typography.Text>
        {type === 'error' && <ErrorIcon />}
        {type === 'warn' && <WarningIcon />}
      </Typography.Text>
      <Typography.Text>{message}</Typography.Text>
      {code && <Typography.Text code={true}>{code}</Typography.Text>}
    </Flex>
  </motion.div>
);

export default AnimatedMessage;
