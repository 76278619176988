import React from 'react';
import BigText from 'components/machine/properties/MachinePropertyList/BigText.tsx';
import JsonViewer from 'components/lib/JsonViewer/JsonViewer.tsx';
import { MachinePropertyWithValueFragment } from 'generated/types.tsx';
import NotSetValue from 'components/machine/properties/MachinePropertyList/NotSetValue.tsx';

interface Props {
  property: MachinePropertyWithValueFragment;
  value: string | null | undefined;
}

const JsonValue: React.FC<Props> = ({ value }) => {
  if (value === null || value === undefined) {
    return <NotSetValue />;
  }

  return (
    <JsonViewer
      json={value}
      fallbackRender={(invalidJson) => (
        <BigText editable={false}>{invalidJson}</BigText>
      )}
    />
  );
};

export default JsonValue;
