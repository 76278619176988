import React, { FC, useCallback, useState } from 'react';
import { Button, Checkbox, Dropdown, Input, Segmented, Space } from 'antd';
import { MoreOutlined, SearchOutlined } from '@ant-design/icons';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { useDebouncedCallback } from 'use-debounce';
import { useAppDispatch, useAppSelector } from 'redux/store.ts';
import {
  selectMachineNavFilter,
  setMachineNavFilter,
  setMachineNavSearchTerm,
} from 'redux/machineNavSlice.ts';
import Alert from 'components/lib/Alert/Alert.tsx';

const SearchBar: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTermLocal, setSearchTermLocal] = useState('');
  const filter = useAppSelector(selectMachineNavFilter);
  const { showInactiveMachines, showRetailersWithoutMachines } = filter;
  const dispatch = useAppDispatch();

  const handleSearchDebounced = useDebouncedCallback((value: string) => {
    dispatch(setMachineNavSearchTerm(value));
  }, 400);

  const handleSearch = useCallback(
    (term: string) => {
      setSearchTermLocal(term);
      handleSearchDebounced(term);
    },
    [handleSearchDebounced]
  );

  const handleOnMenuClick = useCallback<MenuClickEventHandler>((e) => {
    e.domEvent.preventDefault(); // prevent dropdown from closing (for all menu items)
  }, []);

  const handleToggleInactiveRetailers = useCallback(() => {
    dispatch(
      setMachineNavFilter({
        filter: {
          showRetailersWithoutMachines: !showRetailersWithoutMachines,
        },
        store: true,
      })
    );
  }, [dispatch, showRetailersWithoutMachines]);

  const handleToggleInactiveMachines = useCallback(() => {
    dispatch(
      setMachineNavFilter({
        filter: {
          showInactiveMachines: !showInactiveMachines,
        },
        store: true,
      })
    );
  }, [dispatch, showInactiveMachines]);

  const hasSearchTerm = searchTermLocal.length > 0;
  const items = [
    {
      key: 'showInactiveMachines',
      onClick: handleToggleInactiveMachines,
      label: (
        <Checkbox
          checked={filter.showInactiveMachines}
          disabled={hasSearchTerm}
          style={{ userSelect: 'none' }}
        >
          Show inactive machines
        </Checkbox>
      ),
    },
    {
      key: 'showInactiveRetailers',
      onClick: handleToggleInactiveRetailers,
      label: (
        <Checkbox
          checked={filter.showRetailersWithoutMachines}
          style={{ userSelect: 'none' }}
          disabled={hasSearchTerm}
        >
          Show inactive retailers
        </Checkbox>
      ),
    },
    {
      key: 'machineType',
      label: <Segmented options={['All', 'Vending', 'Retail']} />,
    },
  ];

  if (hasSearchTerm) {
    items.push({
      key: 'info',
      label: (
        <Alert
          style={{ maxWidth: 200 }}
          showIcon={false}
          type={'info'}
          message={'When filtering by text, inactive machines and retailers are always included.'}
        />
      ),
      onClick: () => {},
    });
  }

  return (
    <>
      <Space.Compact>
        <Input
          placeholder={'Filter...'}
          size={'middle'}
          prefix={<SearchOutlined />}
          value={searchTermLocal}
          allowClear={true}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <Dropdown
          trigger={['click']}
          menu={{
            onClick: handleOnMenuClick,
            items: items,
          }}
          arrow={true}
          placement={'bottomRight'}
          open={isOpen}
          onOpenChange={(flag) => {
            setIsOpen(flag);
          }}
        >
          <Button icon={<MoreOutlined />} size={'middle'} />
        </Dropdown>
      </Space.Compact>
    </>
  );
};

export default SearchBar;
