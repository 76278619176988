import React, { FC } from 'react';
import { Space } from 'antd';
import TicketSeverityCircle from 'components/ticket/TicketSeverity/TicketSeverityCircle';
import { TicketSeverity } from 'generated/types';
import { FormattedMessage } from 'react-intl';
import { ticketSeverityDescriptionMessages } from 'components/ticket/TicketSeverity/ticketSeverityMessages';

const TicketSeverityDescription: FC = () => {
  return (
    <Space direction={'vertical'} size={'small'}>
      <Space>
        <TicketSeverityCircle severity={TicketSeverity.A} />
        <FormattedMessage {...ticketSeverityDescriptionMessages.A} />
      </Space>
      <Space>
        <TicketSeverityCircle severity={TicketSeverity.B} />
        <FormattedMessage {...ticketSeverityDescriptionMessages.B} />
      </Space>
      <Space>
        <TicketSeverityCircle severity={TicketSeverity.C} />
        <FormattedMessage {...ticketSeverityDescriptionMessages.C} />
      </Space>
      <Space>
        <TicketSeverityCircle severity={TicketSeverity.D} />
        <FormattedMessage {...ticketSeverityDescriptionMessages.D} />
      </Space>
    </Space>
  );
};

export default TicketSeverityDescription;
