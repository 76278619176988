import React from 'react';
import { CheckboxFormFieldItemFragment } from 'generated/types';
import { Col, Switch, Typography } from 'antd';
import styled from 'styled-components';
import { CheckOutlined } from '@ant-design/icons';
import { parseISO } from 'date-fns';
import ChangedByUser from 'components/user/ChangedByUser/ChangedByUser.tsx';
const { Text } = Typography;

interface Props {
  field: CheckboxFormFieldItemFragment;
  loading?: boolean;
  onChange?: (value: boolean) => void;
}

const CheckCol = styled(Col)`
  &&& {
    display: flex;
    flex-direction: row;
    gap: 24px;

    justify-content: flex-start;
    align-items: flex-start;

    //background-color: fuchsia;
  }
`;

const UserCol = styled(Col)`
  &&& {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start; // top or center?

    //background-color: violet;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckboxFormField: React.FC<Props> = (props) => {
  const { field, loading } = props;

  return (
    <>
      <CheckCol xs={20}>
        <div>
          <Switch
            checked={field.checkedValue || false}
            checkedChildren={<CheckOutlined />}
            disabled={field.disabled}
            loading={loading}
            onChange={(value) => props.onChange?.(value)}
          />
        </div>
        <LabelContainer>
          <Text>{field.label}</Text>
          <Text type={'secondary'}>{field.description}</Text>
        </LabelContainer>
      </CheckCol>
      <UserCol xs={4}>
        {field.changedByUser && field.changed && (
          <ChangedByUser
            user={field.changedByUser}
            changed={parseISO(field.changed)}
          />
        )}
      </UserCol>
    </>
  );
};

export default CheckboxFormField;
