import styled from 'styled-components';

const Arrow = styled.div<{ $open: boolean }>`
  position: absolute;
  top: 50%;
  left: 4px;
  z-index: 4;

  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: ${(props) =>
    props.$open ? 'translateY(-2px)' : 'translateY(0px)'};

  &::before,
  &::after {
    position: absolute;
    width: 6px;
    height: 1.5px;
    background-color: currentcolor;
    border-radius: 4px;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    content: '';
  }
  &::before {
    transform: ${(props) =>
      props.$open
        ? 'rotate(45deg) translateX(2.5px)'
        : 'rotate(-45deg) translateX(2.5px)'};
  }
  &::after {
    transform: ${(props) =>
      props.$open
        ? 'rotate(-45deg) translateX(-2.5px)'
        : 'rotate(45deg) translateX(-2.5px)'};
  }
`;

export default Arrow;
