import styled from 'styled-components';
import { Typography } from 'antd';

const BigText = styled(Typography.Text)`
  font-size: 24px;

  &&& {
    textarea.ant-input {
      line-height: 1.5715;
      font-size: 24px;
    }
  }
`;

export default BigText;
