import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import MatrixBreadcrumbs, {
  BreadcrumbRow,
} from 'layouts/MainLayout/MatrixBreadcrumbs/MatrixBreadcrumbs';
import PageTitleRow from 'layouts/components/Page/PageTitleRow';
import { Typography } from 'antd';
import styled from 'styled-components';
import SearchButtonMobile from 'layouts/MainLayout/SearchButtonMobile/SearchButtonMobile';
import PageContentRow from 'components/lib/Page/PageContentRow';
import CreateTicketForm from 'components/ticket/CreateTicketForm/CreateTicketForm';
import useIsMobile from 'layouts/responsive/useIsMobile';

const StyledPageTitleRow = styled(PageTitleRow)`
  &&& {
    margin-bottom: 16px;
  }
`;

const TitleAndInfoContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const SearchButtonContainerMobile = styled.div`
  flex: 0 0 auto;
`;

const CreateTicketPage: React.FC = () => {
  const isMobile = useIsMobile();
  const intl = useIntl();

  const createTicketTitle = intl.formatMessage({
    id: 'create_ticket_page.title',
    defaultMessage: 'New ticket',
  });

  return (
    <>
      <Helmet>
        <title>{createTicketTitle}</title>
      </Helmet>
      <BreadcrumbRow $isMobile={isMobile}>
        <MatrixBreadcrumbs />
      </BreadcrumbRow>
      <StyledPageTitleRow gutter={[16, 16]}>
        <TitleAndInfoContainer>
          <Typography.Title level={1} style={{ flex: '1 1 auto' }}>
            {createTicketTitle}
          </Typography.Title>
          {isMobile && (
            <SearchButtonContainerMobile>
              <SearchButtonMobile />
            </SearchButtonContainerMobile>
          )}
        </TitleAndInfoContainer>
      </StyledPageTitleRow>
      <PageContentRow style={{ paddingBottom: 64 }}>
        <CreateTicketForm />
      </PageContentRow>
    </>
  );
};

export default CreateTicketPage;
