import { useScreenInfo } from 'layouts/responsive/useScreenInfo';

/***
 * Returns true if current resolution is mobile (md)
 */
function useIsMobile(): boolean {
  const { isMobile } = useScreenInfo();
  return isMobile;
}

export default useIsMobile;
