import React from 'react';
import useMapPosition from 'components/lib/ConnectMap/useMapPosition';
import { Marker, useGoogleMap } from '@react-google-maps/api';
import { useIntl } from 'react-intl';

/***
 * TODO:
 * Refactor? Move geo location lookup to here (from MapBAsicControls), and subscribe to location updates
 * - render blue circle + triangle showing current direction
 * @constructor
 */
const MyPositionMarker: React.FC = () => {
  const position = useMapPosition();
  const map = useGoogleMap();
  const intl = useIntl();
  if (!map) {
    throw new Error('Cannot use outside map');
  }

  if (!position) {
    return null;
  }

  return (
    <Marker
      position={{
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }}
      title={intl.formatMessage({
        id: 'my_position_marker.my_position',
        defaultMessage: 'My position',
      })}
    />
  );
};

export default MyPositionMarker;
