import React from 'react';
import { message, Table } from 'antd';
import {
  AddTicketOrderLineInput,
  TicketAccountingFragmentDoc,
  TicketOrderLineFormInput,
  useAddTicketOrderLineMutation,
} from 'generated/types.tsx';
import { gql } from '@apollo/client/core';
import OrderLineForm from 'components/ticket/TicketAccounting/OrderLineForm/OrderLineForm.tsx';

gql`
  mutation AddTicketOrderLine($input: AddTicketOrderLineInput!) {
    addTicketOrderLine(input: $input) {
      ...TicketAccounting
    }
  }
  ${TicketAccountingFragmentDoc}
`;

interface Props {
  ticketId: number;
  tempId: string;
  userId?: string;
  onAdded: (tempId: string) => void;
  onCancel: (tempId: string) => void;
}

const AddOrderLine: React.FC<Props> = (props) => {
  const { ticketId, tempId, userId, onAdded, onCancel } = props;

  const [addLine, { loading: isSaving }] = useAddTicketOrderLineMutation({
    notifyOnNetworkStatusChange: true,
  });

  const handleSubmit = async (values: TicketOrderLineFormInput) => {
    try {
      const input: AddTicketOrderLineInput = {
        form: values,
        ticketId,
      };

      await addLine({
        variables: {
          input,
        },
      });

      onAdded(tempId);
    } catch (err: unknown) {
      if (err instanceof Error) {
        message.error('Could not add order line. ' + err.message);
      }
    }
  };

  const handleCancel = () => {
    onCancel(tempId);
  };

  return (
    <Table.Summary.Row>
      <Table.Summary.Cell index={0} colSpan={100}>
        <OrderLineForm
          key={tempId}
          ticketId={ticketId}
          initialValues={{
            quantity: 1,
            userId,
            date: new Date().toISOString(),
          }}
          isSaving={isSaving}
          mode={'add'}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );
};

export default AddOrderLine;
