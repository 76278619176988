import { Flex, Typography } from 'antd';
import { ArticleListItemFragment, DeletedArticleListItemFragment } from 'generated/types';
import React from 'react';
import { getArticleTypeIcon } from 'components/ticket/TicketAccounting/getArticleTypeIcon';
import { QuestionCircleOutlined, StopOutlined } from '@ant-design/icons';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { ticketAccountingMessages } from 'components/ticket/TicketAccounting/ticketAccountingMessages.ts';

interface Props {
  article: ArticleListItemFragment | DeletedArticleListItemFragment;
  showIcon: boolean;
  showVendorInfo?: boolean;
  showDisabledIcon?: boolean;
}

const ArticleItem: React.FC<Props> = ({ article, showIcon, showVendorInfo, showDisabledIcon }) => {
  const intl = useConnectIntl();
  const showVendor = showVendorInfo === undefined ? true : showVendorInfo;

  return (
    <>
      {article.__typename === 'DeletedTicketArticle' && (
        <Flex gap={'small'}>
          {showIcon && <QuestionCircleOutlined />}
          <div>{intl.formatMsg(ticketAccountingMessages.deletedArticle)}</div>
        </Flex>
      )}
      {article.__typename === 'TicketArticle' && (
        <Flex gap={'small'} align={'center'}>
          {showIcon && <div>{getArticleTypeIcon(article.group.icon)}</div>}

          <Flex gap={'small'} align={'center'} wrap={'wrap'}>
            <Typography.Text>
              {intl.formatMsg(ticketAccountingMessages.article, {
                productId: article.productId,
                productName: article.name,
              })}
            </Typography.Text>
            {showVendor && article.vendor && (
              <Typography.Text type={'secondary'}>
                {intl.formatMsg(ticketAccountingMessages.articleVendor, {
                  vendor: article.vendor,
                  vendorId: article.vendorId,
                })}
              </Typography.Text>
            )}
          </Flex>
          {article.disabled && showDisabledIcon && <StopOutlined />}
        </Flex>
      )}
    </>
  );
};

export default ArticleItem;
