import { TicketSeverity } from 'generated/types';
import { useCallback } from 'react';

export function useTicketSeverityIcon() {
  const getSeverityBackgroundColor = useCallback((severity: TicketSeverity): string => {
    // TODO: align colors with template etc...
    switch (severity) {
      case TicketSeverity.A:
        return '#780000';
      case TicketSeverity.B:
        return '#dc0000';
      case TicketSeverity.C:
        return '#fd8c00';
      case TicketSeverity.D:
        return '#fdc500';
    }
  }, []);

  const textColor = '%23333333';
  const textColorInverse = '%23f8f9fd';

  return (severity: TicketSeverity) => {
    let letter = '?';
    switch (severity) {
      case TicketSeverity.A:
        letter = 'A';
        break;
      case TicketSeverity.B:
        letter = 'B';
        break;
      case TicketSeverity.C:
        letter = 'C';
        break;
      case TicketSeverity.D:
        letter = 'D';
        break;
    }
    const background = getSeverityBackgroundColor(severity).replace('#', '%23');
    const foreground = severity === TicketSeverity.A ? textColorInverse : textColor;

    return `
    <svg width="24" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="${textColor}"/>
      <circle cx="12" cy="12" r="10" fill="${background}"/>
      <text 
        x="50%" 
        y="55%" 
        text-anchor="middle" 
        dominant-baseline='middle' 
        font-family='Arial' 
        font-weight='500'
        fill='${foreground}'
      >${letter}</text>
    </svg>  
  `;
  };
}
