import { ApolloClient } from '@apollo/client';

import apolloCache from 'graphql/apollo/apolloCache';
import apolloLink from 'graphql/apollo/apolloLink';

export const apolloClient = new ApolloClient({
  name: 'connect-app-client',
  version: '1.0',
  cache: apolloCache,
  link: apolloLink,
  headers: {},
  connectToDevTools: true,
  credentials: 'include', // Enable sending cookies over cross-origin requeststic
});
