import React, { useEffect, useLayoutEffect } from 'react';

/***
 * This hook is used to get the width of a div. Also supports resizing.
 **/
const useRefWidth = (ref: React.RefObject<HTMLDivElement>) => {
  const [width, setWidth] = React.useState<number | undefined>(undefined);

  useLayoutEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return { width, setWidth };
};

export default useRefWidth;
