import { TicketSeverity } from 'generated/types';
import React from 'react';
import { SelectProps, SelectValue } from 'antd/es/select';
import Select, { Option } from 'components/lib/Select/Select';
import styled from 'styled-components';
import TicketSeverityCircle from 'components/ticket/TicketSeverity/TicketSeverityCircle';
import { ticketSeverityMessages } from 'components/ticket/TicketSeverity/ticketSeverityMessages';
import { useEnumSelectOptions } from 'components/lib/Select/useEnumSelectOptions';

const OptionContainer = styled.div`
  height: 100%;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  vertical-align: middle;
`;

const Label = styled.div`
  flex: 1 1 auto;
`;

// Strongly typed enum value in onSelect:
function TypedTicketSeveritySelect<T extends SelectValue = TicketSeverity>({ children, ...props }: SelectProps<T>) {
  return <Select<T> {...props}>{children}</Select>;
}

// Pass down ant design props except those that we control
type OtherSelectProps = Omit<SelectProps<TicketSeverity>, 'options'>;

function TicketSeveritySelect(props: OtherSelectProps) {
  const { children, ...rest } = props;

  const options = useEnumSelectOptions<TicketSeverity>({
    enumObject: TicketSeverity,
    customLabelMessages: ticketSeverityMessages,
  });

  return (
    <TypedTicketSeveritySelect {...rest}>
      {options.map((item) => {
        return (
          <Option value={item.value} key={item.key}>
            <OptionContainer>
              <TicketSeverityCircle severity={item.data} size={'small'} />
              <Label>{item.label}</Label>
            </OptionContainer>
          </Option>
        );
      })}
    </TypedTicketSeveritySelect>
  );
}

export default TicketSeveritySelect;
