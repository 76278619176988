import Tag from 'components/lib/Tag/Tag';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';

interface Props {
  name: string;
  showTooltip?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
}

const StyledTag = styled(Tag)`
  &&& {
    width: 34px;
    border-radius: 6px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const CategoryTag: React.FC<Props> = ({ name, disabled, showTooltip, style }) => {
  const trimmedName = name.length >= 2 ? name.substring(0, 2) : name;
  const tag = (
    <div style={style}>
      <StyledTag color={disabled ? 'rgba(39, 41, 50, 0.04)' : '#333'}>{trimmedName}</StyledTag>
    </div>
  );
  return showTooltip ? <Tooltip title={name}>{tag}</Tooltip> : tag;
};

export default CategoryTag;
