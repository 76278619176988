import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useGoogleMap } from '@react-google-maps/api';
import { Button, Space } from 'antd';
import {
  AimOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { StreetViewManIcon } from 'components/icons/Icons';
import useMessageApi from 'components/global/useMessageApi';

export interface MapBasicControlsSettings {
  mapType: boolean;
  fullscreen: boolean;
  locate: boolean;
  streetView: boolean;
  zoom: boolean;
}

const defaultBasicControls: MapBasicControlsSettings = {
  fullscreen: true,
  locate: true,
  streetView: true,
  mapType: true,
  zoom: true,
};

interface Props {
  controls?: Partial<MapBasicControlsSettings>;
  onEnterFullscreen?: () => void;
  onExitFullscreen?: () => void;
  onPositionFound?: (position: GeolocationPosition) => void;
}

const MapBasicControls: React.FC<Props> = (props) => {
  const { controls, onEnterFullscreen, onExitFullscreen, onPositionFound } =
    props;
  const map = useGoogleMap();
  if (map === null) {
    throw new Error('Cannot be used outside a google map context');
  }

  const [isFullscreen, setIsFullscreen] = useState(false);
  const intl = useIntl();
  const message = useMessageApi();

  const ctrl: MapBasicControlsSettings = {
    ...defaultBasicControls,
    ...controls,
  };

  useEffect(() => {
    const element = map.getDiv();
    const handleFull = () => {
      if (document.fullscreenElement) {
        setIsFullscreen(true);
        onEnterFullscreen?.();
      } else {
        setIsFullscreen(false);
        onExitFullscreen?.();
      }
    };
    element.addEventListener('fullscreenchange', handleFull);
    return () => {
      element.removeEventListener('fullscreenchange', handleFull);
    };
  }, [map, onEnterFullscreen, onExitFullscreen]);

  const handleZoomIn = () => {
    const currentZoom = map.getZoom() || 4;
    map.setZoom(currentZoom + 1);
  };

  const handleZoomOut = () => {
    const currentZoom = map.getZoom() || 4;
    map.setZoom(currentZoom - 1);
  };

  // Could probably get this to work, but a lot of work to implement drag and drop etc...
  // useMemo if we keep this:

  // const [isDraggingBob, setIsDraggingBob] = useState(false);
  // const handleStreetView = () => {
  //   coverageLayer.setMap(map); // show layer with street view support, set to null to hide
  //   setIsDraggingBob(true)
  // };

  const handleUserLocated = useCallback(
    (position: GeolocationPosition) => {
      onPositionFound?.(position);
      // TODO: make configurable?
      map?.setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    },
    [onPositionFound, map]
  );

  const handleGetLocation = async () => {
    message.loading({
      key: 'locate',
      content: intl.formatMessage({
        id: 'map_basic_controls.locate',
        defaultMessage: 'Finding your position...',
      }),
    });

    // TODO: watchPosition()? cool in car?

    window.navigator?.geolocation?.getCurrentPosition(
      (position) => {
        message.success({
          key: 'locate',
          content: intl.formatMessage({
            id: 'map_basic_controls.locate_success',
            defaultMessage: 'Position found',
          }),
        });
        handleUserLocated(position);
      },
      async (positionError) => {
        console.error(positionError);
        message.error({
          key: 'locate',
          content: intl.formatMessage({
            id: 'map_basic_controls.locate_fail',
            defaultMessage:
              'Could not get your position. Please check your browser settings and try again.',
          }),
        });
      },
      {
        enableHighAccuracy: true, // ok? I think this is GPS on mobile... can be slow?
        timeout: 7000,
      }
    );
  };

  const handleToggleFullscreen = async () => {
    const element = map.getDiv();
    if (isFullscreen) {
      await document.exitFullscreen();
    } else {
      await element.requestFullscreen();
    }
  };

  return (
    <>
      <Space size={'small'} direction={'vertical'}>
        {ctrl.fullscreen && (
          <Button
            icon={
              isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />
            }
            onClick={handleToggleFullscreen}
          />
        )}
        {ctrl.locate && (
          <Button icon={<AimOutlined />} onClick={handleGetLocation} />
        )}
        {ctrl.streetView && <DraggableStreetViewButton />}
        {ctrl.zoom && (
          <Button icon={<ZoomInOutlined />} onClick={handleZoomIn} />
        )}
        {ctrl.zoom && (
          <Button icon={<ZoomOutOutlined />} onClick={handleZoomOut} />
        )}
      </Space>
    </>
  );
};

export default MapBasicControls;

const Bobby = styled.div<{ $isDragging: boolean }>`
  width: 32px;
  height: 32px;
  background-color: ${(props) => props.theme.ant.colorBgContainer};
  border-radius: 6px;
  cursor: grab;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

function DraggableStreetViewButton() {
  const message = useMessageApi();

  const map = useGoogleMap();
  if (!map) {
    throw new Error('Bob cannot live outside the map');
  }

  const coverageLayer = useMemo(() => {
    return new google.maps.StreetViewCoverageLayer();
  }, []);

  const [isDragging, setIsDragging] = useState(false);

  // TODO: add ontouchstart if we need touch support...
  return (
    <Bobby
      // onMouseDown={initDragDesktop}
      // onMouseUp={handleMouseUp}
      draggable={true}
      onDragStart={() => {
        setIsDragging(true);
        coverageLayer.setMap(map);
      }}
      onDragEnd={(event) => {
        console.log('drag end', event.clientX);
        setIsDragging(false);
        coverageLayer.setMap(null);

        // TODO: need to calculate coordinates..
        // new google.maps.StreetViewPanorama(null, {
        //   position: {
        //     lat:
        //   }
        // })
        message.warning('not implemented yet...');
      }}
      // can use this to render a marker on map...?
      // onDragCapture={event =>console.log(event.clientX)}
      $isDragging={isDragging}
    >
      <StreetViewManIcon />
    </Bobby>
  );
}
