import { MachineListItemFragment } from 'generated/types';
import React from 'react';
import getMachineIcon from 'components/icons/getMachineIcon';
import Link from 'components/lib/Link/Link';
import styled, { css } from 'styled-components';
import useMatrixNav from 'layouts/matrix/useMatrixNav';
import {
  TicketAlertDifferentMachineInstanceTooltip,
  TicketAlertDifferentSerialNoTooltip,
} from 'components/ticket/TicketList/TicketAlert.tsx';

const StyledLink = styled(Link)<{ $noWrap: boolean; $warn?: boolean }>`
  ${(props) =>
    props.$noWrap &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  ${(props) =>
    props.$warn &&
    css`
      color: ${(props) => props.theme.ant.colorWarning};
    `}
`;

const Part = styled.span<{ $warn?: boolean }>`
  margin-left: 4px;
  ${(props) =>
    props.$warn &&
    css`
      color: ${(props) => props.theme.ant.colorWarning};
    `}
`;

interface Props {
  machine: Pick<MachineListItemFragment, 'machineType' | 'machineId' | 'serialNo' | 'location'>;
  allowWrap?: boolean;
  appendSerialNumber?: boolean;
  urlFormatter?: (url: string) => string;

  // NOTE: These are used to show warnings when the ticket is from a different machine or serialNo
  // NOTE: Should probably be moved somewhere else, but for now it's here TicketMachineLink?
  ticketSerialNo?: string;
  scopeMachineId?: number;
  scopeSerialNo?: string;
}

const MachineLink: React.FC<Props> = (props) => {
  const {
    machine,
    ticketSerialNo,
    scopeMachineId,
    scopeSerialNo,
    allowWrap,
    appendSerialNumber,
    urlFormatter,
  } = props;
  const icon = getMachineIcon(machine.machineType);
  const { getUrlToMachine } = useMatrixNav();
  const urlToMachineOriginal = getUrlToMachine(machine.machineId);
  const urlToMachine = urlFormatter ? urlFormatter(urlToMachineOriginal) : urlToMachineOriginal;
  const differentTicketSerialNo =
    (ticketSerialNo && scopeSerialNo && ticketSerialNo !== scopeSerialNo) || false;
  const differentMachineId = (scopeMachineId && scopeMachineId !== machine.machineId) || false;

  return (
    <StyledLink to={urlToMachine} $noWrap={!allowWrap}>
      <Part $warn={differentTicketSerialNo} style={{ marginLeft: 0 }}>
        {icon}
      </Part>

      {machine.location && <Part $warn={differentMachineId}>{machine.location}</Part>}

      {!machine.location && (
        <Part $warn={differentMachineId || differentTicketSerialNo}>
          {ticketSerialNo || machine.serialNo}
        </Part>
      )}

      {appendSerialNumber && machine.location && (
        <>
          <Part $warn={differentTicketSerialNo}>({ticketSerialNo || machine.serialNo})</Part>
        </>
      )}

      {differentTicketSerialNo && ticketSerialNo && (
        <>
          {' '}
          <TicketAlertDifferentSerialNoTooltip
            ticketSerialNo={ticketSerialNo}
            machineSerialNo={machine.serialNo}
          />
        </>
      )}

      {differentMachineId && (
        <>
          {' '}
          <TicketAlertDifferentMachineInstanceTooltip serialNo={machine.serialNo} />
        </>
      )}
    </StyledLink>
  );
};

export default MachineLink;
