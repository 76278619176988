// NOTE: This is pretty quick and simple for now
const getObjectModelColor = (label: string | null | undefined): string => {
  if (!label) {
    return '#faad14';
  }
  const lab = label.toLowerCase();
  if (lab === 'westfalen') {
    return '#1677ff';
  }
  if (lab === 'westfalen_logo') {
    return '#faad14';
  }

  return '#2f54eb';
};

export default getObjectModelColor;
