import { PickerProps } from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';
import React, { useCallback } from 'react';
import { useAppSelector } from 'redux/store';
import { selectCurrentLocale } from 'i18n/i18nSlice';

const FnsDatePickerDa = React.lazy(() => import('./FnsDatePickerDa'));
const FnsDatePickerDe = React.lazy(() => import('./FnsDatePickerDe'));
const FnsDatePickerEn = React.lazy(() => import('./FnsDatePickerEn'));
const FnsDatePickerNo = React.lazy(() => import('./FnsDatePickerNo'));
const FnsDatePickerEt = React.lazy(() => import('./FnsDatePickerEt'));
const FnsDatePickerFi = React.lazy(() => import('./FnsDatePickerFi'));
const FnsDatePickerFr = React.lazy(() => import('./FnsDatePickerFr'));
const FnsDatePickerNl = React.lazy(() => import('./FnsDatePickerNl'));
const FnsDatePickerSv = React.lazy(() => import('./FnsDatePickerSv'));

export type DateFnsTypedPickerProps = PickerProps<Date>;
type PickerPropsExceptCustom = Omit<
  DateFnsTypedPickerProps,
  'onChange' | 'defaultValue' | 'value' | 'onOk'
>;
interface CustomDatePickerProps {
  onChange?: (date: Date | null) => void;
  defaultValue?: Date;
  value?: Date;
  onOk?: (date: Date) => void;
}
type CombinedPickerPropsSingleSelect = PickerPropsExceptCustom & CustomDatePickerProps;

/***
 * DatePicker component that will render the correct date picker based on the current locale and using
 * the date-fns library instead of day.js which is the default in ant design.
 * NOTE:
 * - We lazy load to reduce bundle size.
 * - We have to use the "unknown" type for the date parameter in the handlers,
 *   because TypeScript doesn't know the type of "Date" when lazy loading.
 * - The DatePicker component is currently limited to selecting a single date, not a range.
 * @param props - The props for the date picker.
 */
const DatePicker: React.FC<CombinedPickerPropsSingleSelect> = (props) => {
  const localeKey = useAppSelector(selectCurrentLocale);
  const { defaultValue, value, onChange, onOk, ...rest } = props;

  const handleChange = useCallback(
    (date: unknown) => {
      if (date instanceof Date) {
        onChange?.(date);
      } else if (!date) {
        onChange?.(null);
      }
    },
    [onChange]
  );

  const handleOk = useCallback(
    (date: unknown) => {
      if (date instanceof Date) onOk?.(date);
    },
    [onOk]
  );

  let picker: React.ReactNode = null;
  switch (localeKey) {
    case 'de':
      picker = (
        <FnsDatePickerDe
          defaultValue={defaultValue}
          value={value}
          onChange={handleChange}
          onOk={handleOk}
          {...rest}
        />
      );
      break;
    case 'da':
      picker = (
        <FnsDatePickerDa
          defaultValue={defaultValue}
          value={value}
          onChange={handleChange}
          onOk={handleOk}
          {...rest}
        />
      );
      break;
    case 'no':
      picker = (
        <FnsDatePickerNo
          defaultValue={defaultValue}
          value={value}
          onChange={handleChange}
          onOk={handleOk}
          {...rest}
        />
      );
      break;
    case 'en':
      picker = (
        <FnsDatePickerEn
          defaultValue={defaultValue}
          value={value}
          onChange={handleChange}
          onOk={handleOk}
          {...rest}
        />
      );
      break;
    case 'et':
      picker = (
        <FnsDatePickerEt
          defaultValue={defaultValue}
          value={value}
          onChange={handleChange}
          onOk={handleOk}
          {...rest}
        />
      );
      break;
    case 'fr':
      picker = (
        <FnsDatePickerFr
          defaultValue={defaultValue}
          value={value}
          onChange={handleChange}
          onOk={handleOk}
          {...rest}
        />
      );
      break;
    case 'fi':
      picker = (
        <FnsDatePickerFi
          defaultValue={defaultValue}
          value={value}
          onChange={handleChange}
          onOk={handleOk}
          {...rest}
        />
      );
      break;
    case 'nl':
      picker = (
        <FnsDatePickerNl
          defaultValue={defaultValue}
          value={value}
          onChange={handleChange}
          onOk={handleOk}
          {...rest}
        />
      );
      break;
    case 'sv':
      picker = (
        <FnsDatePickerSv
          defaultValue={defaultValue}
          value={value}
          onChange={handleChange}
          onOk={handleOk}
          {...rest}
        />
      );
      break;
  }

  return picker;
};

export default DatePicker;
