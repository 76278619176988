import React, { useMemo } from 'react';
import styled from 'styled-components';

/***
 * This function adds syntax highlighting to json, adding spans with classes etc.
 * https://stackoverflow.com/questions/4810841/pretty-print-json-using-javascript
 * @param json a pretty printed json string
 */
function syntaxHighlight(json: string) {
  json = json
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
  /* eslint-disable */

  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    function (match) {
      let cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return '<span class="' + cls + '">' + match + '</span>';
    }
  );
  /* eslint-enable */
}

// Apply styling to inserted span classes
const JsonPre = styled.pre`
  flex: 1 1 auto;
  padding: 8px;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 4px;

  background: ${(props) => props.theme.ant.colorBgContainer};
  color: ${(props) => props.theme.ant.colorText};
  .string {
    color: ${(props) => props.theme.ant.colorSuccessText};
  }
  .number {
    color: ${(props) => props.theme.ant.colorPrimaryText};
  }
  .boolean {
    color: rgb(174, 129, 255);
  }
  .null {
    color: ${(props) => props.theme.ant.colorErrorText};
  }
  .key {
    color: ${(props) => props.theme.ant.colorText};
  }
`;

interface Props {
  json: string; // raw json string
  fallbackRender?: (invalidJson: string) => JSX.Element;
}

const JsonViewer: React.FC<Props> = ({ json, fallbackRender }) => {
  const { jsonContent, isValidJson } = useMemo(() => {
    let temp = undefined;
    let isValidJson = false;

    try {
      const jsonStr = JSON.stringify(JSON.parse(json), undefined, 2);
      temp = syntaxHighlight(jsonStr);
      isValidJson = true;
    } catch (err) {
      // console.warn('Failed to parse json, rendering as string');
      temp = json;
    }

    return {
      jsonContent: temp,
      isValidJson,
    };
  }, [json]);

  return isValidJson ? (
    <JsonPre dangerouslySetInnerHTML={{ __html: jsonContent }} />
  ) : fallbackRender ? (
    fallbackRender(jsonContent)
  ) : (
    <JsonPre>{jsonContent}</JsonPre>
  );
};

export default JsonViewer;
