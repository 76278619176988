import styled from 'styled-components';

const PreviewLanguage = styled.div<{ $error: boolean }>`
  border-radius: 4px;
  background-color: ${(props) => (props.$error ? props.theme.ant.colorErrorText : props.theme.ant.colorInfoBg)};
  color: ${(props) => props.theme.ant.colorTextLightSolid};
  padding: 0 6px;
  font-size: 12px;
  min-width: 24px;
  text-align: center;
`;

export default PreviewLanguage;
