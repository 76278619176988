import React from 'react';
import styled, { css } from 'styled-components';
import { Badge as BadgeInternal, BadgeProps } from 'antd';

// TODO: use antd style after antd 5
const StyledBadge = styled(BadgeInternal)`
  && {
    .ant-badge-dot {
      ${(props) =>
        props.color === 'warning' &&
        css`
          background: ${(props) => props.theme.ant.colorWarning};
        `}
      ${(props) =>
        props.color === 'error' &&
        css`
          background: ${(props) => props.theme.ant.colorError};
        `}
      ${(props) =>
        props.color === 'success' &&
        css`
          background: ${(props) => props.theme.ant.colorSuccess};
        `}
      ${(props) =>
        props.color === 'info' &&
        css`
          background: ${(props) => props.theme.ant.colorInfo};
        `}
    }
  }
`;

const Badge: React.FC<BadgeProps> = (props) => {
  const { color, ...rest } = props;
  return (
    <StyledBadge overflowCount={99999} color={color} {...rest}>
      {props.children}
    </StyledBadge>
  );
};

export default Badge;
