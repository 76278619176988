import React from 'react';
import { Col, Row } from 'antd';
import EventActionCard from 'components/events/EventActionCard';
import { Level } from 'layouts/matrix/level';

interface Props {
  retailerId?: number;
  machineId?: number;
}

const EventList: React.FC<Props> = ({ machineId, retailerId }) => {
  const level: Level = machineId ? 'machine' : retailerId ? 'retailer' : 'root';
  const showEventActionCard = level === 'machine';

  return (
    <>
      <Row gutter={[16, 16]} wrap={true} style={{ paddingBottom: 64 }}>
        {showEventActionCard && (
          <Col xs={24} sm={{ span: 8, offset: 16 }}>
            <Row gutter={[16, 16]} wrap={true}>
              <Col xs={24}>
                <EventActionCard machineId={machineId} retailerId={retailerId} />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};

export default EventList;
