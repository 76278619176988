import { css } from 'styled-components';
import { TicketSeverity } from 'generated/types';

const severityColors = css<{ severity: TicketSeverity }>`
  background-color: ${(props) => {
    if (props.severity === TicketSeverity.A) {
      return '#6E0E0A';
      // return props.theme.connect.colors.colorBgSeverityA;
    } else if (props.severity === TicketSeverity.B) {
      return props.theme.connect.colors.colorBgSeverityB;
    } else if (props.severity === TicketSeverity.C) {
      return props.theme.connect.colors.colorBgSeverityC;
    } else if (props.severity === TicketSeverity.D) {
      return props.theme.connect.colors.colorBgSeverityD;
    }

    return 'transparent';
  }};

  color: ${(props) => {
    if (props.severity === TicketSeverity.A) {
      return props.theme.isDark ? props.theme.ant.colorText : props.theme.ant.colorBgBase;
    } else if (props.severity === TicketSeverity.B) {
      return props.theme.isDark ? props.theme.ant.colorText : props.theme.ant.colorBgBase;
    } else if (props.severity === TicketSeverity.C) {
      return !props.theme.isDark ? props.theme.ant.colorText : props.theme.ant.colorBgBase;
    } else if (props.severity === TicketSeverity.D) {
      return !props.theme.isDark ? props.theme.ant.colorText : props.theme.ant.colorBgBase;
    }

    return props.theme.ant.colorText;
  }};
`;
export default severityColors;
