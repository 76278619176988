import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import { TitleProps } from 'antd/es/typography/Title';
import { StyledComponentsTheme } from 'themes/StyledComponentsThemeProvider';

const { Title } = Typography;

declare const levels: [1, 2, 3, 4, 5];
type Level = (typeof levels)[number];

const getTitleFontSize = (
  theme: StyledComponentsTheme,
  level?: Level
): number => {
  switch (level) {
    case 1:
      return theme.ant.fontSizeHeading1;
    case 2:
      return theme.ant.fontSizeHeading2;
    case 3:
      return theme.ant.fontSizeHeading3;
    case 4:
      return theme.ant.fontSizeHeading4;
    case 5:
      return theme.ant.fontSizeHeading5;
    default:
      return theme.ant.fontSize;
  }
};

const StyledTitle = styled(Title)<{ $level?: Level }>`
  &&& {
    &:hover {
      div.ant-typography-edit {
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
      }
    }

    div.ant-typography-edit {
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    textarea.ant-input {
      font-weight: ${(props) => props.theme.ant.fontWeightStrong};
      line-height: 1.23; // TODO: how is this calculated from line-height-base??
      font-size: ${(props) => getTitleFontSize(props.theme, props.$level)}px;
    }
  }
`;

const EditableTitle: React.FC<TitleProps> = (props) => {
  const { children, level, ...rest } = props;

  return (
    <StyledTitle $level={level} level={level} {...rest}>
      {children}
    </StyledTitle>
  );
};

export default EditableTitle;
