import { useConfirmTicketCommentFileUploadMutation, useRequestTicketCommentFileUploadMutation } from 'generated/types';
import useAssetUploadHandler from 'components/lib/upload/useAssetUploadHandler';
import { UploadRequestOption } from 'rc-upload/es/interface';
import { gql } from '@apollo/client/core';

gql`
  mutation RequestTicketCommentFileUpload($input: CreateTicketCommentAttachmentUploadTokenInput!) {
    createTicketCommentAttachmentUploadToken(input: $input) {
      assetUploadToken {
        ...FullAssetUploadToken
      }
    }
  }
`;

gql`
  mutation ConfirmTicketCommentFileUpload($input: SetTicketCommentAttachmentUploadCompletedInput!) {
    setTicketCommentAttachmentUploadCompleted(input: $input) {
      asset {
        ...AssetListItem
      }
    }
  }
`;

function useTicketCommentUploadHandler() {
  const [requestUpload] = useRequestTicketCommentFileUploadMutation();
  const [confirmUpload] = useConfirmTicketCommentFileUploadMutation();

  const upload = useAssetUploadHandler();

  return async (options: UploadRequestOption, signal?: AbortSignal) => {
    await upload({
      options,
      getUploadAccessToken: async (file: File) => {
        const { data } = await requestUpload({
          variables: {
            input: {
              file: {
                filename: file.name,
                byteSize: file.size,
                contentType: file.type,
                tags: [],
              },
            },
          },
        });

        return data?.createTicketCommentAttachmentUploadToken.assetUploadToken;
      },
      confirmUpload: async (assetId) => {
        const { data } = await confirmUpload({
          variables: {
            input: {
              assetId,
            },
          },
        });

        return data?.setTicketCommentAttachmentUploadCompleted?.asset?.url;
      },
      signal: signal,
    });
  };
}

export default useTicketCommentUploadHandler;
