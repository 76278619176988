import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Alert, Button, Empty, Skeleton } from 'antd';
import useGetAllRetailers from 'graphql/retailer/useGetAllRetailers';
import MachineSearchResult from 'layouts/components/MachineNav/MachineSearchResult';
import useMatrixNav from 'layouts/matrix/useMatrixNav';
import MenuItem, { MenuItemSkeleton } from 'components/lib/Menu/MenuItem';
import { defineMessages } from 'react-intl';
import { GlobalOutlined } from '@ant-design/icons';
import Menu from 'components/lib/Menu/Menu';
import Badge from 'components/lib/Badge/Badge';
import commonMessages from 'components/i18n/commonMessages';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { useGetRetailerPermissionsLazyQuery } from 'generated/types.tsx';
import { useAppDispatch, useAppSelector } from 'redux/store.ts';
import {
  closeMobileSearchDrawer,
  collapseRetailer,
  expandRetailer,
  selectExpandedRetailers,
  selectSearchTerm,
} from 'redux/machineNavSlice.ts';

const MachineBadge = styled(Badge)`
  && {
    .ant-badge-count {
      background: ${(props) => props.theme.ant.colorBorder};
      color: ${(props) => props.theme.ant.colorTextSecondary};

      a:hover & {
        background: ${(props) => props.theme.ant.colorBorder};
      }
    }
  }
`;

const messages = defineMessages({
  errorLoadRetailers: {
    id: 'retailer_search_result.error_load_retailers',
    defaultMessage: 'Error loading retailers',
  },
  emptyRetailers: {
    id: 'retailer_search_result.empty_retailers',
    defaultMessage:
      "You don't have access to any retailers yet. Please contact your administrator 🙏",
  },
  emptySearchResult: {
    id: 'retailer_search_result.empty_search_result',
    defaultMessage: 'No matches. {br}Try refining your search',
  },
});

type RetailerRefDict = { [retailerId: string]: HTMLLIElement | null };

const RetailerSearchResult: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isLoading, retailers, hasNextPage, error, retry, totalCount } = useGetAllRetailers(true);
  const { selectedLevel, retailerId, getUrlToRoot, getUrlToRetailer, resolving } = useMatrixNav();
  const retailerRef = useRef<RetailerRefDict>({});
  const rootRef = useRef<HTMLLIElement>(null);
  const intl = useConnectIntl();
  const searchTerm = useAppSelector(selectSearchTerm);

  const expandedRetailers = useAppSelector(selectExpandedRetailers);
  const isRetailerExpanded = useCallback(
    (retailerId?: number) => {
      return (retailerId && expandedRetailers[retailerId]) || false;
    },
    [expandedRetailers]
  );
  const isCurrentRetailerExpanded = isRetailerExpanded(retailerId);

  const handleSelectRetailer = useCallback(() => {
    dispatch(closeMobileSearchDrawer());
  }, [dispatch]);

  const [getRetailerPermissions] = useGetRetailerPermissionsLazyQuery();

  const preloadRetailer = useCallback(
    async (retailerId: number) => {
      await getRetailerPermissions({
        variables: {
          retailerId,
        },
        fetchPolicy: 'cache-first',
      });
    },
    [getRetailerPermissions]
  );

  const veryDone = !isLoading && !hasNextPage && !resolving;
  const isEmpty = veryDone && retailers?.length === 0;

  useEffect(() => {
    if (!veryDone) return;

    if (selectedLevel === 'root') {
      rootRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    } else if (selectedLevel === 'retailer' && retailerId) {
      retailerRef.current[retailerId]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    } else if (selectedLevel === 'ticket' && retailerId) {
      if (!isCurrentRetailerExpanded) {
        retailerRef.current[retailerId]?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, [retailerId, selectedLevel, veryDone, rootRef, isCurrentRetailerExpanded]);

  // Expand retailer(s) if there is only one retailer
  useEffect(() => {
    if (totalCount === undefined) {
      return;
    }
    // demo gas
    if (totalCount <= 3) {
      retailers?.forEach((retailer) => {
        const retailerId = retailer.retailerId;
        dispatch(expandRetailer({ retailerId }));
      });
    }
  }, [totalCount, retailers, dispatch]);

  return (
    <Menu>
      <MenuItem
        title={intl.formatMessage(commonMessages.global)}
        leftIcon={<GlobalOutlined />}
        ref={rootRef}
        isSelected={selectedLevel === 'root'}
        to={getUrlToRoot()}
        onClick={handleSelectRetailer}
      />
      {isLoading && <MenuItemSkeleton rows={5} />}
      {error && (
        <Alert
          message={intl.formatMessage(messages.errorLoadRetailers, {
            msg: error.message,
          })}
          type={'error'}
          showIcon={true}
          style={{ margin: '4px' }}
          action={
            <Button size={'small'} onClick={() => retry()}>
              Retry
            </Button>
          }
        />
      )}
      {isEmpty && searchTerm.length <= 0 && (
        <Empty
          description={
            <Alert
              type={'warning'}
              style={{ margin: '16px 4px' }}
              message={intl.formatMsg(messages.emptyRetailers, { br: <br /> })}
            />
          }
          style={{ marginTop: 16 }}
        />
      )}
      {isEmpty && searchTerm.length > 0 && (
        <Empty
          description={intl.formatMsg(messages.emptySearchResult, { br: <br /> })}
          style={{ marginTop: 16 }}
        />
      )}

      {retailers?.map((retailer) => {
        return (
          <MenuItem
            ref={(ref) => (retailerRef.current[retailer.retailerId] = ref)}
            key={retailer.retailerId}
            title={retailer.name}
            to={getUrlToRetailer(retailer.retailerId)}
            isExpanded={isRetailerExpanded(retailer.retailerId)}
            isSelected={retailerId === retailer.retailerId && selectedLevel === 'retailer'}
            rightIcon={
              retailer?.machines?.totalCount !== undefined ? (
                <MachineBadge showZero={true} dot={false} count={retailer.machines.totalCount} />
              ) : isLoading ? (
                <Skeleton.Avatar shape={'circle'} size={20} active={true} />
              ) : (
                <MachineBadge showZero={true} dot={false} count={0} />
              )
            }
            onChangeExpanded={(nextIsExpanded) => {
              if (nextIsExpanded) {
                dispatch(expandRetailer({ retailerId: retailer.retailerId }));
              } else {
                dispatch(collapseRetailer({ retailerId: retailer.retailerId }));
              }
            }}
            onClick={handleSelectRetailer}
            onHoverIntent={() => preloadRetailer(retailer.retailerId)}
          >
            <MachineSearchResult
              retailerId={retailer.retailerId}
              machineCountHint={retailer.machines?.totalCount}
            />
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default RetailerSearchResult;
