import React, { useCallback } from 'react';
import {
  useGetUserAndSettingsQuery,
  useUpdateMyTimezoneMutation,
} from 'generated/types';
import { useIntl } from 'react-intl';
import TimezoneSelect from 'components/i18n/TimezoneSelect/TimezoneSelect';
import useMessageApi from 'components/global/useMessageApi';

const MyTimezoneSelect: React.FC = () => {
  const message = useMessageApi();
  const { data: dataUser, loading: loadingUser } = useGetUserAndSettingsQuery();
  const [updateMyTimezone] = useUpdateMyTimezoneMutation({
    notifyOnNetworkStatusChange: true,
  });

  const intl = useIntl();

  const handleSelect = useCallback(
    async (timezoneId: string) => {
      try {
        await updateMyTimezone({
          variables: {
            input: {
              timeZoneId: timezoneId,
            },
          },
          optimisticResponse: dataUser?.me
            ? {
                __typename: 'Mutation',
                updateMyTimeZone: {
                  __typename: 'UpdateMyTimeZonePayload',
                  user: {
                    ...dataUser.me,
                    timezoneId,
                  },
                },
              }
            : undefined,
        });
        message.success(
          intl.formatMessage({
            id: 'my_timezone_select.updated_successfully',
            defaultMessage: 'Timezone updated',
          })
        );
      } catch (err) {
        message.error(
          intl.formatMessage({
            id: 'my_timezone_select.could_not_change_timezone',
            defaultMessage: 'Could not change user timezone',
          })
        );
      }
    },
    [dataUser, intl, updateMyTimezone, message]
  );

  return (
    <TimezoneSelect
      style={{ minWidth: 200 }}
      value={dataUser?.me.timezoneId}
      onSelect={handleSelect}
      loading={loadingUser}
    />
  );
};

export default MyTimezoneSelect;
