import React, { useCallback, useMemo } from 'react';
import {
  FullPageInfoFragmentDoc,
  TicketListItemFragment,
  TicketListItemFragmentDoc,
  TicketsFilterInput,
  useGetTicketLazyQuery,
  useGetTicketListQuery,
  useGetTicketPermissionsLazyQuery,
  useOnTicketUpdatedSubscription,
} from 'generated/types';
import { Button, Col, List, Row } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import TicketListItem from 'components/ticket/TicketList/TicketListItem';
import styled from 'styled-components';
import { gql } from '@apollo/client/core';
import commonMessages from 'components/i18n/commonMessages';
import ResponsiveListCard from 'components/lib/List/ResponsiveListCard';
import useMatrixNav from 'layouts/matrix/useMatrixNav';
import TicketCount from 'components/ticket/TicketFilter/TicketCount';
import useTicketFilter from 'components/ticket/TicketFilter/useTicketFilter';
import useTicketDisplayMode from 'components/ticket/TicketFilter/useTicketDisplayMode';
import TicketListDisplayModeSegmented from 'components/ticket/TicketList/TicketListDisplayModeSegmented';
import { useNavigate } from 'react-router-dom';
import { useScreenInfo } from 'layouts/responsive/useScreenInfo';
import TicketListFilter from 'components/ticket/TicketList/TicketListFilter.tsx';
import TicketSortSelect from 'components/ticket/TicketFilter/TicketSortSelect.tsx';

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

gql`
  query GetTicketList(
    $cursor: String
    $sortField: TicketSortField!
    $sortDirection: SortDirection!
    $filter: TicketsFilterInput!
  ) {
    allTickets(
      first: 50
      after: $cursor
      sortField: $sortField
      sortDirection: $sortDirection
      filter: $filter
    ) {
      edges {
        node {
          ...TicketListItem
        }
      }
      totalCount
      pageInfo {
        ...FullPageInfo
      }
    }
  }
  ${TicketListItemFragmentDoc}
  ${FullPageInfoFragmentDoc}
`;

const ActionCol = styled(Col)`
  &&& {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
`;

const StyledList = styled(List<TicketListItemFragment>)`
  &&& {
    li.ant-list-item {
      padding: 12px 0;
    }
  }
`;

type TicketListContextFilter = Pick<TicketsFilterInput, 'retailerId' | 'serialNo' | 'machineId'>;

interface Props {
  canCreateTicket: boolean;
  contextFilter?: TicketListContextFilter;
}

const TicketList: React.FC<Props> = (props) => {
  const { contextFilter, canCreateTicket } = props;
  const { screenMap: breakpoint } = useScreenInfo();
  const intl = useIntl();
  const { ticketFilter } = useTicketFilter();
  const { setTicketDisplayMode } = useTicketDisplayMode();
  const { getUrlToCreateTicket } = useMatrixNav();
  const navigate = useNavigate();

  const handleNewTicket = useCallback(async () => {
    navigate(getUrlToCreateTicket());
  }, [navigate, getUrlToCreateTicket]);

  // NOTE: subscription will only listen for updates for the current machine instance!
  useOnTicketUpdatedSubscription({
    variables: {
      machineId: contextFilter?.machineId,
      retailerId: contextFilter?.retailerId,
      ticketId: undefined,
    },
  });

  const { data, loading, fetchMore } = useGetTicketListQuery({
    variables: {
      cursor: undefined,
      sortField: ticketFilter.sortField,
      sortDirection: ticketFilter.sortDirection,
      filter: {
        closedFrom: ticketFilter.closedFrom,
        closedTo: ticketFilter.closedTo,
        createdFrom: ticketFilter.createdFrom,
        createdTo: ticketFilter.createdTo,
        assignedToUserIds: ticketFilter.assignedToUserIds,
        includeUnassignedUsers: ticketFilter.includeUnassignedUsers,
        search: ticketFilter.search,
        ticketSeverities: ticketFilter.severities,
        ticketTypeIds: ticketFilter.ticketTypeIds,
        ticketStatuses: ticketFilter.statuses,
        ticketTags: ticketFilter.tags,
        dueTo: ticketFilter.dueTo,
        includeNoDueDate: true, // always true for now
        ...contextFilter,
      },
    },
    notifyOnNetworkStatusChange: true, // will update loading when fetching more
    fetchPolicy: 'cache-and-network', // load from cache first, then updated data from network
  });

  const hasNextPage = data?.allTickets?.pageInfo.hasNextPage || false;
  const nextCursor = data?.allTickets?.pageInfo.endCursor;

  const tableData = useMemo(
    () => data?.allTickets?.edges?.map((c) => ({ ...c.node, key: c.node.id })),
    [data]
  );

  const [getTicket] = useGetTicketLazyQuery();
  const [getTicketPermissions] = useGetTicketPermissionsLazyQuery();
  const preloadTicket = useCallback(
    async (ticketId: number) => {
      await Promise.all([
        getTicket({
          variables: {
            ticketId,
          },
          fetchPolicy: 'cache-first',
        }),
        getTicketPermissions({
          variables: {
            ticketId,
          },
          fetchPolicy: 'cache-first',
        }),
      ]);
    },
    [getTicket, getTicketPermissions]
  );

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col xs={12}>
          <TicketListDisplayModeSegmented
            value={'list'}
            onChange={(value) => setTicketDisplayMode(value)}
          />
        </Col>
        <ActionCol xs={12}>
          <Button
            type={'primary'}
            icon={<PlusCircleOutlined style={{ marginRight: 4 }} />}
            onClick={handleNewTicket}
            disabled={!canCreateTicket}
          >
            <FormattedMessage id={'ticket_list.button_new_ticket'} defaultMessage={'New ticket'} />
          </Button>
        </ActionCol>
      </Row>

      <>
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          <Col span={24}>
            <ResponsiveListCard>
              <TicketListFilter />
            </ResponsiveListCard>
          </Col>
          <Col span={24}>
            <ResponsiveListCard alwaysShowCard={false}>
              <StyledList
                itemLayout={breakpoint.md ? 'horizontal' : 'vertical'}
                dataSource={tableData}
                loading={loading}
                bordered={false}
                size={'default'}
                renderItem={(item) => {
                  return (
                    <TicketListItem
                      ticket={item}
                      key={item.id}
                      hideMachineLink={false}
                      hideRetailerLink={!!contextFilter?.retailerId}
                      appendSerialNumber={true}
                      onHoverIntent={() => preloadTicket(item.ticketId)}
                    />
                  );
                }}
                header={
                  <ListHeader>
                    <div>
                      <TicketCount totalCount={data?.allTickets?.totalCount} />
                    </div>
                    <TicketSortSelect size={'middle'} />
                  </ListHeader>
                }
              />
            </ResponsiveListCard>
          </Col>
        </Row>
        {hasNextPage && (
          <Row style={{ marginBottom: 16 }} justify={'center'}>
            <Col span={24}>
              <div>
                <Button
                  disabled={loading}
                  onClick={() =>
                    fetchMore({
                      variables: {
                        cursor: nextCursor,
                        sortField: ticketFilter.sortField,
                        sortDirection: ticketFilter.sortDirection,
                        filter: {
                          closedFrom: ticketFilter.closedFrom,
                          closedTo: ticketFilter.closedTo,
                          createdFrom: ticketFilter.createdFrom,
                          createdTo: ticketFilter.createdTo,
                          assignedToUserIds: ticketFilter.assignedToUserIds,
                          includeUnassignedUsers: ticketFilter.includeUnassignedUsers,
                          ...contextFilter,
                          search: ticketFilter.search,
                          ticketSeverities: ticketFilter.severities,
                          ticketTypeIds: ticketFilter.ticketTypeIds,
                          ticketStatuses: ticketFilter.statuses,
                          ticketTags: ticketFilter.tags,
                          dueTo: ticketFilter.dueTo,
                          includeNoDueDate: true,
                        },
                      },
                    })
                  }
                  loading={loading}
                >
                  {intl.formatMessage(commonMessages.load_more)}
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </>
    </>
  );
};

export default TicketList;
