import React from 'react';
import { useParams } from 'react-router-dom';
import {
  RetailerListItemFragmentDoc,
  RetailerPermissionsFullFragment,
  RetailerPermissionsFullFragmentDoc,
  useGetRetailerPermissionsQuery,
} from 'generated/types.tsx';
import parseNumber from 'util/parseNumber.ts';
import NotFoundPage from 'pages/util/NotFoundPage/NotFoundPage.tsx';
import ErrorPage from 'pages/util/ErrorPage/ErrorPage.tsx';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { isNotFoundError, isUnauthorizedApolloError } from 'graphql/apollo/apolloErrorUtil.ts';
import Spinner from 'components/lib/Spinner/Spinner.tsx';
import styled from 'styled-components';
import { gql } from '@apollo/client/core';

interface Props {
  children?: React.ReactNode;
}

const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

gql`
  query GetRetailerPermissions($retailerId: Int!) {
    retailer(retailerId: $retailerId) {
      ...RetailerListItem
      permissions {
        ...RetailerPermissionsFull
      }
    }
  }
  ${RetailerListItemFragmentDoc}
  ${RetailerPermissionsFullFragmentDoc}
`;

interface RetailerPermissionsContext {
  permissions: RetailerPermissionsFullFragment;
  retailerId: number;
}

const RetailerPermissionsContext = React.createContext<RetailerPermissionsContext | undefined>(
  undefined
);

// Create a hook to get the retailer permissions from context
export const useRetailerPermissions = () => {
  const context = React.useContext(RetailerPermissionsContext);
  if (!context) {
    throw new Error('useRetailerPermissions must be used within a RetailerPermissionsContext');
  }
  return context.permissions;
};

const RequireRetailerAccess: React.FC<Props> = ({ children }) => {
  const intl = useConnectIntl();
  const { retailerId: retailerIdRaw } = useParams();
  const retailerId = parseNumber(retailerIdRaw);
  const { data, loading, error } = useGetRetailerPermissionsQuery({
    variables: retailerId
      ? {
          retailerId: retailerId,
        }
      : undefined,
    skip: retailerId === undefined || isNaN(retailerId),
  });

  const isUnauthorized = isUnauthorizedApolloError(error);
  const isNotFound = isNotFoundError(error);
  const isInvalidRetailerId = retailerId === undefined || isNaN(retailerId);

  if (loading) {
    return (
      <Container>
        <Spinner delayUntil={600} />
      </Container>
    );
  }

  if (isUnauthorized || isNotFound || isInvalidRetailerId) {
    return (
      <NotFoundPage
        subtitle={intl.formatMsg(
          {
            id: 'require_retailer_access.subtitle',
            defaultMessage:
              'Sorry, the retailer with id {retailerId} does not exist, {br}or you might not have access to it.',
          },
          {
            retailerId: retailerIdRaw,
            br: <br />,
          }
        )}
      />
    );
  } else if (error) {
    return <ErrorPage error={error} />;
  }

  return data ? (
    <RetailerPermissionsContext.Provider
      value={{
        permissions: data.retailer.permissions,
        retailerId: retailerId,
      }}
    >
      {children}
    </RetailerPermissionsContext.Provider>
  ) : (
    children
  );
};

export default RequireRetailerAccess;
