import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  color: #252525;
`;

const LoadingPage: React.FC = () => {
  return (
    <Page>
      <Box>
        <Spin style={{ marginBottom: '8px' }} />
        <Text>Loading</Text>
      </Box>
    </Page>
  );
};

export default LoadingPage;
