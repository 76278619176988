import { DeletedUserListItemFragment, UserListItemFragment } from 'generated/types.tsx';

function getUserColor(user: UserListItemFragment | DeletedUserListItemFragment | undefined) {
  const saturation = 55;
  const lightness = 60;

  return user === undefined
    ? stringToHslColor('m', saturation, lightness)
    : user.__typename === 'User'
    ? stringToHslColor(user.name || '', saturation, lightness)
    : stringToHslColor(user.userId.toString(), saturation, lightness);
}

function stringToHslColor(str: string, saturation: number, lightness: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  return 'hsl(' + (hash % 360) + ', ' + saturation + '%, ' + lightness + '%)';
}

export default getUserColor;
