import React from 'react';
import { DeletedUserListItemFragment, UserListItemFragment } from 'generated/types.tsx';
import styled from 'styled-components';
import useFormatTools from 'i18n/useFormatTools.ts';
import { Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import getUserColor from 'components/user/UserAvatar/getUserColor.ts';

const AssignedUserBox = styled.div<{ $color?: string }>`
  position: relative;
  height: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 22px;
  background: ${({ $color }) => $color || 'rgba(39, 41, 50, 0.06)'};
  border-radius: 4px;
  padding-inline-start: 8px;
  padding-inline-end: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;

interface Props {
  assignedToUser?: UserListItemFragment | DeletedUserListItemFragment;
  useColor?: boolean;
}

/***
 * Render a label with the assigned user's name or "Unassigned" if no user is assigned.
 * @param assignedToUser
 * @constructor
 */
const AssignedToUser: React.FC<Props> = ({ assignedToUser, useColor }) => {
  const { formatUserName } = useFormatTools();
  const color = useColor ? getUserColor(assignedToUser) : undefined;

  if (!assignedToUser) {
    return null;
  }

  if (assignedToUser.__typename === 'DeletedUser') {
    return <AssignedUserBox $color={color}>Deleted user</AssignedUserBox>;
  } else {
    return (
      <AssignedUserBox $color={color}>
        <UserOutlined />
        {/*<UserAvatar user={assignedToUser}  />*/}
        <Typography.Text ellipsis={true}>{formatUserName(assignedToUser)}</Typography.Text>
      </AssignedUserBox>
    );
  }
};

export default AssignedToUser;
