import React, { useCallback } from 'react';
import MatrixBreadcrumbs, {
  BreadcrumbRow,
} from 'layouts/MainLayout/MatrixBreadcrumbs/MatrixBreadcrumbs';
import PageTabs from 'components/lib/Page/PageTabs';
import { Alert, Col, Row, Skeleton, Typography } from 'antd';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import PageTitleRow from 'layouts/components/Page/PageTitleRow';
import {
  MachineListItemFragmentDoc,
  MachinePermissionsFullFragmentDoc,
  MachineType,
  useGetMachinePageQuery,
} from 'generated/types';
import SearchButtonMobile from 'layouts/MainLayout/SearchButtonMobile/SearchButtonMobile';
import { Helmet } from 'react-helmet-async';
import TicketList from 'components/ticket/TicketList/TicketList';
import ServiceInfoTags from 'layouts/components/Page/ServiceInfoTags';
import useMatrixNav from 'layouts/matrix/useMatrixNav';
import { useMatrixCategory } from 'layouts/matrix/useMatrixCategory';
import useTicketDisplayMode from 'components/ticket/TicketFilter/useTicketDisplayMode';
import TicketMap from 'components/ticket/TicketMap/TicketMap';
import useIsMobile from 'layouts/responsive/useIsMobile';
import { gql } from '@apollo/client/core';
import { useIntl } from 'react-intl';
import Badge from 'components/lib/Badge/Badge';
import MachineMapCard from 'components/machine/status/MachineMapCard/MachineMapCard';
import MachineActionsCard from 'components/machine/status/MachineActionsCard/MachineActionsCard';
import tabMessages from 'pages/matrix/tabMessages';
import MuteMachineCircle from 'components/events/MuteMachineCircle';
import WfdIllustration from 'components/illustrations/WfdIllustration';
import GasContainer247Illustration from 'components/illustrations/GasContainer247Illustration';
import PvmIllustration from 'components/illustrations/PvmIllustration';
import { AlpiloxIllustration } from 'components/illustrations/AlpiloxIllustration';
import { Category, categoryMessages } from 'layouts/matrix/category';
import { TypedPageTab } from 'components/lib/Page/typedPageTab';
import PageTab from 'components/lib/Page/PageTab';
import EventList from 'components/events/EventList';
import useFormatTools from 'i18n/useFormatTools';
import DataTransferList from 'components/datatransfer/DataTransferList';
import PageHeaderRow from 'components/lib/Page/PageHeaderRow';
import machineMessages from 'components/machine/machineMessages';
import { isMachineTab } from 'layouts/matrix/matrix';
import MachinePropertyList from 'components/machine/properties/MachinePropertyList/MachinePropertyList.tsx';
import LastPredictionCard from 'components/prediction/LastPredictionCard.tsx';
import OrderLineTool from 'components/ticket/TicketAccounting/OrderLineTool/OrderLineTool.tsx';

const { Text, Title } = Typography;

const LogoAndTitleContainer = styled.div<{ $isMobile?: boolean }>`
  flex: 1 1 auto;
  display: flex;
  gap: 8px;
  height: ${(props) => (props.$isMobile ? ' 32px' : ' 80px')};
  overflow: hidden;
`;

const MobilePageHeaderRow = styled(PageHeaderRow)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LogoContainer = styled.div<{ $isMobile?: boolean }>`
  flex: 0 0 auto;
  height: ${(props) => (props.$isMobile ? ' 32px' : ' 80px')};
  width: ${(props) => (props.$isMobile ? ' 32px' : ' 80px')};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.ant.colorText};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 1 auto;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  && {
    .ant-typography {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

const SearchButtonContainerMobile = styled.div`
  flex: 0 0 auto;
`;

gql`
  query GetMachinePage($machineId: Int!) {
    machine(machineId: $machineId) {
      ...MachineListItem
      permissions {
        ...MachinePermissionsFull
      }
    }
  }
  ${MachinePermissionsFullFragmentDoc}
  ${MachineListItemFragmentDoc}
`;

const MachinePage: React.FC = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { resolving, retailerId, machineId } = useMatrixNav();
  const intl = useIntl();

  const { tab: tabRaw, machineId: machineIdRaw } = useParams();
  const activeTab = tabRaw || 'operational';
  const isValidMachineTab = isMachineTab(activeTab);
  const isValidMachineId = machineIdRaw && machineId;

  const category = useMatrixCategory();
  const { ticketDisplayMode } = useTicketDisplayMode();
  const { formatMachineTitle } = useFormatTools();

  const { data, error } = useGetMachinePageQuery({
    variables: machineId
      ? {
          machineId,
        }
      : undefined,
    // fetchPolicy: 'cache-and-network',
    skip: !machineId,
  });

  const handleTabChange = useCallback(
    (tabKey: string) => {
      navigate(`/machine/${machineId}/${tabKey === 'operational' ? '' : tabKey}`);
    },
    [machineId, navigate]
  );

  if (!category || !isValidMachineTab) {
    return <Navigate to={`/machine/${machineId}`} />;
  }

  const machineTitle = formatMachineTitle(data?.machine);
  const muted = data?.machine.notificationMute || false;

  const eventsTab = muted ? (
    <Badge size={'default'} count={<MuteMachineCircle />}>
      {intl.formatMessage(tabMessages.events)}
    </Badge>
  ) : (
    intl.formatMessage(tabMessages.events)
  );

  const permissions = data?.machine.permissions;

  const tabs: { [key in Category]: TypedPageTab[] } = {
    operational: [
      {
        key: 'operational',
        active: activeTab === 'operational',
        label: intl.formatMessage(categoryMessages.operational),
        children: (
          <PageTab>
            <Row gutter={[16, 16]} wrap={true} style={{ paddingBottom: 64 }}>
              <Col xs={24} sm={{ span: 8, offset: 16 }}>
                <Row gutter={[16, 16]} wrap={true}>
                  <Col xs={24}>
                    <MachineMapCard machineId={machineId} />
                  </Col>
                  <Col xs={24}>
                    <MachineActionsCard machineId={machineId} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </PageTab>
        ),
      },
      {
        key: 'tickets',
        disabled: !permissions?.tabs.operational.tickets,
        active: activeTab === 'tickets',
        label: intl.formatMessage(tabMessages.tickets),
        children: (
          <PageTab fullscreen={ticketDisplayMode === 'map'}>
            {!resolving && (
              <>
                {ticketDisplayMode === 'list' && data && (
                  <TicketList
                    contextFilter={{
                      machineId: machineId,
                      serialNo: data.machine.serialNo,
                      retailerId: undefined, // can't pass retailerId, we want to show ticket history
                    }}
                    canCreateTicket={data?.machine.permissions.canCreateTicket || false}
                  />
                )}
                {ticketDisplayMode === 'map' && data && (
                  <TicketMap
                    contextFilter={{
                      machineId: machineId,
                      serialNo: data.machine.serialNo,
                      retailerId: undefined,
                    }}
                  />
                )}
                {ticketDisplayMode === 'order-lines' && data && (
                  <OrderLineTool
                    contextFilter={{
                      machineId: machineId,
                      serialNo: data.machine.serialNo,
                      retailerId: undefined, // can't pass retailerId, we want to show ticket order line history
                    }}
                  />
                )}
              </>
            )}
          </PageTab>
        ),
      },
      {
        key: 'events',
        disabled: !permissions?.tabs.operational.events,
        active: activeTab === 'events',
        label: eventsTab,
        children: (
          <PageTab>
            <EventList machineId={machineId} retailerId={retailerId} />
          </PageTab>
        ),
      },
      {
        key: 'properties',
        disabled: !permissions?.tabs.operational.properties,
        active: activeTab === 'properties',
        label: intl.formatMessage(tabMessages.properties),
        children: (
          <PageTab>
            <MachinePropertyList machineId={machineId} />
          </PageTab>
        ),
      },
      // {
      //   key: 'users',
      //   disabled: !permissions?.tabs.operational.users,
      //   active: activeTab === 'users',
      //   label: intl.formatMessage(tabMessages.users),
      //   children: <PageTab>users</PageTab>,
      // },
      {
        key: 'integrations',
        disabled: !permissions?.tabs.operational.integrations,
        active: activeTab === 'integrations',
        label: intl.formatMessage(tabMessages.integrations),
        children: (
          <PageTab>
            <DataTransferList machineId={machineId} retailerId={retailerId} />
          </PageTab>
        ),
      },
      {
        key: 'predictions',
        disabled: !permissions?.tabs.operational.predictions,
        active: activeTab === 'predictions',
        label: intl.formatMessage(tabMessages.predictions),
        children: (
          <PageTab>
            <LastPredictionCard machineId={machineId} retailerId={retailerId} />
          </PageTab>
        ),
      },
    ],
    commercial: [
      {
        key: 'commercial',
        active: activeTab === 'commercial',
        label: intl.formatMessage(categoryMessages.commercial),
        children: <PageTab>{''}</PageTab>,
      },
      // {
      //   key: 'price-matrix',
      //   disabled: !permissions?.tabs.commercial.priceMatrix,
      //   active: activeTab === 'price-matrix',
      //   label: intl.formatMessage(tabMessages.priceMatrix),
      //   children: <PageTab>Price matrix</PageTab>,
      // },
      // {
      //   key: 'transactions',
      //   disabled: !permissions?.tabs.commercial.transactions,
      //   active: activeTab === 'transactions',
      //   label: intl.formatMessage(tabMessages.transactions),
      //   children: <PageTab>Transactions</PageTab>,
      // },
      // {
      //   key: 'reconciliation',
      //   disabled: !permissions?.tabs.commercial.reconciliation,
      //   active: activeTab === 'reconciliation',
      //   label: intl.formatMessage(tabMessages.reconciliation),
      //   children: <PageTab>Reconciliation</PageTab>,
      // },
      // {
      //   key: 'statistics',
      //   disabled: !permissions?.tabs.commercial.statistics,
      //   active: activeTab === 'statistics',
      //   label: intl.formatMessage(tabMessages.statistics),
      //   children: <PageTab>Statistics</PageTab>,
      // },
    ],
    logistics: [
      {
        key: 'logistics',
        active: activeTab === 'logistics',
        label: intl.formatMessage(categoryMessages.logistics),
        children: <PageTab>{''}</PageTab>,
      },
      // {
      //   key: 'refill-order',
      //   disabled: !permissions?.tabs.logistics.refillOrder,
      //   active: activeTab === 'refill-order',
      //   label: intl.formatMessage(tabMessages.refillOrders),
      //   children: <PageTab>Refill order</PageTab>,
      // },
      // {
      //   key: 'inventory',
      //   disabled: !permissions?.tabs.logistics.inventory,
      //   active: activeTab === 'inventory',
      //   label: intl.formatMessage(tabMessages.inventory),
      //   children: <PageTab>Inventory</PageTab>,
      // },
    ],
  };

  const logo = (
    <LogoContainer $isMobile={isMobile}>
      <Skeleton
        loading={!data}
        avatar={{
          size: isMobile ? 40 : 70,
          shape: 'square',
        }}
        active={true}
        paragraph={{ rows: 0, width: 0 }}
      />
      {data?.machine.machineType === MachineType.WindshieldWasherFluidDispenser && (
        <WfdIllustration height={isMobile ? 30 : 80} />
      )}
      {data?.machine.machineType === MachineType.GasContainer247 && (
        <GasContainer247Illustration height={isMobile ? 30 : 80} />
      )}
      {data?.machine.machineType === MachineType.PropaneVendingMachine && (
        <PvmIllustration height={isMobile ? 30 : 80} />
      )}
      {data?.machine.machineType === MachineType.Alpilox && (
        <AlpiloxIllustration height={isMobile ? 30 : 80} />
      )}
    </LogoContainer>
  );

  const title = (
    <Title level={isMobile ? 3 : 2} style={{ marginBottom: 0, width: '100%' }} ellipsis={true}>
      {data?.machine.location || data?.machine.serialNo}
    </Title>
  );

  const serialNo = (
    <div>
      <Text type={'secondary'} copyable={true}>
        {data?.machine.serialNo}
      </Text>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>{machineTitle}</title>
      </Helmet>

      <BreadcrumbRow $isMobile={isMobile}>
        <MatrixBreadcrumbs />
      </BreadcrumbRow>

      <PageTitleRow>
        {/* LEFT */}
        <LogoAndTitleContainer $isMobile={isMobile}>
          {logo}
          <TitleContainer>
            <Skeleton
              loading={!data}
              title={false}
              active={true}
              paragraph={{ rows: 2, style: { width: 350 } }}
            />
            {title}
            {!isMobile && serialNo}
            {error && <Alert message={error.message} type={'error'} />}
            {!isValidMachineId && (
              <Alert
                message={intl.formatMessage(machineMessages.invalidMachineId)}
                type={'error'}
              />
            )}
          </TitleContainer>
        </LogoAndTitleContainer>

        {/* RIGHT */}
        {!isMobile && <ServiceInfoTags machineId={machineId} />}
        {isMobile && (
          <SearchButtonContainerMobile>
            <SearchButtonMobile />
          </SearchButtonContainerMobile>
        )}
      </PageTitleRow>

      {isMobile && (
        <MobilePageHeaderRow>
          {serialNo}
          <ServiceInfoTags machineId={machineId} direction={'horizontal'} />
        </MobilePageHeaderRow>
      )}

      <PageTabs
        onChange={handleTabChange}
        defaultActiveKey={category}
        activeKey={activeTab}
        items={category ? tabs[category] : []}
      />
    </>
  );
};

export default MachinePage;
