import React from 'react';
import { SelectProps } from 'antd/es/select';
import Select, { Option } from 'components/lib/Select/Select';

type OtherSelectProps = Omit<SelectProps<string[]>, 'options' | 'mode'>;

function TypedMachineTagsSelect<T extends string[]>({ children, ...props }: SelectProps<T>) {
  return <Select<T> {...props}>{children}</Select>;
}

type Props = OtherSelectProps & {
  tags?: string[];
};

const MachineTagsSelect: React.FC<Props> = (props) => {
  const { children, tags, ...rest } = props;

  return (
    <TypedMachineTagsSelect
      mode={'tags'}
      placeholder={'Add a new tag'}
      maxTagCount={10}
      variant={'outlined'}
      maxTagTextLength={30}
      {...rest}
    >
      {tags?.map((tag) => (
        <Option key={tag} value={tag}>
          {tag}
        </Option>
      ))}
    </TypedMachineTagsSelect>
  );
};

export default MachineTagsSelect;
