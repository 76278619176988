import React from 'react';
import { useAppSelector } from 'redux/store.ts';
import { selectIsOnline } from 'redux/appSlice.ts';
import { Flex, Modal, Typography } from 'antd';
import { defineMessages } from 'react-intl';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import DinoGame from 'react-chrome-dino-ts';
import 'react-chrome-dino-ts/index.css';
import styled from 'styled-components';

const messages = defineMessages({
  title: {
    id: 'offlineOverlay.title',
    defaultMessage: 'You are offline!',
  },
  message: {
    id: 'offlineOverlay.message',
    defaultMessage:
      'No internet connection found. Please check your connection and try again. Or press the spacebar to play a game!',
  },
});

const Game = styled.div`
  margin-top: -80px;
  z-index: 1;
`;

const Instructions = styled.div`
  flex: 65% 0 0;
  text-align: center;
  margin-top: -30px;
`;

const OfflineOverlay: React.FC = () => {
  const isOnline = useAppSelector(selectIsOnline);
  const intl = useConnectIntl();
  return (
    <Modal
      title={intl.formatMsg(messages.title)}
      centered={true}
      open={isOnline === false}
      closable={false}
      footer={null}
      destroyOnClose={true}
    >
      <Flex vertical={true} gap={0}>
        <Game>
          <DinoGame
            instructions={
              <Flex justify={'center'}>
                <Instructions>
                  <Typography.Text>{intl.formatMsg(messages.message)}</Typography.Text>
                </Instructions>
              </Flex>
            }
          />
        </Game>
      </Flex>
    </Modal>
  );
};

export default OfflineOverlay;
