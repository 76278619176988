import {
  RetailerProductListItemFullFragment,
  UpdateRetailerProductInput,
  usePreviewTemplateQuery,
  useUpdateRetailerProductMutation,
} from 'generated/types';
import styled from 'styled-components';
import { Alert, Button, Col, Form, Image, Input, message, Row, Space, Spin, Tooltip, Typography } from 'antd';
import React, { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { Reorder, useDragControls, useMotionValue } from 'framer-motion';
import { useIntl } from 'react-intl';
import commonMessages from 'components/i18n/commonMessages';
import { CloseOutlined, EditOutlined, HolderOutlined, SaveOutlined } from '@ant-design/icons';
import { useDebouncedCallback } from 'use-debounce';
import { gql } from '@apollo/client/core';
import { useForm } from 'antd/es/form/Form';
import { ValidateStatus } from 'antd/es/form/FormItem';
import { retailerProductMessages } from 'components/retailer/RetailerProductCard/retailerProductMessages';
import useIsMobile from 'layouts/responsive/useIsMobile';
import useRetailerDeployTools from 'components/retailer/useRetailerDeployTools.ts';
import PreviewLanguage from 'components/retailer/RetailerProductCard/PreviewLanguage.tsx';

const LoveHandle = styled(HolderOutlined)`
  cursor: grab;
  touch-action: none;
`;

const NoDragImage = styled(Image)`
  -webkit-user-drag: none;
  user-select: none;
  flex: 0 0 32px;
`;

const StyledReorderItem = styled(Reorder.Item)`
  position: relative;
  width: 100%;
  padding: 12px 0;
  background: ${(props) => props.theme.ant.colorBgContainer};
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  gap: 16px;
`;

const EditContainer = styled.div`
  background: ${(props) => props.theme.ant.colorFillAlter};
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.ant.colorBorder};
  }
`;

gql`
  mutation UpdateRetailerProduct($input: UpdateRetailerProductInput!) {
    updateRetailerProduct(input: $input) {
      retailerProduct {
        id
        retailerProductId
        timestamp
        sort
        nameActive {
          id
          productId
          concatenated
          isDefaultTemplate
          template
          lines
        }
      }
    }
  }
`;

gql`
  query PreviewTemplate($retailerId: Int!, $productId: Int!, $template: String) {
    previewRetailerProductTemplates(retailerId: $retailerId, productId: $productId, template: $template) {
      __typename
      ... on TemplateRenderError {
        id
        errorMessages
        concatenated
        lines
        language {
          id
          code
          nameNative
          nameEnglish
        }
      }
      ... on TemplateRenderSuccess {
        id
        concatenated
        isDefault
        lines
        language {
          id
          code
          nameEnglish
          nameNative
        }
      }
    }
  }
`;

const PreviewOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PreviewContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
`;

const ProductContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const NameAndDescriptionContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const SecondLine = styled.div<{ $isMobile?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-gap: ${(props) => (props.$isMobile ? 4 : 8)}px;
`;

const PreviewError = styled.div<{ $addMargin: boolean }>`
  color: ${(props) => props.theme.ant.colorErrorText};
  margin-left: ${(props) => (props.$addMargin ? '28px' : '0')};
`;

interface ItemProps {
  item: RetailerProductListItemFullFragment;
  myRef: RefObject<Element>;
}

const RetailerProductSortableListItem: React.FC<ItemProps> = ({ item, myRef }) => {
  const y = useMotionValue(0);
  const intl = useIntl();

  const controls = useDragControls();
  const [editing, setEditing] = useState(false);
  const [form] = useForm<UpdateRetailerProductInput>();
  const isMobile = useIsMobile();

  // Debounced argument to preview template query
  const [templateArg, setTemplateArg] = useState<string | undefined>(undefined);
  const { data, loading, error } = usePreviewTemplateQuery({
    variables: {
      productId: item.productId,
      retailerId: item.retailerId,
      template: templateArg,
    },
    skip: !editing,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!editing || !item) {
      return;
    }
    form.setFieldsValue({
      retailerProductId: item.retailerProductId || undefined,
      retailerId: item.retailerId,
      productId: item.productId,
      nameTemplate: item.nameActive.isDefaultTemplate ? undefined : item.nameActive.template,
    });
    setTemplateArg(item.nameActive.template || undefined);
  }, [item, editing, form]);

  const handleChangeTemplateDebounced = useDebouncedCallback((value) => {
    const trimmed = value.trim();
    if (trimmed) {
      setTemplateArg(trimmed);
    } else {
      setTemplateArg(undefined);
    }
  }, 400);

  const [updateRetailerProduct, { loading: saving }] = useUpdateRetailerProductMutation();

  const { addPendingRetailerChange } = useRetailerDeployTools();

  const templateValidationStatus = useMemo<ValidateStatus>(() => {
    if (!data) {
      return 'validating';
    }

    if (loading) {
      return 'validating';
    }

    if (data?.previewRetailerProductTemplates?.some((c) => c?.__typename === 'TemplateRenderError')) {
      return 'error';
    }

    if (data?.previewRetailerProductTemplates?.every((c) => c?.__typename === 'TemplateRenderSuccess')) {
      return 'success';
    }

    return '';
  }, [data, loading]);

  const handleSubmit = useCallback(async () => {
    try {
      const values = await form.validateFields();
      message.loading({
        key: 'save-retailer-product-template',
        content: 'Saving...',
      });

      if (templateValidationStatus === 'error') {
        message.error({
          key: 'save-retailer-product-template',
          content: 'Template is invalid',
        });
        return;
      } else if (templateValidationStatus === 'validating') {
        message.error({
          key: 'save-retailer-product-template',
          content: 'Template is still validating',
        });
        return;
      }

      await updateRetailerProduct({
        variables: {
          input: {
            retailerId: item.retailerId,
            productId: item.productId,
            retailerProductId: values.retailerProductId ? values.retailerProductId : undefined,
            nameTemplate: values.nameTemplate ? values.nameTemplate : undefined,
          },
        },
      });

      addPendingRetailerChange(item.retailerId);

      message.success({
        key: 'save-retailer-product-template',
        content: 'Saved!',
      });
      setEditing(false);
    } catch (err) {
      console.error(err);
      message.error({
        key: 'save-retailer-product-template',
        content: 'Error saving template',
      });
    }
  }, [
    updateRetailerProduct,
    form,
    item.retailerId,
    item.productId,
    templateValidationStatus,
    addPendingRetailerChange,
  ]);

  return (
    <OuterContainer key={'outer'}>
      <StyledReorderItem
        key={item.id}
        value={item}
        id={item.id}
        style={{ y }}
        dragListener={false}
        dragControls={controls}
        dragConstraints={myRef}
        initial={!editing}
      >
        <ProductContainer>
          <NoDragImage src={item.product.image.url || undefined} width={24} preview={false} />
          <NameAndDescriptionContainer>
            <div>
              <Tooltip title={item.product.description}>
                <Typography.Text>{item.nameActive.concatenated}</Typography.Text>
              </Tooltip>
            </div>
            <SecondLine $isMobile={isMobile}>
              <div>
                <Typography.Text copyable={{ text: item.product.productId.toString() }} type={'secondary'}>
                  {intl.formatMessage(retailerProductMessages.labelProductId)}: {item.product.productId}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text type={'secondary'}>Unit: {item.product.unit}</Typography.Text>
              </div>
              <div>
                {item.retailerProductId && (
                  <Typography.Text type={'secondary'}>
                    {intl.formatMessage(retailerProductMessages.labelRetailerProductId)}: {item.retailerProductId}
                  </Typography.Text>
                )}
              </div>
            </SecondLine>
          </NameAndDescriptionContainer>
        </ProductContainer>
        <Space>
          {!editing && (
            <Tooltip title={intl.formatMessage(commonMessages.edit)}>
              <Button icon={<EditOutlined />} type={'text'} onClick={() => setEditing(!editing)} />
            </Tooltip>
          )}
          {editing && <Button icon={<CloseOutlined />} type={'text'} onClick={() => setEditing(false)} />}
          <LoveHandle onPointerDown={(e) => controls.start(e)} />
        </Space>
      </StyledReorderItem>
      {editing && (
        <EditContainer>
          <Form layout={'vertical'} form={form} autoComplete={'off'}>
            <Row wrap={true}>
              <Col xs={24}>
                <Form.Item
                  name={'nameTemplate'}
                  label={intl.formatMessage(retailerProductMessages.labelNameTemplate)}
                  validateStatus={templateValidationStatus}
                  hasFeedback={true}
                >
                  <Input
                    allowClear={true}
                    placeholder={item.nameActive.template}
                    onChange={(e) => {
                      handleChangeTemplateDebounced(e.target.value);
                      form.setFieldsValue({
                        nameTemplate: e.target.value,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={16}>
                <Form.Item label={intl.formatMessage(retailerProductMessages.labelPreview)}>
                  {loading && !data && <Spin />}
                  {error && <Alert message={error.message} type={'error'} />}
                  <PreviewOuterContainer>
                    {data?.previewRetailerProductTemplates?.map((renderResult, index) => (
                      <div key={index}>
                        {renderResult?.__typename === 'TemplateRenderSuccess' && renderResult.language && (
                          <PreviewContainer>
                            <Tooltip
                              title={intl.formatMessage({
                                id: renderResult.language.nameEnglish,
                              })}
                            >
                              <PreviewLanguage $error={false}>{renderResult.language.code}</PreviewLanguage>
                            </Tooltip>
                            <Tooltip
                              title={
                                <div>
                                  {renderResult.lines?.map((line) => (
                                    <div key={line}>
                                      <span>{line}</span>
                                    </div>
                                  ))}
                                </div>
                              }
                            >
                              <div>{renderResult.concatenated}</div>
                            </Tooltip>
                          </PreviewContainer>
                        )}
                        {renderResult?.__typename === 'TemplateRenderError' && renderResult.language && (
                          <>
                            <PreviewContainer>
                              <PreviewLanguage $error={true} key={index + 'pr3'}>
                                {renderResult.language.code}
                              </PreviewLanguage>
                              <PreviewError $addMargin={false} key={'errorkey848'}>
                                <Tooltip
                                  key={index + 'tooltip3'}
                                  title={
                                    <div>
                                      {renderResult.lines?.map((line) => (
                                        <div key={line}>
                                          <span>{line}</span>
                                        </div>
                                      ))}
                                    </div>
                                  }
                                >
                                  <div>{renderResult.concatenated}</div>
                                </Tooltip>
                              </PreviewError>
                            </PreviewContainer>

                            {renderResult.errorMessages.map((c, index) => (
                              <PreviewContainer key={index}>
                                <PreviewError $addMargin={true} key={index + 'preview-error'}>
                                  {c}
                                </PreviewError>
                              </PreviewContainer>
                            ))}
                          </>
                        )}
                      </div>
                    ))}
                  </PreviewOuterContainer>
                </Form.Item>
              </Col>
              <Col xs={24} lg={8}>
                <Form.Item label={intl.formatMessage(retailerProductMessages.labelTemplateRules)}>
                  <ul style={{ paddingInlineStart: 16 }}>
                    {intl.formatMessage(retailerProductMessages.templateRules, {
                      br: <br />,
                      b: (chunks) => <b>{chunks}</b>,
                      li: (chunks) => <li>{chunks}</li>,
                    })}
                    <li>
                      The default product template is <br />
                      <Typography.Text strong={true} copyable={{ text: item.product.nameTemplate }}>
                        {item.product.nameTemplate}
                      </Typography.Text>
                    </li>
                  </ul>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name={'retailerProductId'}
                  label={intl.formatMessage(retailerProductMessages.labelRetailerProductId)}
                  tooltip={intl.formatMessage(retailerProductMessages.tooltipRetailerProductId)}
                >
                  <Input allowClear={true} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Space>
            <Button icon={<SaveOutlined />} type={'primary'} onClick={handleSubmit} disabled={saving}>
              {intl.formatMessage(commonMessages.save)}
            </Button>
            <Button icon={<CloseOutlined />} type={'default'} onClick={() => setEditing(false)} disabled={saving}>
              {intl.formatMessage(commonMessages.cancel)}
            </Button>
          </Space>
        </EditContainer>
      )}
    </OuterContainer>
  );
};

export default RetailerProductSortableListItem;
