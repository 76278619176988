import React, { useState } from 'react';
import Card from 'components/lib/Card/Card';
import { Button, Divider, Form, Popconfirm } from 'antd';
import styled from 'styled-components';
import { gql } from '@apollo/client/core';
import MachineTagsSelectedConnected from 'components/machine/MachineTagsSelect/MachineTagsSelectConnected';
import MoveMachineDrawer from 'components/machine/status/MachineActionsCard/MoveMachineDrawer';
import {
  useGetMachineServiceAgreementQuery,
  useSetMachineServiceAgreementMutation,
} from 'generated/types';
import commonMessages from 'components/i18n/commonMessages';
import ReDeployParameterButton from 'components/retailer/ReDeployParametersButton/ReDeployParameterButton.tsx';
import useMessageApi from 'components/global/useMessageApi';
import { ApolloError } from '@apollo/client';
import { getFriendlyApolloErrorMessages } from 'graphql/apollo/apolloErrorUtil.ts';
import useConnectIntl from 'i18n/useConnectIntl.ts';

gql`
  query GetMachineServiceAgreement($machineId: Int!) {
    machine(machineId: $machineId) {
      id
      machineId
      serviceAgreement
      active
      permissions {
        canDeactivateAndMove
        canEditDetails
        canEditVendanorCareAgreement
        canRedeployParameters
      }
    }
  }
`;

gql`
  mutation SetMachineServiceAgreement($input: SetMachineServiceAgreementInput!) {
    setMachineServiceAgreement(input: $input) {
      machine {
        id
        machineId
        serviceAgreement
      }
    }
  }
`;

const StyledFormItem = styled(Form.Item)`
  && {
    .ant-popover-disabled-compatible-wrapper {
      width: 100%;
    }
  }
`;

// TODO: When moving a machine, ensure that cache is correctly updated
// I think there is an issue with the cache not being updated correctly for the main menu / machine list
// We might have to re-fetch all machines for both the source and target retailer?

interface Props {
  machineId?: number;
}

const StyledForm = styled(Form)`
  &&& {
    .ant-form-item {
      margin-bottom: 8px;
    }
  }
`;

const MachineActionsCard: React.FC<Props> = ({ machineId }) => {
  const intl = useConnectIntl();
  const message = useMessageApi();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { data, loading } = useGetMachineServiceAgreementQuery({
    variables: machineId
      ? {
          machineId,
        }
      : undefined,
    skip: !machineId,
  });
  const [setServiceAgreement, { loading: savingServiceAgreement }] =
    useSetMachineServiceAgreementMutation();

  const handleSetServiceAgreement = async (value: boolean) => {
    if (!machineId) return;

    try {
      await setServiceAgreement({
        variables: {
          input: {
            machineId: machineId,
            serviceAgreement: value,
          },
        },
      });
      if (value) {
        message.success('Vendanor Care enabled');
      } else {
        message.success('Vendanor Care cancelled!');
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        message.error(getFriendlyApolloErrorMessages(err));
      } else {
        message.error('Failed to update Vendanor Care');
      }
    }
  };

  const enableServiceAgreementButton = (
    <Button
      style={{ width: '100%' }}
      type={'default'}
      loading={loading || savingServiceAgreement}
      onClick={() => handleSetServiceAgreement(true)}
      disabled={data?.machine.active === false}
    >
      Add Vendanor Care
    </Button>
  );

  const cancelServiceAgreementButtonWithTooltip = (
    <Popconfirm
      title={
        data?.machine.serviceAgreement === true
          ? 'Are you sure you want to cancel Vendanor Care?'
          : ''
      }
      onConfirm={() => handleSetServiceAgreement(false)}
      okText={intl.formatMessage(commonMessages.yes)}
      cancelText={intl.formatMessage(commonMessages.no)}
    >
      <Button
        style={{ width: '100%' }}
        type={'default'}
        loading={loading || savingServiceAgreement}
        danger={true}
        disabled={data?.machine.active === false}
      >
        Cancel Vendanor Care
      </Button>
    </Popconfirm>
  );

  const permissions = data?.machine.permissions;
  const hasPermissionToAtLeastOneAction =
    permissions?.canDeactivateAndMove ||
    permissions?.canRedeployParameters ||
    permissions?.canEditVendanorCareAgreement ||
    permissions?.canEditDetails;

  if (!hasPermissionToAtLeastOneAction) {
    return null;
  }

  return (
    <>
      <Card title={intl.formatMsg(commonMessages.actions)}>
        <StyledForm layout={'vertical'}>
          {permissions?.canEditDetails && (
            <>
              <Form.Item label={'Tags'}>
                <MachineTagsSelectedConnected machineId={machineId} />
              </Form.Item>
            </>
          )}

          {(permissions?.canRedeployParameters ||
            permissions?.canDeactivateAndMove ||
            permissions?.canEditVendanorCareAgreement) &&
            permissions.canEditDetails && <Divider />}

          {permissions?.canDeactivateAndMove && (
            <Form.Item>
              <Button
                type={'default'}
                onClick={() => setDrawerOpen(true)}
                style={{ width: '100%' }}
                loading={loading}
                disabled={data?.machine.active === false}
              >
                Deactivate and move
              </Button>
            </Form.Item>
          )}

          {permissions?.canEditVendanorCareAgreement && (
            <StyledFormItem>
              {data?.machine.serviceAgreement === false && enableServiceAgreementButton}
              {data?.machine.serviceAgreement === true && cancelServiceAgreementButtonWithTooltip}
              {loading && !data && enableServiceAgreementButton}
            </StyledFormItem>
          )}

          {permissions?.canRedeployParameters && (
            <StyledFormItem>
              <ReDeployParameterButton
                machineId={machineId}
                canRedeployParameters={permissions.canRedeployParameters}
              />
            </StyledFormItem>
          )}
        </StyledForm>
      </Card>
      <MoveMachineDrawer
        machineId={machineId}
        open={drawerOpen}
        onCancel={() => setDrawerOpen(false)}
        onComplete={() => setDrawerOpen(false)}
      />
    </>
  );
};

export default MachineActionsCard;
