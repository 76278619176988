import React, { useEffect, useState } from 'react';
import uploadMessages from 'components/lib/upload/uploadMessages';
import styled from 'styled-components';
import { Empty, Typography, Upload, Image, Spin, Button } from 'antd';
import { useIntl } from 'react-intl';
import { AssetListItemFragment } from 'generated/types';
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import { VnFile } from 'components/lib/upload/VnFile';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
const { Text } = Typography;
import { mapAsset } from 'components/lib/upload/mapAsset';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import commonMessages from 'components/i18n/commonMessages';

interface Props {
  accept?: string;
  asset?: AssetListItemFragment | null;
  handleUpload: (options: RcCustomRequestOptions) => void;
  loading?: boolean;
  empty?: React.ReactNode;
  deleteText?: string;
  uploadText?: React.ReactNode;
  onDelete?: () => void;
}

const UploadContainer = styled.div`
  border: 1px solid ${(props) => props.theme.ant.colorBorder};
  border-radius: ${(props) => props.theme.ant.borderRadius}px;
  transition: all 0.3s;

  &:hover {
    border-color: ${(props) => props.theme.ant.colorPrimary};
  }

  width: 200px;
  height: 200px;
  overflow: hidden;

  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  flex: 1 1 auto;
  background-color: ${(props) => props.theme.ant.colorBgContainer};
  overflow: hidden;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  &&& {
    .ant-image {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-image-img {
      max-width: 80%;
      max-height: 80%;
      aspect-ratio: auto;
    }
    .ant-empty-image {
      height: 75px;
    }
    .ant-empty-description {
      color: ${(props) => props.theme.ant.colorTextSecondary};
    }
  }
`;

const InnerImageContainer = styled.div`
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Mask = styled.div<{ $visible: boolean }>`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${(props) => (props.$visible ? 1 : 0)};
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  &&& {
    .ant-btn-text {
      color: #fff;
    }
  }
`;

const StyledUploadDragger = styled(Upload.Dragger)`
  &&& {
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: ${(props) => props.theme.ant.colorBorder};

    .ant-upload {
      padding: 8px 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-right: none;
      border-bottom: none;
      border-left: none;
    }
  }
`;

/***
 * ImageUpload is a component that allows you to upload an image.
 * @param asset
 * @param handleUpload
 * @param loading
 * @param empty
 * @param uploadText
 * @param onDelete
 * @param deleteText
 * @param accept
 * @constructor
 */
const UploadImage: React.FC<Props> = ({
  asset,
  handleUpload,
  loading,
  empty,
  uploadText,
  onDelete,
  deleteText,
  accept,
}) => {
  const intl = useIntl();

  const [files, setFiles] = useState<Array<UploadFile<VnFile>> | undefined>(undefined);

  const [isMaskVisible, setIsMaskVisible] = useState(false);

  useEffect(() => {
    if (asset) {
      setFiles([mapAsset(asset)]);
    } else {
      setFiles([]);
    }
  }, [asset]);

  const handleChange = (info: UploadChangeParam) => {
    setFiles(info.fileList);
  };

  const imgUrl = asset?.url;

  const handleClickDelete = () => {
    onDelete?.();
    setIsMaskVisible(false);
  };

  return (
    <UploadContainer>
      <ImageContainer
        onMouseEnter={() => setIsMaskVisible(true)}
        onMouseLeave={() => setIsMaskVisible(false)}
      >
        {loading && <Spin />}
        {!loading && !imgUrl && (empty || <Empty />)}
        {!loading && imgUrl && (
          <InnerImageContainer>
            <Image src={imgUrl} />
            <Mask $visible={isMaskVisible}>
              <Button type={'text'} icon={<DeleteOutlined />} onClick={handleClickDelete}>
                {deleteText || intl.formatMessage(commonMessages.delete)}
              </Button>
            </Mask>
          </InnerImageContainer>
        )}
      </ImageContainer>

      <StyledUploadDragger
        accept={accept || 'image/*'}
        listType={'picture-card'}
        fileList={files}
        locale={{
          downloadFile: intl.formatMessage(uploadMessages.downloadFile),
          uploading: intl.formatMessage(uploadMessages.uploading),
          removeFile: intl.formatMessage(uploadMessages.removeFile),
          uploadError: intl.formatMessage(uploadMessages.uploadError),
          previewFile: intl.formatMessage(uploadMessages.previewFile),
        }}
        maxCount={1}
        multiple={false}
        openFileDialogOnClick={true}
        showUploadList={false} // hide default inline file list
        onChange={handleChange}
        customRequest={handleUpload}
      >
        <Text type={'secondary'}>
          {uploadText ||
            intl.formatMessage(uploadMessages.uploadImageHint, {
              icon: <UploadOutlined />,
            })}
        </Text>
      </StyledUploadDragger>
    </UploadContainer>
  );
};

export default UploadImage;
