import {
  format as formatInternal,
  formatDistanceToNow as formatDistanceToNowInternal,
  formatRelative as formatRelativeInternal,
  formatISO as formatISOInternal,
  FormatDistanceToNowOptions,
} from 'date-fns';
import { FormatOptions } from 'date-fns/format';
import { useCallback } from 'react';
import useDateFnsLocale from 'i18n/useDateFnsLocale';

/***
 * Custom hook, which is a thin wrapper around date-fn formatting functions, injecting our
 * current locale from redux.
 * See https://date-fns.org/v2.24.0/docs/I18n
 */
function useDateFormatTools() {
  const locale = useDateFnsLocale();

  // formats a date using our custom format, ISO8601 date and localized time
  const formatDate = (
    date: Date,
    /**
     * @param representation - Determines the format of the returned date string.
     *                         'complete' returns both date and time,
     *                         'date' returns only the date,
     *                         'time' returns only the time.
     * @param formatStr - A custom format string to use for date formatting. This will override the representation option.
     * @param formatDate - A custom format string to use for formatting the date part when representation is 'complete' or 'date'.
     * @param formatTime - A custom format string to use for formatting the time part when representation is 'complete' or 'time'.
     */
    options?: {
      representation?: 'complete' | 'date' | 'time';
      formatStr?: string;
      formatDate?: string;
      formatTime?: string;
    }
  ) => {
    if (options?.formatStr) {
      return formatInternal(date, options.formatStr, { locale });
    }

    const rep = options?.representation || 'date';
    const datePart = formatInternal(date, options?.formatDate || 'yyyy-MM-dd', { locale });
    const timePart = formatInternal(date, options?.formatTime || 'HH:mm:ss', { locale });
    return rep === 'date' ? datePart : rep === 'time' ? timePart : `${datePart} ${timePart}`;
  };

  // formats a date using the ISO 8601 standard
  const formatISO = (
    date: Date | number,
    options?: {
      format?: 'extended' | 'basic';
      representation?: 'complete' | 'date' | 'time';
    }
  ) => {
    return formatISOInternal(date, options);
  };

  // Formats a date using the date-fns format function, but with our current locale
  const formatLocalized = useCallback(
    (date: Date, format = 'PP', options?: FormatOptions) => {
      return formatInternal(date, format, {
        locale,
        weekStartsOn: 1,
        ...options,
      });
    },
    [locale]
  );

  const formatDistanceToNow = useCallback(
    (date: Date | number, options?: FormatDistanceToNowOptions) => {
      return formatDistanceToNowInternal(date, {
        locale: locale,
        ...options,
      });
    },
    [locale]
  );

  const formatRelative = useCallback(
    (date: Date | number, baseDate: Date | number) => {
      return formatRelativeInternal(date, baseDate, {
        locale,
        weekStartsOn: 1,
      });
    },
    [locale]
  );

  return {
    formatISO,
    formatLocalized,
    formatDistanceToNow,
    formatRelative,
    formatDate,
  };
}

export default useDateFormatTools;
