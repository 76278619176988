import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { UploadFile } from 'antd/es/upload/interface';
import { VnFile } from 'components/lib/upload/VnFile';
import { Button, Image, Progress, Tooltip } from 'antd';
import {
  DeleteOutlined,
  FileGifOutlined,
  FileImageOutlined,
  FileJpgOutlined,
  FileOutlined,
  FilePdfOutlined,
  StopOutlined,
} from '@ant-design/icons';
import SpinningCircle from 'components/lib/upload/UploadList/SpinningCircle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  //width: fit-content;
`;

const FileItemGrid = styled.div<{ $dashed: boolean; $error: boolean }>`
  border: 1px solid
    ${(props) =>
      props.$error ? props.theme.ant.colorError : props.theme.ant.colorBorder};
  border-radius: ${(props) => props.theme.ant.borderRadius}px;
  border-style: ${(props) => (props.$dashed ? 'dashed' : 'solid')};
  padding: 4px 8px;

  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'thumb content action';
  gap: 8px;
`;

const FileThumb = styled.div`
  grid-area: thumb;
  width: 48px;
  height: 48px;
  border-radius: ${(props) => props.theme.ant.borderRadius}px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 24px; // file icon size

  img {
    max-width: 100%;
    min-width: 16px;
    object-fit: contain;
  }
`;

const FileContent = styled.div`
  min-width: 0;
  grid-area: content;
  align-self: center;
`;

const FileAction = styled.div`
  grid-area: action;
  align-self: center;
`;

const FileName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface FileItemProps {
  file: UploadFile<VnFile>;
  showRemoveButton?: boolean;
  onRemove?: (file: UploadFile<VnFile>) => void;
  onCancelUpload?: (file: UploadFile<VnFile>) => void;
}

const isImageMimeType = (mime?: string): boolean => {
  if (!mime) {
    return false;
  }
  return (
    mime === 'image/png' ||
    mime === 'image/jpeg' ||
    mime === 'image/jpg' ||
    mime === 'image/gif'
  );
};

const getFileImageFromMimeType = (mime?: string) => {
  switch (mime) {
    case 'image/png':
      return <FileImageOutlined />;
    case 'image/jpeg':
    case 'image/jpg':
      return <FileJpgOutlined />;
    case 'image/gif':
      return <FileGifOutlined />;
    case 'application/pdf':
      return <FilePdfOutlined />;
    default:
      return <FileOutlined />;
  }
};

const FileItem: React.FC<FileItemProps> = ({
  file,
  showRemoveButton,
  onRemove,
  onCancelUpload,
}) => {
  const isUploading = file.status === 'uploading';
  const isError = file.status === 'error';
  const vnFile = file.response;
  const fileUrl = vnFile?.url;
  const isEntireBoxLinkable =
    fileUrl && file.status === 'done' && !showRemoveButton;
  const isLinkable = fileUrl && file.status === 'done';
  const isImage = isImageMimeType(file.type) && file.thumbUrl;
  const fileIcon = getFileImageFromMimeType(file.type);

  // Images are rendered as <img> tags, other files as icons, uploading files as spinning circle
  const thumb = (
    <FileThumb>
      {isUploading && <SpinningCircle />}
      {!isUploading && isImage && <Image src={file.thumbUrl} preview={false} />}
      {!isUploading && !isImage && fileIcon}
    </FileThumb>
  );

  // When editing a file, we want the thumb to be clickable, but not the entire box
  const thumbnailLink =
    isLinkable && !isEntireBoxLinkable ? (
      <a href={fileUrl} target={'_blank'} rel={'noreferrer'}>
        {thumb}
      </a>
    ) : (
      thumb
    );

  const fileItem = (
    <FileItemGrid $dashed={isUploading || isError} $error={isError}>
      {thumbnailLink}
      <FileContent>
        <FileName>{file.name}</FileName>
        {isUploading && (
          <Progress
            percent={file.percent}
            type={'line'}
            size={'small'}
            showInfo={false}
            status={'normal'}
            style={{ marginBottom: 0 }}
          />
        )}
      </FileContent>
      <FileAction>
        {!isUploading && showRemoveButton && (
          <Tooltip title={'Remove file'}>
            <Button
              icon={<DeleteOutlined onClick={() => onRemove?.(file)} />}
              type={'text'}
            />
          </Tooltip>
        )}
        {isUploading && (
          <Tooltip title={'Cancel upload'}>
            <Button
              icon={<StopOutlined />}
              onClick={() => {
                onCancelUpload?.(file);
              }}
              type={'text'}
            />
          </Tooltip>
        )}
      </FileAction>
    </FileItemGrid>
  );

  return isEntireBoxLinkable ? (
    <a href={fileUrl} target={'_blank'} rel={'noreferrer'}>
      {fileItem}
    </a>
  ) : (
    fileItem
  );
};

interface UploadListProps {
  fileList?: Array<UploadFile<VnFile>>;
  onRemove?: (file: UploadFile<VnFile>) => void;
  onCancelUpload?: (file: UploadFile<VnFile>) => void;
  editable?: boolean;
  style?: CSSProperties;
}

/***
 * FileList is a component that shows a list of uploaded files
 */
const UploadList: React.FC<UploadListProps> = ({
  fileList,
  editable,
  onRemove,
  onCancelUpload,
  style,
}) => {
  if (!fileList || fileList.length === 0) {
    return null;
  }

  const filteredFileList = editable
    ? fileList
    : fileList.filter((f) => f.status === 'done');

  return (
    <Container style={style}>
      {filteredFileList.map((c) => (
        <FileItem
          key={c.uid}
          file={c}
          showRemoveButton={editable}
          onRemove={onRemove}
          onCancelUpload={onCancelUpload}
        />
      ))}
    </Container>
  );
};

export default UploadList;
