import { useCallback, useMemo } from 'react';
import { OnAutoSaveCallback } from 'components/lib/ConnectEditor/AutoSave.tsx';
import { KEY_DRAFT_TICKET_COMMENT, KEY_DRAFT_TICKET_COMMENT_IDS } from 'auth/constants.ts';
import {
  addLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} from 'common/localStorage.ts';
import { RemirrorJSON } from 'remirror';

interface TicketCommentDraftList {
  list: number[];
}

interface TicketCommentDraft {
  ticketId: number;
  json: RemirrorJSON;
  saved: Date;
}

const getTicketIds = () => {
  const data = getLocalStorageItem<TicketCommentDraftList>(KEY_DRAFT_TICKET_COMMENT_IDS);
  return data?.list || [];
};

export const removeTicketCommentDrafts = () => {
  getTicketIds().forEach((ticketId) => {
    removeLocalStorageItem(KEY_DRAFT_TICKET_COMMENT(ticketId));
  });
};

const addDraft = (draft: TicketCommentDraft) => {
  const ids = getTicketIds();
  if (!ids.includes(draft.ticketId)) {
    ids.push(draft.ticketId);
    addLocalStorageItem<TicketCommentDraftList>(KEY_DRAFT_TICKET_COMMENT_IDS, { list: ids });
  }
  addLocalStorageItem<TicketCommentDraft>(KEY_DRAFT_TICKET_COMMENT(draft.ticketId), draft);
};

const removeDraft = (ticketId: number) => {
  const ids = getTicketIds();
  const index = ids.indexOf(ticketId);
  if (index !== -1) {
    ids.splice(index, 1);
    addLocalStorageItem<TicketCommentDraftList>(KEY_DRAFT_TICKET_COMMENT_IDS, { list: ids });
  }
  removeLocalStorageItem(KEY_DRAFT_TICKET_COMMENT(ticketId));
};

function useAutoSaveTicketComment(ticketId: number) {
  const handleAutoSaveTicketComment = useCallback<OnAutoSaveCallback>(
    ({ isEmpty, document }) => {
      if (isEmpty) {
        removeDraft(ticketId);
      } else if (document !== undefined) {
        addDraft({
          json: document.remirrorJson,
          saved: new Date(),
          ticketId,
        });
      }
    },
    [ticketId]
  );

  const draft = useMemo(
    () => getLocalStorageItem<TicketCommentDraft>(KEY_DRAFT_TICKET_COMMENT(ticketId)),
    [ticketId]
  );

  return {
    handleAutoSaveTicketComment,
    draft,
  };
}

export default useAutoSaveTicketComment;
