import React from 'react';
import DatePicker from 'components/lib/DatePicker/DatePicker.tsx';
import useTicketFilter from 'components/ticket/TicketFilter/useTicketFilter.ts';
import { addMonths, parseISO } from 'date-fns';
import LinkButton from 'components/lib/Link/LinkButton.tsx';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import styled from 'styled-components';

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  margin-left: 16px;
  margin-right: 16px;
`;

const TicketDueBeforeFilterSelect: React.FC = () => {
  const { ticketFilter, setTicketFilter } = useTicketFilter();
  const intl = useConnectIntl();
  const { dueTo } = ticketFilter;

  const handleChange = (date: Date | null) => {
    setTicketFilter({
      dueTo: date?.toISOString(),
    });
  };

  const handleShortcut = (months: number) => {
    setTicketFilter({
      dueTo: addMonths(new Date(), months).toISOString(),
    });
  };

  return (
    <DatePicker
      allowClear={true}
      showToday={false}
      value={dueTo ? parseISO(dueTo) : undefined}
      placeholder={intl.formatMsg({
        id: 'ticket_earliest_due_date_filter_select.placeholder',
        defaultMessage: 'Earliest due date',
      })}
      onChange={handleChange}
      style={{ width: '100%' }}
      renderExtraFooter={() => {
        return (
          <Footer>
            <LinkButton
              onClick={() => {
                handleShortcut(1);
              }}
            >
              {intl.formatMsg({
                id: 'ticket_earliest_due_date_filter_select.one_month',
                defaultMessage: 'One month',
              })}
            </LinkButton>
            <LinkButton
              onClick={() => {
                handleShortcut(2);
              }}
            >
              {intl.formatMsg({
                id: 'ticket_earliest_due_date_filter_select.two_months',
                defaultMessage: 'Two months',
              })}
            </LinkButton>
            <LinkButton
              onClick={() => {
                handleShortcut(6);
              }}
            >
              {intl.formatMsg({
                id: 'ticket_earliest_due_date_filter_select.six_months',
                defaultMessage: 'Six months',
              })}
            </LinkButton>
          </Footer>
        );
      }}
    />
  );
};

export default TicketDueBeforeFilterSelect;
