import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { MuteMachineIcon } from 'components/icons/Icons';

const MuteBadgeContainer = styled.div`
  background-color: ${(props) => props.theme.ant.colorError};
  color: white;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  && {
    .anticon {
      margin-right: 0;
      font-size: 0.7em;
    }
  }
`;

const MuteMachineCircle: React.FC<{
  style?: CSSProperties;
  className?: string;
}> = ({ style, className }) => {
  return (
    <MuteBadgeContainer style={style} className={className}>
      <MuteMachineIcon />
    </MuteBadgeContainer>
  );
};

export default MuteMachineCircle;
