import React, { useMemo } from 'react';
import { gql } from '@apollo/client';
import { Spin, Timeline, Typography } from 'antd';
import { TimelineItemProps } from 'antd/es/timeline/TimelineItem';
import {
  MachinePropertyWithValueFragment,
  useGetMachinePropertyHistoryQuery,
} from 'generated/types.tsx';
import useDateFormatTools from 'i18n/useDateFormatTools.ts';
import { parseISO } from 'date-fns';
import ChangedByUser from 'components/user/ChangedByUser/ChangedByUser.tsx';
import commonMessages from 'components/i18n/commonMessages.ts';
import machinePropertyMessages from 'components/machine/properties/MachinePropertyList/machinePropertyMessages.ts';
import { FileTextOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import Popover from 'components/lib/Popover/Popover.tsx';
import JsonViewer from 'components/lib/JsonViewer/JsonViewer.tsx';
import useConnectIntl from 'i18n/useConnectIntl.ts';

gql`
  query GetMachinePropertyHistory($serialNo: String!, $machineId: Int!, $key: String!) {
    machineProperty(serialNo: $serialNo, machineId: $machineId, key: $key) {
      id
      options {
        key
        value
      }
      valueHistory {
        ... on TextPropertyValue {
          ...TextPropertyValueFull
        }
        ... on NumberPropertyValue {
          ...NumberPropertyValueFull
        }
        ... on BooleanPropertyValue {
          ...BooleanPropertyValueFull
        }
        ... on JsonPropertyValue {
          ...JsonPropertyValueFull
        }
        ... on SelectorPropertyValue {
          ...SelectorFormFieldValueFull
        }
        ... on ColorPropertyValue {
          ...ColorPropertyValueFull
        }
        ... on TextLinesPropertyValue {
          ...TextLinesPropertyValueFull
        }
      }
    }
  }
`;

const HistoryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const Right = styled.div`
  flex: 0 0 24px;
`;

interface Props {
  property: MachinePropertyWithValueFragment;
}

const MachinePropertyHistory: React.FC<Props> = ({ property }) => {
  const { formatDate } = useDateFormatTools();
  const intl = useConnectIntl();

  const { data, loading } = useGetMachinePropertyHistoryQuery({
    variables: {
      machineId: property.machineId,
      serialNo: property.machineSerialNo,
      key: property.key,
    },
  });

  const optionsDict = useMemo(() => {
    const temp: { [key: string]: string } = {};

    data?.machineProperty.options?.forEach((c) => {
      temp[c.key] = c.value;
    });

    return temp;
  }, [data?.machineProperty.options]);

  const items = useMemo<TimelineItemProps[] | undefined>(() => {
    if (!data) {
      return undefined;
    }

    const result: TimelineItemProps[] = [];
    for (const item of data.machineProperty.valueHistory) {
      const itemColor = item.active ? 'blue' : 'gray';
      let itemValue: JSX.Element | string | null = null;

      if (item.__typename === 'TextPropertyValue') {
        itemValue = item.textValue ?? intl.formatMessage(machinePropertyMessages.notSetValue);
      } else if (item.__typename === 'BooleanPropertyValue') {
        itemValue =
          item.booleanValue === null || item.booleanValue === undefined
            ? intl.formatMessage(machinePropertyMessages.notSetValue)
            : item.booleanValue
            ? intl.formatMessage(commonMessages.true)
            : intl.formatMessage(commonMessages.false);
      } else if (item.__typename === 'NumberPropertyValue') {
        itemValue =
          item.numberValue?.toString() ?? intl.formatMessage(machinePropertyMessages.notSetValue);
      } else if (item.__typename === 'JsonPropertyValue') {
        itemValue = (
          <Popover
            content={
              <JsonViewer
                json={item.jsonValue || intl.formatMessage(machinePropertyMessages.notSetValue)}
              />
            }
          >
            <FileTextOutlined />
          </Popover>
        );
      } else if (item.__typename === 'SelectorPropertyValue') {
        itemValue = (
          <div>
            {item.selectorValue
              ? optionsDict[item.selectorValue]
              : intl.formatMsg(machinePropertyMessages.notSetValue)}
          </div>
        );
      }

      result.push({
        key: item.id,
        color: itemColor,
        children: (
          <HistoryContainer>
            <Left>
              <div>{itemValue}</div>
              <div>
                <Typography.Text type={'secondary'}>
                  {formatDate(parseISO(item.changed), {
                    representation: 'complete',
                  })}
                </Typography.Text>
              </div>
            </Left>
            <Right>
              {item.changedByUser && (
                <ChangedByUser
                  user={item.changedByUser}
                  changed={parseISO(item.changed)}
                  showText={false}
                />
              )}
            </Right>
          </HistoryContainer>
        ),
      });
    }

    return result;
  }, [data, formatDate, intl, optionsDict]);

  return (
    <div>
      {loading && <Spin />}
      {data && !loading && <Timeline items={items} mode={'left'} />}
    </div>
  );
};

export default MachinePropertyHistory;
