import React, { useCallback } from 'react';
import DatePicker from 'components/lib/DatePicker/DatePicker.tsx';
import { gql } from '@apollo/client/core';
import {
  TicketCommentFullFragmentDoc,
  useGetTicketDueDateQuery,
  useSetTicketDueDateMutation,
} from 'generated/types.tsx';
import styled from 'styled-components';
import { addMonths, addWeeks, parseISO } from 'date-fns';
import useDateFormatTools from 'i18n/useDateFormatTools.ts';
import useMessageApi from 'components/global/useMessageApi.ts';
import LinkButton from 'components/lib/Link/LinkButton.tsx';
import useConnectIntl from 'i18n/useConnectIntl.ts';

gql`
  query GetTicketDueDate($ticketId: Int!) {
    ticket(ticketId: $ticketId) {
      id
      ticketId
      due
    }
  }
`;

gql`
  mutation SetTicketDueDate($input: SetTicketDueDateInput!) {
    setTicketDueDate(input: $input) {
      ticket {
        id
        ticketId
        due
        updated
        comments {
          ...TicketCommentFull
        }
      }
    }
  }
  ${TicketCommentFullFragmentDoc}
`;

interface Props {
  ticketId?: number;
}

// TODO: Expose date picker props. also fix date picker locale and types related to date-fns?
// type OtherDatePickerProps = Omit<DateFnsTypedPickerProps, 'value' | 'onSelect'>;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  margin-left: 16px;
  margin-right: 16px;
`;

const TicketDueDateSelectConnected: React.FC<Props> = ({ ticketId }) => {
  const { formatISO } = useDateFormatTools();
  const intl = useConnectIntl();
  const messageApi = useMessageApi();
  const { data, loading } = useGetTicketDueDateQuery({
    variables: ticketId
      ? {
          ticketId,
        }
      : undefined,
    skip: !ticketId,
  });
  const [setDueDate, { loading: saving }] = useSetTicketDueDateMutation();

  const due = data?.ticket.due ? parseISO(data.ticket.due) : undefined;

  const handleChange = useCallback(
    async (selectedDate: Date | null) => {
      if (ticketId === undefined) return;

      try {
        if (selectedDate) {
          messageApi.loading({
            key: 'ticket.dueDate',
            content: 'Setting due date...',
          });
        } else {
          messageApi.loading({
            key: 'ticket.dueDate',
            content: 'Clearing due date...',
          });
        }
        await setDueDate({
          variables: {
            input: {
              ticketId,
              dueDate: selectedDate ? formatISO(selectedDate) : undefined,
            },
          },
        });
        messageApi.success({
          key: 'ticket.dueDate',
          content: 'Due date changed',
        });
      } catch (err) {
        messageApi.error({
          key: 'ticket.dueDate',
          content: 'Failed to set due date',
        });
      }
    },
    [formatISO, messageApi, setDueDate, ticketId]
  );

  return (
    <DatePicker
      allowClear={true}
      showToday={false}
      disabled={loading || saving}
      value={due}
      onChange={handleChange}
      style={{ width: '100%' }}
      renderExtraFooter={() => {
        return (
          <Footer>
            <LinkButton
              onClick={async () => {
                await handleChange(addWeeks(new Date(), 1));
              }}
            >
              {intl.formatMsg({
                id: 'ticket_due_date_select_connected.one_week',
                defaultMessage: 'One week',
              })}
            </LinkButton>
            <LinkButton
              onClick={async () => {
                await handleChange(addWeeks(new Date(), 2));
              }}
            >
              {intl.formatMsg({
                id: 'ticket_due_date_select_connected.two_weeks',
                defaultMessage: 'Two weeks',
              })}
            </LinkButton>
            <LinkButton
              onClick={async () => {
                await handleChange(addMonths(new Date(), 1));
              }}
            >
              {intl.formatMsg({
                id: 'ticket_due_date_select_connected.one_month',
                defaultMessage: 'One month',
              })}
            </LinkButton>
          </Footer>
        );
      }}
    />
  );
};

export default TicketDueDateSelectConnected;
