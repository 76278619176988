import React from 'react';
import { PredictionItemFragment } from 'generated/types.tsx';
import styled from 'styled-components';
import Card from 'components/lib/Card/Card.tsx';
import PredictionResult from 'components/prediction/PredictionResult.tsx';
import { Flex, Typography } from 'antd';
import useDateFormatTools from 'i18n/useDateFormatTools.ts';
import { parseISO } from 'date-fns';
import usePredictionResultMessage from 'components/prediction/usePredictionResultMessage.ts';
import PredictionSampleImage from 'components/prediction/PredictionSampleImage.tsx';
import getObjectModelColor from 'components/prediction/getObjectModelColor.ts';

interface Props {
  prediction: PredictionItemFragment;
}

const HistoryItemContainer = styled.div`
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: 48px 48px auto;
  grid-gap: 8px;
  align-items: center;
`;

const SampleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: auto;

  border-radius: 4px;
  overflow: hidden;
`;

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: auto;
  background: ${(props) => props.theme.ant.colorSuccessBg};

  border-radius: 4px;
  overflow: hidden;
`;

const InfoBox = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
`;

const PredictionHistoryItem: React.FC<Props> = ({ prediction }) => {
  const { formatDate } = useDateFormatTools();
  const text = usePredictionResultMessage(prediction, 'full');

  return (
    <Card hoverable={true} size={'small'}>
      <HistoryItemContainer>
        <SampleContainer>
          <PredictionSampleImage
            detectedObjects={prediction.objectClassList.map((c) => ({
              naturalX: c.x,
              naturalY: c.y,
              naturalWidth: c.width,
              naturalHeight: c.height,
              color: getObjectModelColor(c.label),
            }))}
            image={{
              src: prediction.sample.dataUrl,
              alt: `Sample image ${prediction.sample.id}`,
              width: prediction.sample.width,
              height: prediction.sample.height,
            }}
          />
        </SampleContainer>
        <ResultContainer>
          <PredictionResult prediction={prediction} size={'small'} />
        </ResultContainer>
        <Flex justify={'center'} align={'center'}>
          <InfoBox>
            <Typography.Text ellipsis={true}>{text}</Typography.Text>
            <Typography.Text type={'secondary'}>
              {formatDate(parseISO(prediction.timestamp), { representation: 'date' })}
            </Typography.Text>
            <Typography.Text type={'secondary'}>
              {formatDate(parseISO(prediction.timestamp), { representation: 'time' })}
            </Typography.Text>
          </InfoBox>
        </Flex>
      </HistoryItemContainer>
    </Card>
  );
};

export default PredictionHistoryItem;
