import { gql } from '@apollo/client/core';
import { UserAndSettingsFragmentDoc } from 'generated/types';

export const GET_USER_AND_SETTINGS = gql`
  query GetUserAndSettings {
    me {
      ...UserAndSettings
    }
  }
  ${UserAndSettingsFragmentDoc}
`;
