import React, { SVGProps } from 'react';

const PvmIllustration: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M44.674 31.3133V38.2058C44.674 38.4703 44.4664 38.6884 44.2022 38.7014C43.9189 38.7153 43.6815 38.4894 43.6815 38.2058V31.3133C43.6815 31.0503 43.8867 30.833 44.1493 30.8179C44.4341 30.8015 44.674 31.028 44.674 31.3133Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M6.46588 74.4541L2.16658 62.0963C2.05631 61.7794 2 61.4462 2 61.1106V13.1959C2 12.7335 2.10691 12.2773 2.31238 11.863L5.96967 4.48863M6.46588 74.4541C6.46588 74.4541 7.4583 77 15.8938 77C24.3294 77 77.92 68.9958 77.92 68.0034C77.92 67.4057 77.92 65.5919 77.92 64.5299M6.46588 74.4541V70.9806M74.4465 61.6175C74.4465 62.0488 77.92 63.0413 77.92 63.5375C77.92 63.7348 77.92 63.8285 77.92 64.5299M74.4465 61.6175C74.4465 61.267 74.4465 26.0876 74.4465 12.9242M74.4465 61.6175C74.4465 61.6175 69.4844 63.0413 67.4996 63.5375M5.96967 4.48863C5.96967 4.48863 7.95451 3.5611 19.8635 3.06489C31.7725 2.56868 60.0564 5.04978 67.0034 6.04214C70.8834 6.5964 72.5964 6.99591 73.439 7.32708C74.1796 7.61811 74.4465 8.47286 74.4465 9.26851C74.4465 10.1154 74.4465 11.3655 74.4465 12.9242M5.96967 4.48863V10.4431M5.96967 10.4431C5.96967 10.4431 12.4204 8.95451 19.8635 8.95451M5.96967 10.4431L6.45368 69.492M19.8635 8.95451L19.774 69.492M19.8635 8.95451C23.015 8.95451 31.2369 9.31033 39.972 9.75833M67.4996 11.5356C71.4692 12.0318 74.4465 12.9242 74.4465 12.9242M67.4996 11.5356C65.8318 11.3271 58.0623 10.7558 49.636 10.2794M67.4996 11.5356V63.5375M6.46588 70.9806C6.46588 70.9806 8.45072 73.9579 15.8938 73.4617C21.1653 73.1102 57.5754 68.4996 67.4996 67.0109C77.4237 65.5223 77.92 64.5299 77.92 64.5299M6.46588 70.9806L6.45368 69.492M6.45368 69.492C6.45368 69.492 8.45072 70.9806 15.8938 69.9882C17.4127 69.7857 18.6836 69.6245 19.774 69.492M49.636 64.5299C50.6285 64.5299 59.5602 64.5299 60.5526 64.5299C61.5451 64.5299 65.5147 64.0337 67.4996 63.5375M49.636 64.5299C48.8145 64.5299 43.2319 65.21 40.2081 65.7257M49.636 64.5299V10.2794M19.774 69.492C24.0272 68.9751 25.5344 68.8945 28.2991 68.4996C31.7725 68.0034 36.7346 66.5147 38.7195 66.0185C39.0611 65.9331 39.5793 65.833 40.2081 65.7257M40.2081 65.7257L39.972 9.75833M39.972 9.75833C43.1999 9.92388 46.4979 10.102 49.636 10.2794"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M40.2081 46.6664L20.3597 48.6512M40.2081 28.8029H19.8635M49.6361 28.8029H67.4996M49.6361 47.1626H67.4996"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export default PvmIllustration;
