import React, { useCallback, useMemo, useState } from 'react';
import { SelectProps } from 'antd/es/select';
import { gql } from '@apollo/client/core';
import {
  useAddMachineTagMutation,
  useGetMachineTagsQuery,
  useRemoveMachineTagMutation,
} from 'generated/types';
import MachineTagsSelect from 'components/machine/MachineTagsSelect/MachineTagsSelect';

gql`
  query GetMachineTags($machineId: Int!) {
    machine(machineId: $machineId) {
      id
      machineId
      tags
      retailer {
        id
        retailerId
        machineTags
      }
    }
  }
`;

gql`
  mutation addMachineTag($input: AddMachineTagInput!) {
    addMachineTag(input: $input) {
      machine {
        id
        machineId
        tags
        retailer {
          id
          retailerId
          machineTags
        }
      }
    }
  }
`;

gql`
  mutation removeMachineTag($input: RemoveMachineTagInput!) {
    removeMachineTag(input: $input) {
      machine {
        id
        machineId
        tags
        retailer {
          id
          retailerId
          machineTags
        }
      }
    }
  }
`;

interface Props {
  machineId?: number;
}

type OtherSelectProps = Omit<
  SelectProps<string[]>,
  | 'options'
  | 'mode'
  | 'value'
  | 'onInputKeyDown'
  | 'onSelect'
  | 'onDeselect'
  | 'searchValue'
  | 'onSearch'
  | 'loading'
>;

const MachineTagsSelectedConnected: React.FC<Props & OtherSelectProps> = ({
  machineId,
  ...rest
}) => {
  const { data, loading } = useGetMachineTagsQuery({
    variables: machineId
      ? {
          machineId,
        }
      : undefined,
    skip: !machineId,
    notifyOnNetworkStatusChange: false,
  });

  const [searchText, setSearchText] = useState('');

  const [addMachineTag] = useAddMachineTagMutation({
    notifyOnNetworkStatusChange: true,
  });
  const [removeMachineTag] = useRemoveMachineTagMutation({
    notifyOnNetworkStatusChange: true,
  });

  const allMachineTags = useMemo(
    () => data?.machine.retailer.machineTags || [],
    [data?.machine.retailer.machineTags]
  );
  const selectedTags = useMemo(() => data?.machine?.tags || [], [data?.machine?.tags]);

  const handleInputKeyDown = useCallback<
    React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
  >((e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      // It's enough to clear this, select event is already dispatched by antd component
      setSearchText('');
    }
  }, []);

  const handleSelect = useCallback(
    async (tag: string) => {
      if (!machineId) {
        return;
      }

      await addMachineTag({
        variables: {
          input: {
            machineId,
            tag,
          },
        },
        optimisticResponse: data
          ? {
              __typename: 'Mutation',
              addMachineTag: {
                __typename: 'AddMachineTagPayload',
                machine: {
                  __typename: 'Machine',
                  id: data.machine.id,
                  machineId: data.machine.machineId,
                  tags: [...selectedTags, tag],
                  retailer: {
                    __typename: 'Retailer',
                    id: data.machine.retailer.id,
                    retailerId: data.machine.retailer.retailerId,
                    machineTags: [...allMachineTags, tag],
                  },
                },
              },
            }
          : undefined,
      });
    },
    [addMachineTag, machineId, allMachineTags, selectedTags, data]
  );

  const handleDeselect = useCallback(
    async (tag: string) => {
      if (!machineId) {
        return;
      }

      await removeMachineTag({
        variables: {
          input: {
            machineId,
            tag,
          },
        },
        optimisticResponse: data
          ? {
              __typename: 'Mutation',
              removeMachineTag: {
                __typename: 'RemoveMachineTagPayload',
                machine: {
                  __typename: 'Machine',
                  id: data.machine.id,
                  machineId: data.machine.machineId,
                  tags: selectedTags.filter((t) => t.toLowerCase() !== tag.toLowerCase()),
                  retailer: {
                    __typename: 'Retailer',
                    id: data.machine.retailer.id,
                    retailerId: data.machine.retailer.retailerId,
                    machineTags: allMachineTags, // .filter((t) => t.toLowerCase() !== tag.toLowerCase()),
                  },
                },
              },
            }
          : undefined,
      });
    },
    [removeMachineTag, machineId, allMachineTags, selectedTags, data]
  );

  const isEmpty = machineId === undefined;
  const showSpinner = loading || isEmpty;

  return (
    <MachineTagsSelect
      tags={allMachineTags}
      value={selectedTags}
      onInputKeyDown={handleInputKeyDown}
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      searchValue={searchText}
      onSearch={(value) => setSearchText(value)}
      loading={showSpinner}
      {...rest}
    />
  );
};

export default MachineTagsSelectedConnected;
