import { SelectProps } from 'antd/es/select';
import React, { useMemo } from 'react';
import Select from 'components/lib/Select/Select.tsx';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { useGetTicketArticleStoragesQuery } from 'generated/types.tsx';
import useTicketAccountingToolFilter from 'components/ticket/TicketAccounting/OrderLineTool/useTicketAccountingToolFilter.ts';
import StorageItem from 'components/ticket/TicketAccounting/StorageItem.tsx';

type OtherSelectProps = Omit<SelectProps<number[]>, 'value' | 'onSelect' | 'onChange' | 'options'>;

const StorageFilterSelect: React.FC<OtherSelectProps> = (props) => {
  const intl = useConnectIntl();
  const { data, loading } = useGetTicketArticleStoragesQuery();
  const { filter, setFilter } = useTicketAccountingToolFilter();

  const options = useMemo(() => {
    return data?.allTicketArticleStorages.map((c) => ({
      value: c.ticketArticleStorageId,
      label: <StorageItem storage={c} />,
    }));
  }, [data?.allTicketArticleStorages]);

  const handleChange = (value: number[]) => {
    setFilter({ storageIds: value.length ? value : undefined });
  };

  return (
    <Select<number[]>
      placeholder={intl.formatMsg({
        id: 'storage_filter_select.placeholder',
        defaultMessage: 'Storage',
      })}
      maxTagCount={1}
      mode={'multiple'}
      loading={loading}
      showSearch={false}
      style={{ minWidth: 300 }}
      popupMatchSelectWidth={false}
      allowClear={true}
      options={options}
      value={loading ? undefined : filter.storageIds}
      onChange={handleChange}
      {...props}
    />
  );
};

export default StorageFilterSelect;
