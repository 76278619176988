import { SupportedLocaleKeys } from 'i18n/supportedLocaleKeys.ts';
import { Locale as AntLocale } from 'antd/lib/locale';

// make a dictionary with code as key and imported locale as value
const loadedLocales: Record<string, AntLocale> = {};

export async function loadAntLocale(key: SupportedLocaleKeys): Promise<AntLocale> {
  // console.log('🌐 loadAntLocale', key);
  if (loadedLocales[key]) {
    return loadedLocales[key];
  }

  // NOTE: This is "not-DRY" on purpose, because vite needs to know the import() at compile time
  let importedLocale;
  switch (key) {
    case 'da':
      importedLocale = await import('antd/es/locale/da_DK');
      break;
    case 'de':
      importedLocale = await import('antd/es/locale/de_DE');
      break;
    case 'et':
      importedLocale = await import('antd/es/locale/es_ES');
      break;
    case 'en':
      importedLocale = await import('antd/es/locale/en_US');
      break;
    case 'fr':
      importedLocale = await import('antd/es/locale/fr_FR');
      break;
    case 'nl':
      importedLocale = await import('antd/es/locale/nl_NL');
      break;
    case 'no':
      importedLocale = await import('antd/es/locale/nb_NO');
      break;
    case 'fi':
      importedLocale = await import('antd/es/locale/fi_FI');
      break;
    case 'sv':
      importedLocale = await import('antd/es/locale/sv_SE');
      break;
    default:
      throw new Error(`Unsupported locale: ${key}`);
  }

  loadedLocales[key] = importedLocale.default;
  return importedLocale.default;
}
