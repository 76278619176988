import TicketSeveritySelect from 'components/ticket/TicketSeverity/TicketSeveritySelect';
import React, { useCallback } from 'react';
import { SelectProps } from 'antd/es/select';
import {
  DeletedUserListItemFragmentDoc,
  TicketSeverity,
  useGetTicketSeverityQuery,
  UserListItemFragmentDoc,
  useSetTicketSeverityMutation,
} from 'generated/types';
import { useIntl } from 'react-intl';
import { gql } from '@apollo/client/core';
import useMessageApi from 'components/global/useMessageApi';

gql`
  query GetTicketSeverity($ticketId: Int!) {
    ticket(ticketId: $ticketId) {
      id
      ticketId
      severity
      isSubscribing
      subscribers {
        ...UserListItem
        ...DeletedUserListItem
      }
    }
  }
  ${UserListItemFragmentDoc}
  ${DeletedUserListItemFragmentDoc}
`;

gql`
  mutation SetTicketSeverity($input: SetTicketSeverityInput!) {
    setTicketSeverity(input: $input) {
      ticket {
        id
        ticketId
        severity
        isSubscribing
        subscribers {
          ...UserListItem
          ...DeletedUserListItem
        }
      }
    }
  }
  ${UserListItemFragmentDoc}
  ${DeletedUserListItemFragmentDoc}
`;

interface Props {
  ticketId?: number;
}

type OtherSelectProps = Omit<SelectProps<TicketSeverity>, 'value' | 'onSelect' | 'options' | 'loading'>;

const TicketSeveritySelectConnected: React.FC<Props & OtherSelectProps> = (props) => {
  const { children, ticketId, ...rest } = props;
  const intl = useIntl();
  const message = useMessageApi();
  const { data } = useGetTicketSeverityQuery({
    variables: ticketId ? { ticketId } : undefined,
    skip: ticketId === undefined,
  });

  const [setSeverity, { loading: loadingMutation }] = useSetTicketSeverityMutation({
    notifyOnNetworkStatusChange: true,
  });

  const handleSelect = useCallback(
    async (value: TicketSeverity) => {
      try {
        if (ticketId === undefined) return;

        await setSeverity({
          variables: {
            input: {
              ticketId: ticketId,
              severity: value,
            },
          },
          optimisticResponse: data
            ? {
                __typename: 'Mutation',
                setTicketSeverity: {
                  __typename: 'SetTicketSeverityPayload',
                  ticket: {
                    __typename: 'Ticket',
                    id: data.ticket.id,
                    ticketId: data.ticket.ticketId,
                    severity: value,
                    // comments: [],
                    isSubscribing: data.ticket.isSubscribing,
                    subscribers: data.ticket.subscribers,
                  },
                },
              }
            : undefined,
        });
      } catch (err) {
        message.error(
          intl.formatMessage({
            id: 'ticket_severity_select_connected.ticket_severity_update_fail',
            defaultMessage: 'Could not set ticket severity',
          })
        );
      }
    },
    [intl, setSeverity, ticketId, data, message]
  );

  return (
    <TicketSeveritySelect
      loading={loadingMutation || ticketId === undefined}
      value={data?.ticket.severity}
      onSelect={handleSelect}
      {...rest}
    />
  );
};

export default TicketSeveritySelectConnected;
