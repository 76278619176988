import React from 'react';
import TicketSeverityTag from 'components/ticket/TicketSeverity/TicketSeverityTag';
import { Flex, Select } from 'antd';
import TicketSeverityCircle from 'components/ticket/TicketSeverity/TicketSeverityCircle';
import { useEnumSelectOptions } from 'components/lib/Select/useEnumSelectOptions';
import { TicketSeverity } from 'generated/types';
import { ticketSeverityMessages } from 'components/ticket/TicketSeverity/ticketSeverityMessages';
import useTicketFilter from 'components/ticket/TicketFilter/useTicketFilter';
import { SelectProps } from 'antd/es/select';

type OtherSelectProps = Omit<
  SelectProps<TicketSeverity[]>,
  'value' | 'onSelect' | 'onChange' | 'options'
>;

const TicketSeverityFilterSelect: React.FC<OtherSelectProps> = (props) => {
  const { children, ...rest } = props;

  const severitiesOptions = useEnumSelectOptions({
    enumObject: TicketSeverity,
    keyPrefix: 'ticket_severity',
    customLabelMessages: ticketSeverityMessages,
  });

  const { ticketFilter, setTicketFilter } = useTicketFilter();
  const ticketSeveritySorted = ticketFilter.severities?.sort() || [];
  const isOneSelected = ticketFilter.severities?.length === 1;

  const handleChangeSeverity = (value: TicketSeverity[]) => {
    if (value.length > 0) {
      setTicketFilter({ severities: value });
    } else {
      setTicketFilter({ severities: undefined });
    }
  };

  return (
    <Select<TicketSeverity[]>
      placeholder={'Severity'}
      value={ticketSeveritySorted}
      onChange={handleChangeSeverity}
      mode={'multiple'}
      maxTagCount={'responsive'}
      showSearch={false}
      style={{ minWidth: 150 }}
      allowClear={true}
      size={'small'}
      tagRender={(props1) => {
        return isOneSelected ? (
          <TicketSeverityTag severity={props1.value} />
        ) : (
          <TicketSeverityCircle severity={props1.value} size={'small'} style={{ marginRight: 2 }} />
        );
      }}
      popupMatchSelectWidth={false}
      options={severitiesOptions}
      optionRender={(node) => {
        return (
          <Flex gap={8} align={'center'}>
            <TicketSeverityCircle severity={node.value as TicketSeverity} size={'small'} />
            <div>{node.label}</div>
          </Flex>
        );
      }}
      {...rest}
    />
  );
};

export default TicketSeverityFilterSelect;
