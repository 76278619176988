import React, { useState } from 'react';
import { gql } from '@apollo/client/core';
import {
  CreateRetailerInput,
  FullCountryFragment,
  useCreateRetailerMutation,
} from 'generated/types';
import { Button, Col, Form, FormProps, Input, Row, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import useMatrixNav from 'layouts/matrix/useMatrixNav';
import CountrySelect, { CountryOptionType } from 'components/i18n/CountrySelect/CountrySelect';
import { isArray } from 'lodash-es';
import retailerMessages from 'components/retailer/retailerMessages';
import { useNavigate } from 'react-router-dom';
import useMessageApi from 'components/global/useMessageApi';
import commonMessages from 'components/i18n/commonMessages.ts';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { setMachineNavFilter } from 'redux/machineNavSlice.ts';
import { useAppDispatch } from 'redux/store.ts';

gql`
  mutation CreateRetailer($input: CreateRetailerInput!) {
    createRetailer(input: $input) {
      retailer {
        id
        retailerId
        name
      }
    }
  }
`;

type CreateForm = CreateRetailerInput;
function TypedCreateForm<T extends object = CreateForm>({
  children,
  ...props
}: FormProps<T> & { children?: React.ReactNode }) {
  return <Form<T> {...props}>{children}</Form>;
}

const CreateRetailerForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const [createForm] = useForm<CreateForm>();
  const intl = useConnectIntl();
  const message = useMessageApi();
  const [createRetailer, { loading }] = useCreateRetailerMutation();
  const { getUrlToRoot, getUrlToRetailer } = useMatrixNav();
  const navigate = useNavigate();
  const initialValues: Partial<CreateForm> = {};
  const [countryId, setCountryId] = useState<string | undefined>(undefined);
  const [country, setCountry] = useState<FullCountryFragment | undefined>(undefined);

  const handleChangeCountryCode = (
    value: string,
    option: CountryOptionType | CountryOptionType[]
  ) => {
    if (!isArray(option)) {
      console.log(option);
      setCountryId(value);
      setCountry(option.country);
      createForm.setFieldValue('countryId', value);
    } else {
      console.log('multiselect not implemented yet');
    }
  };

  const handleSubmit = async (values: CreateForm) => {
    try {
      if (!countryId) {
        throw new Error('Country is required');
      }

      const input: CreateRetailerInput = {
        ...values,
        countryId: countryId,
      };
      message.loading({
        key: 'createRetailer',
        content: intl.formatMessage(retailerMessages.submitting),
      });
      const result = await createRetailer({
        variables: {
          input,
        },
      });
      const newRetailerId = result.data?.createRetailer?.retailer?.retailerId;

      if (newRetailerId) {
        navigate(getUrlToRetailer(newRetailerId));
      }

      message.success({
        key: 'createRetailer',
        content: intl.formatMessage(retailerMessages.submitSuccess),
      });

      // NOTE: this will force enable inactive retailers (retailers with no machines) in main menu
      dispatch(
        setMachineNavFilter({
          filter: {
            showRetailersWithoutMachines: true,
          },
          store: false,
        })
      );

      // TODO: We need to refetch list of retailers in main menu
      // Plan: expose refetch via context? OR, even better, let's use subscriptions!
      // that will update ALL clients when adding a new retailer...
    } catch (err: unknown) {
      let msg = 'Unknown error';
      if (err instanceof Error) {
        msg = err.message;
      }

      message.error({
        key: 'createRetailer',
        content: intl.formatMessage(retailerMessages.errorSubmit, { msg }),
      });
    }
  };

  const handleCancel = () => {
    navigate(getUrlToRoot());
  };

  const handleFailedSubmit = () => {
    message.error({
      content: intl.formatMessage({
        id: 'create_retailer_form.invalid_form',
        defaultMessage: 'Invalid form 😔',
      }),
    });
  };

  return (
    <TypedCreateForm
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      form={createForm}
      name={'createForm'}
      initialValues={initialValues}
      onFinish={handleSubmit}
      onFinishFailed={handleFailedSubmit}
    >
      <Form.Item name={'name'} label={'Name'} rules={[{ required: true }]}>
        <Input style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item label={'RegNo 1'}>
        <Row gutter={8}>
          <Col span={18}>
            <Form.Item name={'regno1'} noStyle={true}>
              <Input
                style={{ width: '100%' }}
                placeholder={intl.formatMessage(retailerMessages.regno1Placeholder)}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'regno1Name'} noStyle={true}>
              <Input placeholder={intl.formatMessage(retailerMessages.regno1NamePlaceholder)} />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item label={'RegNo 2'}>
        <Row gutter={8}>
          <Col span={18}>
            <Form.Item name={'regno2'} noStyle={true}>
              <Input
                style={{ width: '100%' }}
                placeholder={intl.formatMessage(retailerMessages.regno2Placeholder)}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'regno2Name'} noStyle={true}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item name={'web'} label={'Web'}>
        <Input style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item name={'email'} label={'Email'}>
        <Input style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item name={'phone'} label={'Phone'}>
        <Input style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item name={'address1'} label={'Address 1'}>
        <Input style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item name={'address2'} label={'Address 2'}>
        <Input style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item name={'postCode'} label={'Postal code'}>
        <Input style={{ width: '180px' }} />
      </Form.Item>
      <Form.Item name={'postCity'} label={'City'}>
        <Input style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        // name={'countryId'}
        label={'Country'}
        rules={[{ required: true }]}
        tooltip={
          'Country cannot be changed after creation, please be sure to select the correct one'
        }
      >
        <Space direction={'vertical'}>
          <CountrySelect value={countryId} onChange={handleChangeCountryCode} />
          {country && (
            <Space direction={'vertical'} size={'small'}>
              <div>Currency: {country.currency}</div>
              <div>Timezone: {country.timezone.name}</div>
              <div>VAT: {country.vatRate} %</div>
            </Space>
          )}
        </Space>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
        <Space>
          <Button type={'primary'} icon={<SaveOutlined />} htmlType={'submit'} disabled={loading}>
            {intl.formatMessage(retailerMessages.submit)}
          </Button>
          <Button icon={<CloseOutlined />} onClick={handleCancel} disabled={loading}>
            {intl.formatMsg(commonMessages.cancel)}
          </Button>
        </Space>
      </Form.Item>
    </TypedCreateForm>
  );
};

export default CreateRetailerForm;
