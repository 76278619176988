import React, { MouseEventHandler, useCallback } from 'react';
import styled from 'styled-components';

interface Props {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  disabled?: boolean;
  style?: React.CSSProperties;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const Container = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;

  text-decoration: ${(props) => props.theme.ant.linkDecoration};
  transition: color 0.3s;
  color: ${(props) => props.theme.ant.colorLink};

  color: ${(props) =>
    props.$disabled ? props.theme.ant.colorTextDisabled : props.theme.ant.colorLink};

  outline: none;
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    text-decoration: ${(props) => props.theme.ant.linkHoverDecoration};
    color: ${(props) =>
      props.$disabled ? props.theme.ant.colorTextDisabled : props.theme.ant.colorLinkHover};
  }

  &:active {
    text-decoration: ${(props) => props.theme.ant.linkFocusDecoration};
    color: ${(props) =>
      props.$disabled ? props.theme.ant.colorTextDisabled : props.theme.ant.colorLinkActive};
  }
  &:focus {
    text-decoration: ${(props) => props.theme.ant.linkFocusDecoration};
  }
`;

/***
 * LinkButton
 * Use Link component if possible, LinkButton is only for cases where you need to use a button
 * @param props
 * @constructor
 */
const LinkButton: React.FC<Props> = (props) => {
  const { icon, style, disabled, children, onClick } = props;

  const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (!disabled) {
        onClick?.(e);
      }
    },
    [disabled, onClick]
  );

  return (
    <Container style={style} onClick={handleClick} $disabled={disabled}>
      {icon}
      {children}
    </Container>
  );
};

export default LinkButton;
