import { defineMessages } from 'react-intl';

const tabMessages = defineMessages({
  tickets: {
    id: 'connect_tabs.tickets',
    defaultMessage: 'Tickets',
  },
  events: {
    id: 'connect_tabs.events',
    defaultMessage: 'Events',
  },
  properties: {
    id: 'connect_tabs.properties',
    defaultMessage: 'Properties',
  },
  users: {
    id: 'connect_tabs.users',
    defaultMessage: 'Authorization',
  },
  priceMatrix: {
    id: 'connect_tabs.priceMatrix',
    defaultMessage: 'Pricing',
  },
  transactions: {
    id: 'connect_tabs.transactions',
    defaultMessage: 'Transactions',
  },
  reconciliation: {
    id: 'connect_tabs.reconciliation',
    defaultMessage: 'Reconciliation',
  },
  statistics: {
    id: 'connect_tabs.statistics',
    defaultMessage: 'Statistics',
  },
  inventory: {
    id: 'connect_tabs.inventory',
    defaultMessage: 'Inventory',
  },
  refillOrders: {
    id: 'connect_tabs.refillOrders',
    defaultMessage: 'Refill Orders',
  },
  machines: {
    id: 'connect_tabs.machines',
    defaultMessage: 'Machines',
  },
  integrations: {
    id: 'connect_tabs.integrations',
    defaultMessage: 'Integrations',
  },
  products: {
    id: 'connect_tabs.products',
    defaultMessage: 'Products',
  },
  predictions: {
    id: 'connect_tabs.predictions',
    defaultMessage: 'Predictions',
  },
});

export default tabMessages;
