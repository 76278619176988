import { useState, useCallback, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
  addLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} from 'common/localStorage.ts';

// NOTE: This is from ant design forms, copy since it's not exported
export type RecursivePartial<T> = NonNullable<T> extends object
  ? {
      [P in keyof T]?: NonNullable<T[P]> extends (infer U)[]
        ? RecursivePartial<U>[]
        : NonNullable<T[P]> extends object
        ? RecursivePartial<T[P]>
        : T[P];
    }
  : T;

interface AutoSaveOptions {
  key: string;
  saveOnBlur?: boolean;
  saveOnInactivity?: number | false; // save when inactive milliseconds
}

export function useAutoSaveForm<T extends object>({
  key,
  saveOnBlur = true,
  saveOnInactivity = 5000,
}: AutoSaveOptions) {
  const [formValues, setFormValues] = useState<RecursivePartial<T> | undefined>(undefined);
  const previousValuesRef = useRef<RecursivePartial<T> | undefined>(undefined);

  const autoSave = useCallback(() => {
    if (formValues !== previousValuesRef.current) {
      addLocalStorageItem(key, formValues);
      previousValuesRef.current = formValues;
    } else {
      console.log('No changes, no need to trigger autosave', formValues, previousValuesRef.current);
    }
  }, [formValues, key]);

  const debouncedAutoSave = useDebouncedCallback(() => {
    autoSave();
  }, saveOnInactivity || 5000);

  const handleFormBlur = useCallback(() => {
    if (saveOnBlur) {
      autoSave();
    }
  }, [autoSave, saveOnBlur]);

  const handleFormValuesChange = useCallback(
    (changedValues: Partial<T>, allValues: T) => {
      setFormValues(allValues);
      if (saveOnInactivity) {
        debouncedAutoSave();
      }
    },
    [debouncedAutoSave, saveOnInactivity]
  );

  const restoreFormDraft = useCallback(() => {
    const draft = getLocalStorageItem<RecursivePartial<T>>(key);
    if (draft) {
      setFormValues(draft);
      previousValuesRef.current = draft;
      return draft;
    }
  }, [key]);

  const clearFormDraft = useCallback(() => {
    removeLocalStorageItem(key);
  }, [key]);

  return {
    handleFormValuesChange,
    handleFormBlur,
    restoreFormDraft,
    clearFormDraft,
  };
}
