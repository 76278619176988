import React from 'react';
import { useIntl } from 'react-intl';
import BigText from 'components/machine/properties/MachinePropertyList/BigText.tsx';
import machinePropertyMessages from 'components/machine/properties/MachinePropertyList/machinePropertyMessages.ts';
import styled from 'styled-components';

const NotSetText = styled(BigText)`
  font-style: italic;
`;

const NotSetValue: React.FC = () => {
  const intl = useIntl();
  return (
    <NotSetText editable={false}>
      {intl.formatMessage(machinePropertyMessages.notSetValue)}
    </NotSetText>
  );
};

export default NotSetValue;
