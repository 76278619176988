import React from 'react';
import { Alert as AlertInternal, AlertProps as AlertPropsInternal } from 'antd';

export type AlertProps = AlertPropsInternal;

const Alert: React.FC<AlertProps> = (props) => {
  return <AlertInternal {...props} />;
};

export default Alert;
