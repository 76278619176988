import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  getPublicSalt,
  isLoginError,
  loadUserInfo,
  login,
  selectIsAuthenticated,
  selectIsLoggingIn,
  selectPublicSalt,
} from 'auth/authSlice';
import { loadAppMessages } from 'i18n/i18nSlice';
import { loginChannel } from 'auth/useAuthSync';
import useMessageApi from 'components/global/useMessageApi';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import Link from 'components/lib/Link/Link.tsx';
import loginMessages from 'layouts/LoginLayout/loginMessages.ts';
import { AnimatePresence, motion } from 'framer-motion';
import { SerializedError } from '@reduxjs/toolkit';
import { VendanorConnectText } from 'layouts/components/icons/VendanorConnectText.tsx';

type StrictSerializedError = Required<Pick<SerializedError, 'code' | 'name' | 'message'>>;

// NOTE: This was a quick fix to check the code from AxiosError.
// We could improve this by
// - returning a known error result in thunk
// - maybe add a retry logic here if salt is not retrieved,
// - or just an online checker?
function isStrictSerializedError(err: unknown): err is StrictSerializedError {
  return (
    typeof err === 'object' && err !== null && 'code' in err && 'name' in err && 'message' in err
  );
}

interface FormProps {
  username: string;
  password: string;
  remember: boolean;
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const intl = useConnectIntl();
  const message = useMessageApi();

  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isLoggingIn = useAppSelector(selectIsLoggingIn);
  const publicSalt = useAppSelector(selectPublicSalt);

  const [isLoadingAfterLogin, setIsLoadingAfterLogin] = useState(false);

  useEffect(() => {
    dispatch(getPublicSalt());
  }, [dispatch]);

  const [form] = Form.useForm<FormProps>();

  const handleLogin = useCallback(
    async (values: unknown) => {
      let salt = publicSalt;
      if (salt === undefined) {
        try {
          salt = await dispatch(getPublicSalt()).unwrap();
        } catch (err: unknown) {
          if (isStrictSerializedError(err)) {
            if (err.code === 'ERR_NETWORK') {
              message.error(intl.formatMsg(loginMessages.loginFetchSaltNetworkError));
              return;
            }
            message.error(intl.formatMsg(loginMessages.loginFetchSaltError));
            return;
          }
        }
      }

      if (!salt) {
        return;
      }

      const f = values as FormProps; // NOTE: hmm, no strong types here? This could be inferred by antd with new TypeScript functionality?
      dispatch(
        login({
          email: f.username,
          password: f.password,
          remember: f.remember,
          publicSalt: salt,
          intl,
        })
      )
        .unwrap()
        .then((res) => {
          // Now we're logged in and redux state is updated with access token

          setIsLoadingAfterLogin(true);

          // Load protected translations:
          dispatch(
            loadAppMessages({
              locale: res.userLocale,
            })
          )
            .unwrap()
            .then(() => {
              // protected translations are loaded.

              // Load authenticated user:
              dispatch(loadUserInfo())
                .unwrap()
                .then(() => {
                  // authenticated user info, acl etc. is loaded
                  try {
                    loginChannel.postMessage('I got in!');
                  } catch (err) {
                    console.warn('Error when sending login signal', err);
                  }
                })
                .catch((err) => {
                  message.error('Error when loading user info: ' + err.message);
                });
            })
            .catch(() => {
              message.error({
                content: 'Error when loading translations',
              });
            })
            .finally(() => {
              setIsLoadingAfterLogin(false);
            });
        })
        .catch((err: unknown) => {
          let msg: React.ReactNode = intl.formatMsg(loginMessages.loginFailed);
          if (isLoginError(err)) {
            if (err.status === 401) {
              msg = intl.formatMsg(loginMessages.login401WrongPassword);
            } else if (err.status === 429) {
              msg = intl.formatMsg(loginMessages.login429TooManyAttempts);
            } else if (err.status === 423) {
              msg = intl.formatMsg(loginMessages.login423AccountLocked);
            } else if (err.status === 403) {
              msg = intl.formatMsg(loginMessages.login403Unauthorized);
            } else if (err.status === 500) {
              msg = intl.formatMsg(loginMessages.login500InternalServerError);
            } else if (err.status === 412) {
              msg = intl.formatMsg(loginMessages.login412MissingAudience, {
                br: <br />,
              });
            }
          }

          message.error({
            content: msg,
            duration: 10,
          });
        });
    },
    [publicSalt, dispatch, intl, message]
  );

  const redirectUrl = query.get('redirect') || '/';

  useEffect(() => {
    if (isAuthenticated && !isLoggingIn && !isLoadingAfterLogin) {
      console.log('➡️ Logged in, redirecting from LoginPage to: ' + redirectUrl);
      navigate(redirectUrl, { replace: true });
    }
  }, [navigate, isAuthenticated, redirectUrl, isLoggingIn, isLoadingAfterLogin]);

  return (
    <AnimatePresence>
      <motion.div
        key={'loginForm'}
        initial={{ opacity: 0, y: -5 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -5 }}
        style={{
          width: '100%',
        }}
      >
        <Form
          style={{ width: '100%' }}
          name={'login-form'}
          form={form}
          size={'large'}
          onFinish={handleLogin}
          layout={'vertical'}
        >
          <>
            <Form.Item>
              <VendanorConnectText width={'220px'} />
            </Form.Item>
            <Form.Item
              name={'username'}
              rules={[
                {
                  required: true,
                  message: intl.formatMsg(loginMessages.emailMissing),
                },
                {
                  type: 'email',
                  message: intl.formatMsg(loginMessages.emailValidationError),
                },
              ]}
            >
              <Input
                placeholder={intl.formatMsg(loginMessages.emailPlaceholder)}
                prefix={<UserOutlined />}
                inputMode={'email'}
                autoComplete={'username'}
              />
            </Form.Item>
            <Form.Item
              name={'password'}
              rules={[
                {
                  required: true,
                  message: intl.formatMsg(loginMessages.passwordMissing),
                },
              ]}
            >
              <Input.Password
                placeholder={intl.formatMsg(loginMessages.passwordPlaceholder)}
                prefix={<LockOutlined />}
                autoComplete='current-password'
                className={'sentry-mask'}
              />
            </Form.Item>
            <Form.Item initialValue={true} name={'remember'} valuePropName={'checked'}>
              <Checkbox>
                <div>{intl.formatMsg(loginMessages.rememberMe)}</div>
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Button
                style={{ width: '100%' }}
                type='primary'
                htmlType='submit'
                loading={isLoggingIn || isLoadingAfterLogin}
              >
                {intl.formatMsg(loginMessages.login)}
              </Button>
            </Form.Item>
            <Form.Item noStyle={true}>
              <Link to={'/lost-password'}>
                {intl.formatMsg({
                  id: 'login_page.forgot_password',
                  defaultMessage: 'Forgot password?',
                })}
              </Link>
            </Form.Item>
          </>
        </Form>
      </motion.div>
    </AnimatePresence>
  );
};

export default LoginPage;
