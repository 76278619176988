import React, { useCallback } from 'react';
import styled from 'styled-components';
import { TruckOutlineIcon, UserFilledIcon } from 'components/icons/Icons';
import { Dropdown, Space, Typography } from 'antd';
import {
  DashboardOutlined,
  DollarCircleOutlined,
  LogoutOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'redux/store';
import MainNavLink from 'layouts/MainLayout/MainNav/MainNavLink';
import { FormattedMessage, useIntl } from 'react-intl';
import { logout, selectRootPermissions } from 'auth/authSlice';
import useMatrixNav from 'layouts/matrix/useMatrixNav';
import Link from 'components/lib/Link/Link';
import { appVersion } from 'common/appVersion';
import MainNavMiniButton from 'layouts/MainLayout/MainNav/MainNavMiniButton';
import VendanorButterfly from 'layouts/components/icons/VendanorButterfly';
import { categoryMessages } from 'layouts/matrix/category.tsx';
import { useGetUserAndSettingsQuery } from 'generated/types.tsx';
import { VendanorConnectTextCentered } from 'layouts/components/icons/VendanorConnectText.tsx';

const NavBar = styled.nav`
  background-color: ${(props) =>
    props.theme.isDark
      ? props.theme.ant.colorPrimaryActive // a bit darker main color in dark mode
      : props.theme.ant.colorPrimary};
  position: fixed;
  z-index: 2; // to allow Popover to overflow main content div
  left: 0;
  top: 0;
  bottom: 0;
  width: 64px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`;

const Top = styled.div`
  flex: 0 0 auto;
`;

const Bottom = styled(Space)`
  flex: 0 0 auto;
`;

const LogoLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`;

const StyledVendanorConnectTextCentered = styled(VendanorConnectTextCentered)`
  transition: color 0.3s ease-in-out;
  color: ${(props) =>
    props.theme.isDark ? props.theme.ant.colorLink : props.theme.ant.colorPrimaryBg};

  margin-top: 16px;
  margin-bottom: 16px;

  ${LogoLink}:hover & {
    color: ${(props) =>
      props.theme.isDark ? props.theme.ant.colorLinkHover : props.theme.ant.colorPrimaryBgHover};
  }
`;

const DropdownContent = styled.div`
  background: ${(props) => props.theme.ant.colorBgContainer};
  border-radius: ${(props) => props.theme.ant.borderRadius}px;
  box-shadow: ${(props) => props.theme.ant.boxShadow};

  && {
    .ant-dropdown-menu {
      box-shadow: none;
      background-color: unset;
      padding-bottom: 0;
    }
  }
`;

const MainNav: React.FC = () => {
  const { selectedCategory, getUrlToCategory } = useMatrixNav();
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const { data } = useGetUserAndSettingsQuery();

  const handleSignOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const permissions = useAppSelector(selectRootPermissions);
  const { getUrlToRoot } = useMatrixNav();

  return (
    <NavBar>
      <Top>
        <LogoLink to={getUrlToRoot()}>
          <VendanorButterfly height={'42px'} />
          <StyledVendanorConnectTextCentered width={'80%'} />
        </LogoLink>
        <ButtonContainer>
          {permissions?.categories.operational && (
            <MainNavLink
              icon={<DashboardOutlined />}
              title={intl.formatMessage(categoryMessages.operational)}
              isSelected={selectedCategory === 'operational'}
              url={getUrlToCategory('operational')}
            />
          )}
          {permissions?.categories.commercial && (
            <MainNavLink
              icon={<DollarCircleOutlined />}
              title={intl.formatMessage(categoryMessages.commercial)}
              isSelected={selectedCategory === 'commercial'}
              url={getUrlToCategory('commercial')}
            />
          )}
          {permissions?.categories.logistics && (
            <MainNavLink
              icon={<TruckOutlineIcon />}
              title={intl.formatMessage(categoryMessages.logistics)}
              isSelected={selectedCategory === 'logistics'}
              url={getUrlToCategory('logistics')}
            />
          )}
        </ButtonContainer>
      </Top>
      <Bottom size={'small'} direction={'vertical'} align={'center'} style={{ marginBottom: 16 }}>
        <Dropdown
          getPopupContainer={(trigger) =>
            trigger.parentElement ? trigger.parentElement : document.body
          }
          arrow={true}
          autoAdjustOverflow={{ adjustX: 1 }}
          trigger={['click']}
          menu={{
            items: [
              {
                key: 'menu_divider1',
                type: 'divider',
              },
              {
                key: 'menu_preferences',
                label: (
                  <Link to={'/preferences'}>
                    <Space>
                      <SettingOutlined />
                      <span>
                        <FormattedMessage
                          id={'main_nav.menu_preferences'}
                          defaultMessage={'Preferences'}
                        />
                      </span>
                    </Space>
                  </Link>
                ),
              },
              {
                key: 'menu_sign_out',
                onClick: handleSignOut,
                label: (
                  <Space>
                    <LogoutOutlined />
                    <span>
                      <FormattedMessage id={'main_nav.log_out'} defaultMessage={'Sign out'} />
                    </span>
                  </Space>
                ),
              },
              {
                key: 'menu_divider2',
                type: 'divider',
              },
            ],
          }}
          dropdownRender={(menu) => {
            return (
              <DropdownContent>
                {data && (
                  <UsernameItem>
                    <Space>
                      <Typography.Text ellipsis={true}>{data.me.name}</Typography.Text>
                    </Space>
                    {data.me.emailAddress && (
                      <Typography.Text ellipsis={true} style={{ fontSize: 12 }}>
                        {data.me.emailAddress}
                      </Typography.Text>
                    )}
                  </UsernameItem>
                )}
                {menu}
                <VersionItem type={'secondary'} ellipsis={true}>
                  Connect v{appVersion.version}
                </VersionItem>
              </DropdownContent>
            );
          }}
        >
          <MainNavMiniButton
            shape={'circle'}
            type={'primary'}
            icon={<UserFilledIcon />}
            size={'large'}
          />
        </Dropdown>
      </Bottom>
    </NavBar>
  );
};

const VersionItem = styled(Typography.Text)`
  width: 100%;

  //background: palegoldenrod;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;

  font-size: 12px;
`;

const UsernameItem = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;

  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export default MainNav;
