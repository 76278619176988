import React, { useCallback } from 'react';
import { MachineMapItemFragment, TicketMapItemFragment, TicketSeverity } from 'generated/types';
import { MarkerItem } from 'components/lib/ConnectMap/markerItem';
import { Marker, MarkerProps, useGoogleMap } from '@react-google-maps/api';
import { useTicketSeverityIcon } from 'components/ticket/TicketSeverity/useTicketSeverityIcon';
import useConnectIntl from 'i18n/useConnectIntl.ts';

export interface TicketMarkerItem extends MarkerItem {
  machine: MachineMapItemFragment;
  ticket?: TicketMapItemFragment;
}

interface Props {
  data: TicketMarkerItem;
  size: 'small' | 'normal';
}

type OtherProps = Omit<MarkerProps, 'position' | 'title' | 'icon' | 'zIndex'>;

const TicketMarker: React.FC<Props & OtherProps> = (props) => {
  const map = useGoogleMap();
  if (map === null) {
    throw new Error('Cannot be used outside a google map context');
  }

  const getSeverityIcon = useTicketSeverityIcon();
  const { data, size, ...rest } = props;
  const size2 = size === 'small' ? 20 : 24;

  const getMarkerIcon = useCallback(
    (severity: TicketSeverity) => {
      const icon: google.maps.Icon = {
        // eslint-disable-next-line
        url: 'data:image/svg+xml;utf8,' + getSeverityIcon(severity),
        anchor: new google.maps.Point(12, 12),
        scaledSize: new google.maps.Size(size2, size2),
      };
      return icon;
    },
    [getSeverityIcon, size2]
  );

  const getMarkerZIndex = useCallback((sev: TicketSeverity) => {
    switch (sev) {
      case TicketSeverity.D:
        return 1;
      case TicketSeverity.C:
        return 2;
      case TicketSeverity.B:
        return 3;
      case TicketSeverity.A:
        return 4;
    }
  }, []);

  const intl = useConnectIntl();

  if (data.ticket && data.position) {
    return (
      <Marker
        position={data.position}
        title={
          data.ticket.title ||
          intl.formatMsg({
            id: data.ticket.type.nameKeyI18n,
          })
        }
        key={data.machine.id}
        icon={getMarkerIcon(data.ticket.severity)}
        zIndex={getMarkerZIndex(data.ticket.severity)}
        {...rest}
      >
        {props.children}
      </Marker>
    );
  }

  return null;
};

export default TicketMarker;
