import React, { useCallback } from 'react';
import commonMessages from 'components/i18n/commonMessages';
import { Button, Popconfirm } from 'antd';
import { useIntl } from 'react-intl';
import { ApolloError, gql } from '@apollo/client';
import retailerMessages from 'components/retailer/retailerMessages';
import useMessageApi from 'components/global/useMessageApi';
import {
  useForceDeployAllMachineChangesMutation,
  useForceDeployAllRetailerChangesMutation,
  useForceDeployRetailerChangesMutation,
} from 'generated/types';
import { getFriendlyApolloErrorMessages } from 'graphql/apollo/apolloErrorUtil.ts';

gql`
  mutation ForceDeployRetailerChanges($retailerId: Int!) {
    deployRetailerChanges(input: { forceAll: true, retailerId: $retailerId }) {
      retailer {
        id
        retailerId
        name
      }
    }
  }
`;

gql`
  mutation ForceDeployAllRetailerChanges {
    forceDeployAllRetailersChanges {
      boolean
    }
  }
`;

gql`
  mutation ForceDeployAllMachineChanges($input: ForceDeployAllMachineChangesInput!) {
    forceDeployAllMachineChanges(input: $input) {
      machine {
        id
        machineId
        serialNo
      }
    }
  }
`;

interface Props {
  retailerId?: number;
  machineId?: number;
  canRedeployParameters: boolean;
}

const ReDeployParameterButton: React.FC<Props> = ({
  retailerId,
  machineId,
  canRedeployParameters,
}) => {
  const intl = useIntl();
  const message = useMessageApi();

  const [forceDeployAllRetailerChanges] = useForceDeployAllRetailerChangesMutation();
  const [forceDeployRetailerChanges] = useForceDeployRetailerChangesMutation();
  const [forceDeployMachineChanges] = useForceDeployAllMachineChangesMutation();

  const handleDeploy = useCallback(async () => {
    try {
      message.loading({
        key: 'deploy-params',
        content: 'Deploying...',
      });
      if (machineId !== undefined) {
        await forceDeployMachineChanges({
          variables: {
            input: {
              machineId,
            },
          },
        });
      } else if (retailerId !== undefined) {
        await forceDeployRetailerChanges({
          variables: {
            retailerId,
          },
        });
      } else {
        await forceDeployAllRetailerChanges();
      }
      message.success({
        key: 'deploy-params',
        content: '👌 Deployment in progress!',
      });
    } catch (err: unknown) {
      if (err instanceof ApolloError) {
        message.error({
          key: 'deploy-params',
          content: getFriendlyApolloErrorMessages(err, false),
        });
      } else {
        console.log(typeof err);
        message.error({
          key: 'deploy-params',
          content: 'Failed to deploy',
        });
      }
    }
  }, [
    message,
    retailerId,
    machineId,
    forceDeployMachineChanges,
    forceDeployRetailerChanges,
    forceDeployAllRetailerChanges,
  ]);

  const buttonMessage = intl.formatMessage({
    id: 're_deploy_parameters_button.re_deploy_parameters',
    defaultMessage: 'Redeploy parameters',
  });

  const warningMessage =
    machineId !== undefined
      ? undefined
      : !canRedeployParameters
      ? undefined
      : retailerId !== undefined
      ? intl.formatMessage(retailerMessages.redeployWarningRetailer)
      : intl.formatMessage(retailerMessages.redeployWarningAll);

  return warningMessage ? (
    <Popconfirm
      title={intl.formatMessage(commonMessages.warning)}
      onConfirm={handleDeploy}
      description={<div style={{ maxWidth: 230 }}>{warningMessage}</div>}
    >
      <Button type={'default'} style={{ width: '100%' }}>
        {buttonMessage}
      </Button>
    </Popconfirm>
  ) : (
    <Button
      type={'default'}
      style={{ width: '100%' }}
      onClick={handleDeploy}
      disabled={!canRedeployParameters}
    >
      {buttonMessage}
    </Button>
  );
};

export default ReDeployParameterButton;
