import React from 'react';
import { NavigateOptions, useSearchParams } from 'react-router-dom';

/**
 * Copy-paste-ish from: https://stackblitz.com/github/remix-run/react-router/tree/main/examples/custom-query-parsing?file=src/App.tsx
 * https://stackoverflow.com/questions/6807180/how-to-escape-a-json-string-to-have-it-in-a-url
 *
 * TODO:
 * JSURL looks nice?, but no maintained and no TypeScript definitions. We could add a
 * TSURL library? or react-router-tsurl, etc..
 *    And add stuff like:
 *    - ordering keys (to optimize url cache),
 *    - default values in hook? tree shakable hook with dep to useSearchParams? or just native no dep?
 *    - validate input from url? can enter numbers for strings etc..
 *    or use sindresorhos query-string?
 */
function useQueryParam<T>(
  key: string
): [T | undefined, (newQuery: T, options?: NavigateOptions) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(key);

  const value = React.useMemo(() => {
    // return JSURL.parse(paramValue);
    return paramValue ? JSON.parse(decodeURIComponent(paramValue)) : undefined;
  }, [paramValue]);

  const setValue = React.useCallback(
    (newValue: T, options?: NavigateOptions) => {
      const newSearchParams = new URLSearchParams(searchParams);
      // newSearchParams.set(key, JSURL.stringify(newValue));
      newSearchParams.set(key, encodeURIComponent(JSON.stringify(newValue)));
      setSearchParams(newSearchParams, options);
    },
    [key, searchParams, setSearchParams]
  );

  return [value, setValue];
}

export default useQueryParam;
