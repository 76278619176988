import React from 'react';
import Card from 'components/lib/Card/Card';
import { Button, Col, Form, Row, Space } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { defineMessages, useIntl } from 'react-intl';
import { gql } from '@apollo/client/core';
import {
  AssetListItemFragmentDoc,
  FullAssetUploadTokenFragmentDoc,
  RetailerColorPaletteFullFragmentDoc,
  useConfirmRetailerLogoUploadMutation,
  useConfirmRetailerReceiptAdUploadMutation,
  useGetRetailerBrandingQuery,
  useRemoveRetailerLogoMutation,
  useRemoveRetailerReceiptAdMutation,
  useRequestRetailerLogoUploadMutation,
  useRequestRetailerReceiptAdUploadMutation,
  useResetRetailerPaletteMutation,
  UserListItemFragmentDoc,
  useUpdateRetailerColor1Mutation,
  useUpdateRetailerColor2Mutation,
  useUpdateRetailerColor3Mutation,
} from 'generated/types';
import useAssetUploadHandler from 'components/lib/upload/useAssetUploadHandler';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import ColorPicker from 'components/lib/ColorPicker/ColorPicker';
import UploadImage from 'components/lib/upload/UploadImage/UploadImage';
import useMessageApi from 'components/global/useMessageApi';
import { useRetailerPermissions } from 'auth/RequireRetailerAccess.tsx';
import { getFriendlyApolloErrorMessage } from 'graphql/apollo/apolloErrorUtil.ts';
import useRetailerDeployTools from 'components/retailer/useRetailerDeployTools.ts';
import useOnUnmount from 'hooks/useOnUnmount.ts';

interface Props {
  retailerId?: number;
}

const messages = defineMessages({
  resetPalette: {
    id: 'retailer_branding_card.reset_palette',
    defaultMessage: 'Reset palette',
  },
  logo: {
    id: 'retailer_branding_card.logo',
    defaultMessage: 'Logo',
  },
  uploadLogoHint: {
    id: 'retailer_branding_card.upload_logo_hint',
    defaultMessage: 'Upload a logo by dragging & dropping or clicking here.',
  },
  removeLogo: {
    id: 'retailer_branding_card.remove_logo',
    defaultMessage: 'Remove logo',
  },
  removingLogo: {
    id: 'retailer_branding_card.removing_logo',
    defaultMessage: 'Removing logo...',
  },
  removeLogoSuccess: {
    id: 'retailer_branding_card.remove_logo_success',
    defaultMessage: 'Logo removed successfully.',
  },
  removeLogoError: {
    id: 'retailer_branding_card.remove_logo_error',
    defaultMessage: 'Error removing logo.',
  },
  receiptAd: {
    id: 'retailer_branding_card.receipt_ad',
    defaultMessage: 'Receipt ad',
  },
  removeAd: {
    id: 'retailer_branding_card.remove_ad',
    defaultMessage: 'Remove ad',
  },
  removingAd: {
    id: 'retailer_branding_card.removing_ad',
    defaultMessage: 'Removing ad...',
  },
  removeAdSuccess: {
    id: 'retailer_branding_card.remove_ad_success',
    defaultMessage: 'Ad removed successfully.',
  },
  removeAdError: {
    id: 'retailer_branding_card.remove_ad_error',
    defaultMessage: 'Error removing ad.',
  },
  uploadAdHint: {
    id: 'retailer_branding_card.upload_ad_hint',
    defaultMessage: 'Upload an ad by dragging & dropping or clicking here.',
  },
  resetPaletteLoading: {
    id: 'retailer_branding_card.reset_palette_loading',
    defaultMessage: 'Resetting palette...',
  },
  resetPaletteSuccess: {
    id: 'retailer_branding_card.reset_palette_success',
    defaultMessage: 'Palette reset successfully.',
  },
  resetPaletteError: {
    id: 'retailer_branding_card.reset_palette_error',
    defaultMessage: 'Error resetting palette.',
  },
});

gql`
  query GetRetailerBranding($retailerId: Int!) {
    retailer(retailerId: $retailerId) {
      id
      retailerId
      hasLogo
      logo {
        ...AssetListItem
        createdBy {
          ...UserListItem
        }
      }
      hasReceiptAd
      receiptAd {
        ...AssetListItem
        createdBy {
          ...UserListItem
        }
      }
      palette {
        ...RetailerColorPaletteFull
      }
    }
  }
  ${AssetListItemFragmentDoc}
  ${UserListItemFragmentDoc}
  ${RetailerColorPaletteFullFragmentDoc}
`;

gql`
  mutation UpdateRetailerColor1($input: UpdateRetailerColor1Input!) {
    updateRetailerColor1(input: $input) {
      retailer {
        id
        retailerId
        palette {
          ...RetailerColorPaletteFull
        }
      }
    }
  }
  ${RetailerColorPaletteFullFragmentDoc}
`;

gql`
  mutation UpdateRetailerColor2($input: UpdateRetailerColor2Input!) {
    updateRetailerColor2(input: $input) {
      retailer {
        id
        retailerId
        palette {
          ...RetailerColorPaletteFull
        }
      }
    }
  }
  ${RetailerColorPaletteFullFragmentDoc}
`;

gql`
  mutation UpdateRetailerColor3($input: UpdateRetailerColor3Input!) {
    updateRetailerColor3(input: $input) {
      retailer {
        id
        retailerId
        palette {
          ...RetailerColorPaletteFull
        }
      }
    }
  }
  ${RetailerColorPaletteFullFragmentDoc}
`;

gql`
  mutation RequestRetailerLogoUpload($input: CreateRetailerLogoUploadTokenInput!) {
    createRetailerLogoUploadToken(input: $input) {
      assetUploadToken {
        ...FullAssetUploadToken
      }
    }
  }
  ${FullAssetUploadTokenFragmentDoc}
`;

gql`
  mutation ConfirmRetailerLogoUpload($input: SetRetailerLogoUploadCompletedInput!) {
    setRetailerLogoUploadCompleted(input: $input) {
      retailer {
        id
        retailerId
        hasLogo
        logo {
          ...AssetListItem
        }
      }
    }
  }
  ${AssetListItemFragmentDoc}
`;

gql`
  mutation RequestRetailerReceiptAdUpload($input: CreateRetailerReceiptAdUploadTokenInput!) {
    createRetailerReceiptAdUploadToken(input: $input) {
      assetUploadToken {
        ...FullAssetUploadToken
      }
    }
  }
  ${FullAssetUploadTokenFragmentDoc}
`;

gql`
  mutation ConfirmRetailerReceiptAdUpload($input: SetRetailerReceiptAdUploadCompletedInput!) {
    setRetailerReceiptAdUploadCompleted(input: $input) {
      retailer {
        id
        retailerId
        hasReceiptAd
        receiptAd {
          ...AssetListItem
        }
      }
    }
  }
  ${AssetListItemFragmentDoc}
`;

gql`
  mutation RemoveRetailerLogo($retailerId: Int!) {
    deleteRetailerLogo(input: { retailerId: $retailerId }) {
      retailer {
        id
        retailerId
        hasLogo
        logo {
          ...AssetListItem
          createdBy {
            ...UserListItem
          }
        }
      }
    }
  }
  ${AssetListItemFragmentDoc}
  ${UserListItemFragmentDoc}
`;

gql`
  mutation RemoveRetailerReceiptAd($retailerId: Int!) {
    deleteRetailerReceiptAd(input: { retailerId: $retailerId }) {
      retailer {
        id
        retailerId
        hasReceiptAd
        receiptAd {
          ...AssetListItem
          createdBy {
            ...UserListItem
          }
        }
      }
    }
  }
  ${AssetListItemFragmentDoc}
  ${UserListItemFragmentDoc}
`;

gql`
  mutation ResetRetailerPalette($retailerId: Int!) {
    resetRetailerPalette(input: { retailerId: $retailerId }) {
      retailer {
        id
        retailerId
        palette {
          ...RetailerColorPaletteFull
        }
      }
    }
  }
  ${RetailerColorPaletteFullFragmentDoc}
`;

const RetailerBrandingCard: React.FC<Props> = ({ retailerId }) => {
  const intl = useIntl();
  const message = useMessageApi();

  const { canEditRetailer } = useRetailerPermissions();

  const { data, loading } = useGetRetailerBrandingQuery({
    variables: {
      retailerId: retailerId || -1,
    },
    skip: retailerId === undefined,
    fetchPolicy: 'cache-and-network',
  });

  const [requestLogoUpload] = useRequestRetailerLogoUploadMutation();
  const [confirmLogoUpload] = useConfirmRetailerLogoUploadMutation();
  const [removeLogo] = useRemoveRetailerLogoMutation();

  const [requestReceiptAdUpload] = useRequestRetailerReceiptAdUploadMutation();
  const [confirmReceiptAdUpload] = useConfirmRetailerReceiptAdUploadMutation();
  const [removeReceiptAd] = useRemoveRetailerReceiptAdMutation();

  const { flushPendingRetailerChanges, addPendingRetailerChange } = useRetailerDeployTools();
  useOnUnmount(() => {
    flushPendingRetailerChanges();
  });

  const handleRemoveLogo = async () => {
    try {
      if (!retailerId) return;
      message.loading({
        key: 'removeLogo',
        content: intl.formatMessage(messages.removingLogo),
      });

      await removeLogo({
        variables: {
          retailerId,
        },
      });

      addPendingRetailerChange(retailerId);

      message.success({
        key: 'removeLogo',
        content: intl.formatMessage(messages.removeLogoSuccess),
      });
    } catch (err: unknown) {
      message.error({
        key: 'removeLogo',
        content: getFriendlyApolloErrorMessage(err, intl.formatMessage(messages.removeLogoError)),
      });
    }
  };

  const handleRemoveAd = async () => {
    try {
      if (!retailerId) return;
      message.loading({
        key: 'removeAd',
        content: intl.formatMessage(messages.removingAd),
      });
      await removeReceiptAd({
        variables: {
          retailerId,
        },
      });
      addPendingRetailerChange(retailerId);
      message.success({
        key: 'removeAd',
        content: intl.formatMessage(messages.removeAdSuccess),
      });
    } catch (err: unknown) {
      message.error({
        key: 'removeAd',
        content: getFriendlyApolloErrorMessage(err, intl.formatMessage(messages.removeAdError)),
      });
    }
  };

  const upload = useAssetUploadHandler();

  const handleUploadLogo = async (options: RcCustomRequestOptions) => {
    await upload({
      options,
      getUploadAccessToken: async (file) => {
        if (!retailerId) return;
        const { data } = await requestLogoUpload({
          variables: {
            input: {
              retailerId,
              file: {
                filename: file.name,
                tags: [],
                byteSize: file.size,
                contentType: file.type,
              },
            },
          },
        });
        return data?.createRetailerLogoUploadToken.assetUploadToken;
      },
      confirmUpload: async (assetId) => {
        if (!retailerId) return;
        const result = await confirmLogoUpload({
          variables: {
            input: {
              retailerId,
              assetId,
            },
          },
        });
        addPendingRetailerChange(retailerId);

        return result.data?.setRetailerLogoUploadCompleted.retailer?.logo?.url;
      },
      showProgress: true,
    });
  };

  const handleUploadAd = async (options: RcCustomRequestOptions) => {
    await upload({
      options,
      getUploadAccessToken: async (file) => {
        if (!retailerId) return;
        const { data } = await requestReceiptAdUpload({
          variables: {
            input: {
              retailerId,
              file: {
                filename: file.name,
                tags: [],
                byteSize: file.size,
                contentType: file.type,
              },
            },
          },
        });
        return data?.createRetailerReceiptAdUploadToken.assetUploadToken;
      },
      confirmUpload: async (assetId) => {
        if (!retailerId) return;
        const result = await confirmReceiptAdUpload({
          variables: {
            input: {
              retailerId,
              assetId,
            },
          },
        });
        addPendingRetailerChange(retailerId);

        return result.data?.setRetailerReceiptAdUploadCompleted.retailer?.receiptAd?.url;
      },
      showProgress: true,
    });
  };

  const [updateColor1, { loading: savingColor1 }] = useUpdateRetailerColor1Mutation();
  const [updateColor2, { loading: savingColor2 }] = useUpdateRetailerColor2Mutation();
  const [updateColor3, { loading: savingColor3 }] = useUpdateRetailerColor3Mutation();

  const handleChangeColor1 = async (color: string) => {
    if (!retailerId) return;
    if (!data || !data.retailer) return;
    await updateColor1({
      variables: {
        input: {
          retailerId: retailerId,
          color,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateRetailerColor1: {
          __typename: 'UpdateRetailerColor1Payload',
          retailer: {
            palette: {
              __typename: 'RetailerColorPalette',
              id: data.retailer.palette.id,
              color1: color,
              color2: data.retailer.palette.color2,
              color3: data.retailer.palette.color3,
              color1Default: data.retailer.palette.color1Default,
              color2Default: data.retailer.palette.color2Default,
              color3Default: data.retailer.palette.color3Default,
            },
            id: data.retailer.id,
            retailerId: data.retailer.retailerId,
            __typename: 'Retailer',
          },
        },
      },
    });
    addPendingRetailerChange(retailerId);
  };

  const handleChangeColor2 = async (color: string) => {
    if (!retailerId) return;
    if (!data || !data.retailer) return;
    await updateColor2({
      variables: {
        input: {
          retailerId: retailerId,
          color,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateRetailerColor2: {
          __typename: 'UpdateRetailerColor2Payload',
          retailer: {
            palette: {
              __typename: 'RetailerColorPalette',
              id: data.retailer.palette.id,
              color1: data.retailer.palette.color1,
              color2: color,
              color3: data.retailer.palette.color3,
              color1Default: data.retailer.palette.color1Default,
              color2Default: data.retailer.palette.color2Default,
              color3Default: data.retailer.palette.color3Default,
            },
            id: data.retailer.id,
            retailerId: data.retailer.retailerId,
            __typename: 'Retailer',
          },
        },
      },
    });
    addPendingRetailerChange(retailerId);
  };

  const handleChangeColor3 = async (color: string) => {
    if (!retailerId) return;
    if (!data || !data.retailer) return;
    await updateColor3({
      variables: {
        input: {
          retailerId: retailerId,
          color,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateRetailerColor3: {
          __typename: 'UpdateRetailerColor3Payload',
          retailer: {
            palette: {
              __typename: 'RetailerColorPalette',
              id: data.retailer.palette.id,
              color1: data.retailer.palette.color1,
              color2: data.retailer.palette.color2,
              color3: color,
              color1Default: data.retailer.palette.color1Default,
              color2Default: data.retailer.palette.color2Default,
              color3Default: data.retailer.palette.color3Default,
            },
            id: data.retailer.id,
            retailerId: data.retailer.retailerId,
            __typename: 'Retailer',
          },
        },
      },
    });
    addPendingRetailerChange(retailerId);
  };

  const [resetPalette, { loading: resettingPalette }] = useResetRetailerPaletteMutation();
  const handleResetPalette = async () => {
    if (!data || !data.retailer || !retailerId) return;

    try {
      message.loading({
        key: 'resetPalette',
        content: intl.formatMessage(messages.resetPaletteLoading),
      });
      await resetPalette({
        variables: {
          retailerId,
        },
      });
      addPendingRetailerChange(retailerId);

      message.success({
        key: 'resetPalette',
        content: intl.formatMessage(messages.resetPaletteSuccess),
      });
    } catch (err: unknown) {
      message.error({
        key: 'resetPalette',
        content: getFriendlyApolloErrorMessage(err, intl.formatMessage(messages.resetPaletteError)),
      });
    }
  };

  const logoFormItem = (
    <FormItem label={intl.formatMessage(messages.logo)} style={{ marginRight: '16px' }}>
      <UploadImage
        asset={data?.retailer.logo}
        handleUpload={handleUploadLogo}
        loading={loading}
        onDelete={handleRemoveLogo}
      />
    </FormItem>
  );

  const paletteFormItem = (
    <FormItem label={'Palette'}>
      <Row gutter={[16, 16]}>
        <Col>
          <Space>
            <ColorPicker
              value={data?.retailer.palette.color1 || data?.retailer.palette.color1Default}
              loading={loading || savingColor1 || resettingPalette}
              onChange={handleChangeColor1}
            />
            <ColorPicker
              value={data?.retailer.palette.color2 || data?.retailer.palette.color2Default}
              loading={loading || savingColor2 || resettingPalette}
              onChange={handleChangeColor2}
            />
            <ColorPicker
              value={data?.retailer.palette.color3 || data?.retailer.palette.color3Default}
              loading={loading || savingColor3 || resettingPalette}
              onChange={handleChangeColor3}
            />
          </Space>
        </Col>
        <Col>
          <Button
            type={'text'}
            size={'middle'}
            onClick={handleResetPalette}
            disabled={
              loading ||
              savingColor1 ||
              savingColor2 ||
              savingColor3 ||
              resettingPalette ||
              !canEditRetailer
            }
          >
            {intl.formatMessage(messages.resetPalette)}
          </Button>
        </Col>
      </Row>
    </FormItem>
  );

  const adFormItem = (
    <FormItem label={intl.formatMessage(messages.receiptAd)}>
      <UploadImage
        asset={data?.retailer.receiptAd}
        handleUpload={handleUploadAd}
        loading={loading}
        onDelete={handleRemoveAd}
      />
    </FormItem>
  );

  return (
    <Card title={'Branding'}>
      <Form layout={'vertical'} disabled={!canEditRetailer}>
        <Space direction={'vertical'} style={{ width: '100%' }}>
          <Row gutter={[16, 16]} wrap={true}>
            <Col
              span={24}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
              }}
            >
              {logoFormItem}
              {adFormItem}
            </Col>
            <Col span={24}>{paletteFormItem}</Col>
          </Row>
        </Space>
      </Form>
    </Card>
  );
};

export default RetailerBrandingCard;
