import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { TicketSeverity } from 'generated/types';
import { FC } from 'react';
import { Avatar } from 'antd';
import severityColors from 'components/ticket/TicketSeverity/severityColors';

const Circle = styled(Avatar)<{ severity: TicketSeverity }>`
  &&& {
    ${severityColors}
  }
`;

const MiniCircle = styled.div<{ severity: TicketSeverity }>`
  &&& {
    ${severityColors}
  }
  margin-right: 2px;
  width: 18px;
  height: 18px;
  font-size: 0.8em;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

interface Props {
  severity: TicketSeverity;
  size?: 'small' | 'tiny';
  style?: CSSProperties;
}

const TicketSeverityCircle: FC<Props> = ({ severity, size, style }) => {
  return size === 'tiny' ? (
    <MiniCircle severity={severity} style={style}>
      {severity}
    </MiniCircle>
  ) : (
    <Circle severity={severity} size={'small'} style={style}>
      {severity}
    </Circle>
  );
};

export default TicketSeverityCircle;
