import React from 'react';
import styled from 'styled-components';

interface Props {
  text: string;
}

const InfoText = styled.span`
  pointer-events: none;
  user-select: none;
  color: #fff;
  -webkit-text-stroke: 4px white;
  position: relative;
  white-space: nowrap;
`;

const InfoTextOverlay = styled.span`
  pointer-events: none;
  user-select: none;
  -webkit-text-stroke: 0 white;
  position: absolute;
  color: #333333;
  top: -2px;
  left: 0;
  right: 0;
  bottom: 0;
  white-space: nowrap;
`;

const MapText: React.FC<Props> = (props) => {
  const { text } = props;

  return (
    <InfoText>
      {text}
      <InfoTextOverlay>{text}</InfoTextOverlay>
    </InfoText>
  );
};

export default MapText;
