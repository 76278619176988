import React, { SVGProps } from 'react';

const GasContainer247Illustration: React.FC<SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.64 62 77 56.04V27.09L20.64 19m0 43V19m0 43-10.326-4.257M20.64 19l-10.326 5.483m0 33.26L3 54.763V28.365l7.314-3.883m0 33.26v-33.26m23.233 2.14-7.314-.811V56.04l7.314-.61m0-28.806V55.43m0-28.807 8.174.907m-8.174 27.9 8.174-.68m0-27.22v27.22m0-27.22 7.529.835M41.72 54.75l7.53-.626m0-25.758v25.758m0-25.758 7.672.852M49.25 54.124l7.672-.639m0-24.267v24.267m0-24.267 7.673.851m-7.672 23.416 7.672-.639m0-22.777 7.672.852v21.287l-7.672.639m0-22.778v22.777"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export default GasContainer247Illustration;
