import React, { Suspense } from 'react';
import { useAppSelector } from 'redux/store';
import { IntlProvider } from 'react-intl';
import { ConfigProvider, App as AntApp } from 'antd';
import { DEFAULT_LOCALE, selectAllMessages, selectCurrentLocale } from 'i18n/i18nSlice';
import useAntLocale from 'i18n/useAntLocale';
import LoadingPage from 'pages/util/LoadingPage/LoadingPage';
import { useAuthSync } from 'auth/useAuthSync';
import 'antd/dist/reset.css';
import useAntThemeConfig from 'themes/useAntThemeConfig';
import StyledComponentsThemeProvider from 'themes/StyledComponentsThemeProvider';
import { RouterProvider } from 'react-router-dom';
import router from 'router';
import useTranslationErrorHandler from 'i18n/useTranslationErrorHandler.ts';
import ReloadPrompt from 'pwa/ReloadPrompt.tsx';
import { createGlobalStyle } from 'styled-components';

const GlobalBackgroundFromThemeStyle = createGlobalStyle`
  body {
    background: ${(props) => props.theme.ant.colorBgBase};
  }
`;

export default function App() {
  useAuthSync();
  const antThemeConfig = useAntThemeConfig();
  const { locale: antLocale } = useAntLocale();
  const currentLocale = useAppSelector(selectCurrentLocale);
  const translatedMessages = useAppSelector(selectAllMessages);
  const { handleTranslationError } = useTranslationErrorHandler();

  return (
    <IntlProvider
      locale={currentLocale}
      defaultLocale={DEFAULT_LOCALE}
      messages={translatedMessages}
      onError={handleTranslationError}
    >
      <ConfigProvider locale={antLocale} theme={antThemeConfig}>
        <StyledComponentsThemeProvider>
          <GlobalBackgroundFromThemeStyle />
          <AntApp>
            <Suspense fallback={<LoadingPage />}>
              <RouterProvider router={router} />
              <ReloadPrompt />
            </Suspense>
          </AntApp>
        </StyledComponentsThemeProvider>
      </ConfigProvider>
    </IntlProvider>
  );
}
