import { Breakpoint } from 'antd/es/_util/responsiveObserver';
import React from 'react';

export interface ScreenInfo {
  isMobile: boolean;
  isDesktop: boolean;
  screenMap: Record<Breakpoint, boolean>;
}

export const ResponsiveContext = React.createContext<ScreenInfo | undefined>(
  undefined
);
