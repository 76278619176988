import checkAccessToken from 'auth/checkAccessToken';
import { AccessToken } from 'api/api';
import refreshToken from 'auth/refreshToken.ts';

/***
 * Get access token from cache / local storage. Refresh if needed.
 * NOTE: skipRefresh is used to prevent infinite loops when refreshing, and is only set to true when we are actually refreshing (need it to send the anti-forgery token)
 */
async function getAccessToken(
  requestedBy: string,
  options?: {
    skipRefresh: boolean;
  }
): Promise<AccessToken | undefined> {
  const check = checkAccessToken();

  if (check.hasValidAccessToken && check.localData && !check.willExpireSoon) {
    return check.localData.accessToken;
  }

  if (check.hasValidAccessToken && check.localData && check.willExpireSoon) {
    if (!options?.skipRefresh) {
      // console.log('🍏 optimistic refresh', requestedBy);
      // NOTE: This is not awaited, we return the still valid token and let the refresh happen in the background
      refreshToken(requestedBy).catch((err) => console.log('🚨 optimistic refresh failed', err));
    }

    return check.localData.accessToken;
  }

  if (options?.skipRefresh) {
    // console.log('⛷ skip refresh, return expired access token', requestedBy);
    return check.localData?.accessToken;
  }

  if (check.hasRefreshFlagCookie && check.hasAccessToken) {
    // console.log('🥝 need to refresh', requestedBy);
    try {
      return await refreshToken(requestedBy);
    } catch (err) {
      // console.log('🚨 refresh failed, returning undefined', err);
      return undefined;
    }
  }
  return undefined;
}

export default getAccessToken;
