import React, { useEffect, useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import useQueryParam from 'hooks/useQueryParam.ts';
import MachinePropertyListItem from 'components/machine/properties/MachinePropertyList/MachinePropertyListItem.tsx';
import { groupBy } from 'lodash-es';
import { Col, Empty, Input, List, Row, Spin } from 'antd';
import styled from 'styled-components';
import { SearchOutlined } from '@ant-design/icons';
import { useDebouncedCallback } from 'use-debounce';
import ResponsiveListCard from 'components/lib/List/ResponsiveListCard.tsx';
import commonMessages from 'components/i18n/commonMessages.ts';
import { useScreenInfo } from 'layouts/responsive/useScreenInfo.ts';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import {
  BooleanPropertyValueFullFragmentDoc,
  ColorPropertyValueFullFragmentDoc,
  JsonPropertyValueFullFragmentDoc,
  NumberPropertyValueFullFragmentDoc,
  TextLinesPropertyValueFullFragmentDoc,
  TextPropertyValueFullFragmentDoc,
  useGetMachinePropertiesQuery,
} from 'generated/types.tsx';

const groupNamePrefixI18n = 'MachinePropertiesCard_groupName.';

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 200px;
`;

interface Props {
  machineId?: number;
}

gql`
  query GetMachineProperties($machineId: Int!, $filter: MachinePropertiesInput!) {
    machine(machineId: $machineId) {
      id
      machineId
      properties(filter: $filter) {
        ...MachinePropertyWithValue
        value {
          ... on JsonPropertyValue {
            ...JsonPropertyValueFull
          }
          ... on TextPropertyValue {
            ...TextPropertyValueFull
          }
          ... on BooleanPropertyValue {
            ...BooleanPropertyValueFull
          }
          ... on NumberPropertyValue {
            ...NumberPropertyValueFull
          }
          ... on TextLinesPropertyValue {
            ...TextLinesPropertyValueFull
          }
          ... on ColorPropertyValue {
            ...ColorPropertyValueFull
          }
        }
      }
    }
  }
  ${NumberPropertyValueFullFragmentDoc}
  ${BooleanPropertyValueFullFragmentDoc}
  ${JsonPropertyValueFullFragmentDoc}
  ${TextPropertyValueFullFragmentDoc}
  ${TextLinesPropertyValueFullFragmentDoc}
  ${ColorPropertyValueFullFragmentDoc}
`;

interface MachinePropertiesFilter {
  includeEmpty: boolean;
  search: string;
}

const defaultFilter: MachinePropertiesFilter = {
  search: '',
  includeEmpty: false,
};

const MACHINE_PROPERTY_FILTER_KEY = 'mpf';

function useMachinePropertyFilter() {
  const [filterParam, setFilterParam] = useQueryParam<MachinePropertiesFilter>(
    MACHINE_PROPERTY_FILTER_KEY
  );

  const machineFilter = filterParam || defaultFilter;
  const setMachineFilter = (value: Partial<MachinePropertiesFilter>) => {
    setFilterParam({
      ...defaultFilter,
      ...filterParam,
      ...value,
    });
  };

  return { machineFilter, setMachineFilter };
}

const MachinePropertyList: React.FC<Props> = ({ machineId }) => {
  const intl = useConnectIntl();
  const { screenMap } = useScreenInfo();
  const { machineFilter, setMachineFilter } = useMachinePropertyFilter();
  const { data, loading } = useGetMachinePropertiesQuery({
    variables: {
      machineId: machineId || 0,
      filter: {
        search: machineFilter.search,
      },
    },
    skip: machineId === undefined,
  });

  useEffect(() => {
    setSearch(machineFilter.search);
  }, [machineFilter.search]);

  const numberOfWantedColumns = screenMap.xl ? 2 : 1;
  const { groupKeyByColumns, grouped } = useMemo(() => {
    const items = data?.machine.properties || [];
    const grouped = groupBy(items, 'group');
    const sortedGroupKeys = Object.keys(grouped).sort((a, b) => a.localeCompare(b));
    const groupKeyByColumns: string[][] = [];

    sortedGroupKeys.forEach((key, index) => {
      const columnIndex = index % numberOfWantedColumns;

      if (!groupKeyByColumns[columnIndex]) {
        groupKeyByColumns[columnIndex] = [];
      }
      groupKeyByColumns[columnIndex].push(key);
    });
    return {
      groupKeyByColumns,
      grouped,
    };
  }, [data?.machine.properties, numberOfWantedColumns]);

  const [search, setSearch] = useState('');
  const handleSearch = (value: string) => setSearch(value);

  const handleSearchDebounced = useDebouncedCallback((value: string) => {
    setMachineFilter({
      search: value,
    });
  }, 400);

  const numberOfColumns = Object.keys(groupKeyByColumns).length;
  const columnsPerCard = 24 / numberOfColumns;
  const isEmpty = !loading && grouped && Object.keys(grouped).length === 0;

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }} wrap={true}>
        <Col xs={24} sm={{ span: 8, offset: 16 }}>
          <Input
            placeholder={'Filter...'}
            size={'middle'}
            prefix={<SearchOutlined />}
            value={search}
            onChange={(e) => {
              handleSearch(e.target.value);
              handleSearchDebounced(e.target.value);
            }}
            allowClear={true}
          />
        </Col>
      </Row>

      {loading && (
        <Box>
          <Spin />
          <div>{intl.formatMsg(commonMessages.loading)}</div>
        </Box>
      )}

      {!loading && isEmpty && (
        <Box>
          <Empty />
        </Box>
      )}

      {!loading && (
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          {groupKeyByColumns.map((groupKeys, columnIndex) => (
            <Col xs={24} sm={columnsPerCard} key={columnIndex}>
              <Row gutter={[16, 16]}>
                {groupKeys.map((groupName) => (
                  <Col xs={24} key={groupName}>
                    <ResponsiveListCard
                      title={intl.formatMsg({
                        id: `${groupNamePrefixI18n}${groupName}`,
                      })}
                    >
                      <List
                        bordered={false}
                        size={'default'}
                        dataSource={grouped[groupName]}
                        renderItem={(item) => (
                          <List.Item>
                            <MachinePropertyListItem property={item} />
                          </List.Item>
                        )}
                      />
                    </ResponsiveListCard>
                  </Col>
                ))}
              </Row>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

export default MachinePropertyList;
