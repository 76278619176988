import React from 'react';
import styled from 'styled-components';
import Spinner from 'components/lib/Spinner/Spinner.tsx';

const SuspenseContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

/***
 * A default fallback component for Suspense
 * @constructor
 */
const Fallback: React.FC = () => (
  <SuspenseContainer>
    <Spinner delayUntil={600} />
  </SuspenseContainer>
);

export default Fallback;
