import React, { useEffect, useRef } from 'react';
import { TicketListItemFragment, TicketStatus } from 'generated/types';
import { List } from 'antd';
import { parseISO } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import useDateFormatTools from 'i18n/useDateFormatTools';
import TicketSeverityTag from 'components/ticket/TicketSeverity/TicketSeverityTag';
import MachineLink from 'components/machine/MachineLink/MachineLink';
import RetailerLink from 'components/retailer/RetailerLink/RetailerLink';
import styled from 'styled-components';
import useFormatTools from 'i18n/useFormatTools';
import useMatrixNav from 'layouts/matrix/useMatrixNav';
import useTicketFilter from 'components/ticket/TicketFilter/useTicketFilter';
import AssignedToUser from 'components/ticket/AssignedToUser/AssignedToUser.tsx';
import TicketDueDateStatusText from 'components/ticket/TicketDueDate/TicketDueDateStatusText.tsx';
import TicketDueDateResolved from 'components/ticket/TicketDueDate/TicketDueDateResolved.tsx';
import TicketStatusIcon from 'components/ticket/TicketStatusIcon/TicketStatusIcon.tsx';
import TicketTitle from 'components/ticket/TicketTitle/TicketTitle.tsx';
import { useHoverIntent } from 'react-use-hoverintent';

const RightGrid = styled.div`
  flex: 0 0 40%;
  display: grid;
  grid-template-columns: minmax(0, 2fr) minmax(0, 3fr) 90px;
  grid-gap: 16px;
`;

const DueContainer = styled.div`
  order: 2;
  @media (min-width: 480px) {
    order: 1;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const AssignedContainer = styled.div`
  order: 1;
  @media (min-width: 480px) {
    order: 2;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
`;

const SeverityContainer = styled.div`
  order: 3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LinkGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  grid-gap: 8px;
`;

interface Props {
  ticket: TicketListItemFragment;
  hideRetailerLink?: boolean;
  hideMachineLink?: boolean;
  appendSerialNumber: boolean;
  onHoverIntent?: () => void;
}

const TicketListItem: React.FC<Props> = (props) => {
  const { ticket, appendSerialNumber, hideMachineLink, hideRetailerLink, onHoverIntent } = props;
  const { formatDate } = useDateFormatTools();
  const { machineId, serialNo, retailerId } = useMatrixNav();
  const { formatUserName } = useFormatTools();
  const { setTicketFilter } = useTicketFilter();

  const handleClickType = (ticketTypeId: number) => {
    setTicketFilter({
      ticketTypeIds: [ticketTypeId],
    });
  };

  const handleClickTag = (tag: string) => {
    setTicketFilter({
      tags: [tag],
    });
  };

  const [isHovering, intentRef] = useHoverIntent();
  const prevIsHoveringRef = useRef(false);
  useEffect(() => {
    if (isHovering && !prevIsHoveringRef.current) {
      onHoverIntent?.();
    }
    prevIsHoveringRef.current = isHovering;
  }, [isHovering, onHoverIntent, ticket.ticketId]);

  return (
    <List.Item ref={intentRef as React.RefObject<HTMLDivElement>}>
      <List.Item.Meta
        avatar={<TicketStatusIcon status={ticket.status} showText={false} />}
        title={
          <TicketTitle
            ticket={ticket}
            showTags={true}
            onClickType={handleClickType}
            onClickTag={handleClickTag}
          />
        }
        description={
          <MainContainer>
            <div>
              {ticket.status === TicketStatus.Open && (
                <FormattedMessage
                  id={'ticket_list_item.subtitle_opened_by'}
                  defaultMessage={'Opened by {name} {date}'}
                  values={{
                    id: ticket.ticketId,
                    name: formatUserName(ticket.openedBy),
                    date: formatDate(parseISO(ticket.opened), {
                      representation: 'complete',
                      formatTime: 'HH:mm',
                    }),
                  }}
                />
              )}
              {ticket.status === TicketStatus.Closed && ticket.closed && (
                <FormattedMessage
                  id={'ticket_list_item.subtitle_closed_by'}
                  defaultMessage={'Closed by {name} {date}'}
                  values={{
                    id: ticket.ticketId,
                    name: formatUserName(ticket.closedBy),
                    date: formatDate(parseISO(ticket.closed), {
                      representation: 'complete',
                      formatTime: 'HH:mm',
                    }),
                  }}
                />
              )}
            </div>
            <LinkGridContainer>
              {ticket.machine && !hideMachineLink && (
                <MachineLink
                  machine={ticket.machine}
                  appendSerialNumber={appendSerialNumber}
                  ticketSerialNo={ticket.serialNo || undefined}
                  scopeMachineId={machineId}
                  scopeSerialNo={serialNo}
                  allowWrap={true}
                />
              )}
              {ticket.retailer && !hideRetailerLink && (
                <RetailerLink
                  retailer={ticket.retailer}
                  allowWrap={true}
                  scopeRetailerId={retailerId}
                />
              )}
            </LinkGridContainer>
          </MainContainer>
        }
      />
      <RightGrid>
        <DueContainer>
          {ticket.status === TicketStatus.Open && (
            <TicketDueDateStatusText date={ticket.due ? parseISO(ticket.due) : undefined} />
          )}
          {ticket.status === TicketStatus.Closed && <TicketDueDateResolved />}
        </DueContainer>
        <AssignedContainer>
          <AssignedToUser assignedToUser={ticket.assignedTo || undefined} />
        </AssignedContainer>
        <SeverityContainer>
          <TicketSeverityTag severity={ticket.severity} />
        </SeverityContainer>
      </RightGrid>
    </List.Item>
  );
};

export default TicketListItem;
