import React, { useCallback } from 'react';
import { useGetTicketListItemQuery, useSetTicketTypeMutation } from 'generated/types';
import { useIntl } from 'react-intl';
import TicketTypeSelect from 'components/ticket/TicketType/TicketTypeSelect';
import useMessageApi from 'components/global/useMessageApi';

interface Props {
  ticketId?: number;
}

const TicketTypeSelectConnected: React.FC<Props> = (props) => {
  const { ticketId } = props;
  const intl = useIntl();
  const message = useMessageApi();

  const [setTicketType, { loading: saving }] = useSetTicketTypeMutation({
    notifyOnNetworkStatusChange: true,
  });

  const { data } = useGetTicketListItemQuery({
    variables: ticketId
      ? {
          ticketId,
        }
      : undefined,
    skip: !ticketId,
  });

  const handleSelect = useCallback(
    async (value: number) => {
      try {
        message.loading({
          key: 'set_ticket_type',
          content: intl.formatMessage({
            id: 'ticket_type_select_connected.changing_ticket_type',
            defaultMessage: 'Changing ticket type...',
          }),
        });
        if (ticketId === undefined) {
          return;
        }

        await setTicketType({
          variables: {
            input: {
              ticketId,
              ticketTypeId: value,
            },
          },
        });
        message.success({
          key: 'set_ticket_type',
          content: intl.formatMessage({
            id: 'ticket_type_select_connected.ticket_type_changed',
            defaultMessage: 'Ticket type changed',
          }),
        });
      } catch (err) {
        message.error({
          key: 'set_ticket_type',
          content: intl.formatMessage({
            id: 'ticket_type_select_connected.ticket_type_change_fail',
            defaultMessage: 'Could not set ticket type',
          }),
        });
      }
    },
    [ticketId, intl, setTicketType, message]
  );

  return (
    <TicketTypeSelect
      machineType={data?.ticket.machine.machineType}
      loading={saving || ticketId === undefined}
      value={data?.ticket.ticketTypeId}
      onChange={handleSelect}
    />
  );
};

export default TicketTypeSelectConnected;
