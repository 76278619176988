import { IntlShape } from 'react-intl/src/types';
import { MessageDescriptor, useIntl } from 'react-intl';
import {
  PrimitiveType,
  FormatXMLElementFn,
  Options as IntlMessageFormatOptions,
} from 'intl-messageformat';
import { useAppSelector } from 'redux/store.ts';
import { selectAllFallbackMessages } from 'i18n/i18nSlice.ts';
import * as React from 'react';

interface ConnectIntlShape<TBase> extends IntlShape {
  /***
   * Vendanor edition of formatMessage
   * Format a message with a custom default message fallback fetched from the backend
   * @param descriptor
   * @param values
   * @param opts
   */
  formatMsg(
    descriptor: MessageDescriptor,
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
    opts?: IntlMessageFormatOptions
  ): string;
  formatMsg<T extends TBase>(
    descriptor: MessageDescriptor,
    values?: Record<string, PrimitiveType | T | FormatXMLElementFn<T>>,
    opts?: IntlMessageFormatOptions
  ): string | T | (T | string)[];
}

/***
 * Hook to return intl with custom formatMessage
 */
function useConnectIntl(): ConnectIntlShape<React.ReactNode> {
  const intl = useIntl();
  const defaultMessages = useAppSelector(selectAllFallbackMessages);
  const formatMsg = (
    descriptor: MessageDescriptor,
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
    opts?: IntlMessageFormatOptions
  ): string => {
    const messageDescriptorWithCustomFallback: MessageDescriptor = {
      ...descriptor,
    };

    // NOTE: We only want to use the default message from the server if the frontend doesn't have a default message!
    if (
      descriptor.id &&
      messageDescriptorWithCustomFallback.defaultMessage === undefined
    ) {
      messageDescriptorWithCustomFallback.defaultMessage =
        defaultMessages[descriptor.id];
    }

    return intl.formatMessage(
      messageDescriptorWithCustomFallback,
      values,
      opts
    );
  };

  return {
    ...intl,
    formatMsg,
  };
}

export default useConnectIntl;
