import React, { useCallback, useMemo } from 'react';
import { Select } from 'antd';
import { gql } from '@apollo/client/core';
import {
  useGetAssignableUsersQuery,
  UserListItemFragment,
  UserListItemFragmentDoc,
} from 'generated/types';
import { SelectProps } from 'antd/es/select';
import useTicketFilter, {
  messages,
  TicketFilter,
} from 'components/ticket/TicketFilter/useTicketFilter';
import useConnectIntl from 'i18n/useConnectIntl.ts';

gql`
  query GetAssignableUsers {
    allTicketAssignableUsers {
      ...UserListItem
    }
  }
  ${UserListItemFragmentDoc}
`;

type OtherSelectProps = Omit<SelectProps, 'value' | 'onSelect' | 'onChange' | 'options'>;

const TicketAssigneeFilterSelect: React.FC<OtherSelectProps> = (props) => {
  const intl = useConnectIntl();
  const { ticketFilter, setTicketFilter } = useTicketFilter();

  const { data, loading } = useGetAssignableUsersQuery({
    notifyOnNetworkStatusChange: true,
  });

  const userDictionary = useMemo(() => {
    const dictionary: Record<string, UserListItemFragment> = {};
    data?.allTicketAssignableUsers.forEach((user) => {
      dictionary[user.userId] = user;
    });
    return dictionary;
  }, [data?.allTicketAssignableUsers]);

  const selectedValue = useMemo(() => {
    return [
      ...(ticketFilter.assignedToUserIds?.map((c) => c.toString()) ?? []),
      ...(ticketFilter.includeUnassignedUsers ? ['unassigned'] : []),
    ];
  }, [ticketFilter.assignedToUserIds, ticketFilter.includeUnassignedUsers]);

  const getFilterFromValue = (
    value: string[]
  ): Pick<TicketFilter, 'assignedToUserIds' | 'includeUnassignedUsers'> => {
    const includeUnassignedUsers = value.length === 0 ? undefined : value.includes('unassigned');
    const legalUserIdValues = value.filter((v) => v !== 'unassigned');
    const result = {
      assignedToUserIds:
        legalUserIdValues.length > 0 ? legalUserIdValues : includeUnassignedUsers ? [] : undefined,
      includeUnassignedUsers: includeUnassignedUsers,
    };
    console.log('result', result);
    return result;
  };

  const handleSelectAssignee = useCallback(
    async (value: string) => {
      const nextSelectedValue = [...selectedValue, value];
      const nextFilter = getFilterFromValue(nextSelectedValue);
      setTicketFilter(nextFilter);
    },
    [selectedValue, setTicketFilter]
  );

  const handleDeselectAssignee = useCallback(
    async (value: string) => {
      const nextSelectedValue = selectedValue.filter((v) => v !== value);
      const nextFilter = getFilterFromValue(nextSelectedValue);
      setTicketFilter(nextFilter);
    },
    [selectedValue, setTicketFilter]
  );

  const handleClear = () => {
    const nextFilter = getFilterFromValue([]);
    setTicketFilter(nextFilter);
  };

  const items = useMemo(() => {
    const list = data?.allTicketAssignableUsers.map((user) => {
      return {
        value: user.userId.toString(),
        label: user.name,
      };
    });
    list?.unshift({
      value: 'unassigned',
      label: intl.formatMessage(messages.unassigned),
    });
    return list;
  }, [data?.allTicketAssignableUsers, intl]);

  return (
    <Select<string[]>
      placeholder={intl.formatMessage({
        id: 'ticket_assignee_filter_select.placeholder',
        defaultMessage: 'Assignee',
      })}
      showSearch={true}
      mode={'multiple'}
      loading={loading}
      disabled={loading}
      style={{ minWidth: 150 }}
      popupMatchSelectWidth={false}
      value={selectedValue}
      allowClear={true}
      onSelect={handleSelectAssignee}
      onDeselect={handleDeselectAssignee}
      onClear={handleClear}
      filterOption={(input, option) => {
        if (
          option?.label &&
          option.label.toString().toLowerCase().indexOf(input.toLowerCase()) !== -1
        )
          return true;

        if (
          option &&
          option.value !== 'unassigned' &&
          userDictionary[option.value as string].isMe &&
          input.toLowerCase() === 'me'
        )
          return true;

        return false;
      }}
      options={items}
      optionRender={(option) => {
        if (option.value === 'unassigned') {
          return <i>{option.label}</i>;
        }

        const user = userDictionary[option.value as string];
        return user.isMe ? <b>{user.name}</b> : user.name;
      }}
      {...props}
    />
  );
};

export default TicketAssigneeFilterSelect;
