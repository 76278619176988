import { AssetListItemFragment } from 'generated/types';
import { UploadFile } from 'antd/es/upload/interface';
import { VnFile } from 'components/lib/upload/VnFile';

/***
 * Helper to map GraphQL asset to ant.design asset
 * @param asset the asset coming from GraphQL API
 * @returns the asset in ant.design format
 */
export const mapAsset = (asset: AssetListItemFragment): UploadFile<VnFile> => ({
  fileName: asset.filename || undefined,
  name: asset.filename || 'file',
  uid: asset.assetId, // <== the pure GUID id, use to remove etc.
  size: asset.byteSize,
  type: asset.contentType || undefined,
  url: asset.url || undefined,
  thumbUrl: asset.url || undefined, // TODO: we could optimize using thumbnails?
  status: 'done',
  response: {
    url: asset.url || undefined,
    assetId: asset.assetId,
  },
});
