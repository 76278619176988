import { SelectOptionData } from 'components/lib/Select/selectTypes';
import useEnumOptions, { EnumOptionsArgs } from 'i18n/useEnumOptions';
import { useMemo } from 'react';

/***
 * Helper to convert TypeScript enum to ant dropdown Option array
 * @param options
 */
export function useEnumSelectOptions<TEnum extends string | number>(
  options: EnumOptionsArgs<TEnum>
): SelectOptionData<TEnum>[] {
  const items = useEnumOptions(options);
  return useMemo(() => {
    return items.map<SelectOptionData<TEnum>>((item) => {
      return {
        label: item.label,
        key: item.translationKey,
        value: item.rawValue,
        data: item.typedValue,
      };
    });
  }, [items]);
}
