import React, { useCallback, useState } from 'react';
import { useEditorEvent, useHelpers, useKeymap, useRemirrorContext } from '@remirror/react';
import { EditorState } from '@remirror/pm';
import { useDebouncedCallback } from 'use-debounce';
import { areStatesEqual, isDocNodeEmpty, RemirrorJSON } from 'remirror';

type SaveTrigger = 'shortcut' | 'blur' | 'inactive';

export interface PreparedDocument {
  remirrorJson: RemirrorJSON; // object, use for local storage etc.
  rawJson: string; // raw json string, send to server
  rawHtml: string; // raw html string, send to server
  state: EditorState; // can use this to persist actual editor state, like cursor position, selected text, etc.
  trigger: SaveTrigger; // what triggered the save event?
}

export type OnAutoSaveCallback = (data: {
  document?: PreparedDocument;
  isEmpty: boolean;
  trigger: SaveTrigger;
}) => void;

interface Props {
  saveOnBlur: boolean; // save when loosing focus
  saveOnShortcut: boolean; // save when pressing Cmd+S
  saveOnInactivity: number | false; // save when inactive milliseconds
  onSave?: OnAutoSaveCallback;
}

/**
 * AutoSave - empty component to save document automatically when inactive for a while,
 * loosing focus, pressing Cmd+S
 * @param props
 * @constructor
 */
const AutoSave: React.FC<Props> = (props) => {
  const { saveOnBlur, saveOnShortcut, saveOnInactivity, onSave } = props;
  const { getJSON, getHTML } = useHelpers();
  const { getState } = useRemirrorContext();
  const [lastPunch, setLastPunch] = useState<Date | undefined>(undefined);
  const [lastEditorState, setLastEditorState] = useState<EditorState | undefined>(undefined);

  const handleChangeDebounced = useDebouncedCallback(() => {
    if (lastPunch) {
      saveStuff('inactive');
    }
    setLastPunch(new Date());
  }, saveOnInactivity || 5000);
  useRemirrorContext(handleChangeDebounced);

  const saveStuff = useCallback(
    (trigger: SaveTrigger) => {
      if (trigger === 'blur' && !saveOnBlur) {
        return;
      } else if (trigger === 'inactive' && !saveOnInactivity) {
        return;
      } else if (trigger === 'shortcut' && !saveOnShortcut) {
        return;
      }

      const state = getState();

      if (lastEditorState && areStatesEqual(lastEditorState, state)) {
        return;
      }

      const isEmpty = isDocNodeEmpty(state.doc);

      if (isEmpty) {
        onSave?.({ isEmpty, trigger });
        setLastEditorState(undefined);
        return;
      }

      const remirrorJson = getJSON(state);
      const rawJson = JSON.stringify(remirrorJson);
      const rawHtml = getHTML(state);
      onSave?.({
        document: {
          remirrorJson,
          rawJson,
          rawHtml,
          state,
          trigger,
        },
        isEmpty,
        trigger,
      });
      setLastEditorState(state);
    },
    [
      saveOnBlur,
      saveOnInactivity,
      saveOnShortcut,
      getState,
      lastEditorState,
      getJSON,
      getHTML,
      onSave,
    ]
  );

  const handleSaveShortcut = useCallback(() => {
    saveStuff('shortcut');
    return true; // Prevents any further key handlers from being run.
  }, [saveStuff]);

  const handleBlur = useCallback(() => {
    saveStuff('blur');
    return false;
  }, [saveStuff]);

  useKeymap('Mod-s', handleSaveShortcut);
  useEditorEvent('blur', handleBlur);
  return null;
};

export default AutoSave;
