import React from 'react';

const VendanorLogo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 983 298"
    preserveAspectRatio={'xMidYMid meet'}
    fill="none"
    {...props}
  >
    <g clipPath="url(#avendanor)">
      <path
        fill="#00A7AC"
        d="M61.234 198.606H28.018L0 108.041h25.66l17.636 61.707 2.358 9.781 2.84-9.781 20.946-61.707h25.026L61.25 198.606h-.016ZM105.407 198.606v-90.565h64.71v20.075h-40.304v15.585h37.63v19.078h-37.63v15.752h40.229v20.075h-64.635ZM244.122 198.606l-35.423-54.073 1.254 11.277v42.796h-24.406v-90.565h26.915l35.422 55.569-.634-9.614v-45.94h24.406v90.565h-27.55l.016-.015ZM320.71 198.606h-33.216v-90.565h32.581c30.225 0 51.956 13.605 51.956 45.124 0 31.518-19.676 45.441-51.321 45.441Zm-.952-70.49h-8.024v50.43h10.397c18.739 0 25.509-8.299 25.509-24.217 0-17.415-8.342-26.213-27.867-26.213h-.015ZM373.043 198.606l29.499-90.565h32.431l31.902 90.565h-24.724l-6.709-20.907-30.195-.211-6.845 21.103h-25.343l-.016.015Zm37.509-39.636h18.119l-8.976-32.351-9.143 32.351ZM534.819 198.606l-35.423-54.073 1.254 11.277v42.796h-24.406v-90.565h26.915l35.423 55.569-.635-9.614v-45.94h24.406v90.565h-27.549l.015-.015ZM620.157 200.103c-31.176 0-44.686-18.08-44.686-46.772 0-30.521 16.82-46.439 47.044-46.439 32.279 0 44.369 18.578 44.369 46.605 0 30.022-15.248 46.606-46.727 46.606Zm1.255-72.985c-16.85 0-21.384 10.779-21.384 26.047s4.534 26.711 20.447 26.711c17.469 0 21.701-10.944 21.701-26.545s-4.851-26.213-20.749-26.213h-.015ZM733.302 198.606l-18.361-29.856h-10.382v29.856h-24.558v-90.565h35.272c19.842 0 34.955 9.614 34.955 30.521 0 9.614-4.247 18.08-12.755 23.718l22.91 36.326h-27.081Zm-20.719-69.991h-8.024v21.723h5.032c11.652 0 15.581-4.641 15.581-11.277 0-7.468-2.358-10.446-12.589-10.446Z"
      />
      <path
        fill="#FFE76B"
        d="m892.569 251.425-57.789-31.126c-11.712-6.334-21.278-22.418-21.278-35.736v-64.55c0-12.592 13.253-19.259 23.394-13.968l47.709 25.699c1.526.847 7.964 5.17 7.964 13.787v105.894Z"
      />
      <path
        fill="#FFD730"
        d="m892.569 150.716-57.789 31.126c-11.712 6.334-21.278 22.418-21.278 35.736v64.655c0 11.338 12.301 19.622 23.242 13.938l47.725-25.593c4.095-2.283 8.1-7.362 8.1-13.56V150.716Z"
      />
      <path
        fill="#FDB913"
        d="m834.78 181.842 57.789-31.126v100.709l-57.789-31.126c-7.027-3.809-13.269-11.126-17.137-19.229 3.883-8.118 10.125-15.434 17.137-19.228"
      />
      <path
        fill="#87D1D4"
        d="m903.933 46.575 57.789 31.126C973.434 84.035 983 100.12 983 113.437v64.55c0 12.592-13.254 19.259-23.394 13.968l-47.709-25.699c-1.526-.846-7.964-5.17-7.964-13.787V46.575Z"
      />
      <path
        fill="#00AAAF"
        d="m903.933 147.284 57.789-31.126C973.434 109.824 983 93.74 983 80.422v-64.64c0-11.338-12.301-19.622-23.243-13.938l-47.724 25.593c-4.095 2.283-8.1 7.362-8.1 13.56v106.287Z"
      />
      <path
        fill="#00BABC"
        d="m961.722 116.159-57.789 31.125V46.575l57.789 31.126c7.027 3.81 13.268 11.126 17.137 19.229-3.884 8.118-10.125 15.434-17.137 19.229Z"
      />
      <path
        fill="#87D1D4"
        d="m903.933 46.575 57.789 31.126C973.434 84.035 983 100.12 983 113.437v64.55c0 12.592-13.254 19.259-23.394 13.968l-47.709-25.699c-1.526-.846-7.964-5.17-7.964-13.787V46.575Z"
      />
      <path
        fill="#00A7AC"
        d="m903.933 147.284 57.789-31.126C973.434 109.824 983 93.74 983 80.422v-64.64c0-11.338-12.301-19.622-23.243-13.938l-47.724 25.593c-4.095 2.283-8.1 7.362-8.1 13.56v106.287Z"
      />
      <path
        fill="#00B4B8"
        d="m961.722 116.159-57.789 31.125V46.575l57.789 31.126c7.027 3.81 13.268 11.126 17.137 19.229-3.884 8.118-10.125 15.434-17.137 19.229Z"
      />
      <path
        fill="#FFE76B"
        d="m892.569 251.425-57.789-31.126c-11.712-6.334-21.278-22.418-21.278-35.736v-64.55c0-12.592 13.253-19.259 23.394-13.968l47.709 25.699c1.526.847 7.964 5.17 7.964 13.787v105.894Z"
      />
      <path
        fill="#FFD730"
        d="m892.569 150.716-57.789 31.126c-11.712 6.334-21.278 22.418-21.278 35.736v64.655c0 11.338 12.301 19.622 23.242 13.938l47.725-25.593c4.095-2.283 8.1-7.362 8.1-13.56V150.716Z"
      />
      <path
        fill="#FDB913"
        d="m834.78 181.842 57.789-31.126v100.709l-57.789-31.126c-7.027-3.809-13.269-11.126-17.137-19.229 3.883-8.118 10.125-15.434 17.137-19.228"
      />
      <path
        fill="#00A7AC"
        d="M771.596 136.294c-8.13 0-13.45-5.517-13.45-14.119 0-8.601 5.32-14.18 13.45-14.18 8.131 0 13.39 5.518 13.39 14.18 0 8.662-5.365 14.119-13.39 14.119Zm0-24.958c-6.09 0-9.823 4.112-9.823 10.839s3.718 10.884 9.823 10.884c6.106 0 9.717-4.112 9.717-10.884s-3.717-10.839-9.717-10.839Zm2.615 17.853-3.098-5.17h-1.406v5.17h-3.778v-14.663c2.025-.151 3.098-.151 4.745-.151 3.824 0 6.771.771 6.771 4.746v.197c0 2.131-1.013 3.431-2.615 4.021l3.627 5.85h-4.261.015Zm-.529-10.068c0-1.542-.922-1.844-2.856-1.844h-1.119v4.021h1.164c1.934 0 2.811-.393 2.811-2.025v-.152Z"
      />
      <path
        fill="#00A7AC"
        d="M771.596 136.294c-8.13 0-13.45-5.517-13.45-14.119 0-8.601 5.32-14.18 13.45-14.18 8.131 0 13.39 5.518 13.39 14.18 0 8.662-5.365 14.119-13.39 14.119Zm0-24.958c-6.09 0-9.823 4.112-9.823 10.839s3.718 10.884 9.823 10.884c6.106 0 9.717-4.112 9.717-10.884s-3.717-10.839-9.717-10.839Zm2.615 17.853-3.098-5.17h-1.406v5.17h-3.778v-14.663c2.025-.151 3.098-.151 4.745-.151 3.824 0 6.771.771 6.771 4.746v.197c0 2.131-1.013 3.431-2.615 4.021l3.627 5.85h-4.261.015Zm-.529-10.068c0-1.542-.922-1.844-2.856-1.844h-1.119v4.021h1.164c1.934 0 2.811-.393 2.811-2.025v-.152Z"
      />
    </g>
    <defs>
      <clipPath id="avendanor">
        <path fill="#fff" d="M0 0h983v298H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default VendanorLogo;
