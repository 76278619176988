import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';

interface Props {
  delayUntil?: number;
}

const Spinner: React.FC<Props> = ({ delayUntil }) => {
  const [loading, setLoading] = useState(!!delayUntil);

  useEffect(() => {
    if (delayUntil) {
      setTimeout(() => {
        setLoading(false);
      }, delayUntil);
    }
  }, [delayUntil]);

  return loading ? false : <Spin />;
};

export default Spinner;
