import React, { useCallback, useRef, useState } from 'react';
import { Avatar, Button, Card, Dropdown, message, Space, Spin, Tooltip, Typography } from 'antd';
import useFormatTools from 'i18n/useFormatTools';
import UserAvatar from 'components/user/UserAvatar/UserAvatar';
import {
  TicketCommentFullFragment,
  TicketCommentTypes,
  useUpdateTicketCommentMutation,
} from 'generated/types';
import { hasUploadingFile, RemirrorJSON } from 'remirror';
import { defineMessages } from 'react-intl';
import commonMessages from 'components/i18n/commonMessages';
import { CloseOutlined, SaveOutlined, UserOutlined } from '@ant-design/icons';
import { VendanorEditorDef } from 'components/lib/ConnectEditor/vendanorEditorDef';
import styled from 'styled-components';
import getTicketCommentTypeIcon from 'components/ticket/TicketDiscussion/getTicketCommentTypeIcon';
import RelativeTime from 'components/i18n/RelativeTime/RelativeTime';
import { ItemType, MenuClickEventHandler } from 'rc-menu/es/interface';
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import { UploadRequestOption } from 'rc-upload/es/interface';
import useDateFormatTools from 'i18n/useDateFormatTools';
import { parseISO } from 'date-fns';
import { mapAsset } from 'components/lib/upload/mapAsset';
import { VnFile } from 'components/lib/upload/VnFile';
import useTicketCommentUploadHandler from 'components/ticket/TicketDiscussion/useTicketCommentUploadHandler';
import instanceOfRcFile from 'components/ticket/TicketDiscussion/instanceOfRcFile';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import ConnectEditor from 'components/lib/ConnectEditor/ConnectEditor.tsx';

const Container = styled.div<{ $isEmptyContent: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: ${(props) => (props.$isEmptyContent ? 'center' : 'flex-start')};
  gap: 8px;
  flex-direction: row;

  // On non-empty content, add extra bottom padding
  margin-bottom: ${(props) =>
    props.$isEmptyContent
      ? 0
      : '12px'}; // Card size is small (12px padding), but lets add another 12px = 24 on bottom?
`;

const AvatarCol = styled.div`
  flex: 0 0 24px;
`;

const ContentCol = styled.div<{ isEmptyContent: boolean }>`
  flex: 1 1 100px;
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.isEmptyContent ? 0 : '12px')};
  overflow: hidden;
`;

const ActionCol = styled.div`
  flex: 0 0 24px;
`;

const MoreButton = styled(Dropdown.Button)`
  &&& {
    display: flex;
    align-items: center;

    button.ant-btn.ant-btn-text.ant-btn-sm {
      display: none; // hiding extra button
    }

    button.ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-dropdown-trigger {
      display: block;
      border-radius: 0;
      margin: 0;
    }
  }
`;

const ContentHeader = styled.div`
  font-size: 12px;
  line-height: 20px;
  height: 24px; // same height as avatar size small
  display: flex;
  align-items: center;
  overflow: hidden;

  && {
    .anticon {
      margin-left: 2px;
    }
  }
`;

const messages = defineMessages({
  tooltipEdited: {
    id: 'ticket_comment.tooltip_edited',
    defaultMessage: 'Edited: {date}',
  },
  tooltipCreated: {
    id: 'ticket_comment.tooltip_created',
    defaultMessage: 'Created: {date}',
  },
  uploadWarning: {
    id: 'ticket_comment.download_in_progress_warning',
    defaultMessage: 'Upload in progress, please wait until it is finished',
  },
  errorUpdate: {
    id: 'ticket_comment.update_fail',
    defaultMessage: 'Could not update ticket comment',
  },
  ticketOpened: {
    id: 'ticket_comment.opened',
    defaultMessage: '{user} {icon} opened ticket {relativeTime}',
  },
  ticketClosed: {
    id: 'ticket_comment.closed',
    defaultMessage: '{user} {icon} closed ticket {relativeTime}',
  },
  ticketClosedAsNotCompleted: {
    id: 'ticket_comment.closed_as_not_completed',
    defaultMessage: '{user} {icon} closed ticket as not completed {relativeTime}',
  },
  ticketReopened: {
    id: 'ticket_comment.reopened',
    defaultMessage: '{user} {icon} reopened ticket {relativeTime}',
  },
  ticketCommented: {
    id: 'ticket_comment.commented',
    defaultMessage: '{user} {icon} commented {relativeTime}',
  },
  ticketCommentEdited: {
    id: 'ticket_comment.edited',
    defaultMessage: '{user} {icon} edited {relativeTime}',
  },
  ticketSetDueDate: {
    id: 'ticket_comment.set_due_date',
    defaultMessage: '{user} {icon} set due date {relativeTime}',
  },
});

type UploadCancelRefDict = { [key: string]: () => void };

interface Props {
  comment: TicketCommentFullFragment;
}

const TicketComment: React.FC<Props> = (props) => {
  const { comment } = props;
  const { formatUserName } = useFormatTools();
  const { formatDate } = useDateFormatTools();
  const [isEditing, setIsEditing] = useState(false);
  const [originalJson, setOriginalJson] = useState<RemirrorJSON | undefined>(undefined);

  // The original list of assets, set when enabling edit mode
  const [originalAssets, setOriginalAssets] = useState<Array<UploadFile<VnFile>> | undefined>(
    undefined
  );

  // The editable list of assets
  const [editAssets, setEditAssets] = useState<Array<UploadFile<VnFile>> | undefined>(() => {
    return comment.assets.map(mapAsset);
  });

  // Since Editor is uncontrolled we keep a ref to get the current state / json when needed
  const editorRef = useRef<VendanorEditorDef>();

  const [updateTicketComment, { loading }] = useUpdateTicketCommentMutation({
    notifyOnNetworkStatusChange: true,
  });
  const intl = useConnectIntl();
  const commentId = comment.id;

  const getEditorUploadInProgress = useCallback(() => {
    if (editorRef.current === undefined) return false;
    const state = editorRef.current.getState();
    return hasUploadingFile(state);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!editorRef.current) return;
    if (!isEditing) return;

    const isUploadingAsset = editAssets?.some((c) => c.status === 'uploading');
    const isUploadingInline = getEditorUploadInProgress();

    if (isUploadingAsset || isUploadingInline) {
      message.warning({
        content: intl.formatMsg(messages.uploadWarning),
      });
      return;
    }

    try {
      setIsEditing(false); // optimistic-ish

      const editorState = editorRef.current.getState();
      const html = editorRef.current?.helpers.getHTML(editorState);
      const assetIdsToSave: string[] = (editAssets || [])
        .filter((c) => c.status === 'done')
        .map((c) => c.response?.assetId)
        .filter((c): c is string => !!c);
      const assetIdsToRemove = (originalAssets || [])
        .map((c) => c.response?.assetId)
        .filter((c): c is string => !!c)
        .filter((c) => assetIdsToSave.indexOf(c) === -1);

      await updateTicketComment({
        variables: {
          input: {
            ticketCommentId: commentId,
            contentHtml: html,
            addedAssetIds: assetIdsToSave,
            removedAssetIds: assetIdsToRemove,
          },
        },
      });
      setOriginalJson(undefined);
    } catch (err) {
      message.error(intl.formatMsg(messages.errorUpdate));
      setIsEditing(true);
    }
  }, [
    commentId,
    intl,
    updateTicketComment,
    editAssets,
    isEditing,
    getEditorUploadInProgress,
    originalAssets,
  ]);

  const handleCancelEdit = useCallback(async () => {
    // cancel any uploads
    Object.values(uploadCancelByUidRefDict.current).forEach((c) => c());

    if (originalJson !== undefined) {
      if (!editorRef.current) return;
      // NOTE: this will preserve history... meaning ctrl+z will revert the reverted.
      editorRef.current.setContent(originalJson, { triggerChange: false });
      const editorState = editorRef.current.getState();

      editorRef.current.view.updateState(editorState); // <== this resets history
      setOriginalJson(undefined);
      setEditAssets(originalAssets); // reset
      setOriginalAssets(undefined);
      setIsEditing(false);
    }
  }, [originalAssets, originalJson]);

  const handleClickEdit = useCallback<MenuClickEventHandler>(() => {
    // Get a snapshot of the editors current state. If we cancel editing we revert to this state later
    if (!editorRef.current) return;
    const editorState = editorRef.current.getState();
    const json = editorRef.current.helpers.getJSON(editorState);
    setOriginalJson(json);

    const originalAssets = comment.assets.map(mapAsset);
    setOriginalAssets(originalAssets);
    setEditAssets(comment.assets.map(mapAsset));

    setIsEditing(true);
  }, [comment.assets]);

  const avatar = comment.user ? (
    <UserAvatar user={comment.user} />
  ) : (
    <Avatar size={'small'} icon={<UserOutlined />} />
  );

  const menuItems: ItemType[] = [
    {
      key: 'edit',
      label: 'Edit',
      disabled: !comment.isMine,
      onClick: handleClickEdit,
    },
    { key: 'quote', label: 'Quote reply', disabled: true },
  ];

  const isEmpty = !comment.content && !isEditing;
  const uploadCancelByUidRefDict = useRef<UploadCancelRefDict>({});

  const handleUp = useTicketCommentUploadHandler();
  const handleUploadFile = useCallback(
    async (options: UploadRequestOption) => {
      const { file } = options;
      const abortController = new AbortController();
      if (instanceOfRcFile(file)) {
        uploadCancelByUidRefDict.current[file.uid] = () => {
          abortController.abort('Upload cancelled by user');
        };
      }
      await handleUp(options, abortController.signal);
    },
    [handleUp]
  );

  const handleRemoveFile = async (file: UploadFile<VnFile>) => {
    if (!isEditing) return;
    // NOTE: we don't want to delete right now. let's wait until we submit and let backend delete removed assetIds
    const remainingItems = (editAssets || []).filter(
      (c) => c.response && c.response.assetId !== file.response?.assetId
    );
    setEditAssets(remainingItems);
  };

  const handleCancelUpload = async (file: UploadFile<VnFile>) => {
    uploadCancelByUidRefDict.current[file.uid]?.();
    const remainingItems = (editAssets || []).filter((c) => c.uid !== file.uid);
    setEditAssets(remainingItems);
  };

  const handleUploadStateChange = (info: UploadChangeParam) => {
    setEditAssets(info.fileList);
  };

  return (
    <Card size={'small'}>
      <Container $isEmptyContent={isEmpty}>
        <AvatarCol>{avatar}</AvatarCol>
        {
          <ContentCol isEmptyContent={isEmpty}>
            <ContentHeader>
              <Typography.Text type={'secondary'} ellipsis={true}>
                {comment.commentType === TicketCommentTypes.Opened && (
                  <>
                    {intl.formatMsg(messages.ticketOpened, {
                      user: (
                        <Typography.Text strong={true} type={'secondary'}>
                          {formatUserName(comment.user)}
                        </Typography.Text>
                      ),
                      icon: getTicketCommentTypeIcon(comment.commentType),
                      relativeTime: <RelativeTime dateRaw={comment.date} />,
                    })}
                  </>
                )}
                {comment.commentType === TicketCommentTypes.Closed && (
                  <>
                    {intl.formatMsg(messages.ticketClosed, {
                      user: (
                        <Typography.Text strong={true} type={'secondary'}>
                          {formatUserName(comment.user)}
                        </Typography.Text>
                      ),
                      icon: getTicketCommentTypeIcon(comment.commentType),
                      relativeTime: <RelativeTime dateRaw={comment.date} />,
                    })}
                  </>
                )}
                {comment.commentType === TicketCommentTypes.ClosedAsNotCompleted && (
                  <>
                    {intl.formatMsg(messages.ticketClosedAsNotCompleted, {
                      user: (
                        <Typography.Text strong={true} type={'secondary'}>
                          {formatUserName(comment.user)}
                        </Typography.Text>
                      ),
                      icon: getTicketCommentTypeIcon(comment.commentType),
                      relativeTime: <RelativeTime dateRaw={comment.date} />,
                    })}
                  </>
                )}
                {comment.commentType === TicketCommentTypes.Reopened && (
                  <>
                    {intl.formatMsg(messages.ticketReopened, {
                      user: (
                        <Typography.Text strong={true} type={'secondary'}>
                          {formatUserName(comment.user)}
                        </Typography.Text>
                      ),
                      icon: getTicketCommentTypeIcon(comment.commentType),
                      relativeTime: <RelativeTime dateRaw={comment.date} />,
                    })}
                  </>
                )}
                {comment.commentType === TicketCommentTypes.SetDue && (
                  <>
                    {intl.formatMsg(messages.ticketSetDueDate, {
                      user: (
                        <Typography.Text strong={true} type={'secondary'}>
                          {formatUserName(comment.user)}
                        </Typography.Text>
                      ),
                      icon: getTicketCommentTypeIcon(comment.commentType),
                      relativeTime: <RelativeTime dateRaw={comment.date} />,
                    })}
                  </>
                )}
                {comment.commentType === TicketCommentTypes.Commented && !comment.editedDate && (
                  <Tooltip
                    title={
                      <div>
                        {intl.formatMsg(messages.tooltipCreated, {
                          date: formatDate(parseISO(comment.date), {
                            representation: 'complete',
                          }),
                        })}
                      </div>
                    }
                    overlayStyle={{
                      maxWidth: '350px',
                    }}
                  >
                    {intl.formatMsg(messages.ticketCommented, {
                      user: (
                        <Typography.Text strong={true} type={'secondary'}>
                          {formatUserName(comment.user)}
                        </Typography.Text>
                      ),
                      icon: getTicketCommentTypeIcon(comment.commentType),
                      relativeTime: <RelativeTime dateRaw={comment.date} hideTooltip={true} />,
                    })}
                  </Tooltip>
                )}
                {comment.commentType === TicketCommentTypes.Commented && comment.editedDate && (
                  <Tooltip
                    overlayStyle={{
                      maxWidth: '350px',
                    }}
                    title={
                      <div>
                        <div>
                          {intl.formatMsg(messages.tooltipCreated, {
                            date: formatDate(parseISO(comment.date), {
                              representation: 'complete',
                            }),
                          })}
                        </div>
                        <div>
                          {intl.formatMsg(messages.tooltipEdited, {
                            date: formatDate(parseISO(comment.editedDate), {
                              representation: 'complete',
                            }),
                          })}
                        </div>
                      </div>
                    }
                  >
                    {intl.formatMsg(messages.ticketCommentEdited, {
                      user: (
                        <Typography.Text strong={true} type={'secondary'}>
                          {formatUserName(comment.user)}
                        </Typography.Text>
                      ),
                      icon: getTicketCommentTypeIcon(comment.commentType),
                      relativeTime: (
                        <RelativeTime dateRaw={comment.editedDate} hideTooltip={true} />
                      ),
                    })}
                  </Tooltip>
                )}
              </Typography.Text>
            </ContentHeader>
            <ConnectEditor
              defaultValueRaw={comment.content || undefined}
              editable={isEditing}
              ref={editorRef}
              enableUpload={true}
              fileList={editAssets}
              onUploadFile={handleUploadFile}
              onRemoveFile={handleRemoveFile}
              onUploadStateChange={handleUploadStateChange}
              onCancelUpload={handleCancelUpload}
            />
            {isEditing && (
              <Space>
                <Button
                  type={'primary'}
                  icon={<SaveOutlined />}
                  loading={loading}
                  disabled={loading}
                  onClick={handleSubmit}
                >
                  {intl.formatMsg(commonMessages.save)}
                </Button>
                <Button icon={<CloseOutlined />} onClick={handleCancelEdit} disabled={loading}>
                  {intl.formatMsg(commonMessages.cancel)}
                </Button>
              </Space>
            )}
          </ContentCol>
        }
        {!isEditing && (
          <ActionCol>
            {loading && <Spin size={'small'} />}
            {!loading && (
              <MoreButton
                menu={{
                  items: menuItems,
                }}
                type={'text'}
                size={'small'}
                arrow={true}
                title={'title'}
              />
            )}
          </ActionCol>
        )}
      </Container>
    </Card>
  );
};

export default TicketComment;
