import React from 'react';
import { CompanyFilledIcon } from 'components/icons/Icons';
import useIsMobile from 'layouts/responsive/useIsMobile';
import { gql } from '@apollo/client';
import { Image } from 'antd';
import { useGetRetailerLogoQuery } from 'generated/types';

gql`
  query GetRetailerLogo($retailerId: Int!) {
    retailer(retailerId: $retailerId) {
      id
      retailerId
      hasLogo
      logo {
        ...AssetListItem
      }
    }
  }
`;

interface Props {
  width: number;
  retailerId?: number;
}

const RetailerLogo: React.FC<Props> = ({ width, retailerId }) => {
  const isMobile = useIsMobile();

  const company = <CompanyFilledIcon style={{ fontSize: isMobile ? '1.5em' : '3em' }} />;

  const { data, loading } = useGetRetailerLogoQuery({
    variables: {
      retailerId: retailerId || -1,
    },
    skip: retailerId === undefined,
  });

  const logoUrl = data?.retailer?.logo?.url;

  return loading ? (
    company
  ) : logoUrl ? (
    <Image width={width} preview={false} src={logoUrl} />
  ) : (
    company
  );
};

export default RetailerLogo;
