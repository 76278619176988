import { Avatar, AvatarProps, Tooltip } from 'antd';
import React, { ReactElement } from 'react';
import { DeletedUserListItemFragment, UserListItemFragment } from 'generated/types';
import getUserLetters from 'components/user/UserAvatar/getUserLetters';
import { UserOutlined } from '@ant-design/icons';
import useFormatTools from 'i18n/useFormatTools';
import getUserColor from 'components/user/UserAvatar/getUserColor.ts';

interface Props {
  user: UserListItemFragment | DeletedUserListItemFragment;
  tooltip?: string | ReactElement | (string | ReactElement)[];
  hideTooltip?: boolean;
  useColor?: boolean;
}

const UserAvatar: React.FC<Props & AvatarProps> = (props) => {
  const { user, useColor, tooltip, hideTooltip, ...rest } = props;
  const { formatUserName } = useFormatTools();
  const color = getUserColor(user);

  const userNameTooltip = formatUserName(user);
  const content = user.__typename === 'User' ? getUserLetters(user) : <UserOutlined />;

  const avatar = (
    <Avatar size={'small'} style={useColor ? { backgroundColor: color } : undefined} {...rest}>
      {content}
    </Avatar>
  );

  if (hideTooltip) {
    return avatar;
  }

  return <Tooltip title={tooltip || userNameTooltip}>{avatar}</Tooltip>;
};

export default UserAvatar;
