import { useEffect } from 'react';
import { useAppDispatch } from 'redux/store';
import { logout, refreshUserInfo, restoreAuth } from 'auth/authSlice';

export const logoutChannel = new BroadcastChannel('vn-logout');
export const loginChannel = new BroadcastChannel('vn-login');

/***
 * This uses BroadcastChannel to communicate between tabs when we log in or log out
 */
export function useAuthSync() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    console.log('☎️ open channels');

    logoutChannel.onmessage = () => {
      console.log('another tab logged out');
      dispatch(logout({ skipSignal: true }));
    };

    loginChannel.onmessage = async () => {
      console.log('another tab logged in, restoreAuth');
      await dispatch(restoreAuth());
      await dispatch(refreshUserInfo());
    };

    return () => {
      console.log('☎️ close channels');
      try {
        logoutChannel.close();
        loginChannel.close();
      } catch (err) {
        console.log('Error when closing channels', err);
      }
    };
  }, [dispatch]);
}
