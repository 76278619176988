import React, { SVGProps } from 'react';

export const AlpiloxIllustration: React.FC<SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.442 54.027V23m0 31.027L3 50.955V26.956L14.442 23m0 31.027 10.372-.714M14.442 23l10.372.97m0 0v29.343m0-29.344 9.973.932m-9.973 28.412 9.973-.687m0-27.725v27.725m0-27.725 8.865.829m-8.865 26.896 8.865-.61m0-26.286v26.286m0-26.286 8.865.828m-8.865 25.458 8.865-.611m0-24.847v24.847m0-24.847 8.31.777m-8.31 24.07 8.31-.572m0-23.498v23.498m0-23.498 8.035.75m-8.034 22.748 4.986-.344m3.048-22.403 8.033.75v20.89l-8.033.553m0-22.193V50.28m0 0-3.048.21m-54.297 10.2 5.817 1.75M22.598 64l-5.264-1.56m42.859-7.582 5.621 1.385m5.622 1.385-5.622-1.385m-48.48 6.197v-8.612m48.48 2.415V50.49"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);
