import {
  MachinePropertyWithValueFragment,
  useGetMachinePropertyHistoryQuery,
  useSetMachinePropertySelectorValueMutation,
} from 'generated/types.tsx';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { MachinePropertyValueEditor } from 'components/machine/properties/MachinePropertyList/machinePropertyValueEditor.ts';
import NotSetValue from 'components/machine/properties/MachinePropertyList/NotSetValue.tsx';
import { gql } from '@apollo/client';
import Select from 'components/lib/Select/Select.tsx';
import { getFriendlyApolloErrorMessage } from 'graphql/apollo/apolloErrorUtil.ts';
import useMessageApi from 'components/global/useMessageApi.ts';
import { useMachinePermissions } from 'auth/RequireMachineAccess.tsx';
import { DefaultOptionType } from 'antd/es/select';

gql`
  mutation SetMachinePropertySelectorValue($input: SetSelectorInput!) {
    setMachinePropertyValue(input: { selectorValue: $input }) {
      ...MachinePropertyWithValue
      valueHistory {
        ...SelectorFormFieldValueFull
      }
    }
  }
`;

interface Props {
  property: MachinePropertyWithValueFragment;
  value: string | null | undefined;
}

const SelectorValue = forwardRef<MachinePropertyValueEditor, Props>(
  ({ property, value: originalValue }, ref) => {
    const [editing, setEditing] = useState(false);
    const [setValue] = useSetMachinePropertySelectorValueMutation();
    const messageApi = useMessageApi();
    const { canEditDetails } = useMachinePermissions();

    const options = useMemo(() => {
      const temp: DefaultOptionType[] = [];
      property.options?.forEach((c) => {
        temp.push({
          label: c.value,
          value: c.key,
        });
      });

      return temp;
    }, [property.options]);

    React.useImperativeHandle(
      ref,
      () => ({
        enableEditMode: () => setEditing(true),
      }),
      [setEditing]
    );

    const { data } = useGetMachinePropertyHistoryQuery({
      variables: {
        machineId: property.machineId,
        serialNo: property.machineSerialNo,
        key: property.key,
      },
      fetchPolicy: 'cache-only',
    });

    const save = useCallback(
      async (value: string) => {
        try {
          setEditing(false);
          if (value === originalValue) {
            return;
          }
          const myHistory =
            data?.machineProperty.valueHistory.filter(
              (c) => c.__typename === 'SelectorPropertyValue'
            ) || [];

          await setValue({
            variables: {
              input: {
                machineId: property.machineId,
                serialNo: property.machineSerialNo,
                key: property.key,
                value: value,
              },
            },
            optimisticResponse: {
              __typename: 'Mutation',
              setMachinePropertyValue: {
                ...property,
                __typename: 'MachineProperty',
                key: property.key,
                machineId: property.machineId,
                machineSerialNo: property.machineSerialNo,
                valueHistory: [
                  {
                    __typename: 'SelectorPropertyValue',
                    selectorValue: value,
                    // machineId: property.machineId,
                    active: true,
                    // serialNo: property.machineSerialNo,
                    id: 'temp-id-value',
                    changed: new Date().toISOString(),
                    changedByUser: null, // This will make the value update optimistically, and the user will pop up when saved to server
                  },
                  ...myHistory,
                ],
                value: {
                  __typename: 'SelectorPropertyValue',
                  selectorValue: value,
                  // machineId: property.machineId,
                  active: true,
                  // serialNo: property.machineSerialNo,
                  id: 'temp-id-value',
                  changed: new Date().toISOString(),
                  changedByUser: null,
                },
              },
            },
          });
        } catch (err) {
          messageApi.error(
            getFriendlyApolloErrorMessage(err, 'Could not set machine property value. ')
          );
        }
      },
      [originalValue, data?.machineProperty.valueHistory, setValue, property, messageApi]
    );

    if (!editing && (originalValue === null || originalValue === undefined)) {
      return <NotSetValue />;
    }

    return (
      <div>
        <Select<string>
          style={{ width: '100%' }}
          options={options}
          value={originalValue}
          onChange={save}
          disabled={!canEditDetails}
        />
      </div>
    );
  }
);

SelectorValue.displayName = 'TextValue';

export default SelectorValue;
