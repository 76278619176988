import { SelectProps } from 'antd/es/select';
import { gql } from '@apollo/client/core';
import React, { useMemo } from 'react';
import { useGetTicketArticleStoragesQuery } from 'generated/types.tsx';
import Select from 'components/lib/Select/Select.tsx';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { ticketAccountingMessages } from 'components/ticket/TicketAccounting/ticketAccountingMessages.ts';
import StorageItem from 'components/ticket/TicketAccounting/StorageItem.tsx';

interface Props {
  value?: string;
  onChange?: (value: string) => void;
}

type OtherSelectProps = Omit<SelectProps<string>, 'options' | 'value' | 'onChange'>;

gql`
  query GetTicketArticleStorages {
    allTicketArticleStorages {
      id
      ticketArticleStorageId
      name
    }
  }
`;

const StorageSelect: React.FC<Props & OtherSelectProps> = ({ value, onChange, ...rest }) => {
  const intl = useConnectIntl();

  const { data, loading } = useGetTicketArticleStoragesQuery({
    notifyOnNetworkStatusChange: true,
  });

  const options = useMemo(() => {
    return data?.allTicketArticleStorages.map((storage) => ({
      value: storage.id,
      label: <StorageItem storage={storage} />,
    }));
  }, [data]);

  return (
    <Select
      value={loading ? undefined : value}
      onChange={(selectedValue) => {
        onChange?.(selectedValue);
      }}
      loading={loading}
      options={options}
      placeholder={intl.formatMsg(ticketAccountingMessages.placeholderStorageSelect)}
      {...rest}
    />
  );
};

export default StorageSelect;
