import api, { AccessToken, LoginResponse } from 'api/api.ts';
import { retryPromise } from 'auth/retryPromise.ts';
import { KEY_LOGIN, KEY_REFRESH_LOCK } from 'auth/constants.ts';
import checkAccessToken from 'auth/checkAccessToken.ts';
import { addLocalStorageItem } from 'common/localStorage.ts';
import { setRefreshFlagCookie } from 'auth/refreshFlagCookie.ts';
import Lock from 'browser-tabs-lock';
import { store } from 'redux/store.ts';
import { refreshUserInfo } from 'auth/authSlice.ts';

const lock = new Lock();

/***
 * Refresh the access_token using the refresh_token.
 * Refresh is cross-tab safe, only one tab will be allowed to refresh at once
 * **/
async function refreshToken(requestedBy: string): Promise<AccessToken> {
  if (await retryPromise(() => lock.acquireLock(KEY_REFRESH_LOCK, 5000), 10)) {
    try {
      // check cache / local storage again for valid access token, perhaps another tab refreshed already?
      const reCheck = checkAccessToken();
      if (reCheck.hasValidAccessToken && reCheck.localData && !reCheck.willExpireSoon) {
        // console.log('🐇 returning valid access token (recheck)');
        return reCheck.localData.accessToken;
      }

      console.log('💫 refresh... ', requestedBy);

      // NOTE: when adding app to ios home screen, auth info is preserved, but the anti-forgery token is not!
      // In this case, undefined will be passed here, and the refresh will fail with 401 and log out the user.
      const antiForgeryToken = reCheck.localData?.accessToken.antiForgeryToken;
      const refreshResult = await api.refreshToken(antiForgeryToken);

      if (refreshResult.status === 200) {
        console.log('👌 refresh ok', requestedBy);
        addLocalStorageItem<LoginResponse>(KEY_LOGIN, refreshResult.data);
        setRefreshFlagCookie('refresh', refreshResult.data.remember, refreshResult.data.expires);
        store.dispatch(refreshUserInfo());
      }
      return refreshResult.data.accessToken;
    } finally {
      await lock.releaseLock(KEY_REFRESH_LOCK);
    }
  } else {
    throw new Error('Timeout occurred, could not acquire lock');
  }
}

export default refreshToken;
