import React from 'react';
import { ArticleStorageListItemFragment } from 'generated/types.tsx';
import { Flex } from 'antd';
import styled from 'styled-components';
import Tag from 'components/lib/Tag/Tag.tsx';

interface Props {
  storage?: ArticleStorageListItemFragment | null;
}

export const StorageItem: React.FC<Props> = ({ storage }) => {
  if (!storage) return null;

  return (
    <Flex gap={'small'} align={'center'}>
      <div>
        <StorageIdTag bordered={false}>{storage.ticketArticleStorageId}</StorageIdTag>
      </div>
      <div>{storage.name}</div>
    </Flex>
  );
};

const StorageIdTag = styled(Tag)`
  &&& {
    color: ${({ theme }) => theme.ant.Tag?.defaultColor};
    background: ${({ theme }) => theme.ant.Tag?.defaultBg};
    width: 34px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default StorageItem;
