import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Flex, Form, FormProps, Row, Space, Typography } from 'antd';
import {
  ArticleListItemFragment,
  ArticleListItemFragmentDoc,
  TicketOrderLineFormInput,
  useGetMostUsedArticleQuery,
  useGetMostUsedArticleStorageQuery,
  useGetTicketOrderLineArticleQuery,
} from 'generated/types.tsx';
import { useForm, useWatch } from 'antd/es/form/Form';
import useConnectIntl from 'i18n/useConnectIntl';
import useArticleDiscountTools from 'components/ticket/TicketAccounting/useArticleDiscountTools.ts';
import ArticleSelect from 'components/ticket/TicketAccounting/ArticleSelect.tsx';
import StorageSelect from 'components/ticket/TicketAccounting/StorageSelect.tsx';
import CategorySelect from 'components/ticket/TicketAccounting/CategorySelect.tsx';
import UserSelect from 'components/user/UserSelect/UserSelect.tsx';
import InputNumber from 'components/lib/InputNumber/InputNumber.tsx';
import { ticketAccountingMessages } from 'components/ticket/TicketAccounting/ticketAccountingMessages.ts';
import DatePicker from 'components/lib/DatePicker/DatePicker.tsx';
import TextArea from 'antd/es/input/TextArea';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import commonMessages from 'components/i18n/commonMessages.ts';
import { parseISO } from 'date-fns';
import { gql } from '@apollo/client/core';

gql`
  query GetMostUsedArticleStorage {
    me {
      id
      mostUsedArticleStorage {
        id
        ticketArticleStorageId
        name
      }
    }
  }
`;

gql`
  query GetMostUsedArticle {
    me {
      id
      mostUsedArticle {
        ...ArticleListItem
      }
    }
  }
  ${ArticleListItemFragmentDoc}
`;

// ExtraData that is stored in the form, but not submitted.
// Note that this must be passed to form when editing for initial values (except parsedDate)
interface ExtraData {
  discount: number; // Discount in percentage. This is not sent to the server, used for rendering + allow user to change discount (which will affect price)
  parsedDate: Date; // Date sent to/from backend is ISO string, the DatePicker component requires a Date object. We use parsedDate in the form state
  sum: number;
  unitPriceOriginal: number; // The original price (either stored on order line or fetched from article)
}

type TicketOrderLineForm = TicketOrderLineFormInput & ExtraData;

function TypedTicketOrderLineForm<T extends object = TicketOrderLineForm>({
  children,
  ...props
}: FormProps<T> & { children?: React.ReactNode }) {
  return <Form<T> {...props}>{children}</Form>;
}

const ARTICLE_SERVICE_TECHNICIAN = 23;
const ARTICLE_SUPPORT = 77;

interface Props {
  mode: 'add' | 'edit';
  initialValues: Partial<TicketOrderLineForm>;
  ticketId: number;
  isSaving: boolean;
  onCancel: () => void;
  onSubmit: (form: TicketOrderLineFormInput) => void;
}

/*****
 * OrderLineForm, used for both add and edit order lines
 * */
const OrderLineForm: React.FC<Props> = ({
  ticketId,
  isSaving,
  onCancel,
  onSubmit,
  mode,
  initialValues: initialValuesIn,
}) => {
  const intl = useConnectIntl();
  const { getArticleDiscount, hasServiceAgreement } = useArticleDiscountTools(ticketId);

  // Get the most used article for logged on user if unset
  const shouldFetchMostUsedArticle = mode === 'add' && initialValuesIn.articleId === undefined;
  const { data: dataMostUsed } = useGetMostUsedArticleQuery({
    skip: !shouldFetchMostUsedArticle,
  });

  const shouldFetchMostUsedStorage =
    mode === 'add' && initialValuesIn.articleStorageId === undefined;
  const { data: dataMostUsedStorage } = useGetMostUsedArticleStorageQuery({
    skip: !shouldFetchMostUsedStorage,
  });

  // Fetch article from selectedArticleId, we need this to calculate the discount.
  const shouldFetchArticle = mode === 'edit' && initialValuesIn.articleId !== undefined;
  const { data: dataArticle } = useGetTicketOrderLineArticleQuery({
    variables: initialValuesIn.articleId
      ? {
          ticketArticleId: initialValuesIn.articleId,
        }
      : undefined,
    skip: !shouldFetchArticle,
  });

  const initialValues: Partial<TicketOrderLineForm> = {
    parsedDate: initialValuesIn.date ? parseISO(initialValuesIn.date) : new Date(),
    discount: 0,
    priceOut: 0,
    unitPriceOriginal: 0,
    sum: 0,
    quantity: 1,
    ...initialValuesIn,
  };

  const [form] = useForm<TicketOrderLineForm>();

  const [article, setArticle] = useState<ArticleListItemFragment | undefined>(undefined);
  const [showAccountServiceAgreementTip, setShowAccountServiceAgreementTip] = useState(false);
  const [showStorage, setShowStorage] = useState(false);
  const formPriceOut = useWatch('priceOut', { form, preserve: true });

  const unitPriceOriginalValue = Form.useWatch('unitPriceOriginal', { form, preserve: true });

  const showOriginalPriceTip = useMemo(() => {
    return unitPriceOriginalValue !== formPriceOut;
  }, [unitPriceOriginalValue, formPriceOut]);

  const quantityStep = article?.group.stepSize || 1;
  const mostUsedArticleStorageId = dataMostUsedStorage?.me.mostUsedArticleStorage?.id || undefined;

  const handleChangeArticle = useCallback(
    async (option: ArticleListItemFragment) => {
      const discount = getArticleDiscount(option);

      const currentArticleStorageId = form.getFieldValue('articleStorageId');
      form.setFieldsValue({
        priceOut: option.priceOut * discount.factor,
        unitPriceOriginal: option.priceOut,
        discount: discount.discountPercentage,
        articleId: option.id,
        sum: option.priceOut * discount.factor * form.getFieldValue('quantity'),
        // NOTE: if the user has selected storage, don't override.
        // But if the user has selected article without storage, use the most used storage id if set
        articleStorageId: option.group.hasStorage
          ? currentArticleStorageId
            ? currentArticleStorageId
            : mostUsedArticleStorageId
          : undefined,
      });

      setArticle(option);

      if (discount.hasServiceAgreement) {
        if (
          option.ticketArticleId === ARTICLE_SERVICE_TECHNICIAN ||
          option.ticketArticleId === ARTICLE_SUPPORT
        ) {
          setShowAccountServiceAgreementTip(true);
        } else {
          setShowAccountServiceAgreementTip(false);
        }
      } else {
        setShowAccountServiceAgreementTip(false);
      }

      if (option.group.hasStorage) {
        setShowStorage(true);
      } else {
        setShowStorage(false);
      }
    },
    [mostUsedArticleStorageId, form, getArticleDiscount]
  );

  useEffect(() => {
    if (mode === 'add' && dataMostUsed?.me.mostUsedArticle) {
      handleChangeArticle(dataMostUsed.me.mostUsedArticle);
    }
  }, [mode, dataMostUsed?.me.mostUsedArticle, handleChangeArticle]);

  useEffect(() => {
    if (mode === 'edit' && dataArticle) {
      // edit mode, article loaded
      setArticle(dataArticle.ticketArticle);

      const { hasServiceAgreement } = getArticleDiscount(dataArticle.ticketArticle);
      if (hasServiceAgreement) {
        if (
          dataArticle.ticketArticle.ticketArticleId === ARTICLE_SERVICE_TECHNICIAN ||
          dataArticle.ticketArticle.ticketArticleId === ARTICLE_SUPPORT
        ) {
          setShowAccountServiceAgreementTip(true);
        } else {
          setShowAccountServiceAgreementTip(false);
        }
      } else {
        setShowAccountServiceAgreementTip(false);
      }

      if (dataArticle.ticketArticle.group.hasStorage) {
        setShowStorage(true);
      } else {
        setShowStorage(false);
      }
    }
  }, [mode, dataArticle, initialValuesIn, form, getArticleDiscount]);

  const handleChangePriceOut = (value: number | null) => {
    if (value === null || article === undefined) return;

    let discount = 0;
    const originalPrice = form.getFieldValue('unitPriceOriginal');
    if (value > 0 && value < originalPrice) {
      const diff = originalPrice - value;
      discount = Math.round((diff / originalPrice) * 100.0);
    }

    form.setFieldsValue({
      discount,
      sum: value * form.getFieldValue('quantity'),
    });
  };

  const handleChangeDiscount = (value: number | null) => {
    if (value === null || article === undefined) return;

    if (value >= 0 && value <= 100) {
      const originalPrice = form.getFieldValue('unitPriceOriginal');
      const diff = (originalPrice * value) / 100.0;
      const discountedPrice = Math.max(originalPrice - diff, 0);

      form.setFieldsValue({
        priceOut: discountedPrice,
        sum: discountedPrice * form.getFieldValue('quantity'),
      });
    }
  };

  const handleChangeQuantity = (value: number | null) => {
    if (value === null) {
      return;
    }
    form.setFieldsValue({
      sum: value * form.getFieldValue('priceOut'),
    });
  };

  const handleFinish = (values: TicketOrderLineForm) => {
    const { discount, date, parsedDate, sum, unitPriceOriginal, ...rest } = values;

    const formValuesWithoutExtra: TicketOrderLineFormInput = {
      ...rest,
      date: parsedDate.toISOString(),
    };
    onSubmit?.(formValuesWithoutExtra);
  };

  const accountHint = showAccountServiceAgreementTip
    ? intl.formatMsg(ticketAccountingMessages.accountTip)
    : undefined;

  const handleCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);

  return (
    <TypedTicketOrderLineForm
      form={form}
      layout={'vertical'}
      onFinish={handleFinish}
      initialValues={initialValues}
    >
      <Row gutter={[16, 16]} wrap={true}>
        <Col xs={16} md={16} lg={8}>
          <Form.Item
            name={'articleId'}
            label={intl.formatMsg(ticketAccountingMessages.col_article)}
            rules={[{ required: true }]}
          >
            <ArticleSelect onChange={handleChangeArticle} style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            name={'categoryId'}
            label={intl.formatMsg(ticketAccountingMessages.col_account)}
            rules={[{ required: true }]}
            help={accountHint}
          >
            <CategorySelect hasServiceAgreement={hasServiceAgreement} />
          </Form.Item>

          {showStorage && (
            <Form.Item
              name={'articleStorageId'}
              label={intl.formatMsg(ticketAccountingMessages.col_storage)}
              rules={[{ required: true }]}
            >
              <StorageSelect />
            </Form.Item>
          )}
        </Col>

        <Col xs={8} md={8} lg={4}>
          <Form.Item
            name={'discount'}
            label={intl.formatMsg(ticketAccountingMessages.col_discount)}
          >
            <InputNumber
              min={0}
              max={100}
              formatter={(value) => `${value}%`}
              parser={(displayValue): 0 | 100 => {
                if (displayValue !== undefined) {
                  return parseInt(displayValue?.replace('%', '')) as 0 | 100;
                }
                return 0;
              }}
              onChange={(value) => handleChangeDiscount(value)}
              style={{ width: '100%' }}
            />
          </Form.Item>

          <Form.Item
            name={'priceOut'}
            label={
              <Flex gap={4} align={'center'}>
                <Typography.Text>
                  {intl.formatMsg(ticketAccountingMessages.col_price_out)}
                </Typography.Text>
                {showOriginalPriceTip && (
                  <Typography.Text type={'secondary'}>
                    {intl.formatMsg(ticketAccountingMessages.priceOutTip, {
                      price: intl.formatNumber(form.getFieldValue('unitPriceOriginal'), {
                        style: 'currency',
                        currency: 'NOK',
                      }),
                    })}
                  </Typography.Text>
                )}
              </Flex>
            }
          >
            <InputNumber
              precision={2}
              min={0}
              onChange={(value) => {
                handleChangePriceOut(value);
              }}
              style={{ width: '100%' }}
            />
          </Form.Item>

          <Form.Item name={'quantity'} label={'Quantity'} rules={[{ required: true }]}>
            <InputNumber<number>
              precision={2}
              step={quantityStep}
              style={{ width: '100%' }}
              onChange={(value) => {
                handleChangeQuantity(value);
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={24} lg={8}>
          <Form.Item label={'Date'} name={'parsedDate'} rules={[{ required: true }]}>
            <DatePicker style={{ width: '100%' }} allowClear={false} />
          </Form.Item>

          <Form.Item
            name={'userId'}
            label={intl.formatMsg(ticketAccountingMessages.col_user)}
            rules={[{ required: true }]}
          >
            <UserSelect />
          </Form.Item>

          <Form.Item
            label={intl.formatMsg(ticketAccountingMessages.col_sum)}
            style={{ width: '100%' }}
            name={'sum'}
          >
            <InputNumber style={{ width: '100%' }} readOnly={true} precision={2} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={20}>
          <Form.Item name={'description'} label={'Note'}>
            <TextArea variant={'outlined'} size={'large'} rows={4} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col>
          <Space>
            <Button
              type={'primary'}
              icon={<SaveOutlined />}
              htmlType={'submit'}
              loading={isSaving}
              disabled={isSaving}
            >
              {intl.formatMsg(commonMessages.save)}
            </Button>
            <Button icon={<CloseOutlined />} onClick={handleCancel} disabled={isSaving}>
              {intl.formatMsg(commonMessages.cancel)}
            </Button>
          </Space>
        </Col>
      </Row>
    </TypedTicketOrderLineForm>
  );
};

export default OrderLineForm;
