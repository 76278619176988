import React from 'react';
import MachineNav from 'layouts/components/MachineNav/MachineNav';
import { Drawer } from 'antd';
import { useAppDispatch, useAppSelector } from 'redux/store.ts';
import { closeMobileSearchDrawer, selectIsMobileSearchVisible } from 'redux/machineNavSlice.ts';

const ScopeDrawerMobile: React.FC = () => {
  const dispatch = useAppDispatch();
  const isMobileSearchVisible = useAppSelector(selectIsMobileSearchVisible);

  return (
    <Drawer
      title={'Scope'}
      placement={'right'}
      onClose={() => dispatch(closeMobileSearchDrawer())}
      open={isMobileSearchVisible}
      width={'85%'}
      styles={{
        body: {
          padding: 0,
        },
      }}
    >
      <MachineNav />
    </Drawer>
  );
};

export default ScopeDrawerMobile;
