import { gql } from '@apollo/client/core';
import { ConnectAppThemeFragmentDoc, MachineNavFiltersFragmentDoc } from 'generated/types';

gql`
  mutation UpdateMachineNavFilters($input: UpdateConnectAppFiltersInput!) {
    updateConnectAppFilters(input: $input) {
      user {
        connectAppSettings {
          ...MachineNavFilters
        }
      }
    }
  }
  ${MachineNavFiltersFragmentDoc}
`;

export const UPDATE_THEME = gql`
  mutation UpdateTheme($theme: Theme!) {
    updateConnectAppTheme(input: { theme: $theme }) {
      user {
        connectAppSettings {
          ...ConnectAppTheme
        }
      }
    }
  }
  ${ConnectAppThemeFragmentDoc}
`;
