import { SortDirection, TicketSeverity, TicketSortField, TicketStatus } from 'generated/types';
import useQueryParam from 'hooks/useQueryParam';
import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  unassigned: {
    id: 'ticket_assignee_filter_select.unassigned',
    defaultMessage: 'Unassigned',
  },
});

export interface TicketFilter {
  closedFrom?: string;
  closedTo?: string;
  createdFrom?: string;
  createdTo?: string;
  search?: string;
  statuses: TicketStatus[];
  assignedToUserIds?: string[];
  includeUnassignedUsers?: boolean; // If true, include rows where assignedToUserId is null
  ticketTypeIds?: number[];
  severities?: TicketSeverity[];
  sortField: TicketSortField;
  sortDirection: SortDirection;
  tags?: string[];
  dueTo?: string;
}

const TICKET_FILTER_KEY = 'tf';

const defaultFilter: TicketFilter = {
  statuses: [TicketStatus.Open],
  sortField: TicketSortField.Opened,
  sortDirection: SortDirection.Descending,
};

function useTicketFilter() {
  const [filterParam, setFilterParam] = useQueryParam<TicketFilter>(TICKET_FILTER_KEY);
  const ticketFilter = filterParam || defaultFilter;
  const setTicketFilter = (value: Partial<TicketFilter>) => {
    setFilterParam({
      ...defaultFilter,
      ...filterParam,
      ...value,
    });
  };

  return {
    ticketFilter,
    setTicketFilter,
  };
}

export default useTicketFilter;
