import React from 'react';
import { motion, useMotionValue, useTransform, animate } from 'framer-motion';
import { useEffect } from 'react';

const Counter: React.FC<{ from: number; to: number }> = ({ from, to }) => {
  const count = useMotionValue(from);
  const rounded = useTransform(count, (latest: number) => Math.round(latest));

  useEffect(() => {
    const controls = animate(count, to, { duration: 0.35 });
    return controls.stop;
  }, [count, to]);

  return <motion.span>{rounded}</motion.span>;
};

export default Counter;
