import React from 'react';

const VendanorButterfly: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 170 298"
    {...props}
  >
    <path
      fill="#FFE76B"
      d="m79.569 251.425-57.79-31.126C10.069 213.965.503 197.881.503 184.563v-64.55c0-12.592 13.253-19.259 23.393-13.968l47.71 25.699c1.526.847 7.964 5.17 7.964 13.787v105.894Z"
    />
    <path
      fill="#FFD730"
      d="m79.569 150.716-57.79 31.126C10.069 188.176.503 204.26.503 217.578v64.655c0 11.338 12.301 19.622 23.242 13.938l47.725-25.593c4.095-2.283 8.1-7.362 8.1-13.56V150.716Z"
    />
    <path
      fill="#FDB913"
      d="m21.78 181.842 57.789-31.126v100.709l-57.79-31.126c-7.026-3.809-13.268-11.126-17.136-19.229 3.883-8.118 10.125-15.434 17.137-19.228"
    />
    <path
      fill="#87D1D4"
      d="m90.933 46.575 57.789 31.126C160.434 84.035 170 100.12 170 113.437v64.55c0 12.592-13.254 19.259-23.394 13.968l-47.709-25.699c-1.526-.846-7.964-5.17-7.964-13.787V46.575Z"
    />
    <path
      fill="#00AAAF"
      d="m90.933 147.284 57.789-31.126C160.434 109.824 170 93.74 170 80.422v-64.64c0-11.338-12.301-19.622-23.243-13.938L99.033 27.437c-4.095 2.283-8.1 7.362-8.1 13.56v106.287Z"
    />
    <path
      fill="#00BABC"
      d="m148.722 116.159-57.789 31.125V46.575l57.789 31.126c7.027 3.81 13.268 11.126 17.137 19.229-3.884 8.118-10.125 15.434-17.137 19.229Z"
    />
    <path
      fill="#87D1D4"
      d="m90.933 46.575 57.789 31.126C160.434 84.035 170 100.12 170 113.437v64.55c0 12.592-13.254 19.259-23.394 13.968l-47.709-25.699c-1.526-.846-7.964-5.17-7.964-13.787V46.575Z"
    />
    <path
      fill="#00A7AC"
      d="m90.933 147.284 57.789-31.126C160.434 109.824 170 93.74 170 80.422v-64.64c0-11.338-12.301-19.622-23.243-13.938L99.033 27.437c-4.095 2.283-8.1 7.362-8.1 13.56v106.287Z"
    />
    <path
      fill="#00B4B8"
      d="m148.722 116.159-57.789 31.125V46.575l57.789 31.126c7.027 3.81 13.268 11.126 17.137 19.229-3.884 8.118-10.125 15.434-17.137 19.229Z"
    />
    <path
      fill="#FFE76B"
      d="m79.569 251.425-57.79-31.126C10.069 213.965.503 197.881.503 184.563v-64.55c0-12.592 13.253-19.259 23.393-13.968l47.71 25.699c1.526.847 7.964 5.17 7.964 13.787v105.894Z"
    />
    <path
      fill="#FFD730"
      d="m79.569 150.716-57.79 31.126C10.069 188.176.503 204.26.503 217.578v64.655c0 11.338 12.301 19.622 23.242 13.938l47.725-25.593c4.095-2.283 8.1-7.362 8.1-13.56V150.716Z"
    />
    <path
      fill="#FDB913"
      d="m21.78 181.842 57.789-31.126v100.709l-57.79-31.126c-7.026-3.809-13.268-11.126-17.136-19.229 3.883-8.118 10.125-15.434 17.137-19.228"
    />
  </svg>
);
export const VendanorButterflySquare: React.FC<
  React.SVGProps<SVGSVGElement>
> = (props) => (
  <svg
    width="1em"
    height="1em"
    preserveAspectRatio={'xMidYMid meet'}
    viewBox="0 0 1024 1024"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1319_7750)">
      <path
        d="M492.758 858.43L297.057 753.023C257.395 731.574 225 677.104 225 632.003V413.409C225 370.765 269.882 348.189 304.222 366.106L465.787 453.134C470.956 456.001 492.758 470.642 492.758 499.822V858.379V858.43Z"
        fill="#FFE76B"
      />
      <path
        d="M492.758 517.379L297.057 622.785C257.395 644.235 225 698.705 225 743.806V962.759C225 1001.15 266.658 1029.21 303.71 1009.96L465.327 923.289C479.196 915.559 492.758 898.358 492.758 877.369V517.379Z"
        fill="#FFD730"
      />
      <path
        d="M297.057 622.785L492.758 517.379V858.427L297.057 753.021C273.26 740.12 252.124 715.343 239.023 687.903C252.175 660.412 273.311 635.635 297.057 622.785"
        fill="#FDB913"
      />
      <path
        d="M531.243 164.709L726.943 270.116C766.605 291.565 799 346.035 799 391.136V609.73C799 652.374 754.118 674.95 719.778 657.033L558.213 570.005C553.044 567.138 531.243 552.497 531.243 523.317V164.709Z"
        fill="#87D1D4"
      />
      <path
        d="M531.243 505.759L726.943 400.352C766.605 378.902 799 324.433 799 279.332V60.4303C799 22.0355 757.342 -6.0183 720.29 13.2303L558.673 99.9002C544.804 107.63 531.243 124.831 531.243 145.82V505.759Z"
        fill="#00AAAF"
      />
      <path
        d="M726.943 400.351L531.243 505.757V164.709L726.943 270.116C750.74 283.016 771.876 307.794 784.978 335.233C771.825 362.724 750.689 387.501 726.943 400.351Z"
        fill="#00BABC"
      />
      <path
        d="M531.243 164.709L726.943 270.116C766.605 291.565 799 346.035 799 391.136V609.73C799 652.374 754.118 674.95 719.778 657.033L558.213 570.005C553.044 567.138 531.243 552.497 531.243 523.317V164.709Z"
        fill="#87D1D4"
      />
      <path
        d="M531.243 505.759L726.943 400.352C766.605 378.902 799 324.433 799 279.332V60.4303C799 22.0355 757.342 -6.0183 720.29 13.2303L558.673 99.9002C544.804 107.63 531.243 124.831 531.243 145.82V505.759Z"
        fill="#00A7AC"
      />
      <path
        d="M726.943 400.351L531.243 505.757V164.709L726.943 270.116C750.74 283.016 771.876 307.794 784.978 335.233C771.825 362.724 750.689 387.501 726.943 400.351Z"
        fill="#00B4B8"
      />
      <path
        d="M492.758 858.43L297.057 753.023C257.395 731.574 225 677.104 225 632.003V413.409C225 370.765 269.882 348.189 304.222 366.106L465.787 453.134C470.956 456.001 492.758 470.642 492.758 499.822V858.379V858.43Z"
        fill="#FFE76B"
      />
      <path
        d="M492.758 517.379L297.057 622.785C257.395 644.235 225 698.705 225 743.806V962.759C225 1001.15 266.658 1029.21 303.71 1009.96L465.327 923.289C479.196 915.559 492.758 898.358 492.758 877.369V517.379Z"
        fill="#FFD730"
      />
      <path
        d="M297.057 622.785L492.758 517.379V858.427L297.057 753.021C273.26 740.12 252.124 715.343 239.023 687.903C252.175 660.412 273.311 635.635 297.057 622.785"
        fill="#FDB913"
      />
    </g>
    <defs>
      <clipPath id="clip0_1319_7750">
        <rect width="1024" height="1024" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default VendanorButterfly;
