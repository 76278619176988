import React from 'react';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { ResponsiveContext } from 'layouts/responsive/responsiveContext';

const ResponsiveProvider = ResponsiveContext.Provider;

/***
 * This wraps the useBreakpoint() hook from Ant Design, but doesn't return undefined on first render.
 * (by removing SSR support)
 *
 * Default ant design breakpoints
 *                      isMobile
 *   xs: '480px',       true
 *   sm: '576px',       false
 *   md: '768px',       false
 *   lg: '992px',       false
 *   xl: '1200px',      false
 *   xxl: '1600px',     false
 *
 * @param props
 * @constructor
 */
const ResponsiveGate: React.FC<{
  loader?: React.ReactNode;
  children?: React.ReactNode;
}> = (props) => {
  const breakpoint = useBreakpoint();
  const canLiftGate = breakpoint.lg !== undefined;
  const isMobile = breakpoint.xs === true && breakpoint.md === false;

  return canLiftGate ? (
    <ResponsiveProvider
      value={{
        screenMap: {
          xs: breakpoint.xs || false,
          sm: breakpoint.sm || false,
          md: breakpoint.md || false,
          lg: breakpoint.lg || false,
          xl: breakpoint.xl || false,
          xxl: breakpoint.xxl || false,
        },
        isMobile,
        isDesktop: !isMobile,
      }}
    >
      {props.children}
    </ResponsiveProvider>
  ) : (
    props.loader || null
  );
};

export default ResponsiveGate;
