import { MachineSearchIcon } from 'components/icons/Icons';
import { Button } from 'antd';
import React from 'react';
import { openMobileSearchDrawer } from 'redux/machineNavSlice.ts';
import { useAppDispatch } from 'redux/store.ts';

const SearchButtonMobile: React.FC = () => {
  const dispatch = useAppDispatch();

  return (
    <Button
      type={'primary'}
      icon={<MachineSearchIcon />}
      onClick={() => {
        dispatch(openMobileSearchDrawer());
      }}
    />
  );
};

export default SearchButtonMobile;
