import { ServerParseError } from '@apollo/client/link/http';
import { ServerError } from '@apollo/client/link/utils';
import { ApolloError } from '@apollo/client';
import { isArray } from 'lodash-es';

// NOTE: Error codes aligned with backend, HC defaults + custom
const AUTH_NOT_AUTHORIZED = 'AUTH_NOT_AUTHORIZED';
const AUTH_NO_DEFAULT_POLICY = 'AUTH_NO_DEFAULT_POLICY';
const AUTH_POLICY_NOT_FOUND = 'AUTH_POLICY_NOT_FOUND';
const NOT_FOUND = 'NOT_FOUND';

// HotChocolate error response
interface HotChocolateErrorResult {
  errors: Array<{
    message: string;
    extensions?: {
      code?: string;
      message?: string;
    };
    path: string[];
  }>;
}

// Type guard for ServerError
export function isServerError(
  networkError: Error | ServerParseError | ServerError | null | undefined
): networkError is ServerError {
  return networkError !== null && networkError !== undefined && 'statusCode' in networkError;
}

function isHcResult(
  //eslint-disable-next-line
  result: Record<string, any> | string
): result is HotChocolateErrorResult {
  if (typeof result === 'string') {
    return false;
  }
  return 'errors' in result && isArray(result.errors);
}

export function getFriendlyApolloErrorMessages(
  error: ApolloError,
  includeOuterMessage = false
): string[] {
  if (isServerError(error.networkError)) {
    if (isHcResult(error.networkError.result)) {
      const friendlyMessages = error.networkError.result.errors.map((e) => {
        if (e.extensions?.message) {
          return e.extensions.message;
        }
        if (e.extensions?.code) {
          return e.extensions.code;
        }
        return e.message;
      });
      return includeOuterMessage ? [error.message, ...friendlyMessages] : friendlyMessages;
    }
  }

  return [error.message];
}

export function getFriendlyApolloErrorMessage(error: unknown, prefixMessage?: string) {
  if (error instanceof ApolloError) {
    return `${prefixMessage || ''}${getFriendlyApolloErrorMessages(error, false).join('\n')}`;
  } else {
    return prefixMessage;
  }
}

export function isUnauthorizedApolloError(error: ApolloError | undefined) {
  if (error === undefined) {
    return false;
  }
  if (isServerError(error.networkError)) {
    if (isHcResult(error.networkError.result)) {
      return error.networkError.result.errors.some(
        (e) =>
          e.extensions?.code === AUTH_NOT_AUTHORIZED ||
          e.extensions?.code === AUTH_NO_DEFAULT_POLICY ||
          e.extensions?.code === AUTH_POLICY_NOT_FOUND
      );
    }
  }
}

export function isNotFoundError(error: ApolloError | undefined) {
  if (error === undefined) {
    return false;
  }

  if (isServerError(error.networkError)) {
    if (isHcResult(error.networkError.result)) {
      return error.networkError.result.errors.some((e) => e.extensions?.code === NOT_FOUND);
    }
  }

  return false;
}

export function getStatusCode(error: ApolloError | undefined): 403 | 404 | 500 | undefined {
  if (error === undefined) {
    return undefined;
  }

  if (isServerError(error.networkError)) {
    if (
      error.networkError.statusCode === 403 ||
      error.networkError.statusCode === 404 ||
      error.networkError.statusCode === 500
    ) {
      return error.networkError.statusCode;
    }
  }

  return undefined;
}
