import 'symbol-observable';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { store } from 'redux/store';
import { Provider as ReduxProvider } from 'react-redux';
import { apolloClient } from 'graphql/apollo/apolloClient';
import { ApolloProvider } from '@apollo/client';
import { HelmetProvider } from 'react-helmet-async';
import ResponsiveGate from 'layouts/responsive/ResponsiveGate';
import { initApp } from 'redux/appSlice.ts';
import AppProvider from 'pages/util/AppProvider/AppProvider.tsx';
import LocalThemeProvider from 'pages/util/AppProvider/LocalThemeProvider.tsx';

// NOTE: Placed here to ensure that we only dispatch the initApp action once
store.dispatch(initApp());
const root = createRoot(document.getElementById('root')!);
root.render(
  <ReduxProvider store={store}>
    <ApolloProvider client={apolloClient}>
      <HelmetProvider>
        <LocalThemeProvider>
          <AppProvider>
            <ResponsiveGate>
              <App />
            </ResponsiveGate>
          </AppProvider>
        </LocalThemeProvider>
      </HelmetProvider>
    </ApolloProvider>
  </ReduxProvider>
);
