import React from 'react';
import { AntIconComplete } from 'components/icons/antIconComplete';
import Icon from '@ant-design/icons';

const TicketOpenSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 1024 1024'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#a948173)' fill='currentColor'>
      <path d='M512 608a95.996 95.996 0 0 0 67.882-28.118 95.996 95.996 0 0 0 0-135.764 95.996 95.996 0 0 0-135.764 0 95.996 95.996 0 0 0 0 135.764A95.996 95.996 0 0 0 512 608Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M512 0A512.002 512.002 0 0 0 0 512a512.002 512.002 0 0 0 512 512 512.003 512.003 0 0 0 362.039-149.961 512.003 512.003 0 0 0 0-724.078A512.002 512.002 0 0 0 512 0ZM96 512a415.996 415.996 0 0 1 121.844-294.156A415.996 415.996 0 0 1 512 96a415.996 415.996 0 0 1 294.156 121.844A415.996 415.996 0 0 1 928 512a415.996 415.996 0 0 1-121.844 294.156A415.996 415.996 0 0 1 512 928a415.996 415.996 0 0 1-294.156-121.844A415.996 415.996 0 0 1 96 512Z'
      />
    </g>
    <defs>
      <clipPath id='a948173'>
        <path fill='currentColor' d='M0 0h1024v1024H0z' />
      </clipPath>
    </defs>
  </svg>
);

export const TicketOpenIcon: React.FC<AntIconComplete> = (props) => (
  <Icon {...props} component={TicketOpenSvg} />
);

const TicketReopenSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 1024 1024'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M512 608a95.996 95.996 0 0 0 67.882-28.118 95.996 95.996 0 0 0 0-135.764 95.996 95.996 0 0 0-135.764 0 95.996 95.996 0 0 0 0 135.764A95.996 95.996 0 0 0 512 608Z'
      fill='currentColor'
    />
    <path
      d='M283.385 157.259c142.141-91.157 323.614-87.203 460.344 2.289l-42.247 5.203c-23.308 2.914-39.749 24.142-37.044 47.451 2.706 21.437 21.02 37.254 42.039 37.254 1.665 0 150.673-18.315 150.673-18.315 11.238-1.456 21.436-7.076 28.304-16.025 6.867-8.949 9.989-20.188 8.532-31.426L875.88 38.214c-2.913-23.31-24.349-39.96-47.449-36.838-23.309 2.914-39.75 24.142-36.836 47.452l5.619 44.33C632.181-19.02 410.541-25.472 237.6 85.665 35.523 215.324-48.554 471.73 37.604 695.458c6.452 16.858 27.471 33.716 54.942 24.35 22.268-7.7 32.882-33.091 24.349-54.943-71.59-186.268-1.665-399.592 166.49-507.606Zm703.003 171.283c-6.452-16.858-27.471-33.716-54.942-24.35-22.268 7.7-32.881 33.091-24.349 54.944 71.799 186.267 1.665 399.591-166.49 507.605-142.14 91.157-323.614 87.203-460.344-2.289l42.247-5.203c23.309-2.914 39.749-24.142 37.044-47.451-2.705-21.437-21.019-37.254-42.039-37.254-1.665 0-150.673 18.315-150.673 18.315-11.238 1.456-21.435 7.076-28.303 16.025-6.868 8.949-9.99 20.188-8.533 31.426l18.106 145.476c2.914 23.314 24.349 39.964 47.45 36.834 23.308-2.91 39.749-24.138 36.835-47.448l-5.619-44.33c165.242 111.968 386.881 118.418 560.03 7.285 201.661-129.451 285.742-385.856 199.58-609.585Z'
      fill='currentColor'
    />
  </svg>
);

export const TicketReopenIcon: React.FC<AntIconComplete> = (props) => (
  <Icon {...props} component={TicketReopenSvg} />
);

const TicketCloseSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 1024 1024'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M721.92 433.92a48 48 0 0 0-67.84-67.84L464 556.16l-94.08-94.08a48 48 0 0 0-67.84 67.84l128 128a48 48 0 0 0 67.84 0l224-224Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1024 512a512.003 512.003 0 0 1-149.961 362.039 512.003 512.003 0 0 1-724.078 0A512.002 512.002 0 0 1 512 0a512.002 512.002 0 0 1 512 512Zm-96 0a415.996 415.996 0 0 1-121.844 294.156A415.996 415.996 0 0 1 512 928a415.996 415.996 0 0 1-294.156-121.844A415.996 415.996 0 0 1 96 512a415.996 415.996 0 0 1 121.844-294.156A415.996 415.996 0 0 1 512 96a415.996 415.996 0 0 1 294.156 121.844A415.996 415.996 0 0 1 928 512Z'
      fill='currentColor'
    />
  </svg>
);

export const TicketCloseIcon: React.FC<AntIconComplete> = (props) => (
  <Icon {...props} component={TicketCloseSvg} />
);

const TicketCloseAsNotCompletedSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 1024 1024'
    width='1em'
    height='1em'
  >
    <g clipPath='url(#clip0_1845_1187)'>
      <g clipPath='url(#clip1_1845_1187)'>
        <path
          fill='currentColor'
          d='M512 0a512.002 512.002 0 0 1 512 512 512.003 512.003 0 0 1-149.961 362.039 512.003 512.003 0 0 1-724.078 0A512.002 512.002 0 0 1 512 0ZM96 512a415.996 415.996 0 0 0 121.844 294.156A415.996 415.996 0 0 0 512 928a415.996 415.996 0 0 0 294.156-121.844A415.996 415.996 0 0 0 928 512a415.996 415.996 0 0 0-121.844-294.156A415.996 415.996 0 0 0 512 96a415.996 415.996 0 0 0-294.156 121.844A415.996 415.996 0 0 0 96 512Zm625.92-142.08-352 352a47.943 47.943 0 0 1-47.002 13.824 47.937 47.937 0 0 1-34.598-34.688 47.938 47.938 0 0 1 13.76-46.976l352-352a48.064 48.064 0 0 1 66.688 1.152 48.063 48.063 0 0 1 1.152 66.688Z'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_1845_1187'>
        <path fill='currentColor' d='M0 0h1024v1024H0z' />
      </clipPath>
      <clipPath id='clip1_1845_1187'>
        <path fill='currentColor' d='M0 0h1024v1024H0z' />
      </clipPath>
    </defs>
  </svg>
);

export const TicketCloseAsNotCompletedIcon: React.FC<AntIconComplete> = (props) => (
  <Icon {...props} component={TicketCloseAsNotCompletedSvg} />
);
