import React, { CSSProperties, useMemo } from 'react';
import { Tabs, TabsProps } from 'antd';
import styled from 'styled-components';
import { ScreenMap } from 'antd/es/_util/responsiveObserver';
import { pagePadding } from 'components/lib/Page/pagePadding';
import { useScreenInfo } from 'layouts/responsive/useScreenInfo';

const Container = styled.div<{
  breakpoint: ScreenMap;
}>`
  flex: auto;
  display: flex;
  flex-direction: column;

  &&& {
    .ant-tabs-top {
      flex: auto;
      background-color: ${(props) => props.theme.ant.colorBgContainer};
    }

    .ant-tabs-nav {
      background-color: ${(props) => props.theme.ant.colorBgContainer};
      ${pagePadding};
      margin-bottom: 0; // disable default margin 16 px bottom tabs.. tabs pane content need to handle it
    }

    .ant-tabs-content-holder {
      background-color: ${(props) => props.theme.ant.colorBgLayout};
      display: flex;
      flex-direction: column;
    }

    .ant-tabs-content {
      flex: auto;
      display: flex;
      flex-direction: column;
    }

    .ant-tabs-tabpane-active {
      flex: auto;
      display: flex;
      flex-direction: column;
    }
  }
`;

interface Props {
  containerStyle?: CSSProperties;
}

const PageTabs: React.FC<Props & TabsProps> = (props) => {
  const { containerStyle, items, activeKey, ...rest } = props;
  const { screenMap: breakpoint } = useScreenInfo();

  // const hasSelectedDisabledTab = props.activeKey && (items || []).find((item) => item.key === props.activeKey && item.disabled);
  // memoize the above line:
  const hasSelectedDisabledTab = useMemo(
    () => props.activeKey && (items || []).find((item) => item.key === props.activeKey && item.disabled),
    [items, props.activeKey]
  );

  // NOTE: This will hide all disabled tabs
  const enabledTabs = useMemo(() => (items || []).filter((item) => !item.disabled), [items]);

  // NOTE: This will select the default active key if the active key is disabled
  // Example scenario:
  // - User has access to tab "tickets" for retailer A, but not for retailer B
  // - User navigates to retailer B, and the selected tab is "tickets"
  // - This will override the selected tab to be "operational" (or whatever the defaultActiveKey is) instead, but not change the url
  const actualActiveKey = hasSelectedDisabledTab ? props.defaultActiveKey : activeKey;

  return (
    <Container breakpoint={breakpoint} style={containerStyle}>
      <Tabs
        type={'line'}
        animated={{ inkBar: false, tabPane: false }}
        activeKey={actualActiveKey}
        items={enabledTabs}
        {...rest}
      />
    </Container>
  );
};

export default PageTabs;
