import { Locale as DateFnsLocale } from 'date-fns';
import { SupportedLocaleKeys } from 'i18n/supportedLocaleKeys.ts';

const loadedLocales: Record<string, DateFnsLocale> = {};

export async function loadDateFnsLocale(key: SupportedLocaleKeys): Promise<DateFnsLocale> {
  if (loadedLocales[key]) {
    return loadedLocales[key];
  }

  // NOTE: We can't use dynamic import() here because it's not supported by vite
  let importedLocale;

  switch (key) {
    case 'da':
      importedLocale = (await import('date-fns/locale/da')).da;
      break;
    case 'de':
      importedLocale = (await import('date-fns/locale/de')).de;
      break;
    case 'et':
      importedLocale = (await import('date-fns/locale/et')).et;
      break;
    case 'en':
      importedLocale = (await import('date-fns/locale/en-US')).enUS;
      break;
    case 'fr':
      importedLocale = (await import('date-fns/locale/fr')).fr;
      break;
    case 'nl':
      importedLocale = (await import('date-fns/locale/nl')).nl;
      break;
    case 'no':
      importedLocale = (await import('date-fns/locale/nb')).nb;
      break;
    case 'fi':
      importedLocale = (await import('date-fns/locale/fi')).fi;
      break;
    case 'sv':
      importedLocale = (await import('date-fns/locale/sv')).sv;
      break;
    default:
      throw new Error(`Unsupported locale: ${key}`);
  }

  loadedLocales[key] = importedLocale;
  return importedLocale;
}
