import {
  Drawer,
  Image,
  Input,
  List,
  message,
  Space,
  Spin,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { QuestionOutlined, SearchOutlined } from '@ant-design/icons';
import { useDebouncedCallback } from 'use-debounce';
import { gql } from '@apollo/client/core';
import groupItemsBy from 'components/util/groupItemsBy';
import enumKeys from 'components/util/enumKeys';
import {
  MinimalPageInfoFragmentDoc,
  ProductCategory,
  RetailerProductListItemSmallFragment,
  RetailerProductListItemSmallFragmentDoc,
  useGetRetailerProductsQuery,
  useToggleRetailerProductMutation,
} from 'generated/types';
import { useIntl } from 'react-intl';
import { retailerProductMessages } from 'components/retailer/RetailerProductCard/retailerProductMessages';
import Flag from 'components/i18n/Flag/Flag';
import styled from 'styled-components';
import { getFriendlyApolloErrorMessage } from 'graphql/apollo/apolloErrorUtil.ts';
import useRetailerDeployTools from 'components/retailer/useRetailerDeployTools.ts';

interface Props {
  retailerId?: number;
  open: boolean;
  onClose?: () => void;
}

gql`
  query GetRetailerProducts(
    $retailerId: Int!
    $cursor: String
    $filter: RetailerProductFilterInput!
  ) {
    retailer(retailerId: $retailerId) {
      id
      retailerId
      name
      products(sortField: CATEGORY_AND_PRODUCT_ID, first: 50, after: $cursor, filter: $filter) {
        totalCount
        pageInfo {
          ...MinimalPageInfo
        }
        edges {
          cursor
          node {
            ...RetailerProductListItemSmall
          }
        }
      }
    }
  }
  ${RetailerProductListItemSmallFragmentDoc}
  ${MinimalPageInfoFragmentDoc}
`;

gql`
  mutation ToggleRetailerProduct($input: ToggleRetailerProductInput!) {
    toggleRetailerProduct(input: $input) {
      retailerProduct {
        id
        productId
        retailerId
        enabled
      }
    }
  }
`;

const NameAndDescriptionContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const SecondLine = styled.div<{ $isMobile?: boolean }>`
  width: 100%;
  display: grid;
  //grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
  grid-template-columns: 64px 1fr;
  grid-gap: ${(props) => (props.$isMobile ? 4 : 8)}px;
`;

const SelectRetailerProductDrawer: React.FC<Props> = ({ open, retailerId, onClose }) => {
  const [search, setSearch] = useState('');
  const [searchTerm, setSearchTerm] = useState(''); // debounced search term, pass to query

  const skip = !open || !retailerId;
  const intl = useIntl();

  const { data, loading, fetchMore } = useGetRetailerProductsQuery({
    variables: retailerId
      ? {
          retailerId: retailerId,
          cursor: undefined,
          filter: {
            productSearchTerm: searchTerm,
          },
        }
      : undefined,
    skip,
  });

  const [toggle] = useToggleRetailerProductMutation();

  const { addPendingRetailerChange } = useRetailerDeployTools();

  const handleSearch = useCallback((term: string) => {
    setSearch(term);
  }, []);

  const handleSearchDebounced = useDebouncedCallback((value: string) => {
    setSearchTerm(value);
  }, 400);

  const [savingDict, setSavingDict] = useState<{ [key: string]: boolean }>({});
  const setSaving = useCallback((key: string, value: boolean) => {
    setSavingDict((dict) => ({ ...dict, [key]: value }));
  }, []);

  const handleClickSwitch = useCallback(
    async (checked: boolean, retailerProduct: RetailerProductListItemSmallFragment) => {
      try {
        setSaving(retailerProduct.id, true);
        addPendingRetailerChange(retailerProduct.retailerId);

        await toggle({
          variables: {
            input: {
              productId: retailerProduct.productId,
              enabled: checked,
              retailerId: retailerProduct.retailerId,
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            toggleRetailerProduct: {
              __typename: 'ToggleRetailerProductPayload',
              retailerProduct: {
                id: retailerProduct.id,
                __typename: 'RetailerProduct',
                productId: retailerProduct.productId,
                retailerId: retailerProduct.retailerId,
                enabled: checked,
              },
            },
          },
        });
      } catch (err: unknown) {
        message.error(getFriendlyApolloErrorMessage(err, 'Failed to enable/disable product'));
      } finally {
        setSaving(retailerProduct.id, false);
      }
    },
    [toggle, setSaving, addPendingRetailerChange]
  );

  const hasNextPage = data?.retailer.products?.pageInfo.hasNextPage || false;
  const nextCursor = data?.retailer.products?.pageInfo.endCursor;

  useEffect(() => {
    if (hasNextPage) {
      fetchMore({
        variables: {
          cursor: nextCursor,
          retailerId: retailerId,
          filter: {
            productSearchTerm: searchTerm,
          },
        },
      });
    }
  }, [hasNextPage, nextCursor, fetchMore, searchTerm, retailerId]);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const groupedData = useMemo(() => {
    const allEdges = data?.retailer.products?.edges;
    if (!allEdges) {
      return undefined;
    }

    const items = allEdges
      .map((c) => ({
        category: c.node.product.category,
        enabled: c.node.enabled,
        node: c.node,
        cursor: c.cursor,
        productId: c.node.productId,
      }))
      // Override the default sort order, always sort on productId!
      // TODO: improve? move sort to api?
      .sort((c1, c2) => {
        if (c1.productId > c2.productId) {
          return 1;
        }
        if (c1.productId < c2.productId) {
          return -1;
        }
        return 0;
      });
    return groupItemsBy(items, 'category', ProductCategory);
  }, [data?.retailer.products?.edges]);

  const productLists = [];
  for (const category of enumKeys(ProductCategory)) {
    const categoryValue = ProductCategory[category];
    const data = groupedData ? groupedData[categoryValue] : undefined;
    if (!data) {
      continue;
    }

    productLists.push(
      <List
        header={<Typography.Title level={5}>{category}</Typography.Title>}
        key={category}
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Switch
                checked={item.enabled}
                key={item.node.id}
                loading={savingDict[item.node.id] || false}
                onChange={(checked) => handleClickSwitch(checked, item.node)}
              />,
            ]}
          >
            <List.Item.Meta
              avatar={
                item.node.product.image.url ? (
                  <Image src={item.node.product.image.url} height={48} preview={false} />
                ) : (
                  <QuestionOutlined />
                )
              }
              title={
                <NameAndDescriptionContainer>
                  <div>
                    <Tooltip title={item.node.product.description}>
                      <Typography.Text>{item.node.nameDefault.concatenated}</Typography.Text>
                    </Tooltip>
                  </div>
                  <SecondLine>
                    <Typography.Text copyable={true}>{item.node.product.productId}</Typography.Text>
                    <Space size={'small'}>
                      {item.node.product.markets?.map((c) => (
                        <Flag country={c} key={c.id} showTooltip={true} />
                      ))}
                    </Space>
                  </SecondLine>
                </NameAndDescriptionContainer>
              }
            />
          </List.Item>
        )}
      />
    );
  }

  return (
    <Drawer
      open={open}
      title={intl.formatMessage(retailerProductMessages.drawerTitle, {
        retailer: data?.retailer.name || 'retailer',
      })}
      onClose={handleClose}
    >
      <Space direction={'vertical'} style={{ width: '100%' }}>
        <Input
          placeholder={'Filter...'}
          size={'middle'}
          prefix={<SearchOutlined />}
          value={search}
          onChange={(e) => {
            handleSearch(e.target.value);
            handleSearchDebounced(e.target.value);
          }}
          suffix={loading ? <Spin size={'small'} /> : null}
          allowClear={true}
        />

        {!loading && (
          <Typography.Text type={'secondary'}>
            {data?.retailer.products?.totalCount} products found
          </Typography.Text>
        )}
        {loading && <Typography.Text type={'secondary'}>Loading...</Typography.Text>}

        {productLists}
      </Space>
    </Drawer>
  );
};

export default SelectRetailerProductDrawer;
