import styled from 'styled-components';
import { Col, Row } from 'antd';
import ImageLogin from './login-colors.jpg';
import { Helmet } from 'react-helmet-async';
import React from 'react';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import loginMessages from 'layouts/LoginLayout/loginMessages.ts';
import { Outlet } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import fallbackRender from 'layouts/components/fallbackRender.tsx';

const ContainerRow = styled(Row)`
  && {
    background-color: ${(props) => props.theme.ant.colorBgLayout}};
    height: 100vh;
  }
`;

const FormCol = styled(Col)`
  && {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ImageCol = styled(Col)`
  && {
    background-image: url(${() => ImageLogin});
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 30%;
  }
`;

const LoginLayout: React.FC = () => {
  const intl = useConnectIntl();

  return (
    <ContainerRow gutter={[0, 0]}>
      <Helmet>
        <title>{intl.formatMsg(loginMessages.loginPageTitle)}</title>
      </Helmet>
      <Col xs={2} sm={4} lg={2} />
      <FormCol xs={20} sm={16} lg={6}>
        <Sentry.ErrorBoundary showDialog={false} fallback={fallbackRender}>
          <Outlet />
        </Sentry.ErrorBoundary>
      </FormCol>
      <Col xs={2} sm={4} lg={2} />
      <ImageCol xs={0} sm={0} lg={14} />
    </ContainerRow>
  );
};

export default LoginLayout;
