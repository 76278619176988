import React, { useEffect, useState } from 'react';
import { SearchProps } from 'antd/es/input/Search';
import useTicketFilter from 'components/ticket/TicketFilter/useTicketFilter.ts';
import { Input } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import { SearchOutlined } from '@ant-design/icons';
import useConnectIntl from 'i18n/useConnectIntl.ts';

type RestProps = Omit<SearchProps, 'onSearch'>;

const TicketSearchFilterInput: React.FC<RestProps> = (props) => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const { ticketFilter, setTicketFilter } = useTicketFilter();
  const intl = useConnectIntl();

  const handleSearchDebounced = useDebouncedCallback((value: string) => {
    setTicketFilter({ search: value });
  }, 500);

  useEffect(() => {
    setSearchTerm(ticketFilter.search);
  }, [ticketFilter.search]);

  return (
    <Input
      value={searchTerm}
      onChange={(event) => {
        setSearchTerm(event.target.value);
        handleSearchDebounced(event.target.value);
      }}
      addonAfter={<SearchOutlined />}
      placeholder={intl.formatMsg({
        id: 'ticket_search_filter_input.placeholder',
        defaultMessage: 'Title search',
      })}
      allowClear={true}
      {...props}
    />
  );
};

export default TicketSearchFilterInput;
