import React from 'react';
import { Tag as TagInternal, TagProps } from 'antd';
import styled, { css } from 'styled-components';

const StyledTag = styled(TagInternal)<{ $hidePadding?: boolean }>`
  && {
    border-radius: 16px; // rounder corners than default
    margin-right: 0;

    ${(props) =>
      props.color === 'warning' &&
      css`
        border-color: ${(props) => props.theme.ant.colorWarning};
      `}
    ${(props) =>
      props.color === 'warning' &&
      !props.theme.isDark &&
      css`
        color: ${(props) => props.theme.ant.colorText};
      `}
    ${(props) =>
      props.color === 'error' &&
      css`
        border-color: ${(props) => props.theme.ant.colorError};
      `}
    ${(props) =>
      props.color === 'success' &&
      css`
        border-color: ${(props) => props.theme.ant.colorSuccess};
        color: ${(props) => props.theme.ant.colorText};
      `}
    
    ${(props) =>
      props.color === 'info' &&
      css`
        border-color: ${(props) => props.theme.ant.colorInfo};
        color: ${(props) => props.theme.ant.colorInfo};
        background-color: rgba(55, 145, 162, 0.15);
      `}
  }
`;

/***
 * Styled Tag component, rounder corners etc.
 * @param props
 * @constructor
 */
const Tag: React.FC<TagProps> = (props) => {
  return <StyledTag {...props}>{props.children}</StyledTag>;
};

export default Tag;
