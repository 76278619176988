/***
 * Global layout for Connect App.
 */
export interface ConnectAppLayout {
  header: {
    heightMobile: string;
    heightDesktop: string;
  };
  page: {
    paddingMobile: string;
    paddingDesktop: string;
  };
}

const connectAppLayout: ConnectAppLayout = {
  header: {
    heightMobile: '48px',
    heightDesktop: '56px',
  },
  page: {
    paddingMobile: '16px',
    paddingDesktop: '8%',
  },
};

export default connectAppLayout;
