import React from 'react';
import PageContentRow from 'components/lib/Page/PageContentRow.tsx';
import Card from 'components/lib/Card/Card.tsx';
import { gql } from '@apollo/client/core';
import { useGetMeAndAllQuery } from 'generated/types.tsx';

gql`
  query GetMeAndAll {
    me {
      id
      name
      title
      emailAddress
      active
      eulaAccepted
      retailer {
        id
        retailerId
        name
      }
      permissions {
        canCreateRetailer
        canRedeployParameters
        canViewTicketOrderLines
        translationTool
        tabs {
          operational {
            users
            tickets
            integrations
            events
            properties
          }
        }
      }
      acl {
        role
        resourceType
        resourceId
        allow
      }
      connectAppSettings {
        appearance {
          theme
        }
        filters {
          showActiveMachines
          showInactiveRetailers
        }
      }
    }
  }
`;

const MePage: React.FC = () => {
  const { data } = useGetMeAndAllQuery();
  return (
    <>
      <PageContentRow style={{ paddingTop: 64 }}>
        <Card title={'Me'}>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </Card>
      </PageContentRow>
    </>
  );
};

export default MePage;
