import React, { useMemo } from 'react';
import { GlobalToken } from 'antd/es/theme/interface';
import connectAppLayout, { ConnectAppLayout } from 'themes/connectAppLayout';
import { ThemeProvider } from 'styled-components';
import { theme } from 'antd';
import { useConnectAppTheme } from 'themes/useConnectAppTheme';
import { Theme } from 'generated/types';
import { ConnectAppColors } from 'themes/connectAppColors';

const { useToken } = theme;

export interface StyledComponentsTheme {
  ant: GlobalToken;
  connect: {
    layout: ConnectAppLayout;
    colors: ConnectAppColors;
  };
  isDark: boolean;
}

const StyledComponentsThemeProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { token } = useToken(); // This is the complete token after theme algorithm is run
  const { connectAppTheme } = useConnectAppTheme();
  const { colorError, colorBorder } = token;

  const connectAppColors: ConnectAppColors = useMemo(
    () => ({
      colorBgSeverityA: '#6E0E0A',
      colorBgSeverityB: colorError,
      colorBgSeverityC: token.colorWarning,
      colorBgSeverityD: '#F1D302',
      tableFooterBackground: colorBorder,
    }),
    [colorError, token.colorWarning, colorBorder]
  );

  const scTheme: StyledComponentsTheme = useMemo(
    () => ({
      ant: token,
      isDark: connectAppTheme === Theme.Dark,
      connect: {
        layout: connectAppLayout,
        colors: connectAppColors,
      },
    }),
    [token, connectAppTheme, connectAppColors]
  );

  return <ThemeProvider theme={scTheme}>{children}</ThemeProvider>;
};

export default StyledComponentsThemeProvider;
