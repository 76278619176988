import React from 'react';
import { MachineType } from 'generated/types';
import {
  GasContainer247Icon,
  PropaneVendingMachineIcon,
  WasherFluidDispenserIcon,
} from 'components/icons/Icons';

export default function getMachineIcon(machineType: MachineType) {
  if (machineType === MachineType.PropaneVendingMachine) {
    return <PropaneVendingMachineIcon />;
  } else if (machineType === MachineType.WindshieldWasherFluidDispenser) {
    return <WasherFluidDispenserIcon />;
  } else if (machineType === MachineType.GasContainer247) {
    return <GasContainer247Icon />;
  } else if (machineType === MachineType.Alpilox) {
    return <GasContainer247Icon />;
  } else {
    return null;
  }
}
