import React from 'react';
import { useAppSelector } from 'redux/store';
import { selectIsAuthenticated } from 'auth/authSlice';
import { Navigate, useLocation } from 'react-router-dom';

interface Props {
  children?: React.ReactNode;
}

const RequireAuthenticated: React.FC<Props> = (props) => {
  const { children } = props;
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const location = useLocation();

  const navigatingToUrl: string = location.pathname;
  const redirectUrl =
    navigatingToUrl.length > 0
      ? navigatingToUrl.indexOf('/login') < 0
        ? '?redirect=' + encodeURIComponent(navigatingToUrl)
        : ''
      : '';
  const loginUrl = `/login/${redirectUrl}`;

  if (!isAuthenticated) {
    console.log('👮‍ [auth]> redirecting to login...');
    return <Navigate to={loginUrl} state={{ from: location }} />;
  }

  return children;
};

export default RequireAuthenticated;
