import React, { forwardRef } from 'react';
import { Card as AntCard, CardProps } from 'antd';
import styled from 'styled-components';
import { useScreenInfo } from 'layouts/responsive/useScreenInfo';

interface Props {
  alwaysShowCard?: boolean;
}

const MobileCard = styled(AntCard)`
  margin-left: -16px;
  margin-right: -16px;
  border: none;
  border-radius: 0;
`;

/***
 * ResponsiveListCard - helper component to get "Ralf style" lists
 * Render a Card component on desktop, simply pass children on mobile
 * @param props
 * @constructor
 */
const ResponsiveListCard = forwardRef<HTMLDivElement, CardProps & Props>((props, ref) => {
  const { children, alwaysShowCard, ...rest } = props;
  const { isMobile } = useScreenInfo();
  const showCard = alwaysShowCard || !isMobile;

  if (!showCard) {
    return (
      <MobileCard ref={ref} {...rest}>
        {children}
      </MobileCard>
    );
  }

  return (
    <AntCard ref={ref} {...rest}>
      {children}
    </AntCard>
  );
});

ResponsiveListCard.displayName = 'ResponsiveListCard';

export default ResponsiveListCard;
